import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';

// import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';


import { connect } from 'react-redux';

import {
  getFooterPage,
} from '../../../../actions/contentManagement';

class AppTandCPage extends Component {
  static propTypes = {
    fetchFooterPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    //  user: {},
    //  admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      content: null,
      editorState: EditorState.createEmpty(),
      fetchedEditorState: false,
    };
  }

  componentDidMount() {
    const { fetchFooterPage } = this.props;

    fetchFooterPage('terms').then((res) => {
      if (res && res.text) {
        let contentState = stateFromHTML(res.text);

        if (contentState) {
          this.setState({ fetchedEditorState: true, editorState: EditorState.createWithContent(contentState) });
        }

        this.setState({ fetchedEditorState: true, content: res.text });
      }
    });
  }

  render() {
    const {
      content,
      editorState,
    } = this.state;

    return (
      <div>
        <div class="breadcrumbs">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">Terms & Conditions</h1>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <ul class="breadcrumb-nav">
                  <li><a href="/">Home</a></li>
                  <li>Terms & Conditions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section class="about-us section">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 col-md-12 col-12">
                <Editor
                  className="content-management-text-area"
                  readOnly
                  toolbarHidden
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  //  user: state.user.userData || {},
});

const mapDispatchToProps = {
  fetchFooterPage: getFooterPage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppTandCPage));
