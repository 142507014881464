import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
// import { Eye, EyeOff } from 'react-feather';

import ReactWOW from 'react-wow';

// import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';

import { storage } from '../../../lib/firebase';

import CampaignCard from './Components/CampaignCard';

import {
  getVoucherList,
  getSingleVoucher,
  getVoucherCompanies,
  getVoucherCategories,
  doPayment,
} from '../../../actions/customer';

import { loginAppUser, getAppUserData } from '../../../actions/user';

import notifications from '../../../const/notifications';

class VouchersList extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    getVouchers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    const { match } = props;
    const categoryId = match && match.params && match.params.id ? match.params.id : null;

    console.log('categoryId', categoryId, match);

    this.state = {
      error: null,
      loading: false,
      enabled: false,
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      companyNotifications: [],
      companyInformation: '',
      selectedStep: 1,
      purchaseModal: false,
      showPassword1: 'password',
      activeCategory: categoryId || null,
    };
  }

  componentDidMount() {
    const { user, history, getVouchers, fetchVoucherCompanies, fetchVoucherCategories, fetchUserData } = this.props;

   // getVouchers();
   // fetchVoucherCompanies();
   // fetchVoucherCategories();

   // console.log('componentDidMount', user);

    // if (user && user.uid) {
   // fetchUserData();
    // }
  }

   /*
  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

 
  openPreview = async (voucherId) => {
    const { vouchersList, fetchSingleVoucher } = this.props;

    console.log('openPreview', voucherId, vouchersList[voucherId]);

    const { logo, image, images, discount, availableVouchers } = vouchersList[voucherId];

    fetchSingleVoucher(voucherId).then(async (res) => {
      console.log('fetchSingleVoucher', res.payload);

      const { companyData } = res.payload;

      console.log('companyData', companyData);

      const { primaryVoucherImage, logo } = companyData;

      let companyPrimaryVoucherImage;
      let logoImage;

      if (primaryVoucherImage && logo) {
        console.log('test aaaa');
        const primaryVoucherImageReference = storage.ref(primaryVoucherImage);
        const logoReference = storage.ref(logo);

        console.log('test bbbb');

        await primaryVoucherImageReference.getDownloadURL().then((url) => {
          companyPrimaryVoucherImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });

        console.log('test cccc');

        await logoReference.getDownloadURL().then((url) => {
          logoImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });

        this.setState({ previewModal: true, previewVoucher: vouchersList[voucherId], primaryVoucherImage: companyPrimaryVoucherImage || null, logo: logoImage || null, discount, availableVouchers });
      }
    });
  }

  closePreview = () => {
    this.setState({ previewModal: false, primaryVoucherImage: null, logo: null });
  }

  toggleReadMore = () => {
    this.setState((prevState) => ({ readMore: !prevState.readMore }))
  }

  togglePreviewStep = (step) => {
    this.setState({ previewStep: step });
  }

  openPurchaseModal = (selectedVoucher) => {
    const { selectedStep } = this.state;
    console.log('openPurchaseModal', selectedStep, selectedVoucher);

    this.setState({ purchaseModal: true, selectedVoucher });
  }

  closePurchaseModal = () => {
    console.log('closePurchaseModal');

    this.setState({ purchaseModal: false, selectedVoucher: null });
  }

  finishPayment = () => {
    const { doPaymentFunc } = this.props;
    const { selectedStep, selectedVoucher } = this.state;

    console.log('finishPayment', selectedStep, selectedVoucher);
  }

  changeStep = (step) => {
    console.log('changeStep', step);

    this.setState({ selectedStep: step });
  }

  changeActiveCategory = (id) => {
    const { getVouchers, history } = this.props;

    console.log('changeActiveCategory', id);

    this.setState({ activeCategory: id });

    history.push(`/categories/${id}`)
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = (e) => {
    e.preventDefault();

    const { login, history, fetchUserData, getVouchers, fetchVoucherCompanies, fetchVoucherCategories } = this.props;
    const { email, password, loading } = this.state;

    if (loading) return;

    this.setState({ loading: true });

    console.log('handleSubmit', email, password);

    return login({ email, password })
      .then((res) => {
        console.log('login then', { res });

        fetchUserData();

        getVouchers();
      //  fetchVoucherCompanies();
      //  fetchVoucherCategories();
      })
      .catch((err) => {
        console.log('login error', err);

        console.log(notifications.login);
        if (err === 'missing email') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noEmail || err });
        } else if (err === 'missing password') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noPassword || err });
        } else if (err === 'The email address is badly formatted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.badEmail || err });
        } else if (err === 'To access your account please click the verification link in the email sent to you.') {
          this.setState({ loading: false, errorGreen: true, error: notifications.login.needVerification || err });
        } else if (err === 'The password is invalid or the user does not have a password.' || 'There is no user record corresponding to this identifier. The user may have been deleted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.wrongLogin || err });
        } else {
          this.setState({ loading: false, errorGreen: false, error: err });
        }
      });
  }


  toggleShowPassword = (id) => {
    console.log('toggleShowPassword');
    console.log(id);
    if (id === 1) {
      //  this.setState((prevState) => ({ showPassword1: !prevState.showPassword1 }));

      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      //  this.setState((prevState) => ({ showPassword2: !prevState.showPassword2 }));
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  } */

  render() {
    const { user, vouchersList, companies, categories } = this.props;
    const {
      activeTab,
      dontShowPage,
      perks,
      perksTabTitle,

      previewModal,
      activeCategory,
    } = this.state;

    console.log('activeCategoryactiveCategory', activeCategory);

    return (
      <div style={{ marginBottom: 2 }}>
        <section className="items-tab section">
          <div className="container">
            <div className="row">
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-latest" role="tabpanel">
                  <div className="row">
                    {vouchersList ? Object.keys(vouchersList).map((key, index) => {
                      if (activeCategory && !vouchersList[key].categoryIds.includes(activeCategory)) { return null };

                      return (
                        <ReactWOW animation='fadeInUp' delay=".4s">
                          <div className="col-lg-4 col-md-6 col-12">
                            <CampaignCard key={key} vouchersList={vouchersList} voucher={vouchersList[key]} voucherId={key} />
                          </div>
                        </ReactWOW>
                      );
                    }) : null}
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="pagination left">
                      <ul class="pagination-list">
                        <li>1</li>
                        <li class="active">2</li>
                        <li>3</li>
                        <li>4</li>
                        <li><i class="lni lni-chevron-right"></i></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );

    /*
    return (
      <div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div>
              <CategoriesList categories={categories} changeActiveCategory={this.changeActiveCategory} activeCategory={activeCategory} />

              <PopularVouchers vouchersList={vouchersList} activeCategory={activeCategory} />

              <HowItWorks />

              <AllVouchers vouchersList={vouchersList} activeCategory={activeCategory} />
            </div>
          </TabPane>
        </TabContent>

      </div>
    ); */
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  vouchersList: state.customer.list || {},
  companies: state.customer.companies || {},
  categories: state.customer.categories || {},
});

const mapDispatchToProps = {
  getVouchers: getVoucherList,
  fetchSingleVoucher: getSingleVoucher,
  fetchVoucherCompanies: getVoucherCompanies,
  fetchVoucherCategories: getVoucherCategories,
  doPaymentFunc: doPayment,
  login: loginAppUser,
  fetchUserData: getAppUserData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VouchersList));