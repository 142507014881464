import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { PersistGate } from 'redux-persist/integration/react';

import DashboardLayout from './layouts/DashboardLayout';
import './vibe/scss/styles.scss';
import './scss/styles.scss';

// import { store, persistor } from './store/store';

import { store, persistor } from './store/store';

console.log('store', store);
// import configureStore from './store/store';

// const { persistor, store } = configureStore();

/*
const Wrapper = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location.pathname]);

  return children;
}; */

export default function App() {
  console.log('process.env', process.env);

  return (
    <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
            <Switch>
              <Route component={DashboardLayout} />
            </Switch>
        </BrowserRouter>

       </PersistGate>
    </Provider>
  );
}
