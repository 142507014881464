
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import ReactWOW from 'react-wow';

import {
  Col,
  Media,
} from 'reactstrap';

// import 'rsuite-table/dist/css/rsuite-table.css';

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import { connect } from 'react-redux';

import companyLogo1 from '../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Amadeus Jewellery.webp';
// import companyLogo2 from '../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Buddha Beauty.webp';
import companyLogo3 from '../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Crystals Healing.webp';
import companyLogo4 from '../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Fennel and Ginger.webp';
import companyLogo5 from '../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Grounded Body Scrub.webp';
import companyLogo6 from '../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Guava and Gold.webp';
import companyLogo7 from '../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Mos Accessories.webp';
import companyLogo8 from '../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Munki Magik.webp';
import companyLogo9 from '../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Pitod.webp';
import companyLogo10 from '../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Rocket Jack.webp';
import companyLogo11 from '../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Trend Tonic.webp';
import companyLogo12 from '../../../assets/images/BusinessHomepage/Co Logos/CP_webp.webp';


// import CUST from '../../../assets/images/BusinessHomepage/Goliveinminutes/CUST.svg';
// import MARKET from '../../../assets/images/BusinessHomepage/Goliveinminutes/MARKET.svg';
// import POW_1 from '../../../assets/images/BusinessHomepage/Goliveinminutes/POW_1.svg';
// import SHIELD from '../../../assets/images/BusinessHomepage/Goliveinminutes/SHIELD.svg';
// import SHIELD_white from '../../../assets/images/BusinessHomepage/Goliveinminutes/SHIELD_white.svg';


// import shopifyIcon from '../../../assets/icons/shopify.svg';
// import woocommerceIcon from '../../../assets/icons/woocommerce.svg';
// import no_internet from '../../../assets/icons/no-internet.svg';
// import network from '../../../assets/icons/network.svg';
// import connection from '../../../assets/icons/connection.svg';

import businessImage from '../../../assets/Steppr - loyalty and gifting - vouchers and hero.webp';

import Megaphone1 from './ReactIcons/Megaphone1';
import RefreshCircle1Clockwise from './ReactIcons/RefreshCircle1Clockwise';
import Shield2Check from './ReactIcons/Shield2Check';
import ArrowBothDirectionHorizontal1 from './ReactIcons/ArrowBothDirectionHorizontal1';
import DashboardSquare1 from './ReactIcons/DashboardSquare1';
import MonitorCode from './ReactIcons/MonitorCode';

const sliderSettings = {
  lazyload: true,
  // slideBy: 'page',
  autoplay: true,
  autoplayButtonOutput: false,
  mouseDrag: true,
  gutter: 15,
  nav: false,
  controls: true,
  controlsText: ['<i class="lni lni-chevron-left"></i>', '<i class="lni lni-chevron-right"></i>'],
  items: 6,
};

const sliderSettingsMobile = {
  lazyload: true,
  autoplay: true,
  autoplayButtonOutput: false,
  mouseDrag: true,
  gutter: 15,
  nav: false,
  controls: true,
  controlsText: ['<i class="lni lni-chevron-left"></i>', '<i class="lni lni-chevron-right"></i>'],
  items: 1,
};

const MOBILE_SIZE = 992;

class BusinessHomepage extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      activeTab: 2,
    };
  }

  componentDidMount() {
    const { user } = this.props;
  }


  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  }

  render() {
    const { user, vouchersList, companies, categories, activeCategory } = this.props;
    const {
      activeTab,
      //  sliderSettings,
    } = this.state;

    console.log('vouchersList sliderSettings', sliderSettings, vouchersList);

    return (
      <div style={{ marginBottom: 2 }}>
        <section class="hero-area style2 overlay">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-7 col-md-12 col-12">
                <div class="hero-text wow fadeInLeft" data-wow-delay=".3s">
                  <div class="section-heading">
                    <h2>Super-platform for selling vouchers. Free.</h2>
                    <p style={{ marginBottom: 15, color: "#fff" }}>Sell vouchers with ridiculous ease. And keep the money, we insist. We also insist on finding customers for you. <b>Setup without any coding over a coffee.</b></p>
                  </div>
                  <div className="col-12">
                    <a href="https://steppr.com/signup" class="search-btn button">
                      <button className="btn business-homepage-start-selling-btn">Start selling vouchers</button>
                    </a>
                  </div>
                </div>

              </div>
              <div className="col-lg-5 col-md-12 col-12">
                <div className="style2 wow fadeInRight" data-wow-delay=".5s">
                  <Media className="business-homepage-start-selling-image" src={businessImage} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="categories style2">
          <ReactWOW animation='fadeInUp' delay=".4s">
            <div className="container">
              <div >
                <div className="row">
                  <div className="col-12">
                    <div className="section-title">
                      <h2 className="wow fadeInUp" data-wow-delay=".4s">Vouchers boost <span style={{ color: '#ff801b' }}>sales</span> & improve retention</h2>
                      <p className="wow fadeInUp" data-wow-delay=".6s">Our mission is to remove all hurdles to selling vouchers for businesses big & small.</p>
                    </div>
                  </div>
                </div>
                <div className="client-logo-section">
                  <div className="container">
                    <div className="client-logo-wrapper d-none d-md-block d-lg-block" style={{ position: 'relative' }}>
                      <TinySlider
                        settings={sliderSettings}
                        className="client-logo-carousel d-flex align-items-center justify-content-between"
                      >
                        <a key={0} className="client-logo">
                          <Media src={companyLogo1} alt="#" />
                        </a>
                        <a key={1} className="client-logo">
                          <Media src={companyLogo12} alt="#" />
                        </a>
                        <a key={2} className="client-logo">
                          <Media src={companyLogo3} alt="#" />
                        </a>
                        <a key={3} className="client-logo">
                          <Media src={companyLogo4} alt="#" />
                        </a>
                        <a key={4} className="client-logo">
                          <Media src={companyLogo5} alt="#" />
                        </a>
                        <a key={5} className="client-logo">
                          <Media src={companyLogo6} alt="#" />
                        </a>
                        <a key={6} className="client-logo">
                          <Media src={companyLogo7} alt="#" />
                        </a>
                        <a key={7} className="client-logo">
                          <Media src={companyLogo8} alt="#" />
                        </a>
                        <a key={8} className="client-logo">
                          <Media src={companyLogo9} alt="#" />
                        </a>
                        <a key={9} className="client-logo">
                          <Media src={companyLogo10} alt="#" />
                        </a>
                        <a key={10} className="client-logo">
                          <Media src={companyLogo11} alt="#" />
                        </a>
                      </TinySlider>
                    </div>
                    <div className="client-logo-wrapper d-block d-md-none d-lg-none" style={{ position: 'relative' }}>
                      <TinySlider
                        settings={sliderSettingsMobile}
                        className="client-logo-carousel d-flex align-items-center justify-content-between"
                      >
                        <a key={0} className="client-logo">
                          <Media src={companyLogo1} alt="#" />
                        </a>
                        <a key={1} className="client-logo">
                          <Media src={companyLogo12} alt="#" />
                        </a>
                        <a key={2} className="client-logo">
                          <Media src={companyLogo3} alt="#" />
                        </a>
                        <a key={3} className="client-logo">
                          <Media src={companyLogo4} alt="#" />
                        </a>
                        <a key={4} className="client-logo">
                          <Media src={companyLogo5} alt="#" />
                        </a>
                        <a key={5} className="client-logo">
                          <Media src={companyLogo6} alt="#" />
                        </a>
                        <a key={6} className="client-logo">
                          <Media src={companyLogo7} alt="#" />
                        </a>
                        <a key={7} className="client-logo">
                          <Media src={companyLogo8} alt="#" />
                        </a>
                        <a key={8} className="client-logo">
                          <Media src={companyLogo9} alt="#" />
                        </a>
                        <a key={9} className="client-logo">
                          <Media src={companyLogo10} alt="#" />
                        </a>
                        <a key={10} className="client-logo">
                          <Media src={companyLogo11} alt="#" />
                        </a>
                      </TinySlider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
        </section>

        <i style={{ width: 0, height: 0, padding: 0, margin: 0, border: 0 }} class="lni lni-target-user"></i>

        <section className="hero-area how-works style2 overlay section why-choose" style={{ background: "#F4FAFF" }}>
          <ReactWOW animation='fadeInUp' delay=".4s">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title">
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">Go live in minutes with our free, fully automated platform</h2>
                    <p className="wow fadeInUp" style={{ color: '#FFF' }} data-wow-delay=".6s">Easily create voucher campaigns, monitor voucher sales, track their redemptions & more. No tricky setup, full integration in minutes. We do the heavy lifting for you.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="choose-content">
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="single-work single-list wow fadeInUp" data-wow-delay=".2s">
                          {/* <Media src={CUST} /> */}
                          <Megaphone1 />
                          <h4>We <span style={{ textDecoration: "line-through" }}>fight</span> market for you</h4>
                          <p style={{ color: "#888" }}>We promote your voucher campaigns to our customers on your behalf.</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="single-work single-list wow fadeInUp" data-wow-delay=".4s">
                          {/*<Media src={MARKET} /> */}
                          <i class="lni lni-target-user"></i>
                          <h4>Find <span style={{ textDecoration: "line-through" }}>allies</span> customers</h4>
                          <p style={{ color: "#888" }}>Our inbuilt loyalty program encourages customers to stick with you.</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="single-work single-list wow fadeInUp" data-wow-delay=".6s">
                          {/* <Media src={POW_1} /> */}
                          <RefreshCircle1Clockwise />
                          <h4>Fully <span style={{ textDecoration: "line-through" }}>powerful</span> automated</h4>
                          <p style={{ color: "#888" }}>Quick & simple no-code setup gives you unparalleled access to our voucher ecosystem.</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="single-work single-list wow fadeInUp" data-wow-delay=".2s"> {/*  single-list-last*/}
                          <div className="single-list-logo-wrapper">
                            <Shield2Check />
                            {/* <div className="single-list-logo-wrapper2">
                              <div className="single-list-logo-img"></div>
                            </div> */}
                          </div>
                          <h4>We're free to use!</h4>
                          <p style={{ color: "#888" }}>No, really! Zero setup fees, voucher commissions (or any charges like that).</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
        </section>

        <section className="our-achievement section">
          <ReactWOW animation='fadeInUp' delay=".4s">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-12">
                  <div className="single-achievement wow fadeInUp" data-wow-delay=".2s">
                    <h3 className="counter"><span id="secondo1" className="countup" cup-end="230">230</span>+</h3>
                    <p>Businesses on Steppr</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                  <div className="single-achievement wow fadeInUp" data-wow-delay=".4s">
                    <h3 className="counter"><span id="secondo2" className="countup" cup-end="0">0%</span></h3>
                    <p>Zero Fees</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                  <div className="single-achievement wow fadeInUp" data-wow-delay=".6s">
                    <h3 className="counter">MULTI</h3>
                    <p>Website Connection Methods</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                  <div className="single-achievement wow fadeInUp" data-wow-delay=".6s">
                    <h3 className="counter">BROAD</h3>
                    <p>Customer reach via apps & website</p>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
        </section>

        <section className="section vouchers-are-important">
          <ReactWOW animation='fadeInUp' delay=".4s">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="section-title">
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">Vouchers are increasingly important</h2>
                    <p className="wow fadeInUp" data-wow-delay=".6s">More and more people in the UK are buying and gifting vouchers. Many recipients of gift cards are introduced to new businesses as a result (UK GCVA 2022).</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="choose-content">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-list" data-wow-delay=".2s">
                          <div className="single-list-number"> 29% </div>
                          <h4>Support for businesses</h4>
                          <p>29% purchasers want to support local businesses</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-list" data-wow-delay=".4s">
                          <div className="single-list-number">{`50%+`}</div>
                          <h4>Great for marketing</h4>
                          <p>50%+ recipients are introduced to new businesses</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-list single-list-last" data-wow-delay=".6s">
                          <div className="single-list-number-last">36%</div>
                          <h4>Perfect for gifting</h4>
                          <p>36% say gift cards easier to buy than physical gifts</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ReactWOW>
        </section>


        <section className="how-works section" style={{ background: "#F4FAFF" }}>
          <div className="container">
            <ReactWOW animation='fadeInUp' delay=".4s">
              <div className="row">
                <div className="col-12">
                  <div className="section-title">
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">How Steppr Works</h2>
                  </div>
                </div>
              </div>
              <div className="row how-works-row" >
                <Col xs="12" md="3" lg="3">
                  <div className="single-work wow fadeInUp" data-wow-delay=".2s">
                    <span className="serial">1</span>
                    <h3>Voucher Campaigns</h3>
                    <p>You launch them from your online business account.</p>
                  </div>
                </Col>
                <Col xs="12" md="3" lg="3">
                  <div className="single-work wow fadeInUp" data-wow-delay=".4s">
                    <span className="serial">2</span>
                    <h3>Marketing Starts</h3>
                    <p>We showcase them.</p>
                  </div>
                </Col>
                <Col xs="12" md="3" lg="3">
                  <div className="single-work wow fadeInUp" data-wow-delay=".6s">
                    <span className="serial">3</span>
                    <h3>Make Sales</h3>
                    <p>Customers buy your vouchers.</p>
                  </div>
                </Col>
                <Col xs="12" md="3" lg="3">
                  <div className="single-work wow fadeInUp" data-wow-delay=".8s">
                    <span className="serial">4</span>
                    <h3>Make Money</h3>
                    <p>You're paid directly and immediately.</p>
                  </div>
                </Col>
              </div>
            </ReactWOW>
          </div>
        </section>
        <section className="connect-easily section" style={{ background: "#FFFFFF" }}>
          <div className="container">
            <ReactWOW animation='fadeInUp' delay=".4s">
              <div className="row">
                <div className="col-12">
                  <div className="section-title">
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">Connect Extremely Easily</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="choose-content">
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="single-work single-list wow fadeInUp" data-wow-delay=".2s">
                          <ArrowBothDirectionHorizontal1 />
                          <h4>Direct</h4>
                          <p>Via Shopify or WooCommerce.</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="single-work single-list wow fadeInUp" data-wow-delay=".4s">
                          <i class="lni lni-www-cursor"></i>
                          <h4>Manual</h4>
                          <p>Offline, where you redeem vouchers manually.</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="single-work single-list wow fadeInUp" data-wow-delay=".6s">
                          <DashboardSquare1 />
                          <h4>Multi</h4>
                          <p>Multiple connections for groups of businesses.</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 col-12">
                        <div className="single-work single-list wow fadeInUp" data-wow-delay=".2s">
                          <div className="single-list-logo-wrapper">
                            <MonitorCode />
                          </div>
                          <h4>Manual</h4>
                          <p>Specific requirements? <a style={{ color: "#00aeff", fontWeight: 600 }} href="mailto: hello@steppr.co.uk">Get In touch</a>.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row connect-easily-row">
                <Col xs="12" md="4" lg="4" className="connect-easily-col">
                  <div className="single-work wow fadeInUp" data-wow-delay=".2s">
                    <Media style={{ width: 68, height: 68, paddingLeft: 0, paddingRight: 0 }} src={shopifyIcon} />
                    <h3>Shopify</h3>
                    <p>Via Shopify.</p>
                  </div>
                </Col>
                <Col xs="12" md="4" lg="4" className="connect-easily-col">
                  <div className="single-work wow fadeInUp" data-wow-delay=".4s">
                    <Media style={{ width: 68, height: 68, paddingLeft: 0, paddingRight: 0 }} src={woocommerceIcon} />
                    <h3>WooCommerce</h3>
                    <p>Via WooCommerce.</p>
                  </div>
                </Col>
                <Col xs="12" md="4" lg="4" className="connect-easily-col">
                  <div className="single-work wow fadeInUp" data-wow-delay=".6s">
                    <Media className="filter_blue" style={{ width: 68, height: 68, paddingLeft: 0, paddingRight: 0 }} src={no_internet} />
                    <h3>Manual</h3>
                    <p>Offline, where you redeem vouchers manually.</p>
                  </div>
                </Col>
                <Col xs="12" md="4" lg="4" className="connect-easily-col">
                  <div className="single-work wow fadeInUp" data-wow-delay=".8s">
                    <Media className="filter_blue" style={{ width: 68, height: 68, paddingLeft: 0, paddingRight: 0 }} src={network} />
                    <h3>Multi</h3>
                    <p>Multiple connections for groups of businesses.</p>
                  </div>
                </Col>
                <Col xs="12" md="4" lg="4" className="connect-easily-col">
                  <div className="single-work wow fadeInUp" data-wow-delay=".8s">
                    <Media className="filter_blue" style={{ width: 68, height: 68, paddingLeft: 0, paddingRight: 0 }} src={connection} />
                    <h3>Bespoke</h3>
                    <p>Specific requirements? <a style={{ color: "#00aeff", fontWeight: 600 }} href="mailto: hello@steppr.co.uk">Get In touch</a>.</p>
                  </div>
                </Col> 
              </div>*/}
            </ReactWOW>
          </div>
        </section>
        <section className="what-steppr-offers section" style={{ background: "#F4FAFF" }}>
          <div className="container">
            <ReactWOW animation='fadeInUp' delay=".4s">
              <div className="row">
                <div className="col-12">
                  <div className="section-title">
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">What Steppr Offers</h2>
                  </div>
                </div>
              </div>
              <div className="row what-steppr-offers-row">
                <Col xs="12" sm="12" md="6" lg="3">
                  <div className="single-work wow fadeInUp" data-wow-delay=".2s">
                    <div className="what-steppr-offers-card-text-top">
                      <h3>New Revenue Stream</h3>
                    </div>
                    <div className="what-steppr-offers-card-text">
                      <p>Launch your voucher sales with next to no additional effort.</p>
                    </div>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="3">
                  <div className="single-work single-work-odd wow fadeInUp" data-wow-delay=".4s">
                    <div className="what-steppr-offers-card-text-top">
                      <h3>Expanded Offering</h3>
                    </div>
                    <div className="what-steppr-offers-card-text">
                      <p>Vouchers make great, eco-friendly gifts.</p>
                    </div>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="3">
                  <div className="single-work wow fadeInUp" data-wow-delay=".6s">
                    <div className="what-steppr-offers-card-text-top">
                      <h3>More Customers</h3>
                    </div>
                    <div className="what-steppr-offers-card-text">
                      <p>Different products reach new consumers.</p>
                    </div>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="3">
                  <div className="single-work single-work-odd wow fadeInUp" data-wow-delay=".8s">
                    <div className="what-steppr-offers-card-text-top">
                      <h3>Loyalty Programme</h3>
                    </div>
                    <div className="what-steppr-offers-card-text">
                      <p>Our platform mechanics offer in-built customer rewards.</p>
                    </div>
                  </div>
                </Col>
              </div>
            </ReactWOW>
          </div>
        </section>


      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  // isLoggedIn: state.user.isLoggedIn || false,
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessHomepage));









