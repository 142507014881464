
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import ReactWOW from 'react-wow';

import { connect } from 'react-redux';

import CampaignCard from './Components/CampaignCard';

import CategoriesList from './Components/CategoriesList';

class AllVouchers extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      activeTab: 2,
      currentPage: 0,
    };
  }

  componentDidMount() {
    const { user } = this.props;
  }

  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  }

  setCurrentPage = (page) => {
    const { scrollToAllVouchers } = this.props;
    this.setState({ currentPage: page });

    scrollToAllVouchers();
  }

  render() {
    const { user, vouchersList, activeCategory, changeActiveCategory, orderedVouchers } = this.props;
    const {
      activeTab,
      currentPage,
    } = this.state;

    console.log('vouchersList activeTab', activeTab, vouchersList, activeCategory);

    let pageCount = 1;

    if (vouchersList && Object.keys(vouchersList)) {
      pageCount = Math.ceil(Object.keys(vouchersList).length / 12);
    }

    let vouchersFiltered = vouchersList;

    if (activeCategory && activeCategory !== 'all') {
      let newVouchersFiltered = {};
      Object.keys(vouchersList).map((key) => {
        if (activeCategory && vouchersList[key] && vouchersList[key].categoryIds && vouchersList[key].categoryIds.includes(activeCategory)) {
          newVouchersFiltered[key] = vouchersList[key];
        }
      });
      vouchersFiltered = newVouchersFiltered;
    }

    console.log('vouchersFiltered', vouchersFiltered, activeCategory);

    console.log('pageCount', pageCount, currentPage);

    /* let voucherPurchasedLevels = {};

    Object.keys(orderedVouchers).map((key) => {
      if (orderedVouchers[key].voucher && orderedVouchers[key].voucher.id) {
        const voucherId = orderedVouchers[key].voucher.id;
        if (voucherPurchasedLevels[voucherId]) {
          if (voucherPurchasedLevels[voucherId][orderedVouchers[key].voucherStep]) {
            voucherPurchasedLevels[voucherId][orderedVouchers[key].voucherStep][orderedVouchers[key].voucherId] = true;
          } else {
            voucherPurchasedLevels[voucherId][orderedVouchers[key].voucherStep] = { [orderedVouchers[key].voucherId]: true };
          }
        } else {
          voucherPurchasedLevels[voucherId] = {
            [orderedVouchers[key].voucherStep]: { [orderedVouchers[key].voucherId]: true },
          };
        }
      }
    });

    console.log('voucherPurchasedLevels', voucherPurchasedLevels, orderedVouchers);

    let finishedCampaigns = {};

    Object.keys(voucherPurchasedLevels).map((key) => {
      let allStepsFinished = false;
      if (voucherPurchasedLevels[key] && voucherPurchasedLevels[key][1] && voucherPurchasedLevels[key][1][2] && voucherPurchasedLevels[key][2] && voucherPurchasedLevels[key][2][2] && voucherPurchasedLevels[key][3] && voucherPurchasedLevels[key][3][2]) {
        console.log('all steps finished');

        finishedCampaigns[key] = voucherPurchasedLevels[key];
      }

      // Bellow is for testing only
    //  if (voucherPurchasedLevels[key] && voucherPurchasedLevels[key][1] && voucherPurchasedLevels[key][1][1]) {
    //    console.log('all steps finished 2');

     //   finishedCampaigns[key] = voucherPurchasedLevels[key];
    //  }
    });

    if (finishedCampaigns && Object.keys(finishedCampaigns).length) {
      console.log('has finished campaigns');
      let newVouchersFiltered = {};
      Object.keys(vouchersFiltered).map((key) => {
        if (vouchersList[key] && !finishedCampaigns[key]) {
          newVouchersFiltered[key] = vouchersList[key];
        }
      });
      vouchersFiltered = newVouchersFiltered;
    }

    console.log('filtered vouchersFiltered', vouchersFiltered); */

    return (
      <div style={{ marginBottom: 2 }}>
        <section className="items-tab section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <h2 className="wow fadeInUp" data-wow-delay=".4s">All Vouchers</h2>
                  <p className="wow fadeInUp" data-wow-delay=".6s">Look through the range of unique vouchers, and buy them for yourself or as a gift.</p>
                </div>
              </div>
            </div>

            <CategoriesList changeActiveCategory={changeActiveCategory} activeCategory={activeCategory} />

            <div className="row">
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-latest" role="tabpanel">
                  <div className="row">
                    <>
                      {(() => {
                        const rows = [];

                        console.log('pagecount currentpage render', currentPage, (12 * currentPage), ((12 * currentPage) + 12))

                        // for (let i = 12 * currentPage; i < ((12 * currentPage) + 12); i++) {
                        for (let i = 0; i < Object.keys(vouchersFiltered).length; i++) {
                          let hasVouchers = false;
                          let isInPage = false;

                          console.log('render voucher', i);

                          if (i >= 12 * currentPage && i < ((12 * currentPage) + 12)) {
                            isInPage = true;
                          }

                          if (vouchersFiltered && Object.keys(vouchersFiltered).length) {
                            hasVouchers = true;
                          }

                          let voucher = null;
                          let voucherId = null;

                          if (hasVouchers && Object.keys(vouchersFiltered)[i]) {
                            voucherId = Object.keys(vouchersFiltered)[i];
                            voucher = vouchersFiltered[voucherId];
                          }

                        //  console.log('voucherIdvoucherId', hasVouchers, voucherId, voucher);

                        //  if (voucher) {
                        //    console.log('activeCategoryactiveCategory', activeCategory, voucher);
                         // }

                          if ((!activeCategory || activeCategory === 'all' || (activeCategory && voucher && voucher.categoryIds.includes(activeCategory)))) {
                            console.log('VALID CATEGORY', isInPage);
                            rows.push(
                              <ReactWOW animation='fadeInUp' delay=".4s">
                                <div className="col-lg-4 col-md-6 col-12" class="single-item-grid-wrapper">
                                  <CampaignCard
                                    key={voucherId}
                                    vouchersList={vouchersFiltered}
                                    display={!(hasVouchers)}
                                    notInPage={!isInPage}
                                    voucher={voucher}
                                    voucherId={voucherId}
                                  />
                                </div>
                              </ReactWOW>
                            );
                          } else {
                            console.log('NOT VALID CATEGORY');
                          }
                        }

                        console.log('row', rows);

                        return rows;
                      })()}
                    </>

                    {/*
                      {vouchersList ? Object.keys(vouchersList).map((key, index) => {
                        if (activeCategory && !vouchersList[key].categoryIds.includes(activeCategory)) { return null };

                        return (
                          <div className="col-lg-4 col-md-6 col-12" class="single-item-grid-wrapper">
                            <CampaignCard key={key} vouchersList={vouchersList} voucher={vouchersList[key]} voucherId={key} />
                          </div>
                        );
                      }) : (
                        <>
                          {(() => {
                            const rows = [];
                            for (let i = 0; i < 12; i++) {
                              rows.push(
                                <div className="col-lg-4 col-md-6 col-12"><PlaceholderCard key={i} /></div>
                              );
                            }
                            return rows;
                          })()}
                        </>
                      )} */}
                    </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="pagination left">
                      <ul class="pagination-list">
                        {(pageCount) ? (
                          <>
                            {(() => {
                              const rows = [];

                              console.log('pageCount call', pageCount);

                              for (let j = 0; j < pageCount; j++) {
                                rows.push(
                                  <li onClick={() => this.setCurrentPage(j)} className={j === currentPage ? 'active' : ''}>{j + 1}</li>
                                );
                              }

                              if (pageCount === 1) {
                                rows.push(
                                  <li disabled={true}>2</li>
                                );
                              }

                              rows.push(
                                <li disabled={pageCount === 1 ? true : false} onClick={() => this.setCurrentPage(currentPage + 1)} className={pageCount === currentPage ? 'active' : ''}><i class="lni lni-chevron-right"></i></li>
                              );

                              console.log('pageCount rows', rows);

                              return rows;
                            })()}
                          </>
                        ) : (null)}        

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  orderedVouchers: state.customer.orderedVouchers || {},
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AllVouchers));









