import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
// import { Eye, EyeOff } from 'react-feather';

import {
  TabContent,
  TabPane,
} from 'reactstrap';

// import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';

// import { storage } from '../../../lib/firebase';

import {
  getVoucherList,
  getSingleVoucher,
  getVoucherCompanies,
  getVoucherCategories,
  // doPayment,
} from '../../../actions/customer';

import { loginAppUser, getAppUserData } from '../../../actions/user'; 

import notifications from '../../../const/notifications';
import DashboardInfoBox from './Components/DashboardInfoBox';
import PopularVouchers from './PopularVouchers';
import HowItWorks from './HowItWorks';
import AllVouchers from './AllVouchers';
import AsSeenIn from './AsSeenIn';

const pound = `${'\u00A3'}`;

class VouchersList extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    getVouchers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      companyNotifications: [],
      companyInformation: '',
      selectedStep: 1,
      purchaseModal: false,
      showPassword1: 'password',
      activeCategory: null,
    };

    this.allVouchersRef = React.createRef();
  }

  componentDidMount() {
    const { user, history } = this.props;
  }

  /* openPreview = async (voucherId) => {
    const { vouchersList, fetchSingleVoucher } = this.props;

    console.log('openPreview', voucherId, vouchersList[voucherId]);

    const { logo, image, images, discount, availableVouchers } = vouchersList[voucherId];

    fetchSingleVoucher(voucherId).then(async (res) => {
      console.log('fetchSingleVoucher', res.payload);

      const { companyData } = res.payload;

      console.log('companyData', companyData);

      const { primaryVoucherImage, logo } = companyData;

      let companyPrimaryVoucherImage;
      let logoImage;

      if (primaryVoucherImage && logo) {
        console.log('test aaaa');
        const primaryVoucherImageReference = storage.ref(primaryVoucherImage);
        const logoReference = storage.ref(logo);

        console.log('test bbbb');

        await primaryVoucherImageReference.getDownloadURL().then((url) => {
          companyPrimaryVoucherImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });

        console.log('test cccc');

        await logoReference.getDownloadURL().then((url) => {
          logoImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });

        this.setState({ previewModal: true, previewVoucher: vouchersList[voucherId], primaryVoucherImage: companyPrimaryVoucherImage || null, logo: logoImage || null, discount, availableVouchers });
      }
    });
  }

  closePreview = () => {
    this.setState({ previewModal: false, primaryVoucherImage: null, logo: null });
  }

  toggleReadMore = () => {
    this.setState((prevState) => ({ readMore: !prevState.readMore }))
  }

  togglePreviewStep = (step) => {
    this.setState({ previewStep: step });
  }

  openPurchaseModal = (selectedVoucher) => {
    const { selectedStep } = this.state;
    console.log('openPurchaseModal', selectedStep, selectedVoucher);

    this.setState({ purchaseModal: true, selectedVoucher });
  }

  closePurchaseModal = () => {
    console.log('closePurchaseModal');

    this.setState({ purchaseModal: false, selectedVoucher: null });
  }

  finishPayment = () => {
    const { doPaymentFunc } = this.props;
    const { selectedStep, selectedVoucher } = this.state;

    console.log('finishPayment', selectedStep, selectedVoucher);
  }

  changeStep = (step) => {
    console.log('changeStep', step);

    this.setState({ selectedStep: step });
  } */

  changeActiveCategory = (id) => {
    const { getVouchers, history } = this.props;

    console.log('changeActiveCategory', id);

    if (id === 'all') {
      this.setState({ activeCategory: 'all' });
    } else {
      this.setState({ activeCategory: id });
    }

    // history.push(`/categories/${id}`);
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  /*
  handleSubmit = (e) => {
    e.preventDefault();

    const { login, history, fetchUserData, getVouchers, fetchVoucherCompanies, fetchVoucherCategories } = this.props;
    const { email, password, loading } = this.state;

    if (loading) return;

    this.setState({ loading: true });

    return login({ email, password })
      .then((res) => {
        console.log('login then', { res });

        fetchUserData();
      //  getVouchers();

        // fetchVoucherCompanies();
      //  fetchVoucherCategories();
      })
      .catch((err) => {
        console.log('login error', err);

        console.log(notifications.login);
        if (err === 'missing email') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noEmail || err });
        } else if (err === 'missing password') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noPassword || err });
        } else if (err === 'The email address is badly formatted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.badEmail || err });
        } else if (err === 'To access your account please click the verification link in the email sent to you.') {
          this.setState({ loading: false, errorGreen: true, error: notifications.login.needVerification || err });
        } else if (err === 'The password is invalid or the user does not have a password.' || 'There is no user record corresponding to this identifier. The user may have been deleted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.wrongLogin || err });
        } else {
          this.setState({ loading: false, errorGreen: false, error: err });
        }
      });
  } */


  toggleShowPassword = (id) => {
    console.log('toggleShowPassword');
    console.log(id);
    if (id === 1) {
      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  }

  scrollToAllVouchers = () => {
    console.log('scrollToAllVouchers', this.allVouchersRef);

    if (this.allVouchersRef && this.allVouchersRef.current) {
      this.allVouchersRef.current.scrollIntoView();
    }
  }

  render() {
    const { vouchersList, orderedVouchers } = this.props;
    const {
      activeTab,
    //  dontShowPage,
    //  perks,
    //  perksTabTitle,

    //  purchaseModal,
   //   selectedStep,
    //  selectedVoucher,
      activeCategory,

    //  loading,
    //  error,
    //  errorGreen,
    } = this.state;

    let vouchersFiltered = vouchersList;

    let voucherPurchasedLevels = {};

    Object.keys(orderedVouchers).map((key) => {
      if (orderedVouchers[key].voucher && orderedVouchers[key].voucher.id) {
        const voucherId = orderedVouchers[key].voucher.id;
        if (voucherPurchasedLevels[voucherId]) {
          if (voucherPurchasedLevels[voucherId][orderedVouchers[key].voucherStep]) {
            voucherPurchasedLevels[voucherId][orderedVouchers[key].voucherStep][orderedVouchers[key].voucherId] = true;
          } else {
            voucherPurchasedLevels[voucherId][orderedVouchers[key].voucherStep] = { [orderedVouchers[key].voucherId]: true };
          }
        } else {
          voucherPurchasedLevels[voucherId] = {
            [orderedVouchers[key].voucherStep]: { [orderedVouchers[key].voucherId]: true },
          };
        }
      }
    });

    console.log('voucherPurchasedLevels', voucherPurchasedLevels, orderedVouchers);

    let finishedCampaigns = {};

    Object.keys(voucherPurchasedLevels).map((key) => {
      let allStepsFinished = false;
      if (voucherPurchasedLevels[key] && voucherPurchasedLevels[key][1] && voucherPurchasedLevels[key][1][2] && voucherPurchasedLevels[key][2] && voucherPurchasedLevels[key][2][2] && voucherPurchasedLevels[key][3] && voucherPurchasedLevels[key][3][2]) {
        console.log('all steps finished');

        finishedCampaigns[key] = voucherPurchasedLevels[key];
      }

      // Bellow is for testing only
      //  if (voucherPurchasedLevels[key] && voucherPurchasedLevels[key][1] && voucherPurchasedLevels[key][1][1]) {
      //    console.log('all steps finished 2');

      //   finishedCampaigns[key] = voucherPurchasedLevels[key];
      //  }
    });

    if (finishedCampaigns && Object.keys(finishedCampaigns).length) {
      console.log('has finished campaigns');
      let newVouchersFiltered = {};
      Object.keys(vouchersFiltered).map((key) => {
        if (vouchersList[key] && !finishedCampaigns[key]) {
          newVouchersFiltered[key] = vouchersList[key];
        }
      });
      vouchersFiltered = newVouchersFiltered;
    }

    console.log('filtered vouchersFiltered', vouchersFiltered);

    if (!vouchersList) {
      return (
        <div className="preloader">
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div>
              <DashboardInfoBox changeActiveCategory={this.changeActiveCategory} activeCategory={activeCategory} />

              <PopularVouchers vouchersList={vouchersFiltered} scrollToAllVouchers={this.scrollToAllVouchers} />

              <HowItWorks />
              <AsSeenIn />

              <div ref={this.allVouchersRef} style={{ height: 0, position: 'relative', top: 150 }}></div>
              <AllVouchers vouchersList={vouchersFiltered} changeActiveCategory={this.changeActiveCategory} activeCategory={activeCategory} scrollToAllVouchers={this.scrollToAllVouchers} />
            </div>
          </TabPane>
        </TabContent>


        {/* <Modal isOpen={previewModal} toggle={this.closePreview}>
          <ModalHeader toggle={this.closePreview}>
            Purchase voucher
          </ModalHeader>
          <ModalBody>
            <div className="voucher-preview" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <div className="voucher-preview-top">
                {primaryVoucherImage ? (
                  <Media className="voucher-preview-top-image" src={primaryVoucherImage} />
                ) : null}
              </div>

              <div className="voucher-preview-header">
                {logo ? (
                  <>
                    <Media className="voucher-preview-header-logo" src={logo} />
                    <div className="voucher-preview-header-text">
                      <div className="voucher-preview-header-name">
                        {previewVoucher ? previewVoucher.name : ''}
                      </div>
                      <div className="voucher-preview-header-website">
                        {(previewVoucher && previewVoucher.website) ? previewVoucher.website.replace(/(^\w+:|^)\/\//, '') : ''}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="voucher-preview-body">
                <div className={readMore ? 'voucher-preview-body-description' : 'voucher-preview-body-description-hidden'}>
                  {previewVoucher ? previewVoucher.description : ''}
                </div>
                <Button className="voucher-preview-body-more" color="link" onClick={this.toggleReadMore}>
                  {readMore ? 'show less' : 'read more'}
                </Button>
              </div>
              <div className="voucher-preview-loyalty-text"> Loyalty steps </div>
              <div className="voucher-preview-footer">
                <div className="voucher-preview-steps">
                  <Media className="voucher-preview-steps-image" src={line} />
                  <div className={`voucher-purchase-step-1 ${selectedStep === 1 ? 'voucher-purchase-step-active' : ''}`} onClick={() => this.changeStep(1)}>
                    <div className="voucher-preview-steps-percent">
                      {discount ? `${discount['1']}%` : null}
                    </div>
                    <div className="voucher-preview-steps-name">
                      Step 1
                    </div>
                  </div>
                  <div className={`voucher-purchase-step-2 ${selectedStep === 2 ? 'voucher-purchase-step-active' : ''}`} onClick={() => this.changeStep(2)}>
                    <div className="voucher-preview-steps-percent">
                      {discount ? `${discount['2']}%` : null}
                    </div>
                    <div className="voucher-preview-steps-name">
                      Step 2
                    </div>
                  </div>
                  <div className={`voucher-purchase-step-3 ${selectedStep === 3 ? 'voucher-purchase-step-active' : ''}`} onClick={() => this.changeStep(3)}>
                    <div className="voucher-preview-steps-percent">
                      {discount ? `${discount['3']}%` : null}
                    </div>
                    <div className="voucher-preview-steps-name">
                      Step 3
                    </div>
                  </div>
                </div>

                <div className="voucher-preview-vouchers">
                  <div className="voucher-preview-voucher">
                    <div className="voucher-preview-vouchers-left">
                      <div className="voucher-preview-vouchers-left-1">
                        {`${pound}${availableVouchers ? availableVouchers['0'] : null}`}
                      </div>
                      <div className="voucher-preview-vouchers-left-2">
                        VOUCHER
                      </div>
                    </div>
                    <div className="voucher-preview-vouchers-right" onClick={() => this.openPurchaseModal(0)}>
                      <div className="voucher-preview-vouchers-right-1">
                        BUY FOR
                      </div>
                      <div className="voucher-preview-vouchers-right-2">
                        {(availableVouchers && discount && availableVouchers['0'] && discount['1']) ? `${pound}${(availableVouchers['0'] - (availableVouchers['0'] * (discount['1'] / 100)))}` : null}
                      </div>
                    </div>
                  </div>
                  <div className="voucher-preview-voucher">
                    <div className="voucher-preview-vouchers-left">
                      <div className="voucher-preview-vouchers-left-1">
                        {`${pound}${availableVouchers ? availableVouchers['1'] : null}`}
                      </div>
                      <div className="voucher-preview-vouchers-left-2">
                        VOUCHER
                      </div>
                    </div>
                    <div className="voucher-preview-vouchers-right">
                      <div className="voucher-preview-vouchers-right-1" onClick={() => this.openPurchaseModal(1)}>
                        BUY FOR
                      </div>
                      <div className="voucher-preview-vouchers-right-2">
                        {(availableVouchers && discount && availableVouchers['1'] && discount['2']) ? `${pound}${(availableVouchers['1'] - (availableVouchers['1'] * (discount['2'] / 100)))}` : null}
                      </div>
                    </div>
                  </div>
                  <div className="voucher-preview-voucher">
                    <div className="voucher-preview-vouchers-left">
                      <div className="voucher-preview-vouchers-left-1">
                        {`${pound}${availableVouchers ? availableVouchers['2'] : null}`}
                      </div>
                      <div className="voucher-preview-vouchers-left-2">
                        VOUCHER
                      </div>
                    </div>
                    <div className="voucher-preview-vouchers-right" onClick={() => this.openPurchaseModal(2)}>
                      <div className="voucher-preview-vouchers-right-1">
                        BUY FOR
                      </div>
                      <div className="voucher-preview-vouchers-right-2">
                        {(availableVouchers && discount && availableVouchers['2'] && discount['3']) ? `${pound}${(availableVouchers['2'] - (availableVouchers['2'] * (discount['3'] / 100)))}` : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={purchaseModal} toggle={this.closePurchaseModal}>
          <ModalHeader toggle={this.closePurchaseModal}>
            Purchase voucher
          </ModalHeader>
          <ModalBody>
            <div >
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.finishPayment}>Buy</Button>
            <Button color="secondary" onClick={this.closePurchaseModal}>Close</Button>
          </ModalFooter>
        </Modal> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  vouchersList: state.customer.list || {},
  orderedVouchers: state.customer.orderedVouchers || {},
});

const mapDispatchToProps = {
  getVouchers: getVoucherList,
  fetchSingleVoucher: getSingleVoucher,
  fetchVoucherCompanies: getVoucherCompanies,
  fetchVoucherCategories: getVoucherCategories,
  login: loginAppUser,
  fetchUserData: getAppUserData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VouchersList));