import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { LazyLoadImage } from 'react-lazy-load-image-component';

class PlaceholderCard extends PureComponent {
  static propTypes = {
    user: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  render() {
    return (
      <div class="single-item-grid" style={{ width: '100%' }}>
        <div class="image">
          <a>
            <LazyLoadImage
              alt={''}
              src={''}
            />
            <div class="primary-image-placeholder">
            </div>
          </a>
          <div className="cross-badge">
            <div class="logo-placeholder" />
          </div>
          <span className="cross-badge-text"><div class="text-placeholder" /></span>
        </div>
        <div class="content">
          <div class="text-placeholder cat-placeholder" />
          <h3 class="title">
            <div class="text-placeholder desc-placeholder" />
          </h3>
          <ul class="info">
            <div class="text-placeholder voucher-val-placeholder" />
          </ul>
          <ul class="info campaign-card-loyalty-range">
            <div class="text-placeholder voucher-val2-placeholder" />
          </ul>
        </div>
      </div>
    );
  }
}

export default PlaceholderCard;
