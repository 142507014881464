import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
  Row,
  Button,
} from 'reactstrap';

// import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';

// import {
//  PaymentElement,
//   Elements,
//   ElementsConsumer,
// } from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';

import { auth } from '../../../lib/firebase';

// import CheckoutForm from './Components/CheckoutForm';

// Make sure to call `loadStripe` outside of a component�s render to avoid
// recreating the `Stripe` object on every render.

import { storage } from '../../../lib/firebase';

import {
  getVoucherList,
  getSingleVoucher,
  getVoucherCompanies,
  getVoucherCategories,
  doPayment,
  getPaymentStatus,
  // loginAppUser,
} from '../../../actions/customer';

import { loginAppUser, getAppUserData } from '../../../actions/user';

// import gift_card from '../../../assets/icons/gift_card.svg';
// import heart from '../../../assets/icons/heart.svg';
// import padlock from '../../../assets/icons/padlock.svg';

// import whenToSend from '../../../assets/icons/time.png';

import notifications from '../../../const/notifications';

const pound = `${'\u00A3'}`;

let stripePromise;

const getParameterByName = (paramName, url) => {
  let url2;
  if (!url) {
    url2 = window.location.href;
  } else {
    url2 = url;
  }

  const name2 = paramName.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name2}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url2);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

class PaymentSuccess extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    getVouchers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    const { match } = props;

    console.log('matchmatch', match);

    const campaignId = match && match.params && match.params.id ? match.params.id : null;

    console.log('campaignId', campaignId);

    this.state = {
      error: null,
      loading: false,
      activeTab: '1',
      selectedCampaign: campaignId || null,
      selectedVoucher: '0',
      selectedStep: '1',

      recipientInputOpen: false,
      paymentsInputOpen: false,

      message: '',
      name: '',
      email: '',

      CVC: '',
      expirationDate: '',
      cardNumber: '',
      purchasedSteps: {},
    };
  }

  componentDidMount() {
    const { user, history, getVouchers, match, companies, fetchPaymentStatus, fetchSingleVoucher } = this.props;
    const { selectedCampaign } = this.state;

    const campaignId = match && match.params && match.params.id ? match.params.id : null;

    let payment_intent;
    let payment_intent_client_secret;
    let redirect_status;

    try {
      const urlParams = new URLSearchParams(document.location.search);
      payment_intent = urlParams.get('payment_intent');
      payment_intent_client_secret = urlParams.get('payment_intent_client_secret');
      redirect_status = urlParams.get('redirect_status');
    } catch (err) {
      payment_intent = getParameterByName('payment_intent');
      payment_intent_client_secret = getParameterByName('payment_intent_client_secret');
      redirect_status = getParameterByName('redirect_status');
    }

    console.log('payment_intent', payment_intent, payment_intent_client_secret, redirect_status);

    this.setState({ payment_intent, payment_intent_client_secret, redirect_status });

    // getVouchers().then((res) => {
    fetchSingleVoucher(campaignId, null).then((res) => {
      const { vouchersList, companies } = this.props;

      console.log('getVouchers res', res, vouchersList, companies);

      const campaignData = vouchersList[campaignId] || res.payload;

      console.log('campaignData', campaignData, campaignId);

      this.setState({ campaignDataState: campaignData || null });

      let companyData;
      if (companies && campaignData && companies[campaignData.companyId]) {
        console.log('companies', companies[campaignData.companyId]);
        companyData = companies[campaignData.companyId];
      } else if (res.companyData) {
        companyData = res.companyData;
      }

      console.log('companyDatacompanyData', companyData);

      if (companyData && companyData.companyStripeId) {
        console.log('process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        const promise1 = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, { stripeAccount: companyData.companyStripeId });

        Promise.all([promise1]).then((promisesRes) => {

          console.log('stripe promise res');

          fetchPaymentStatus(payment_intent);

          promisesRes.map((resp, index) => {
            if (index === 0) {
              const stripe = resp;

              console.log('stripe awaited', stripe);

              stripePromise = stripe;
            }
          });
        });
      }
    });
  }

   /*
  openPreview = async (voucherId) => {
    const { vouchersList, fetchSingleVoucher } = this.props;

    console.log('openPreview', voucherId, vouchersList[voucherId]);

    const { logo, image, images, discount, availableVouchers } = vouchersList[voucherId];

    fetchSingleVoucher(voucherId).then(async (res) => {
      console.log('fetchSingleVoucher', res.payload);

      const { companyData } = res.payload;

      console.log('companyData', companyData);

      const { primaryVoucherImage, logo } = companyData;

      let companyPrimaryVoucherImage;
      let logoImage;

      if (primaryVoucherImage && logo) {
        console.log('test aaaa');
        const primaryVoucherImageReference = storage.ref(primaryVoucherImage);
        const logoReference = storage.ref(logo);

        console.log('test bbbb');

        await primaryVoucherImageReference.getDownloadURL().then((url) => {
          companyPrimaryVoucherImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });

        console.log('test cccc');

        await logoReference.getDownloadURL().then((url) => {
          logoImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });

        this.setState({ previewModal: true, previewVoucher: vouchersList[voucherId], primaryVoucherImage: companyPrimaryVoucherImage || null, logo: logoImage || null, discount, availableVouchers });
      }
    });
  }

  closePreview = () => {
    this.setState({ previewModal: false, primaryVoucherImage: null, logo: null });
  }

  toggleReadMore = () => {
    this.setState((prevState) => ({ readMore: !prevState.readMore }))
  }

  togglePreviewStep = (step) => {
    this.setState({ previewStep: step });
  }

  openPurchaseModal = (selectedVoucher) => {
    const { selectedStep } = this.state;
    console.log('openPurchaseModal', selectedStep, selectedVoucher);

    this.setState({ purchaseModal: true, selectedVoucher });
  }

  closePurchaseModal = () => {
    console.log('closePurchaseModal');

    this.setState({ purchaseModal: false, selectedVoucher: null });
  }

  changeStep = (step) => {
    console.log('changeStep', step);

    this.setState({ selectedStep: step });
  }

  changeActiveCategory = (id) => {
    const { getVouchers, history } = this.props;

    this.setState({ activeCategory: id });

    history.push(`/categories/${id}`)
  }

 
  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = (e) => {
    e.preventDefault();

    const { login, history, fetchUserData, getVouchers, fetchVoucherCompanies, fetchVoucherCategories } = this.props;
    const { email, password, loading } = this.state;

    if (loading) return;

    this.setState({ loading: true });

    console.log('handleSubmit', email, password);

    return login({ email, password })
      .then((res) => {
        console.log('login then', { res });

        fetchUserData();

        getVouchers();
        // fetchVoucherCompanies();
        // fetchVoucherCategories();
      })
      .catch((err) => {
        console.log('login error', err);

        console.log(notifications.login);
        if (err === 'missing email') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noEmail || err });
        } else if (err === 'missing password') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noPassword || err });
        } else if (err === 'The email address is badly formatted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.badEmail || err });
        } else if (err === 'To access your account please click the verification link in the email sent to you.') {
          this.setState({ loading: false, errorGreen: true, error: notifications.login.needVerification || err });
        } else if (err === 'The password is invalid or the user does not have a password.' || 'There is no user record corresponding to this identifier. The user may have been deleted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.wrongLogin || err });
        } else {
          this.setState({ loading: false, errorGreen: false, error: err });
        }
      });
  }


  toggleShowPassword = (id) => {
    console.log('toggleShowPassword');
    console.log(id);
    if (id === 1) {
      //  this.setState((prevState) => ({ showPassword1: !prevState.showPassword1 }));

      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      //  this.setState((prevState) => ({ showPassword2: !prevState.showPassword2 }));
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  }

  changeGift = () => {
    this.setState((prevState) => ({ selectedGift: !prevState.selectedGift }));
  }

  changeAvailableVouchers = (id, disabled) => {
    if (disabled) {
      return null;
    }
    this.setState({ selectedVoucher: id });
  }

  changeDiscount = (id) => {
    const { selectedVoucher, purchasedSteps } = this.state;

    const bool1 = (id === 1 && purchasedSteps[id] && purchasedSteps[id][0]);
    const bool2 = (id > 1 && (!purchasedSteps[id] || (purchasedSteps[id] && purchasedSteps[id][0])));
    const bool3 = (id > 1 && (!purchasedSteps[id - 1] || (purchasedSteps[id - 1] && purchasedSteps[id - 1][0])))

    const disabled = (bool1 || bool2 || bool3);

    console.log('disableddisabled', disabled, bool1, bool2, bool3);

    this.setState({ selectedStep: id, selectedVoucher: disabled ? null : '0' });
  }

  openPaymentInputs = () => {
    const { history } = this.props;

    const { selectedGift, selectedStep, selectedVoucher } = this.state;

    if (!auth.currentUser || !auth.currentUser.uid) {
      history.push(`/signup`);
    }

    if (!(selectedStep || selectedStep === 0) || !(selectedVoucher || selectedVoucher === 0)) {
      console.log('please select step and discount');

      return null;
    }

    if (selectedGift) {
      this.setState({ recipientInputOpen: true, paymentsInputOpen: false });
    } else {
      this.setState({ recipientInputOpen: false, paymentsInputOpen: true });
    }
  }

  openPaymentInputs2 = () => {
    this.setState({ recipientInputOpen: false, paymentsInputOpen: true });
  }

  returnToVoucherInputs = () => {
    this.setState({ recipientInputOpen: false, paymentsInputOpen: false });
  }

  closeModal = () => {
    this.setState({ showStripePaymentFlow: null });
  } */

  setImageLoaded = (id) => {
    this.setState({ [id]: true });
  }

  scrollToTop = () => {
    console.log('scrollToTop');
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }

  refresh = () => {
    try {
      window.location.reload(false);
    } catch (err) {

    }
  }

  render() {
    const { user, vouchersList, companies, categories, paymentStatus } = this.props;
    const {
      selectedCampaign,
      selectedVoucher,
      selectedStep,
      selectedGift,
      recipientInputOpen,
      paymentsInputOpen,

      message,
      name,
      email,

      purchasedSteps,

      primaryLoaded,
      logoLoaded,

      payment_intent,
      payment_intent_client_secret,
      redirect_status,
      campaignDataState,
    } = this.state;

    let campaignData = campaignDataState;

    if (!campaignData && vouchersList && vouchersList[selectedCampaign]) {
      campaignData = vouchersList[selectedCampaign];
    }

    console.log('paymentStatus', paymentStatus, campaignData);

    console.log('campaignDataState', campaignDataState);

    if (!campaignData || !Object.keys(campaignData).length) {
      return (
        <div className="preloader">
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div class="breadcrumbs">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">Voucher Campaign Details</h1>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <ul class="breadcrumb-nav">
                  <li><Link onClick={this.scrollToTop} to="/">Home</Link></li>
                  <li>Voucher Campaign Details</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section className="item-details section">
          <div className="container">
            <div className="top-area">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="product-info" style={{ paddingLeft: 0 }}>
                    <h2 className="title">{campaignData.companyName || ''}</h2>
                    {(companies[campaignData.companyId] && companies[campaignData.companyId].website) ? (
                      <a className="website" target="_blank" href={companies[campaignData.companyId].website} style={{ marginTop: 10 }}>
                        Business website
                      </a>
                    ) : (
                      <a className="website" target="_blank" style={{ marginTop: 10 }} />
                    )}
                  </div>
                  <div className="product-images">
                    <main id="gallery">
                      <div className="main-img">
                        {/* <Media src={campaignData.primaryImage || null} id="current" alt="#" /> */}
                        <LazyLoadImage
                          alt={''}
                          src={campaignData.primaryImage || null}
                          onLoad={() => { this.setImageLoaded(`primaryLoaded`) }}
                          style={!primaryLoaded ? { opacity: 0, position: 'absolute' } : { opacity: 1, position: 'static' }}
                        />
                        {primaryLoaded ? (null) : (
                          <div class="primary-placeholder" />
                        )}
                        {/* <div className="product-info-logo-col"> */}
                        <LazyLoadImage
                          alt={''}
                          src={campaignData.logo || null}
                          onLoad={() => { this.setImageLoaded(`logoLoaded`) }}
                          className="product-info-logo"
                          style={!logoLoaded ? { opacity: 0 } : { opacity: 1 }}
                        />
                        {/* <Media className="product-info-logo" src={campaignData.logo || null} id="current" alt="#" /> */}
                        {logoLoaded ? (null) : (
                          <div class="logo-placeholder" />
                        )}
                        {/* </div> */}
                      </div>
                      <div className="single-block description">
                        <Row>
                          <div className="product-info-description-col">
                            <p> {campaignData.description || null} </p>
                          </div>
                        </Row>
                        <Row>
                          <div className="product-info-description-col">
                            <span> {`Voucher expiry: `} </span>
                            <span className="product-info-description-highlight">{` ${campaignData.expiry || ''} years `} </span>
                            <span> {` from purchase. `}</span>
                          </div>
                        </Row>
                        <Row>
                          <div className="product-info-description-col">
                            <span> {` You are purchasing a voucher from `} </span>
                            <span className="product-info-description-highlight">{` ${campaignData.companyName || ''} `} </span>
                            <span> {` not Steppr. `}</span>
                          </div>
                        </Row>
                      </div>
                    </main>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                  <div class="product-info">
                    {paymentStatus && paymentStatus.status === 'created' ? (
                      <>
                        <h2 className="title" style={{ marginTop: 45, textAlign: 'center' }}>
                          Payment Processing
                        </h2>
                        {/* <Link className="website" to={`/campaigns/${selectedCampaign}`} style={{ marginTop: 15, textAlign: 'center' }}> Proceed </Link> */}
                      </>
                    ) : (null)}
                    {paymentStatus && paymentStatus.status === 'succeeded' ? (
                      <>
                        <h2 className="title" style={{ marginTop: 45, textAlign: 'center' }}>
                          Payment Complete
                        </h2>
                        {/* <Button color="link" className="website" onClick={this.refresh} style={{ marginTop: 15, textAlign: 'center' }}> Check status </Button> */}
                        <Link className="website" to={`/for-me`} style={{ marginTop: 15, textAlign: 'center' }}>
                          Congratulations. Click here to check your wallet. Your purchase may take a minute to display.
                        </Link>
                      </>
                    ) : (null)}
                    {paymentStatus && paymentStatus.status === 'payment_failed' ? (
                      <>
                        <h2 className="title" style={{ marginTop: 45, textAlign: 'center' }}>
                          Payment Failed
                        </h2>
                        <Link className="website" to={`/campaigns/${selectedCampaign}`} style={{ marginTop: 15, textAlign: 'center' }}> Back to payment </Link>
                      </>
                    ) : (null)}

                    {(!paymentStatus || (paymentStatus && paymentStatus.status !== 'payment_failed' && paymentStatus.status !== 'succeeded')) ? (
                      <>
                        <h2 className="title" style={{ marginTop: 45, textAlign: 'center' }}>
                          Payment Complete
                        </h2>
                        <Button color="link" className="website" onClick={this.refresh} style={{ marginTop: 15, textAlign: 'center' }}> Check status </Button>
                      </>
                    ) : (null)}
                  </div>
                </div>
              </div>
              {/*   )} */}
            </div>
          </div>
        </section >

      </div >
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  vouchersList: state.customer.list || {},
  companies: state.customer.companies || {},
  categories: state.customer.categories || {},
  paymentStatus: state.customer.paymentStatus || null,
});

const mapDispatchToProps = {
  getVouchers: getVoucherList,
  fetchSingleVoucher: getSingleVoucher,
  fetchVoucherCompanies: getVoucherCompanies,
  fetchVoucherCategories: getVoucherCategories,
  doPaymentFunc: doPayment,
  fetchPaymentStatus: getPaymentStatus,
  login: loginAppUser,
  fetchUserData: getAppUserData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess));