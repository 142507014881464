export const DO_LOGIN='user/DO_LOGIN';
export const DO_LOGOUT='user/DO_LOGOUT';
export const GET_USER_DATA='user/GET_USER_DATA';
export const STRIPE_LOGIN_LINK='user/STRIPE_LOGIN_LINK';
export const STRIPE_ACCOUNT_STATUS='/user/STRIPE_ACCOUNT_STATUS';

export const GET_VOUCHER_LIST='vouchers/GET_VOUCHER_LIST';
export const GET_COMPANY_VOUCHERS='vouchers/GET_COMPANY_VOUCHERS';
export const SORT_COMPANY_VOUCHERS='vouchers/SORT_COMPANY_VOUCHERS';
export const GET_VOUCHER_COMPANIES='vouchers/GET_VOUCHER_COMPANIES';
export const GET_VOUCHER_CATEGORIES='vouchers/GET_VOUCHER_CATEGORIES';
export const GET_VOUCHER_SALES_DATA='vouchers/GET_VOUCHER_SALES_DATA';

export const GET_ADMINS='admin/GET_ADMINS';
export const GET_MORE_ADMINS='admin/GET_MORE_ADMINS';
export const UPDATE_ADMIN='admin/UPDATE_ADMIN';
export const DELETE_ADMIN='admin/DELETE_ADMIN';
export const GET_ADMIN_STATISTICS='admin/GET_ADMIN_STATISTICS';
export const GET_ADMIN_STATISTIC='admin/GET_ADMIN_STATISTIC';
export const GET_ADMIN_SETTINGS='./admin/GET_ADMIN_SETTINGS';
export const GET_APP_SETTINGS='./admin/GET_APP_SETTINGS';
export const GET_ADMIN_DASHBOARD='admin/GET_ADMIN_DASHBOARD';
export const GET_ADMIN_DASHBOARD_CUSTOM='admin/GET_ADMIN_DASHBOARD_CUSTOM';
export const GET_ADMIN_DASHBOARD_TOTALS='admin/GET_ADMIN_DASHBOARD_TOTALS';
export const GET_ADMIN_DASHBOARD_STRIPE_DATA='admin/GET_ADMIN_DASHBOARD_STRIPE_DATA';
export const GET_ADMIN_DASHBOARD_STRIPE_CUSTOM_DATA='admin/GET_ADMIN_DASHBOARD_STRIPE_CUSTOM_DATA';
export const GET_ADMIN_PAYMENTS='admin/GET_ADMIN_PAYMENTS';
export const SORT_ADMIN_PAYMENTS='admin/SORT_ADMIN_PAYMENTS';

export const GET_MORE_ADMIN_PAYMENTS='admin/GET_MORE_ADMIN_PAYMENTS';
export const GET_FIRST_ADMIN_PAYMENTS='admin/GET_FIRST_ADMIN_PAYMENTS';
export const GET_SINGLE_ADMIN_PAYMENT = 'admin/GET_SINGLE_ADMIN_PAYMENT';

export const GET_USERS='admin/GET_USERS';
export const COMPANIES_LIST = 'admin/COMPANIES_LIST';

export const UPDATE_USER='admin/UPDATE_USER';
export const DELETE_USER = 'admin/DELETE_USER';
export const GET_COMPANY_COMPLIANCE_DATA = 'admin/GET_COMPANY_COMPLIANCE_DATA';
export const UPDATE_COMPANIES_ACTIVE_API_DATA = 'admin/UPDATE_COMPANIES_ACTIVE_API_DATA';
export const GET_COMPANY_OWNERS='admin/GET_COMPANY_OWNERS';
export const GET_MORE_COMPANY_OWNERS='admin/GET_MORE_COMPANY_OWNERS';
export const UPDATE_COMPANY='admin/UPDATE_COMPANY';
export const GET_COMPANYS_USERS='admin/GET_COMPANYS_USERS';
export const GET_COMPANY_LOGO='admin/GET_COMPANY_LOGO';
export const GET_REQUESTING_CAMPAIGNS='admin/GET_REQUESTING_CAMPAIGNS';

export const SORT_ADMIN_DATA='admin/SORT_ADMIN_DATA';

export const GET_CAMPAIGNS='admin/GET_CAMPAIGNS';
export const UPDATE_CAMPAIGN='admin/UPDATE_CAMPAIGN';
export const GET_MORE_CAMPAIGNS='admin/GET_MORE_CAMPAIGNS';
export const GET_CAMPAIGNS_BY_COMPANY='admin/GET_CAMPAIGNS_BY_COMPANY';
export const UPDATE_CAMPAIGN_BY_COMPANY='admin/UPDATE_CAMPAIGN_BY_COMPANY';
export const GET_MORE_CAMPAIGNS_BY_COMPANY='admin/GET_MORE_CAMPAIGNS_BY_COMPANY';

export const GET_APP_CUSTOMERS='admin/GET_APP_CUSTOMERS';
export const GET_MORE_APP_CUSTOMERS='admin/GET_MORE_APP_CUSTOMERS';
export const UPDATE_APP_CUSTOMER = 'admin/UPDATE_APP_CUSTOMER';

// notifications actions
export const GET_EMAIL_SETTINGS='notifications/GET_EMAIL_SETTINGS';
export const REMOVE_EMAIL_SETTING='notifications/REMOVE_EMAIL_SETTING';
export const GET_COMPANY_NOTIFICATION='notifications/GET_COMPANY_NOTIFICATION';

export const GET_ROLES='admin/GET_ROLES';
export const GET_ADMIN_ROLES = 'admin/GET_ADMIN_ROLES';
export const GET_ADMIN_ACTIONS = 'admin/GET_ADMIN_ACTIONS';
export const GET_COMPANIES_ROLES='admin/GET_COMPANIES_ROLES';
export const GET_ADMINS_ROLES='admin/GET_ADMINS_ROLES';
export const UPDATE_ROLE='admin/UPDATE_ROLE';
export const REMOVE_ROLE='admin/REMOVE_ROLE';

export const GET_COMPANY_USERS='users/GET_COMPANY_USERS';
export const GET_COMPANY_ROLES='users/GET_COMPANY_ROLES';
export const UPDATE_COMPANY_USER='users/UPDATE_COMPANY_USER';
export const DELETE_COMPANY_USER = 'users/DELETE_COMPANY_USER';

export const GET_COMPANY_LINKED_USERS = 'users/GET_COMPANY_LINKED_USERS';
export const UPDATE_COMPANY_LINKED_USER = 'users/UPDATE_COMPANY_LINKED_USER';
export const DELETE_COMPANY_LINKED_USER = 'users/DELETE_COMPANY_LINKED_USER';

// contentManagement actions
export const CONTENT_MANAGEMENT_DATA='contentManagement/CONTENT_MANAGEMENT_DATA';
export const UPDATE_CONTENT_MANAGEMENT_DATA='contentManagement/UPDATE_CONTENT_MANAGEMENT_DATA';
export const DASHBOARD_TOOLTIPS = 'contentManagement/DASHBOARD_TOOLTIPS';

export const NEW_VOUCHER_TOOLTIPS='contentManagement/NEW_VOUCHER_TOOLTIPS';
export const API_TOOLTIPS = 'contentManagement/API_TOOLTIPS';
export const PAYMENT_TOOLTIPS = 'contentManagement/PAYMENT_TOOLTIPS';
export const COMPANY_POPOUT_TOOLTIPS='contentManagement/COMPANY_POPOUT_TOOLTIPS';
export const COMPANY_TABS_TOOLTIPS = 'contentManagement/COMPANY_TABS_TOOLTIPS';
export const COMPANY_INFO_TOOLTIPS = 'contentManagement/COMPANY_INFO_TOOLTIPS';
export const PENDING_TAB='contentManagement/PENDING_TAB';
export const CONTACT_TAB='contentManagement/CONTACT_TAB';
export const API_TAB='contentManagement/API_TAB';
export const NEW_VOUCHER_DESCRIPTION='contentManagement/NEW_VOUCHER_DESCRIPTION';
export const CONTENT_MANAGEMENT_FOOTERS='contentManagement/CONTENT_MANAGEMENT_FOOTERS';
export const BRANDING_DATA='contentManagement/BRANDING_DATA';
export const UPDATE_BRANDING_DATA='contentManagement/UPDATE_BRANDING_DATA';
export const EVENT_CATEGORIES='contentManagement/EVENT_CATEGORIES';
export const UPDATE_EVENT_CATEGORY='contentManagement/UPDATE_EVENT_CATEGORY';
export const REMOVE_EVENT_CATEGORY = 'contentManagement/REMOVE_EVENT_CATEGORY';

export const FAQ_LINKS = 'contentManagement/FAQ_LINKS';
export const SIGNPOSTS = 'contentManagement/SIGNPOSTS';

export const GREETING_TAB = 'admin/GREETING_TAB';
export const GIFTING_PRICE = 'admin/GIFTING_PRICE';
export const APP_GIFT_PREVIEW_LINK = 'admin/APP_GIFT_PREVIEW_LINK';

export const FOOTER_ICONS='contentManagement/FOOTER_ICONS';

export const CARD_TEMPLATES='admin/CARD_TEMPLATES';
export const CARD_TEMPLATES_CATEGORIES='admin/CARD_TEMPLATES_CATEGORIES';
export const CARD_QUOTE='admin/CARD_QUOTE';
export const CARD_QUOTE_DESCRIPTIONS='admin/CARD_QUOTE_DESCRIPTIONS';

export const GET_COMPANY_DATA='company/GET_COMPANY_DATA';
export const GET_COMPANY_OWNER_DATA='company/GET_COMPANY_OWNER_DATA';
export const COMPANY_NOTIFICATIONS='company/COMPANY_NOTIFICATIONS';
export const UPDATE_API_KEY='company/UPDATE_API_KEY';
export const RESET_COMPANY_DATA = 'company/RESET_COMPANY_DATA';
export const COMPLIANCE_DATA = 'company/COMPLIANCE_DATA';
export const GET_CATEGORIES='company/GET_CATEGORIES';
export const GET_REWARDS_UNLOCKED='company/GET_REWARDS_UNLOCKED';
export const GET_COMPANY_CHANGES='company/GET_COMPANY_CHANGES';
export const SORT_COMPANY_PAYMENTS='company/SORT_COMPANY_PAYMENTS';
export const GET_COMPANY_PAYMENTS='company/GET_COMPANY_PAYMENTS';
export const GET_MORE_COMPANY_PAYMENTS='company/GET_MORE_COMPANY_PAYMENTS';
export const GET_COMPANY_PAYMENTS_TOTAL='company/GET_COMPANY_PAYMENTS_TOTAL';
export const UPDATE_SHOPIFY_API = 'company/UPDATE_SHOPIFY_API';
export const UPDATE_WOOCOMMERCE_API = 'company/UPDATE_WOOCOMMERCE_API';
export const UPDATE_MANUAL_API = 'company/UPDATE_MANUAL_API';
export const UPDATE_ACTIVE_API = 'company/UPDATE_ACTIVE_API';
export const GET_SINGLE_COMPANY_PAYMENT='company/GET_SINGLE_COMPANY_PAYMENT';

export const GET_COMPANY_LINKED_COMPANIES = 'company/GET_COMPANY_LINKED_COMPANIES';
export const UPDATE_COMPANY_LINKED_COMPANIES = 'company/UPDATE_COMPANY_LINKED_COMPANIES';

// RESOURCE_CENTER
export const RESOURCE_CENTER_UPLOAD_VIDEO='resourceCenter/RESOURCE_CENTER_UPLOAD_VIDEO';
export const RESOURCE_CENTER_VIDEOS='resourceCenter/RESOURCE_CENTER_VIDEOS';
export const RESOURCE_CENTER_DELETE_VIDEO='resourceCenter/RESOURCE_CENTER_DELETE_VIDEO';
export const RESOURCE_CENTER_ADD_VIDEO='resourceCenter/RESOURCE_CENTER_ADD_VIDEO';
export const RESOURCE_CENTER_EDIT_VIDEO='resourceCenter/RESOURCE_CENTER_EDIT_VIDEO';
//export const RESOURCE_CENTER_CONTRACT='resourceCenter/RESOURCE_CENTER_CONTRACT';
//export const RESOURCE_CENTER_CONTRACT_UPDATE='resourceCenter/RESOURCE_CENTER_CONTRACT_UPDATE';
//export const RESOURCE_CENTER_FAQ='resourceCenter/RESOURCE_CENTER_FAQ';
//export const RESOURCE_CENTER_FAQ_UPDATE='resourceCenter/RESOURCE_CENTER_FAQ_UPDATE';
export const RESOURCE_CENTER_DATA='resourceCenter/RESOURCE_CENTER_DATA';
export const RESOURCE_CENTRE_TEXT = 'resourceCenter/RESOURCE_CENTRE_TEXT';
export const RESOURCE_CENTRE_TOP_LINKS = 'resourceCenter/RESOURCE_CENTRE_TOP_LINKS';

export const OPEN_COMPANY_DASHBOARD='admin/OPEN_COMPANY_DASHBOARD';
export const LEAVE_COMPANY_DASHBOARD='admin/LEAVE_COMPANY_DASHBOARD';

export const SET_ANALYTICS_TEMPLATE='admin/SET_ANALYTICS_TEMPLATE';
export const ANALYTICS_TEMPLATES='admin/ANALYTICS_TEMPLATES';

export const PERKS = 'perks/PERKS';
export const UPDATE_PERK = 'perks/UPDATE_PERK';
export const REMOVE_PERK = 'perks/REMOVE_PERK';
export const SET_PERKS_ENABLED = 'perks/SET_PERKS_ENABLED';











export const CUSTOMER_GET_VOUCHER_LIST = 'customer/CUSTOMER_GET_VOUCHER_LIST';
export const CUSTOMER_GET_VOUCHER_LIST_UNLOCKED = 'customer/CUSTOMER_GET_VOUCHER_LIST_UNLOCKED';

export const GET_SINGLE_ORDER = 'vouchers/GET_SINGLE_ORDER';

export const ADD_GIFTING_DATA = 'vouchers/ADD_GIFTING_DATA';

export const GET_GIFTING_PRICE = 'customer/GET_GIFTING_PRICE';
export const GET_ORDERED_TEMPLATE = 'vouchers/GET_ORDERED_TEMPLATE';
export const GET_SINGLE_VOUCHER = 'vouchers/GET_SINGLE_VOUCHER';
export const GET_SINGLE_VOUCHER_PURCHASED_LEVELS = 'vouchers/GET_SINGLE_VOUCHER_PURCHASED_LEVELS';
export const CUSTOMER_GET_VOUCHER_COMPANIES = 'vouchers/CUSTOMER_GET_VOUCHER_COMPANIES';
export const GET_VOUCHER_ORDERS = 'vouchers/GET_VOUCHER_ORDERS';
export const GET_USER_VOUCHERS = 'customer/GET_USER_VOUCHERS';
export const GET_EVENT_REMINDERS = 'vouchers/GET_EVENT_REMINDERS';

export const DELETE_EVENT_REMINDERS = 'vouchers/DELETE_EVENT_REMINDERS';
export const GET_VOUCHER_IMAGES = 'vouchers/GET_VOUCHER_IMAGES';
export const CUSTOMER_GET_VOUCHER_CATEGORIES = 'customer/CUSTOMER_GET_VOUCHER_CATEGORIES';

export const REMOVE_PAYMENT_DATA = 'vouchers/REMOVE_PAYMENT_DATA';
export const SAVE_PAYMENT_DATA = 'vouchers/SAVE_PAYMENT_DATA';
export const GET_PAYMENT_PROGRESS = 'vouchers/GET_PAYMENT_PROGRESS';
