import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';
// import { Eye, EyeOff } from 'react-feather';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';

import momentLocalizer from 'react-widgets-moment';

import {
  Row,
  Col,
  Modal,
  ModalBody,
  Button,
  Input,
  Spinner,
  Alert,
} from 'reactstrap';

// import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';

import {
  PaymentElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';

import { auth } from '../../../lib/firebase';

// import CheckoutForm from './Components/CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

// import { storage } from '../../../lib/firebase';

import {
  getVoucherList,
  getSingleVoucher,
  getVoucherCompanies,
  getVoucherCategories,
  doPayment,
  companyOffline,
  // loginAppUser,
} from '../../../actions/customer';

import { loginAppUser, getAppUserData } from '../../../actions/user';

// import gift_card from '../../../assets/icons/gift_card.svg';
// import heart from '../../../assets/icons/heart.svg';
import padlock from '../../../assets/icons/padlock.svg';

// import whenToSend from '../../../assets/icons/time.png';

import BoxGift1 from './ReactIcons/BoxGift1';

import notifications from '../../../const/notifications';

const pound = `${'\u00A3'}`;

let stripePromise;

// moment.locale('en-GB');
momentLocalizer();

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: null,
    };
  }

  handleSubmit = async (event) => {
    console.log('CheckoutForm handleSubmit');
    event.preventDefault();
    const { stripe, elements, clientSecret, campaignId } = this.props;

    console.log('CheckoutForm handleSubmit 2', clientSecret);

    if (elements == null) {
      return;
    }

    this.setState({ spinner: true });

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.log('submitError', submitError);
      // Show error to your customer

      this.setState({ spinner: null });

      return;
    }

    // Create the PaymentIntent and obtain clientSecret
    //  const res = await fetch('/create-intent', {
    //    method: 'POST',
    //  });

    // const { client_secret: clientSecret } = await res.json();

    console.log('elements', elements);

    const { hostname } = window.location;

    console.log('hostname', hostname);

    const return_url = `https://${hostname}/payment-success/${campaignId}`;

    console.log('return_url', return_url);

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: return_url, // `https://steppr-client.web.app/payment-success/${campaignId}`,
      },
    });

    this.setState({ spinner: null });

    if (error) {
      console.log('error', error);
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
    } else {
      console.log('retuern url');
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  render() {
    const { stripe, processingPaymentAmount } = this.props;
    const { spinner } = this.state;

    const { hostname } = window.location;

    console.log('hostname', hostname);

    let processingPaymentAmountParsed;

    console.log('processingPaymentAmount', processingPaymentAmount);

    if (processingPaymentAmount) {
      processingPaymentAmountParsed = (processingPaymentAmount / 100).toFixed(2);
    }

    console.log('processingPaymentAmountParsed', processingPaymentAmountParsed);

    return (
      <form onSubmit={this.handleSubmit}>
        <PaymentElement />
        <button className="stripe-pay-button" type="submit" disabled={!stripe}>
          <span className="stripe-pay-button-title">
            {`Pay ${pound}${processingPaymentAmount ? processingPaymentAmountParsed : ''}`}
            {(spinner) ? (
              <Spinner animation="border" variant="primary" style={{ width: '1.2rem', height: '1.2rem', marginLeft: 10 }}> {''} </Spinner>
            ) : (null)}
          </span>
        </button>
      </form>
    );
  }
}

const InjectedCheckoutForm = ({ customerSecret, processingPaymentAmount, campaignId }) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm campaignId={campaignId} stripe={stripe} elements={elements} clientSecret={customerSecret} processingPaymentAmount={processingPaymentAmount} />
    )}
  </ElementsConsumer>
);



class SingleCampaign extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    // getVouchers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    const { match } = props;

    console.log('match', match);

    const campaignId = match && match.params && match.params.id ? match.params.id : null;

    console.log('campaignId', campaignId);

    this.state = {
      error: null,
      loading: false,
      activeTab: '1',
      selectedCampaign: campaignId || null,
      selectedVoucher: null,
      selectedStep: 1,

      recipientInputOpen: false,
      paymentsInputOpen: false,

      message: '',
      name: '',
      email: '',

      CVC: '',
      expirationDate: '',
      cardNumber: '',
      voucherDataFetched: null,
      giftDateSet: null,
      giftDate: null,
    };
  }

  componentDidMount() {
    const { doPaymentFunc, user, history, match, companies, fetchSingleVoucher, fetchVoucherCompanies } = this.props;
    const { selectedCampaign } = this.state;

    const campaignId = match && match.params && match.params.id ? match.params.id : null;

    fetchVoucherCompanies().then(() => {
      auth.onAuthStateChanged(user => {
        // console.log('onAuthStateChanged', user.uid);
      //  if (user && user.uid) {
        fetchSingleVoucher(campaignId, user ? user.uid : null).then((res) => {
          console.log('fetchSingleVoucher', res.payload);
          this.setState({ voucherDataFetched: true });

          // const { companyData } = res.payload;

          const { companies } = this.props;

          console.log('getVouchers res', res, companies);

          const campaignData = res.payload;

          console.log('campaignData', campaignData, campaignId);

          let companyData;
          if (companies && campaignData && campaignData.company) {
            console.log('companies', companies[campaignData.company.id]);
            companyData = companies[campaignData.company.id];
          }

          console.log('companyDatacompanyData', companyData, campaignData.company.id);

          // stripe_account: "acct_1Q6ryTPJNILJ5Qu1",
          if (companyData && companyData.companyStripeId) {
            console.log('process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
            const promise1 = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, { stripeAccount: companyData.companyStripeId });

            Promise.all([promise1]).then((promisesRes) => {

              console.log('stripe promise res');

              promisesRes.map((resp, index) => {
                if (index === 0) {
                  const stripe = resp;

                  doPaymentFunc();

                  console.log('stripe awaited', stripe);

                  stripePromise = stripe;
                }
              });
            });
          }
        }).catch((err) => {
          this.setState({ voucherDataFetched: true });
        });
       // }
      });
    //  }
    });
  }

  componentDidUpdate() {
    const { match, fetchSingleVoucher } = this.props;
    const { selectedCampaign } = this.state;

    const campaignId = match && match.params && match.params.id ? match.params.id : null;

    if (campaignId !== selectedCampaign) {
      fetchSingleVoucher(campaignId).then((res) => {
        console.log('fetchSingleVoucher', res.payload);
        console.log('campaign changed');

        this.setState({ selectedCampaign: campaignId });

        const { vouchersList, companies } = this.props;

        // const campaignData = vouchersList[campaignId];
        const campaignData = res.payload;

        console.log('campaignData', campaignData, campaignId);

        let companyData;
        if (companies && campaignData && campaignData.company) {
          console.log('companies', companies[campaignData.company.id]);
          companyData = companies[campaignData.company.id];
        }

        console.log('companyDatacompanyData', companyData);

        if (companyData && companyData.companyStripeId) {
          console.log('process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
          const promise1 = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, { stripeAccount: companyData.companyStripeId });

          Promise.all([promise1]).then((promisesRes) => {
            console.log('stripe promise res');

            promisesRes.map((resp, index) => {
              if (index === 0) {
                const stripe = resp;

                console.log('stripe awaited', stripe);

                stripePromise = stripe;
              }
            });
          });
        }
      });
    };
  }

  /* fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  
  openPreview = async (voucherId) => {
    const { vouchersList, fetchSingleVoucher } = this.props;

    console.log('openPreview', voucherId, vouchersList[voucherId]);

    const { logo, image, images, discount, availableVouchers } = vouchersList[voucherId];

    fetchSingleVoucher(voucherId).then(async (res) => {
      console.log('fetchSingleVoucher', res.payload);

      const { companyData } = res.payload;

      console.log('companyData', companyData);

      const { primaryVoucherImage, logo } = companyData;

      let companyPrimaryVoucherImage;
      let logoImage;

      if (primaryVoucherImage && logo) {
        console.log('test aaaa');
        const primaryVoucherImageReference = storage.ref(primaryVoucherImage);
        const logoReference = storage.ref(logo);

        console.log('test bbbb');

        await primaryVoucherImageReference.getDownloadURL().then((url) => {
          companyPrimaryVoucherImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });

        console.log('test cccc');

        await logoReference.getDownloadURL().then((url) => {
          logoImage = url;
        }).catch((error) => {
          console.log('get image error', { error });
        });

        this.setState({ previewModal: true, previewVoucher: vouchersList[voucherId], primaryVoucherImage: companyPrimaryVoucherImage || null, logo: logoImage || null, discount, availableVouchers });
      }
    });
  } */

  /* closePreview = () => {
    this.setState({ previewModal: false, primaryVoucherImage: null, logo: null });
  }

  toggleReadMore = () => {
    this.setState((prevState) => ({ readMore: !prevState.readMore }))
  }

  togglePreviewStep = (step) => {
    this.setState({ previewStep: step });
  } */

  openPurchaseModal = (selectedVoucher) => {
    const { selectedStep } = this.state;
    console.log('openPurchaseModal', selectedStep, selectedVoucher);

    this.setState({ purchaseModal: true, selectedVoucher });
  }

  closePurchaseModal = () => {
    console.log('closePurchaseModal');

    this.setState({ purchaseModal: false, selectedVoucher: null });
  }

  finishPayment = () => {
    const { doPaymentFunc, vouchersList, companies, giftingPrice, companyOfflineFunc } = this.props;
    const {
      selectedStep, // step is discount val
      selectedVoucher, // this is voucher val in pounds
      CVC,
      expirationDate,
      cardNumber,
      expirationDateMonth,
      expirationDateYear,
      name,
      email,
      message,
      selectedCampaign,
      selectedGift,
      giftDate,
      giftDateSet,
    } = this.state;

    console.log('finishPayment', selectedVoucher, selectedStep);

    const selectedVoucherData = vouchersList[selectedCampaign];

    // if (!(selectedStep || selectedStep === 0) || !(selectedVoucher || selectedVoucher === 0)) {

    if (!(selectedVoucher || selectedVoucher === 0) || !(selectedStep || selectedStep === 0)) {
      return null;
    }

    console.log('selectedVoucherData', selectedVoucherData, selectedStep, selectedVoucher);

    if (selectedVoucherData) {
      const price = Number(selectedVoucherData.availableVouchers[selectedVoucher]).toFixed(2);
      const discount = selectedVoucherData.discount[selectedStep];

      console.log('price 4', ((price * 100) - Math.round((price * 100) * (discount / 100))) + ((selectedGift && giftingPrice) ? (Math.round(giftingPrice * 100)) : 0));

      const giftingAmount = ((selectedGift && giftingPrice) ? (Math.round(giftingPrice * 100)) : 0);
      const voucherAmount = ((price * 100) - Math.round((price * 100) * (discount / 100)));

      const amount = (voucherAmount || 0) + (giftingAmount || 0);

      console.log('amount', amount);

      const paymentData = {
        //  card,
        amount: amount, // ((price * 100) - Math.round((price * 100) * (discount / 100)))
        currency: 'GBP',
        voucherAmount,
        giftingAmount,
      }

      console.log('paymentData', paymentData);

      // voucherRef = firestore.collection(`vouchers`).doc(selectedCampaign);

      let parsedDate = null;

      if (giftDateSet && giftDate) {
        parsedDate = Date.parse(giftDate);
      }

      const voucherData = {
        voucherStep: selectedStep,
        voucherId: selectedVoucher,
        buyFor: selectedGift ? 'gift' : 'self',
        voucher: selectedCampaign,
        giftingData: selectedGift ? {
          name,
          email,
          message,
          date: (parsedDate) ? parsedDate : null,
        } : {}
      };

      console.log('finishPaymentfinishPayment', paymentData, voucherData);

      this.setState({ processingPaymentSpinner: true });

      companyOfflineFunc(selectedVoucherData.companyId).then((companyOfflineRes) => {
        console.log('companyOfflineRes', companyOfflineRes);

        if (!companyOfflineRes || !companyOfflineRes.status || companyOfflineRes.status !== 'online') {
          this.setState({ processingPaymentSpinner: null, paymentError: "Oops, this campaign is currently unavailable. Please try again soon." });
          return null;
        }

        doPaymentFunc(paymentData, voucherData, null).then((res) => {
          console.log('do payment res', res, res.clientSecret);

          if (res && res.clientSecret) {
            console.log('res.clientSecret', res.clientSecret);

            this.setState({
              showStripePaymentFlow: true,
              processingPaymentAmount: amount, // ((price * 100) - Math.round((price * 100) * (discount / 100)))
              customerSecret: res.clientSecret,
              processingPaymentSpinner: null,
            });

            /* console.log('companies[selectedVoucherData.companyId]', companies[selectedVoucherData.companyId]);
  
            const companyStripeId = companies[selectedVoucherData.companyId].companyStripeId;
  
            console.log('companyStripeId', companyStripeId);
  
            (async () => {
              console.log('stripePromise', stripePromise);
              const { paymentIntent, error } = await stripePromise.confirmCardPayment(res.clientSecret);
              if (error) {
                console.log('confirmCardPayment err', error);
              } else if (paymentIntent && paymentIntent.status === 'succeeded') {
                console.log('confirmCardPayment succeeded');
              }
            })(); */
          } else {
            this.setState({ processingPaymentSpinner: null, paymentError: "Oops, this campaign is currently unavailable. Please try again soon." });
          }
        }).catch((err) => {
          this.setState({ processingPaymentSpinner: null, paymentError: "Oops, this campaign is currently unavailable. Please try again soon." });
        });
      }).catch((err) => {
        this.setState({ processingPaymentSpinner: null, paymentError: "Oops, this campaign is currently unavailable. Please try again soon." });
      });
    }
  }

  /* changeStep = (step) => {
    console.log('changeStep', step);

    this.setState({ selectedStep: parseInt(step, 10), processingPaymentSpinner: null });
  } */

  handleChange = e => this.setState({ [e.target.name]: e.target.value, recipientInputError: null });
  

  /*
  handleSubmit222 = (e) => {
    e.preventDefault();

    const { login, history, fetchUserData, getVouchers, fetchVoucherCompanies, fetchVoucherCategories } = this.props;
    const { email, password, loading } = this.state;

    if (loading) return;

    this.setState({ loading: true });

    console.log('handleSubmit', email, password);

    return login({ email, password })
      .then((res) => {
        console.log('login then', { res });

        fetchUserData();

        // getVouchers();
        // fetchVoucherCompanies();
        fetchVoucherCategories();
      })
      .catch((err) => {
        console.log('login error', err);

        console.log(notifications.login);
        if (err === 'missing email') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noEmail || err });
        } else if (err === 'missing password') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noPassword || err });
        } else if (err === 'The email address is badly formatted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.badEmail || err });
        } else if (err === 'To access your account please click the verification link in the email sent to you.') {
          this.setState({ loading: false, errorGreen: true, error: notifications.login.needVerification || err });
        } else if (err === 'The password is invalid or the user does not have a password.' || 'There is no user record corresponding to this identifier. The user may have been deleted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.wrongLogin || err });
        } else {
          this.setState({ loading: false, errorGreen: false, error: err });
        }
      });
  } */

  /*
  toggleShowPassword = (id) => {
    console.log('toggleShowPassword');
    console.log(id);
    if (id === 1) {
      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  } */

  changeGift = () => {
    this.setState((prevState) => ({ selectedGift: !prevState.selectedGift }));
  }

  changeAvailableVouchers = (id, disabled) => {
    if (disabled) {
      return null;
    }
    this.setState({ selectedVoucher: parseInt(id, 10) });
  }

  changeDiscount = (id) => {
    const { voucherPurchasedLevels } = this.props;
    const { selectedCampaign, selectedVoucher, selectedStep } = this.state;

    let voucherPurchasedLevelsThisVoucher = {};

    if (voucherPurchasedLevels && voucherPurchasedLevels[selectedCampaign]) {
      voucherPurchasedLevelsThisVoucher = voucherPurchasedLevels[selectedCampaign];
    }

    console.log("voucherPurchasedLevelsThisVoucher", voucherPurchasedLevelsThisVoucher, selectedStep, id);

    // If step 1 check if this step already bought, disabled if bought
    /*  const bool1 = (selectedStep === 1 && (voucherPurchasedLevelsThisVoucher[selectedStep] && voucherPurchasedLevelsThisVoucher[selectedStep][parseInt(id, 10)]));
      // If step > 1 check if this step already bought, disabled if bought
      const bool2 = ((selectedStep > 1 && (voucherPurchasedLevelsThisVoucher[selectedStep] && voucherPurchasedLevelsThisVoucher[selectedStep][parseInt(id, 10)])));
      // If step > 1 check if previous step is bought, disabled if not previous bought
      const bool3 = ((selectedStep > 1 && !(voucherPurchasedLevelsThisVoucher[selectedStep - 1] && voucherPurchasedLevelsThisVoucher[selectedStep - 1][parseInt(id, 10)])));
  
      // If step 1 check if this step already bought 2nd step, disabled if bought (having second bought an only be true if first was bought before)
      const bool4 = (selectedStep === 1 && !(voucherPurchasedLevelsThisVoucher[selectedStep + 1] && voucherPurchasedLevelsThisVoucher[selectedStep + 1][parseInt(id, 10)]));
  
      const disabled = !(bool1 || bool2 || bool3 || bool4);
  
      console.log('disableddisableddisableddisabled', disabled, bool1, bool2, bool3, bool4);  */

    this.setState({ selectedStep: parseInt(id, 10), selectedVoucher: null });
  }

  openPaymentInputs = () => {
    const { history } = this.props;

    const { selectedGift, selectedStep, selectedVoucher } = this.state;

    console.log('openPaymentInputs', selectedGift, selectedStep, selectedVoucher);

    if (!auth.currentUser || !auth.currentUser.uid) {
      this.scrollToTop();
      history.push(`/login`);
    }

    if (!(selectedStep || selectedStep === 0) || !(selectedVoucher || selectedVoucher === 0)) {
      console.log('please select step and discount');

      return null;
    }

    if (selectedGift) {
      this.setState({ recipientInputOpen: true, paymentsInputOpen: false });
    } else {
      this.finishPayment();
    }
  }

  openPaymentInputs2 = () => {
    const { message, name, email } = this.state;

    console.log('openPaymentInputs2', message, name, email);

    if (!message || !name || !email) {
      this.setState({ recipientInputError: 'Please complete recipient details' });
      return null;
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      this.setState({ recipientInputError: 'Recipient email needs to a single, valid email address' });
      return null;
    }

    this.finishPayment();
  }

  returnToVoucherInputs = () => {
    this.setState({ recipientInputOpen: false, paymentsInputOpen: false });
  }

  handleSubmitStripePayment = async (event) => {
    console.log('handleSubmitStripePayment');
    event.preventDefault();
    const { stripe, elements } = this.props;

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      return;
    }

    // Create the PaymentIntent and obtain clientSecret
    const res = await fetch('/create-intent', {
      method: 'POST',
    });

    const { client_secret: clientSecret } = await res.json();

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://example.com/order/123/complete',
      },
    });
  };

  closeModal = () => {
    this.setState({ showStripePaymentFlow: null, processingPaymentAmount: null });
  }

  setImageLoaded = (id) => {
    this.setState({ [id]: true });
  }

  scrollToTop = () => {
    console.log('scrollToTop');
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }

  toggleGiftDateSection = () => {
    const { giftDateOpen } = this.state;

    console.log('toggleGiftDateSection', giftDateOpen);

    this.setState({ giftDateOpen: !giftDateOpen });
  }

  changeGiftDate = (date) => {
    console.log('changeGiftDate', date);

    this.setState({ giftDateSet: true, giftDate: date });
  }

  render() {
    const { user, vouchersList, companies, categories, orderedVouchers, voucherPurchasedLevels, giftingPrice } = this.props;
    const {
      activeTab,
      voucherDataFetched,

      activeCategory,
      selectedCampaign,
      selectedVoucher,
      selectedStep,
      selectedGift,
      recipientInputOpen,
      paymentsInputOpen,

      message,
      name,
      email,

      showStripePaymentFlow,
      customerSecret,
      primaryLoaded,
      logoLoaded,
      processingPaymentSpinner,
      processingPaymentAmount,
      recipientInputError,
      giftDateOpen,
      giftDate,
      giftDateSet,
      paymentError,
    } = this.state;

    let amount;
    let amountInit;
    let giftingAmount;
    let giftingAmountInit;

    if (vouchersList && selectedCampaign && vouchersList[selectedCampaign] && (selectedVoucher || selectedVoucher === 0) && (selectedStep || selectedStep === 0)) {
      const selectedVoucherData = vouchersList[selectedCampaign];

      const price = Number(selectedVoucherData.availableVouchers[selectedVoucher]).toFixed(2);
      const discount = selectedVoucherData.discount[selectedStep];

    //  console.log('price', price, discount);

      amountInit = ((price * 100) - Math.round((price * 100) * (discount / 100)));
      giftingAmountInit = ((selectedGift && giftingPrice) ? (Math.round(giftingPrice * 100)) : 0);

      if (amountInit) {
        amount = (amountInit / 100).toFixed(2);
      }

      if (giftingAmountInit) {
        giftingAmount = (giftingAmountInit / 100).toFixed(2);
      }
    }

  //  console.log('amountamountamount', amount, giftingAmount);

    let voucherPurchasedLevelsThisVoucher = {};

  //  if (voucherPurchasedLevels && voucherPurchasedLevels[selectedCampaign]) {
  //    console.log('voucherPurchasedLevels[selectedCampaign]', voucherPurchasedLevels[selectedCampaign]);

    //  voucherPurchasedLevelsThisVoucher = voucherPurchasedLevels[selectedCampaign];
  //  }

    // const selectedCampaignVoucherDoc = firestore.collection(DB_TABLE_NAME.vouchers).doc(selectedCampaign);
    // const thisCampaignOrderedVouchers = {};
    const voucherPurchasedLevelsThisVoucher2 = [];

  //  console.log('orderedVouchers', orderedVouchers);

    Object.keys(orderedVouchers).map((key) => {
      if (orderedVouchers[key].voucher && orderedVouchers[key].voucher.id === selectedCampaign) {
        // thisCampaignOrderedVouchers[key] = orderedVouchers[key];

      //  console.log('ordered vouhcer', key, orderedVouchers[key].voucherStep, orderedVouchers[key].voucherId, orderedVouchers[key]);

        if (voucherPurchasedLevelsThisVoucher2[orderedVouchers[key].voucherStep]) {
          voucherPurchasedLevelsThisVoucher2[orderedVouchers[key].voucherStep][orderedVouchers[key].voucherId] = true;
        } else {
          voucherPurchasedLevelsThisVoucher2[orderedVouchers[key].voucherStep] = { [orderedVouchers[key].voucherId]: true };
        }

        // voucherPurchasedLevelsThisVoucher2[orderedVouchers[key].voucherStep][orderedVouchers[key].voucherId] = true;
      }
    });

  //  console.log('voucherPurchasedLevelsThisVoucher2', voucherPurchasedLevelsThisVoucher2);

    voucherPurchasedLevelsThisVoucher = voucherPurchasedLevelsThisVoucher2;

    let campaignData = {};

    if (vouchersList && vouchersList[selectedCampaign]) {
      campaignData = vouchersList[selectedCampaign];
    }

    const options = {
      clientSecret: customerSecret,
    };

    if (!voucherDataFetched || !campaignData || !Object.keys(campaignData).length) {
      return (
        <div className="preloader">
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div class="breadcrumbs">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">Voucher Campaign Details</h1>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <ul class="breadcrumb-nav">
                  <li><Link onClick={this.scrollToTop} to="/">Home</Link></li>
                  <li>Voucher Campaign Details</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section className="item-details section">
          <div className="container">
            <div className="top-area">
              <Modal size="md" isOpen={(showStripePaymentFlow && customerSecret)} toggle={this.closeModal} className="stripe-pay-modal">
                <ModalBody>
                  <Elements stripe={stripePromise} options={options}>
                    <InjectedCheckoutForm customerSecret={customerSecret} campaignId={selectedCampaign} processingPaymentAmount={processingPaymentAmount} />
                  </Elements>
                </ModalBody>
              </Modal>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="product-info" style={{ paddingLeft: 0 }}>
                    <h2 className="title">{campaignData.companyName || ''}</h2>
                    {(companies[campaignData.companyId] && companies[campaignData.companyId].website) ? (
                      <a className="website" target="_blank" href={companies[campaignData.companyId].website} style={{ marginTop: 10 }}>
                        Business website
                      </a>
                    ) : (
                      <a className="website" target="_blank" style={{ marginTop: 10 }} />
                    )}
                  </div>
                  <div className="product-images">
                    <main id="gallery">
                      <div className="main-img">
                        <LazyLoadImage
                          alt={''}
                          src={campaignData.primaryImage || null}
                          onLoad={() => { this.setImageLoaded(`primaryLoaded`) }}
                          style={!primaryLoaded ? { opacity: 0, position: 'absolute' } : { opacity: 1, position: 'static' }}
                        />
                        {primaryLoaded ? (null) : (
                          <div class="primary-placeholder" />
                        )}
                        <LazyLoadImage
                          alt={''}
                          src={campaignData.logo || null}
                          onLoad={() => { this.setImageLoaded(`logoLoaded`) }}
                          className="product-info-logo"
                          style={!logoLoaded ? { opacity: 0 } : { opacity: 1 }}
                        />
                        {logoLoaded ? (null) : (
                          <div class="logo-placeholder" />
                        )}
                      </div>
                      <div className="single-block description">
                        <Row>
                          <div className="product-info-description-col">
                            <p> {campaignData.description || null} </p>
                          </div>
                        </Row>
                        <Row>
                          <div className="product-info-description-col">
                            <span> {`Voucher expiry: `} </span>
                            <span className="product-info-description-highlight">{` ${campaignData.expiry || ''} years `} </span>
                            <span> {` from purchase. `}</span>
                          </div>
                        </Row>
                        <Row>
                          <div className="product-info-description-col">
                            <span> {` You are purchasing a voucher from `} </span>
                            <span className="product-info-description-highlight">{` ${campaignData.companyName || ''} `} </span>
                            <span> {` not Steppr. `}</span>
                          </div>
                        </Row>
                      </div>
                    </main>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-12">
                  {paymentError ? (
                    <Col xs="12" style={{ marginLeft: 15, marinBottom: 15 }}>
                      <Alert color="danger" style={{ marginBottom: 0, marginTop: '0.5rem' }}>{paymentError} </Alert>
                    </Col>
                  ) : (null)}
                  {recipientInputOpen ? (
                    <div class="product-info">
                      <Row>
                        <Col xs="8">
                          <h6>
                            Select Your Voucher
                          </h6>
                        </Col>
                        <Col xs="4">
                          <h6>
                            <p class="product-info-edit-button" onClick={this.returnToVoucherInputs}>Edit</p>
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="list-info">
                          <span>
                            {'Loyalty Discount Level: '}
                          </span>
                          <b style={{ paddingLeft: 12, color: '#00B2FF' }}>
                            {campaignData.discount[selectedStep]}%
                          </b>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="list-info">
                          <span>
                            {'Voucher Value: '}
                          </span>
                          <b style={{ paddingLeft: 12, color: '#00B2FF' }}>
                            {pound}{campaignData.availableVouchers[selectedVoucher]}
                          </b>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="list-info">
                          <span>
                            {'You Pay: '}
                          </span>
                          <b style={{ paddingLeft: 12, color: '#00B2FF' }}>
                            {`${pound}${(campaignData.availableVouchers[selectedVoucher] * ((100 - campaignData.discount[selectedStep]) / 100)).toFixed(2)}`}
                          </b>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="list-info">
                          <span>
                            {'Purchase Type: '}
                          </span>
                          <b style={{ paddingLeft: 12, color: '#00B2FF' }}>
                            {selectedGift ? `Gift - Bespoke Scheduled Email Service - ${pound}${giftingPrice}` : 'Self'}
                          </b>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs="12" style={{ paddingTop: 15, marginTop: 15 }}>
                          <h6>
                            Complete Recipient Details For Your Voucher
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="list-info">
                          <span>
                            We'll send a beautiful, timed gift email to your selected recipient. Any discounts are hidden.
                          </span>
                        </Col>
                      </Row>
                          <Row>
                            {recipientInputError ? (
                              <Col xs="12" style={{ marginLeft: 15 }}>
                                <Alert color="danger" style={{ marginBottom: 0, marginTop: '0.5rem' }}>{recipientInputError} </Alert>
                              </Col>
                            ) : (null)}
                            <Col xs="12" className="list-info">
                              <h6 className="list-info-input-label">
                                Name
                              </h6>
                              <div>
                                <Input
                                  name="name"
                                  type="text"
                                  value={name}
                                  placeholder="Giggles McGee"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" className="list-info">
                              <h6 className="list-info-input-label">
                                Email Address
                              </h6>
                              <div>
                                <Input
                                  name="email"
                                  type="text"
                                  value={email}
                                  placeholder="GiggleMcGee@steppr.com"
                                  onChange={this.handleChange}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" className="list-info">
                              <h6 className="list-info-input-label">
                                Your Message
                              </h6>
                              <div>
                                <Input
                                  name="message"
                                  type="textarea"
                                  maxLength="800"
                                  value={message}
                                  placeholder="(Don't forget to say who it's from)"
                                  onChange={this.handleChange}
                                  className="list-info-textarea"
                                />
                                <div className="list-info-char-count">
                                  <span className="list-info-char-count-cur">{message ? (message.length || 0) : 0}</span>
                                  <span className="list-info-char-count-max">/ 800</span>
                                </div>
                              </div>
                            </Col>
                      </Row>
                      <Row>
                        <Col xs="12" className="list-info">
                      <DateTimePicker
                            className="date-picker list-info-date"
                            style={{ border: '1px solid #BAE9FF' }}
                        time={true}
                        culture="en"
                        format="MMM DD YYYY HH:mm"
                        onChange={this.changeGiftDate}
                        value={giftDate}
                            defaultValue={new Date()}
                            placeholder="Default is immediate delivery"
                          />
                        </Col>
                      </Row>

                      <Row>
                        {/* <Col xs="4" md="5" lg="4">
                          <Button
                            onClick={this.toggleGiftDateSection}
                            color="primary"
                            className="recipient-when-to-send-button"
                            style={{
                              background: '#00B2FF',
                            }}
                          >
                            <img
                              src={whenToSend}
                              alt=""
                              className="filter_white recipient-when-to-send-button-icon"
                            />
                            {(giftDateSet && giftDate) ? (
                              <div style={{ fontSize: 11 }}>
                                 <div>
                                </div>
                              </div>
                            ) : (
                              <div>When?</div>
                            )}
                          </Button>
                          <div className="recipient-when-to-send-button-info">Leave for immediate</div>


                        </Col> */}
                        <Col xs="12" md="12" lg="12" onClick={this.openPaymentInputs2} style={{ marginBottom: 25, paddingRight: 0 }}>
                          <Button
                            onClick={this.openPaymentInputs2}
                            color="primary"
                            className="recipient-next-button"
                            style={{
                              background: '#00B2FF',
                            }}
                          >
                            {`Let's Go ${amount ? `${pound}${amount}` : ''} ${giftingAmount ? ` + ${pound}${giftingAmount}` : ''}`}
                            {(processingPaymentSpinner) ? (
                              <Spinner animation="border" variant="primary" style={{ width: '1.2rem', height: '1.2rem', marginLeft: 10, marginTop: 8, position: 'absolute' }}> {''} </Spinner>
                            ) : (null)}
                          </Button>
                          <div className="recipient-next-button-info" style={{ textAlign: 'right' }}>
                            Please check the <a href="/app-t-and-c" target="_blank" style={{ color: "#00aeff", fontWeight: 600 }}>T&Cs</a> before buying.
                          </div>

                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <>
                      {paymentsInputOpen ? (
                        <div class="product-info">
                          <Row>
                            <Col xs="8">
                              <h6>
                                Select Your Voucher
                              </h6>
                            </Col>
                            <Col xs="4">
                              <h6>
                                <p class="product-info-edit-button" onClick={this.returnToVoucherInputs}>Edit</p>
                              </h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" className="list-info">
                              <span>
                                {'Loyalty Discount Level: '}
                              </span>
                              <b style={{ paddingLeft: 12, color: '#00B2FF' }}>
                                {campaignData.discount[selectedStep]}%
                              </b>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" className="list-info">
                              <span>
                                {'Voucher Value: '}
                              </span>
                              <b style={{ paddingLeft: 12, color: '#00B2FF' }}>
                                {pound}{campaignData.availableVouchers[selectedVoucher]}
                              </b>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" className="list-info">
                              <span>
                                {'You Pay: '}
                              </span>
                              <b style={{ paddingLeft: 12, color: '#00B2FF' }}>
                                {`${pound}${(campaignData.availableVouchers[selectedVoucher] * ((100 - campaignData.discount[selectedStep]) / 100)).toFixed(2)}`}
                              </b>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" className="list-info">
                              <span>
                                {'Purchase Type: '}
                              </span>
                              <b style={{ paddingLeft: 12, color: '#00B2FF' }}>
                                {selectedGift ? `Gift - Bespoke Scheduled Email Service - ${pound}${giftingPrice}` : 'Self'}
                              </b>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" onClick={this.openPaymentInputs2} className={`product-info-discount-step product-info-discount-step-active`}>
                              <Button
                                onClick={this.finishPayment}
                                color="primary"
                                className="recipient-next-button"
                                style={{
                                  marginTop: 15,
                                  marginLeft: 10,
                                  width: '100%',
                                  height: '100%',
                                  color: '#FFFFFF',
                                  background: '#00B2FF',
                                }}
                              >
                                Continue to stripe
                                {(processingPaymentSpinner) ? (
                                  <Spinner animation="border" variant="primary" style={{ width: '1.2rem', height: '1.2rem', marginLeft: 10, marginTop: 0, position: 'absolute' }}> {''} </Spinner>
                                ) : (null)}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div class="product-info">
                          <h6>Select Your Voucher</h6>
                          <p class="location">Choose your loyalty discount level</p>
                          <Row>
                            {campaignData.discount ? Object.keys(campaignData.discount).map((key, index) => (
                              <Col xs="4" onClick={() => this.changeDiscount(key)} className={`product-info-discount-step ${selectedStep === parseInt(key, 10) ? 'product-info-discount-step-active' : ''}`}>
                                <div
                                  className={`product-info-discount-step-wrapper ${selectedStep === parseInt(key, 10) ? 'product-info-discount-step-wrapper-active' : ''}`}
                                  style={{ minHeght: 56 }}
                                >
                                  <div className="product-info-discount-step-title">
                                    {`${campaignData.discount[key]}%`}
                                  </div>
                                  <div className="product-info-discount-step-info">
                                    {`Step ${index + 1}`}
                                  </div>
                                </div>
                              </Col>
                            )) : (
                              <>
                                <Col xs="4" className={`product-info-discount-step product-info-discount-step-active`}>
                                  <div
                                    className={`product-info-discount-step-wrapper product-info-discount-step-wrapper-active`}
                                    style={{ minHeght: 56 }}
                                  />
                                </Col>
                                <Col xs="4" className={`product-info-discount-step`}>
                                  <div className={`product-info-discount-step-wrapper`} style={{ minHeght: 56 }} />
                                </Col>
                                <Col xs="4" className={`product-info-discount-step`}>
                                  <div className={`product-info-discount-step-wrapper`} style={{ minHeght: 56 }} />
                                </Col>
                              </>
                            )}
                          </Row>

                          <p class="location">Pick your voucher value</p>
                          <Row>
                            {campaignData.availableVouchers ? Object.keys(campaignData.availableVouchers).map((key, index) => {
                              // If step 1 check if this step already bought 1st step, disabled if bought
                              const bool1 = (selectedStep === 1 && (voucherPurchasedLevelsThisVoucher[selectedStep] && voucherPurchasedLevelsThisVoucher[selectedStep][parseInt(key, 10)]));
                              // If step > 1 check if this step already bought, disabled if bought
                              const bool2 = ((selectedStep > 1 && (voucherPurchasedLevelsThisVoucher[selectedStep] && voucherPurchasedLevelsThisVoucher[selectedStep][parseInt(key, 10)])));
                              // If step > 1 check if previous step is bought, disabled if not previous bought
                              const bool3 = ((selectedStep > 1 && !(voucherPurchasedLevelsThisVoucher[selectedStep - 1] && voucherPurchasedLevelsThisVoucher[selectedStep - 1][parseInt(key, 10)])));
                              // If step 1 check if this step already bought 2nd step, disabled if bought (having second bought an only be true if first was bought before)
                              const bool4 = (selectedStep === 1 && (voucherPurchasedLevelsThisVoucher[selectedStep + 1] && voucherPurchasedLevelsThisVoucher[selectedStep + 1][parseInt(key, 10)]));

                              const disabled = (bool1 || bool2 || bool3 || bool4);

                              console.log('disabled', disabled, bool1, bool2, bool3, bool4, selectedStep, selectedStep === 1, selectedStep > 1, key, voucherPurchasedLevelsThisVoucher);

                              return (
                                <Col
                                  xs="4"
                                  onClick={() => this.changeAvailableVouchers(key, disabled)}
                                  className={`product-info-discount-step ${disabled ? 'product-info-discount-step-disabled' : ''} ${selectedVoucher === parseInt(key, 10) ? 'product-info-discount-step-active' : ''}`}
                                  disabled={disabled}
                                >
                                  <div
                                    className={`product-info-discount-step-wrapper ${(!disabled && selectedVoucher === parseInt(key, 10)) ? 'product-info-discount-step-wrapper-active' : ''}`}
                                    style={{ minHeght: 56 }}
                                  >
                                    <div className="product-info-discount-step-title">
                                      {pound}{campaignData.availableVouchers[key]}
                                    </div>
                                    {console.log('selectedStepselectedStep', selectedStep, selectedVoucher)}

                                    <div className="product-info-discount-step-info">
                                      {`You pay ${pound}${(campaignData.availableVouchers[key] * ((100 - campaignData.discount[selectedStep]) / 100)).toFixed(2)}`}
                                    </div>

                                  </div>
                                  <div>
                                    {selectedStep > 1 ? (
                                      <div className="product-info-discount-step" style={{ padding: 0, marginLeft: -5, marginRight: -5 }}>
                                        {(voucherPurchasedLevelsThisVoucher[selectedStep] && voucherPurchasedLevelsThisVoucher[selectedStep][key]) ? (
                                          <div className="product-info-discount-step-info-bought">
                                            Already Bought
                                          </div>
                                        ) : (
                                          <>
                                            {(voucherPurchasedLevelsThisVoucher[selectedStep - 1] && voucherPurchasedLevelsThisVoucher[selectedStep - 1][key]) ? (null) : (
                                              <>
                                                <div className="product-info-discount-step-info-locked">
                                                  {selectedStep > 2 ? "Buy Step 2 To Unlock" : "Buy Step 1 To Unlock"}
                                                </div>
                                                <img
                                                  src={padlock}
                                                  alt=""
                                                  className="filter_grey product-info-discount-step-info-locked-icon"
                                                />
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="product-info-discount-step" style={{ padding: 0, marginLeft: -5, marginRight: -5 }}>
                                        {((voucherPurchasedLevelsThisVoucher[selectedStep] && voucherPurchasedLevelsThisVoucher[selectedStep][parseInt(key, 10)])
                                          || (voucherPurchasedLevelsThisVoucher[selectedStep + 1] && voucherPurchasedLevelsThisVoucher[selectedStep + 1][parseInt(key, 10)])) ? (
                                          <div className="product-info-discount-step-info-bought">
                                            Already Bought
                                          </div>
                                        ) : (null)}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              );
                            }) : (
                              <>
                                <Col xs="4" className={`product-info-discount-step product-info-discount-step-active`}>
                                  <div
                                    className={`product-info-discount-step-wrapper product-info-discount-step-wrapper-active`}
                                    style={{ minHeght: 56 }}
                                  />
                                </Col>
                                <Col xs="4" className={`product-info-discount-step`}>
                                  <div className={`product-info-discount-step-wrapper`} style={{ minHeght: 56 }} />
                                </Col>
                                <Col xs="4" className={`product-info-discount-step`}>
                                  <div className={`product-info-discount-step-wrapper`} style={{ minHeght: 56 }} />
                                </Col>
                              </>
                            )}
                          </Row>

                          <h6 style={{ marginTop: 0, marginBottom: 15 }}>Decide Your Purchase Type</h6>
                          <Row>
                            <Col xs="4" onClick={() => this.changeGift()} className={`product-info-discount-step ${!selectedGift ? 'product-info-discount-step-active' : ''}`}>
                              <div
                                className={`product-info-discount-step-wrapper ${!selectedGift ? 'product-info-discount-step-wrapper-active' : ''}`}
                                style={{}}
                              >
                                <div className="product-info-discount-step-title">
                                </div>
                                <div className="product-info-discount-step-info">
                                  <i class="lni lni-heart"></i>
                                  <div>Treat yourself</div>
                                </div>
                              </div>
                            </Col>
                            <Col xs="4" onClick={() => this.changeGift()} className={`product-info-discount-step ${selectedGift ? 'product-info-discount-step-active' : ''}`}>
                              <div
                                className={`product-info-discount-step-wrapper ${selectedGift ? 'product-info-discount-step-wrapper-active' : ''}`}
                                style={{}}
                              >
                                <div className="product-info-discount-step-title">

                                </div>
                                <div className="product-info-discount-step-info">
                                  <BoxGift1 />
                                  <div className="product-info-discount-step-info-gift-text">Gift</div>

                                </div>
                              </div>
                              <div className="product-info-discount-step-info-bespoke" style={{ width: '100%', float: 'center' }}>
                                {`Bespoke Scheduled Email Service`}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs="12" onClick={this.openPaymentInputs} style={{ marginBottom: 25 }}>
                              <Button
                                onClick={this.openPaymentInputs}
                                disabled={(!(selectedStep || selectedStep === 0) || !(selectedVoucher || selectedVoucher === 0))}
                                color="primary"
                                className="product-info-discount-step-next-button"
                              >
                                    {`Let's Go ${amount ? `${pound}${amount}` : ''} ${giftingAmount ? ` + ${pound}${giftingAmount}` : ''}`}
                                {(processingPaymentSpinner) ? (
                                  <Spinner animation="border" variant="primary" style={{ width: '1.2rem', height: '1.2rem', marginLeft: 10, marginTop: 8, position: 'absolute' }}> {''} </Spinner>
                                ) : (null)}
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="12" onClick={this.openPaymentInputs} style={{ marginBottom: 25, paddingRight: 0, textAlign: 'right' }}>
                                  <div>Please check the <a href="/app-t-and-c" target="_blank" style={{ color: "#00aeff", fontWeight: 600 }}>T&Cs</a> before buying.</div>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section >

      </div >
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  vouchersList: state.customer.list || {},
  companies: state.customer.companies || {},
  categories: state.customer.categories || {},
  orderedVouchers: state.customer.orderedVouchers || {},
  giftingPrice: state.customer.giftingPrice || null,
  voucherPurchasedLevels: state.customer.voucherPurchasedLevels || {},
});

const mapDispatchToProps = {
  // getVouchers: getVoucherList,
  fetchSingleVoucher: getSingleVoucher,
  fetchVoucherCompanies: getVoucherCompanies,
  fetchVoucherCategories: getVoucherCategories,
  doPaymentFunc: doPayment,
  companyOfflineFunc: companyOffline,
  login: loginAppUser,
  fetchUserData: getAppUserData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleCampaign));