import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';
// import ReactWOW from 'react-wow';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

// import {
//   Row,
// } from 'reactstrap';

import PlaceholderCard from './PlaceholderCard';

import { connect } from 'react-redux';

const pound = `${'\u00A3'}`;

class CampaignCard extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);

    this.image = React.createRef();

    this.state = {
      error: null,
      loading: false,
      enabled: false,
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      companyNotifications: [],
      companyInformation: '',
      selectedStep: 1,
      purchaseModal: false,
      showPassword1: 'password',
      loaded: false,
      imagesLoaded: {},
    };
  }

  componentDidMount() {
    const { user } = this.props;

    const img = this.image.current;

    console.log('componentDidMount img', img);
    if (img && img.complete) {
      this.handleImageLoaded();
    }
  }

  handleImageLoaded = () => {
    console.log('handleImageLoaded');

    if (!this.state.loaded) {
      console.log('image loaded');
      this.setState({ loaded: true });
    }
  }

  scrollToTop = () => {
    console.log('scrollToTop');
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });

    const { parentActionFunction } = this.props;

    console.log('parentActionFunction');

    if (parentActionFunction) {
      parentActionFunction();
    }
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  setImageLoaded = (id) => {
    console.log('setImageLoaded', id);

    this.setState(prevState => ({ imagesLoaded: { ...prevState.imagesLoaded, [id]: true } }));
  }

  goToCard = (event, voucherId) => {
    const { history } = this.props;

    console.log('goToCard', voucherId);

    // event.stopPropagation();

    // In that case, event.ctrlKey does the trick.
    if (event.ctrlKey) {
      console.debug("Ctrl+click has just happened!");
    } else {
      history.push(`/campaign/${voucherId}`);
    }

    this.scrollToTop();
  }

  openInNewTabUrl = (url) => {
    console.log('openInNewTab', url);
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

    if (newWindow) newWindow.opener = null;
  }

  openInNewTab = (voucherId) => {
    const { history } = this.props;

    console.log('openInNewTab', voucherId, window.location);

    const { origin } = window.location;

    console.log('origin', origin);

    this.openInNewTabUrl(`${origin}/campaign/${voucherId}`)
  }
   

  render() {
    const { user, categories, voucherId, voucher, display, tabChanged, type, newest, notInPage } = this.props;
    const {
      imagesLoaded,
    } = this.state;

    console.log('render card', type, display, notInPage, voucherId, voucher);

    // return (<PlaceholderCard />);

    if (!tabChanged && (!voucher || !voucherId)) {
      // console.log('voucherIdtest 1', voucher, voucherId, display);
      if (!display) {
      //  console.log('test 2', voucherId);
        return null;
      } else {
      //  console.log('test 3', voucherId);
        return (<PlaceholderCard />);
      }
    } // else {
    //  console.log('test 4', voucherId);
  //  }

    if (!voucher && voucher === undefined) {
      return (null);
    }

    if (!voucher && voucher === null) {
      return (<PlaceholderCard />);
    }

    let cardStyles = {};

    if (!tabChanged && (!imagesLoaded || !imagesLoaded[voucherId] || !imagesLoaded[`logo${voucherId}`])) {
      cardStyles = { display: '', opacity: 0, position: 'absolute' };
    } else {
      cardStyles = { opacity: 1, position: 'static' };
    }

    if (notInPage) {
      cardStyles.display = 'none';
    }

    console.log('cardStyles', cardStyles);

    // style={(!tabChanged && (!imagesLoaded || !imagesLoaded[voucherId] || !imagesLoaded[`logo${voucherId}`])) ? { opacity: 0, position: 'absolute' } : { opacity: 1, position: 'static' }}

    return (
      <>
        <Link
          className="single-item-grid"
          to={`/campaign/${voucherId}`}
          style={cardStyles}
          onClick={(e) => this.goToCard(e, voucherId)}
          // onContextMenu={(e) => this.openInNewTab(voucherId)}
        > 
          <div className="image">
            <img
                alt={''}
                fetchPriority="high"
              src={voucher.primaryImage}
                onLoad={() => { this.setImageLoaded(voucherId) }}
            />
            {imagesLoaded[voucherId] ? (null) : (
              <div className="primary-image-placeholder"></div>
            )}
          <div className="cross-badge">
              <img
                alt={''}
                fetchPriority="high"
              src={voucher.logo}
                onLoad={() => { this.setImageLoaded(`logo${voucherId}`) }}
            />
            {imagesLoaded[`logo${voucherId}`] ? (null) : (
              <div className="logo-placeholder" />
            )}
          </div>
            <span className="flat-badge sale" style={{ background: '#00B2FF' }}>
            {`${voucher.discount[3]}%`}
            </span>
        </div>
          <div className="content">
            <div className="campaign-card-company-name">{voucher.companyName}</div>
            <h3 className="title">
              <a><div>{voucher.description}</div></a>
          </h3>
            <ul className="info">
              <li className="priceDesc"><p>Voucher Values: </p></li>
              <li style={{ color: '#00B2FF' }} className="price"><p>{`${pound}${voucher.availableVouchers[0]} to ${pound}${voucher.availableVouchers[2]}`}</p></li>
          </ul>
            <ul className="info campaign-card-loyalty-range">
              <li className="priceDesc"><p>Loyalty Discount Range: </p></li>
              <li style={{ color: '#00B2FF' }} className="like2222 price"><p>{`${voucher.discount[1]}% to ${voucher.discount[3]}%`}</p></li>
            </ul>
            <div className="tag campaign-card-category">{(categories && voucher.categoryIds[0] && categories[voucher.categoryIds[0]]) ? categories[voucher.categoryIds[0]].name : ''}</div>
        </div>
        </Link>
        {(!tabChanged && (!imagesLoaded || !imagesLoaded[voucherId] || !imagesLoaded[`logo${voucherId}`])) ? (<PlaceholderCard key={voucherId} />) : (null) }
    </>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.customer.categories || {},
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignCard));
