import {
  ADD_GIFTING_DATA,
  GET_ORDERED_TEMPLATE,
  GET_SINGLE_ORDER,
  GET_SINGLE_VOUCHER,
  GET_GIFTING_PRICE,
  GET_SINGLE_VOUCHER_PURCHASED_LEVELS,
  GET_USER_VOUCHERS,
  CUSTOMER_GET_VOUCHER_CATEGORIES,
  CUSTOMER_GET_VOUCHER_COMPANIES,
  GET_VOUCHER_IMAGES,
  CUSTOMER_GET_VOUCHER_LIST,
  CUSTOMER_GET_VOUCHER_LIST_UNLOCKED,
  GET_VOUCHER_ORDERS,

  SAVE_PAYMENT_DATA,
  REMOVE_PAYMENT_DATA,
  GET_PAYMENT_PROGRESS,
} from '../../actions';

const defaultState = {
  // list: {},
  companies: {},
  categories: {},
  orders: {},
  images: {},
  orderedVouchers: {},
  voucherById: {},
  orderData: {},
  templateOrdered: {},
  giftingData: {},
};

const voucherReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CUSTOMER_GET_VOUCHER_LIST: {
      console.log('CUSTOMER_GET_VOUCHER_LIST', action);
      return {
        ...state,
        list: action.payload,
        mostPopular: action.mostPopular,
        newest: action.newest,
        featured: action.featured,
      };
    }

    case CUSTOMER_GET_VOUCHER_LIST_UNLOCKED: {
      console.log('CUSTOMER_GET_VOUCHER_LIST_UNLOCKED', action);
      return {
        ...state,
        unlockedList: action.payload,
        unlockedMostPopular: action.mostPopular,
        unlockedNewest: action.newest,
        unlockedFeatured: action.featured,
      };
    }

    case ADD_GIFTING_DATA:
      return { ...state, giftingData: action.payload };
    case GET_ORDERED_TEMPLATE:
      return { ...state, templateOrdered: action.payload };
    case GET_SINGLE_ORDER:
      return { ...state, orderData: action.payload };
    case CUSTOMER_GET_VOUCHER_COMPANIES:
      return { ...state, companies: { ...state.companies, ...action.payload } };
    case CUSTOMER_GET_VOUCHER_CATEGORIES: {
      let categoriesArr = [];
      const categories = { ...state.categories, ...action.payload };

      Object.keys(categories).map((key) => {
        categoriesArr.push({ key, ...categories[key] });
      });

      categoriesArr.sort((a, b) => a.order - b.order);

      return { ...state, categories: { ...state.categories, ...action.payload }, categoriesArr };
    }
    case GET_VOUCHER_ORDERS:
      return { ...state, orders: action.payload };
    case GET_VOUCHER_IMAGES:
      return { ...state, images: action.payload };
    case GET_USER_VOUCHERS: {
      console.log('GET_USER_VOUCHERS', action.payload);

      const used = [];
      const gifted = [];
      const unused = [];
      const canceled = [];
      const vouchersArr = [];

      Object.keys(action.payload).map(key => {
        vouchersArr.push({ key, ...action.payload[key] });

        if (action.payload[key].canceled) {
          canceled.push({ key, ...action.payload[key] });
        } else if (action.payload[key].buyFor === 'gift') {
          gifted.push({ key, ...action.payload[key] });
        } else if (action.payload[key].used) {
          used.push({ key, ...action.payload[key] });
        } else {
          unused.push({ key, ...action.payload[key] });
        }

        return null;
      });

      used.sort((a, b) => (b.paymentDate.seconds - a.paymentDate.seconds ));
      gifted.sort((a, b) => (b.paymentDate.seconds - a.paymentDate.seconds ));
      unused.sort((a, b) => (b.paymentDate.seconds - a.paymentDate.seconds));
      canceled.sort((a, b) => (b.paymentDate.seconds - a.paymentDate.seconds));

      vouchersArr.sort((a, b) => (b.paymentDate.seconds - a.paymentDate.seconds));

    //  const sortedOrderedVouchers = [...used, ...gifted, ...unused, ...canceled];

      console.log('sortedOrderedVouchers', vouchersArr);

      return {
        ...state,
        orderedVouchers: action.payload,
        sortedUsed: used,
        sortedGifted: gifted,
        sortedUnused: unused,
        sortedCanceled: canceled,
        sortedOrderedVouchers: vouchersArr,

        myVouchersCount: action.myVouchersCount || 0,
        giftedVouhcersCount: action.giftedVouhcersCount || 0,
        usedVouchersCount: action.usedVouchersCount || 0,
      };
    }
    case GET_GIFTING_PRICE: {
      return {
        ...state,
        giftingPrice: action.payload,
      };
    }

    case GET_SINGLE_VOUCHER: {
      return {
        ...state,
        voucherById: { ...state.voucherById, [action.voucher]: action.payload },
      };
    }
    case GET_SINGLE_VOUCHER_PURCHASED_LEVELS: {
      console.log('GET_SINGLE_VOUCHER_PURCHASED_LEVELS', action);
      return {
        ...state,
        voucherPurchasedLevels: { ...state.voucherPurchasedLevels, [action.voucher]: action.payload },
      };
    }
    case SAVE_PAYMENT_DATA:
      return { ...state, paymentData: { ...state.paymentData, ...action.payload } };
    case REMOVE_PAYMENT_DATA:
      return { ...state, paymentData: {} };
    case GET_PAYMENT_PROGRESS:
      return { ...state, paymentStatus: { ...action.payload } };
    default:
      return state;
  }
};

export default voucherReducer;
