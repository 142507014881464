import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';

import { Row, Col, Media, Card, CardBody, Table, Button, Input, Label, Form, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from 'reactstrap';

import { connect } from 'react-redux';

import { Mail } from 'react-feather';

class Footer extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    pendingTabData: PropTypes.shape(),
    fetchFooters: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    dashboardTooltips: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      data: {},
    };
  }

  componentDidMount() {
    const { admin, user, isLoggedIn, history } = this.props;
  }

  scrollToTop = () => {
    console.log('scrollToTop');
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }

  render() {
    const { user } = this.props;
    const {
      error,
      success,
      name,
      data,
    } = this.state;

    return (
      <div style={{ width: '100%' }}>
        <footer class="footer">
          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-12">
                  <div class="single-footer mobile-app">
                    <h3>Mobile Apps</h3>
                    <div class="app-button">
                      <a href="https://play.google.com/store/apps/details?id=com.steppr&hl=en_GB" class="btn" target="_blank">
                        <i class="lni lni-play-store"></i>
                        <span class="text">
                          <span class="small-text">Get It On</span>
                          Google Play
                        </span>
                      </a>
                      <a href="https://apps.apple.com/gb/app/steppr/id1629026815" class="btn" target="_blank">
                        <i class="lni lni-apple"></i>
                        <span class="text">
                          <span class="small-text">Get It On</span>
                          App Store
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <div class="single-footer f-link">
                    <h3>Customers</h3>
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-12">
                        <ul>
                          <li><Link onClick={this.scrollToTop} to="/signup">Sign Up</Link></li>
                          <li><Link onClick={this.scrollToTop} to="/login">Sign In</Link></li>
                          <li><Link onClick={this.scrollToTop} to="/app-t-and-c">T&Cs</Link></li>
                          <li><Link onClick={this.scrollToTop} to="/app-privacy-policy">Privacy Policy</Link></li>
                          <li><a href="https://steppr.freshdesk.com/support/solutions/101000186530" target="_blank">FAQs</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <div class="single-footer f-link">
                    <h3>Businesses</h3>
                    <ul>
                      <li><Link onClick={this.scrollToTop} to="/business">Why Join Steppr</Link></li>
                      <li><a href="https://app.steppr.com/signup" target="_blank">Sign Up</a></li>
                      <li><a href="https://app.steppr.com/login" target="_blank">Sign In</a></li>
                      <li><Link onClick={this.scrollToTop} to="/business-t-and-c">T&Cs</Link></li>
                      <li><Link onClick={this.scrollToTop} to="/business-privacy-policy">Privacy Policy</Link></li>
                      <li><a href="https://steppr.freshdesk.com/support/solutions/101000186531" target="_blank">FAQs</a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                  <div class="single-footer f-contact">
                    <h3>Contact</h3>
                    <ul>
                      <li><Link onClick={this.scrollToTop} to="/about-us" style={{ color: '#FFF' }}>About Us</Link></li>

                      <li>128 City Road, London, United Kingdom, <br /> EC1V 2NX</li>
                      <li><a href="mailto: hello@steppr.co.uk" style={{ color: "#FFF" }}>hello@steppr.co.uk</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-bottom">
            <div class="container">
              <div class="inner">
                <div class="row">
                  <div class="col-12">
                    <div class="content">
                      <ul class="footer-bottom-links">
                        <li>
                          <Link onClick={this.scrollToTop} to="/cookies" style={{ marginRight: 5 }}>Cookies</Link>
                          <Link onClick={this.scrollToTop} to="/">&copy; 2025 Steppr Limited. Registered in England & Wales, company number: 14433954.</Link>
                        </li>
                      </ul>
                      <ul class="footer-social">
                        <li><a href="https://facebook.com/steppruk" target="_blank"><i class="lni lni-facebook-filled"></i></a></li>
                        <li><a href="https://twitter.com/steppruk" target="_blank"><i class="lni lni-twitter-original"></i></a></li>
                        <li><a href="mailto: hello@steppr.co.uk"><i class="lni lni-envelope"></i></a></li>
                        <li><a href="https://uk.linkedin.com/company/steppr" target="_blank"><i class="lni lni-linkedin-original"></i></a></li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
