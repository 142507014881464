
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import ReactWOW from 'react-wow';

import {
  Row,
  Col,
} from 'reactstrap';

import { connect } from 'react-redux';

import CampaignCard from '../../pages/Customer/Components/CampaignCard';

class SearchResults extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
  //  getVouchers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      activeTab: 2,
      currentPage: 0,
    };
  }

  componentDidMount() {
    const { user } = this.props;
  }

  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  }

  setCurrentPage = (page) => {
    const { scrollToAllVouchers } = this.props;
    this.setState({ currentPage: page });

    scrollToAllVouchers();
  }

  render() {
    const { user, vouchersList, closeFilteredModal } = this.props;
    const {
      activeTab,
      currentPage,
    } = this.state;

    console.log('vouchersList activeTab', activeTab, vouchersList);

    let pageCount = 1;

    if (vouchersList && Object.keys(vouchersList)) {
      pageCount = Math.ceil(Object.keys(vouchersList).length / 12);
    }

    console.log('pageCount', pageCount, currentPage);

    return (
      <div style={{ marginBottom: 2 }}>
        <section className="items-tab">
          <div className="row">
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-latest" role="tabpanel">
                <Row>
                  <>
                    {(() => {
                      const rows = [];

                      console.log('pagecount currentpage render', currentPage, (12 * currentPage), ((12 * currentPage) + 12))

                      for (let i = 0; i < Object.keys(vouchersList).length; i++) {
                        let hasVouchers = false;
                        let isInPage = false;

                        console.log('render voucher', i);

                        if (i >= 12 * currentPage && i < ((12 * currentPage) + 12)) {
                          isInPage = true;
                        }

                        if (vouchersList && Object.keys(vouchersList).length) {
                          hasVouchers = true;
                        }

                        let voucher = null;
                        let voucherId = null;

                        if (hasVouchers && Object.keys(vouchersList)[i]) {
                          voucherId = Object.keys(vouchersList)[i];
                          voucher = vouchersList[voucherId];
                        }

                        console.log('isInPage', isInPage);
                        rows.push(
                          <Col xs="12" md="6" lg="4" className="single-item-grid-wrapper">
                              <CampaignCard
                              key={voucherId}
                              vouchersList={vouchersList}
                              display={!(hasVouchers)}
                              notInPage={!isInPage}
                              voucher={voucher}
                              voucherId={voucherId}
                              parentActionFunction={closeFilteredModal}
                              />
                            </Col>
                        );
                      }

                      console.log('row', rows);

                      return rows;
                    })()}
                  </>
                </Row>
              </div>
              {/* <div class="row">
                <div class="col-12">
                  <div class="pagination left">
                    <ul class="pagination-list">
                      {(pageCount) ? (
                        <>
                          {(() => {
                            const rows = [];

                            console.log('pageCount call', pageCount);

                            for (let j = 0; j < pageCount; j++) {
                              rows.push(
                                <li onClick={() => this.setCurrentPage(j)} className={j === currentPage ? 'active' : ''}>{j + 1}</li>
                              );
                            }

                            if (pageCount === 1) {
                              rows.push(
                                <li disabled={true}>2</li>
                              );
                            }

                            rows.push(
                              <li disabled={pageCount === 1 ? true : false} onClick={() => this.setCurrentPage(pageCount - 1)} className={pageCount === currentPage ? 'active' : ''}><i class="lni lni-chevron-right"></i></li>
                            );

                            console.log('pageCount rows', rows);

                            return rows;
                          })()}
                        </>
                      ) : (null)}

                    </ul>
                  </div>
                </div>
              </div> 
            </div> */}
            </div>
          </div>
        </section>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResults));









