import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {  Switch, Route } from 'react-router-dom';
// import { Button, Badge, NavItem, UncontrolledDropdown, DropdownToggle, } from 'reactstrap';
import { Footer, PageContent, PageAlert, Page } from '../vibe';

import { Helmet } from "react-helmet";
import { matchPath } from 'react-router-dom';

import nav from '../_nav';
import routes from '../views';
import ResetPasswordPage from '../views/pages/ResetPassword';
import EmailVerifyPage from '../views/pages/User/EmailVerify';

import ContextProviders from '../vibe/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../vibe/helpers/handleTabAccessibility';

import { connect } from 'react-redux';

import { auth } from '../lib/firebase';

import FooterComponent from '../views/components/Footer/Footer';
import HeaderComponent from '../views/components/Header/Header';

import {
  getUserData,
  doLogout,
  getAppUserData,
} from '../actions/user';

import {
  getGiftingPrice,
  getVoucherList,
  getVoucherCompanies,
  getVoucherCategories,
  getOrderedVouchers,
} from '../actions/customer';
import { getBrandingData } from '../actions/contentManagement/index';

const MOBILE_SIZE = 992;
// const faviconFallback = 'https://firebasestorage.googleapis.com/v0/b/steppr-app.appspot.com/o/siteImages%2Ffavicon.png?alt=media&token=81470106-1f40-4850-95f3-297607aa0841';

class DashboardLayout extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    getUser: PropTypes.func.isRequired,
    fetchVoucherCategories: PropTypes.func.isRequired,
    fetchOrderedVouchers: PropTypes.func.isRequired,
    fetchGiftingPrice: PropTypes.func.isRequired,
    getVouchers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: true,
      userFetched: false,
      perksDisabled: true,
      perkTitle: 'Perks',
    };
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidMount() {
    console.log('DashboardLayout componentDidMount');
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);

    const {
      getUser,
      fetchBrandingData,
      history,
      getVouchers,
      fetchGiftingPrice,
      fetchVoucherCategories,
      fetchUserData,
      fetchOrderedVouchers,
    } = this.props;

    fetchVoucherCategories();
    fetchBrandingData();
    fetchGiftingPrice();

    auth.onAuthStateChanged(user => {
      getVouchers();

        if (user && user.uid) {
          console.log('componentDidMount onAuthStateChanged', user);

          fetchUserData();
          fetchOrderedVouchers();

          this.setState({ uid: user.uid });
        } else {
          fetchOrderedVouchers();

          this.setState({
            uid: null,
            userFetched: true,
          });
        }
      });
    // });
  }

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      console.log('call toggleSideCollapse');
      this.toggleSideCollapse();
    }
  }

  componentWillUnmount() {
    const { cancelActiveListeners, unsubscribeVouchersListeners, unsubscribeAdminListeners, fetchOrderedVouchers } = this.props;
    window.removeEventListener('resize', this.handleResize);

    fetchOrderedVouchers();
  }

  logout = () => {
    const { logout, cancelActiveListeners, unsubscribeVouchersListeners, unsubscribeAdminListeners } = this.props;

    logout();
  }

  logoutVerify = () => new Promise((resolve, reject) => {
    const { logout, cancelActiveListeners, unsubscribeVouchersListeners, unsubscribeAdminListeners } = this.props;
    const promises = [];
    promises.push(logout());

    Promise.all(promises).then(() => {
      console.log('logoutVerify then');
      return resolve();
    }).catch(err => {
      console.log('logoutVerify err');
      return resolve();
    })
  })

  toggleSideCollapse = () => {
    console.log('toggleSideCollapse');
    this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  shadeColor2 = (color, percent) => {
    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
  }

  shadeColor = (col, amt) => {
    col = col.replace(/^#/, '')
    if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    let [r, g, b] = col.match(/.{2}/g);

    if (amt > 0) {
      if (b > r && b > g) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt + 30])
      } else if (r > b && r > g) {
        ([r, g, b] = [parseInt(r, 16) + amt + 30, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      } else if (g > r && g > b) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt + 30, parseInt(b, 16) + amt])
      } else {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      }
    } else {
      if (b > r && b > g) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt - 20])
      } else if (r > b && r > g) {
        ([r, g, b] = [parseInt(r, 16) + amt - 30, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      } else if (g > r && g > b) {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt - 30, parseInt(b, 16) + amt])
      } else {
        ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      }
    }


    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
  }

  getPageTooltip = () => {
    const { companyTabsTooltips } = this.props;

    let name;
    routes.map(prop => {
      if (
        matchPath(this.props.location.pathname, {
          path: prop.path,
          exact: true,
          strict: false
        })
      ) {
        name = prop.name;
      }
      return null;
    });

    if (name === 'Dashboard') {
      name = 'dashboard';
    } else if (name === 'Setup Help') {
      name = 'setupHelp';
    } else if (name === 'Contact') {
      name = 'contact';
    }

    if (companyTabsTooltips && companyTabsTooltips[name]) {
      return companyTabsTooltips[name].value;
    } else {
      return name;
    }
  }

  render() {
    const {
      location,
      branding,
    } = this.props;

    const { sidebarCollapsed, userFetched, perksDisabled, perkTitle, isMobile } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';

    if (location && location.pathname && location.pathname === "/reset-password-confirm") {
      return (
        < ResetPasswordPage
          {...this.props}
          nav={nav}
          logo={branding.logo || null}
          logoText={branding.siteName || ''}
          isSidebarCollapsed={sidebarCollapsed}
          toggleSidebar={this.toggleSideCollapse}
          shadeColor={this.shadeColor}
          logout={this.logoutVerify}
        />
      )
    }

    if (location && location.pathname && location.pathname === "/email-verify") {
      return (
        < EmailVerifyPage
          {...this.props}
          nav={nav}
          logo={branding.logo || null}
          logoText={branding.siteName || ''}
          isSidebarCollapsed={sidebarCollapsed}
          toggleSidebar={this.toggleSideCollapse}
          shadeColor={this.shadeColor}
          logout={this.logoutVerify}
        />
      )
    }

    return (
      <ContextProviders>
        <Helmet>
          <title>{branding.tabInfo || ''}</title>
          {/* <link id="favicon" rel="icon" href={branding.favicon || ''} /> */}
        </Helmet>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />

          <div className="app-body">
              <Page>
              <HeaderComponent />
              <PageContent>
                <Switch>
                  {routes.map((page, key) => (
                    <Route path={page.path} component={page.component} key={key} />
                  ))}
                </Switch>
              </PageContent>
            </Page>
          </div>
          <Footer>
            <FooterComponent />
          </Footer>
        </div>
      </ContextProviders>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  vouchers: state.vouchers.vouchers || null,
  isLoggedIn: state.user.isLoggedIn || false,
  userDataFetched: state.user.userDataFetched || false,
  roles: { ...state.users.companiesRoles, ...state.admin.adminsRoles } || {},
  adminStatistics: state.admin.adminStatistics || null,
  branding: state.contentManagement.branding || {},
  signposts: state.contentManagement.signposts || {},

  faqLinks: state.contentManagement.faqLinks || {},
  faqLinksArray: state.contentManagement.faqLinksArray || [],
});

const mapDispatchToProps = {
  getUser: getUserData,
  logout: doLogout,
  fetchGiftingPrice: getGiftingPrice,
  getVouchers: getVoucherList,
  fetchVoucherCompanies: getVoucherCompanies,
  fetchVoucherCategories: getVoucherCategories,
  fetchUserData: getAppUserData,
  fetchOrderedVouchers: getOrderedVouchers,
  fetchBrandingData: getBrandingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);