// import Dashboard from './pages/Dashboard';
// import BlankPage from './pages/BlankPage';
// import SubNav from './pages/SubNav';

import ErrorPage from './pages/404';
import LoginPage from './pages/Login';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';
import SignupPage from './pages/SignUp';

//import CompanyPerks from './pages/Company/Perks';

//import TAndCPage from './pages/Footer/TAndCPage';
import CookiesPage from './pages/Customer/Pages/CookiesPage';
import BusinessTandCPage from './pages/Customer/Pages/BusinessTandCPage';
import BusinessPrivacyPolicy from './pages/Customer/Pages/BusinessPrivacyPolicy';
import AppTandCPage from './pages/Customer/Pages/AppTandCPage';
import AppPrivacyPolicy from './pages/Customer/Pages/AppPrivacyPolicy';

//import TAndCApp from './pages/Footer/TAndCApp';
//import PrivacyPolicyApp from './pages/Footer/PrivacyPolicyApp';

import EmailVerify from './pages/User/EmailVerify';
import EmailVerifyUpdate from './pages/User/EmailVerifyUpdate';

import VouchersList from './pages/Customer/VouchersList';
import CategoriesList from './pages/Customer/Categories';
import SingleCampaign from './pages/Customer/SingleCampaign';
import PaymentSuccess from './pages/Customer/PaymentSuccess';

import BusinessHomepage from './pages/Customer/BusinessHomepage';
import ForMe from './pages/Customer/ForMe';
import AboutUs from './pages/Customer/AboutUs';
// import AboutUs from './pages/Customer/AboutUs';

const pageList = [
  {
    name: 'Email Verify',
    path: '/email-verify',
    component: EmailVerify,
  },
  {
    name: 'Email Verify',
    path: '/email-verify-update',
    component: EmailVerifyUpdate,
  },
  /* {
    name: 'Dashboard',
    path: '/home',
    component: Dashboard,
  }, */
  {
    name: 'Login',
    path: '/login',
    component: LoginPage,
    hidden: true,
  },
  {
    name: 'Reset Password',
    path: '/reset-password',
    component: ForgotPasswordPage,
  },
  {
    name: 'Reset Password',
    path: '/reset-password-confirm',
    component: ResetPasswordPage,
  },
  {
    name: 'Sign up',
    path: '/signup',
    component: SignupPage,
    hidden: true,
  },

  {
    name: 'Categories',
    path: '/categories/:id',
    component: CategoriesList,
  },

  {
    name: 'Categories',
    path: '/categories',
    component: CategoriesList,
  },

  {
    name: 'Campaign',
    path: '/campaign/:id',
    component: SingleCampaign,
  },

  {
    name: 'Payment Success',
    path: '/payment-success/:id',
    component: PaymentSuccess,

  },
  {
    name: 'Business Homepage',
    path: '/business',
    component: BusinessHomepage,
  },
  {
    name: 'My Vouchers & Account',
    path: '/for-me',
    component: ForMe,
  },

  {
    name: 'About Us',
    path: '/about-us',
    component: AboutUs,
  },

  {
    name: 'Cookies',
    path: '/cookies',
    component: CookiesPage,
  },

  {
    name: 'T&C',
    path: '/business-t-and-c',
    component: BusinessTandCPage,
  },

  {
    name: 'Privacy Policy',
    path: '/business-privacy-policy',
    component: BusinessPrivacyPolicy,
  },

  {
    name: 'T&C',
    path: '/app-t-and-c',
    component: AppTandCPage,
  },

  {
    name: 'Privacy Policy',
    path: '/app-privacy-policy',
    component: AppPrivacyPolicy,
  },

  {
    name: 'Vouchers',
    path: '/',
    component: VouchersList,
  },
  /*
  {
    name: 'Blank',
    path: '/pages/blank',
    component: BlankPage,
  },
  {
    name: 'Sub Navigation',
    path: '/pages/subnav',
    component: SubNav,
  },*/
  {
    name: '404',
    path: '/pages/404',
    component: ErrorPage,
  }, 
];

export default pageList;
