export const DB_TABLE_NAME = {
    vouchers: 'vouchers',
    categories: 'categories',
    companies: 'companies',
    vouchersUnlocked: 'vouchersUnlocked',
  orders: 'orders',
  ordersFinished: 'ordersFinished',
    eventReminders: 'eventReminders',
    ordersRating: 'ordersRating',
    pages: 'pages',
    payments: 'payments',
    vouchersGallery: 'vouchersGallery',
    appClients: 'appClients',
    appSettings: 'appSettings',
    templates: 'templates',
    templatesOrdered: 'templatesOrdered',
  templatesCategory: 'templatesCategory',
  vouchersBoughtByUser: 'vouchersBoughtByUser',
  vouchersUnlocked: 'vouchersUnlocked',
}
