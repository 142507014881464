import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';
import { Eye, EyeOff } from 'react-feather';

import { Row, Col, Input, Label, Form, Alert, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import {
  doSignup,
} from '../../actions/customer';

import notifications from '../../const/notifications';

class Signup extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    signup: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      loading: false,
      email: null,
      password: null,
      showPassword1: 'password',
      showPassword2: 'password',
    };
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = (e) => {
    console.log('handleSubmit');
    e.preventDefault();

    const { signup, history } = this.props;
    const { email, password, confirmPassword, loading, name } = this.state;

    console.log(email);
    console.log(loading);

    console.log(password);

    if (!email) {
     this.setState({ loading: false, error: notifications.signUp.noEmail || 'Missing email', success: null });

     window.scrollTo({
       top: 0,
       left: 0,
       behavior: 'smooth'
     });
    } else if (!name) {
      this.setState({ loading: false, error: notifications.signUp.noName || 'Missing name', success: null });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else if (!password) { // || !confirmPassword || password !== confirmPassword
      this.setState({ loading: false, error: notifications.signUp.noPassword || 'Missing password', success: null });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      if(loading) return;

      this.setState({ loading: true });

     // if (password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/)) {
      if (password.match(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/)) { ///^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/
        console.log('valid password');
        this.setState({ error: null, success: null });

        return signup(email, password, name)
          .then((res) => {
            console.log('signup res');
            console.log(res);

            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });

            if (res.type === 'success') {
              this.setState({ loading: false, success: true, error: false });
            } else if (res.error) {
              if (res.error.message) {
                this.setState({ loading: false, success: false, error: res.error.message });
              } else if (res.error.code) {
                this.setState({ loading: false, success: false, error: res.error.code });
              } else if (!Object.keys(res.error).length) {
                this.setState({ loading: false, success: false, error: res.error });
              }
            }
          })
          .catch((err) => {
            console.log(err);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });

            if (err === 'The email address is badly formatted.') {
              this.setState({ loading: false, error: notifications.signUp.badEmail || err, success: null })
            } else if (err === 'The email address is already in use by another account.') {
              this.setState({ loading: false, error: notifications.signUp.usedEmail || err, success: null })
            } else {
              this.setState({ loading: false, error: notifications.signUp.failedToCreate || err, success: null });
            }

          });
      } else {
        this.setState({ loading: false, success: null, error: notifications.signUp.invalidPassword || 'Password needs to be at least 8 characters, have at least 1 uppercase, lowercase, number, symbol.' });

        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    }
  }

  toggleShowPassword = (id) => {
    console.log('toggleShowPassword');
    console.log(id);
    if (id === 1) {
      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  }

  render() {
    const {
      email,
      password,
      error,
      success,
      name,
    //  confirmPassword,
      showPassword1,
      showPassword2,
      loading,
     } = this.state;

    console.log('success', { error, success });

    return (
      <>
        <div class="breadcrumbs">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">Customer Sign Up</h1>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <ul class="breadcrumb-nav">
                  <li><Link to="/">Home</Link></li>
                  <li>Customer Sign up</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section class="login registration section">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
                <div class="form-head">
                  <h4 class="title">Customer Sign Up</h4>
                  {(!!error) && <Alert color="danger">{error}</Alert>}
                  {(!!success) && <Alert color="success"> Thank you for signing up. To access your account please click the verification link in the email sent to you. You may need to check your junk folder. </Alert>}
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col xs="12">
                        <div class="form-group">
                          {/*  {!name ? (
                            <Label className="custom-input-placeholder" for="name">Name</Label>
                          ) : (null)} */}
                          <Label for="name">Name</Label>
                          <Input
                            name="name"
                            id="name"
                            value={name}
                            placeholder="Jonny Dover"
                            onChange={this.handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div class="form-group">
                      {/* {!email ? (
                        <Label className="custom-input-placeholder" for="email">Email</Label>
                      ) : (null)} */}
                      <Label for="email">Email</Label>
                      <Input
                        name="email"
                        id="email"
                        value={email}
                        placeholder="john@dover.com"
                        onChange={this.handleChange}
                      />
                    </div>
                    <Row>
                      <Col xs="12">
                        <div class="form-group" style={{ position: 'relative' }}>
                          <Label for="password">Password</Label>
                          <Input
                            type={showPassword1}
                            name="password"
                            id="password"
                            value={password}
                            placeholder="******"
                            onChange={this.handleChange}
                          />
                          {showPassword1 === 'input' ? (
                            <Eye style={{ position: 'absolute', right: 0, bottom: 8, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)} />
                          ) : (
                            <EyeOff style={{ position: 'absolute', right: 0, bottom: 8, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)} />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div class="button">
                      <button disabled={loading} id="sign-up-button" className="btn">
                        Create your account
                        {loading ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                        ) : (null)}
                      </button>
                      </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user || {},
});

const mapDispatchToProps = {
  signup: doSignup,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));