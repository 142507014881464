import { auth, firestore, functions, storage, firebase } from '../../lib/firebase';
import { DO_LOGIN, DO_LOGOUT, GET_USER_DATA, STRIPE_LOGIN_LINK, STRIPE_ACCOUNT_STATUS } from '../index';

let unsubscribe;

export const reAuthenticateUser = (password) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  console.log('reAuthenticateUser', user, password);

  console.log('auth.currentUser.metadata', auth.currentUser.metadata);

  if (user && user.email) {
    var credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password,
    );

    user.reauthenticateWithCredential(credential).then(() => {
      // User re-authenticated.

      return resolve(dispatch({
        type: DO_LOGIN,
        payload: 1,
      }));
    }).catch((error) => {
      console.log('error', error);

      if (error.code == 'auth/multi-factor-auth-required') {
        const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('reauth-container', {
          'size': 'invisible',
          'callback': function (response) {
            console.log('captcha finished');
          }
        });

        const resolver = error.resolver;
        // Ask user which second factor to use.
        if (resolver.hints[0].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
          var phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          };
          var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function (verificationId) {
              return resolve({ status: 'success', verificationId, verificationType: 'verification', resolver });
            });
        } else {
          // Unsupported second factor.
        }
      } else if (error.code == 'auth/wrong-password') {
        // Handle other errors such as wrong password.
        return reject(error.message);
      } else {
        return reject(error.message);
      }

      // return reject();
    });
  }
});

export const doSignup = ({ email, password, name, roleInCompany, phoneNumber, companyName, companyNumber, companyType }) => (dispatch) => new Promise(async (resolve, reject) => {  //, website, logo
  if (!name) return reject('missing name');
  if (!roleInCompany) return reject('missing role in company');
  if (!companyName) return reject('missing company name');
  if (!companyType) return reject('missing company type');
  if (companyType === 'company' && !companyNumber) return reject('missing company number');
  if (!email) return reject('missing email');
  if (!password) return reject('missing password');

  const beforeSignupFunction = functions.httpsCallable('beforeSignup');

  console.log('doSignup', { email, password, name, roleInCompany, phoneNumber, companyName, companyNumber, companyType });

  beforeSignupFunction({
    companyName,
    companyNumber,
    companyType,
    name,
    roleInCompany,
    phoneNumber,
    email,
  }).then(async (beforeResult) => {

    console.log('beforeResult', beforeResult);

    if (!(beforeResult && beforeResult.data && beforeResult.data.status === 'success')) {
      return resolve(beforeResult.data.error);
    }

    auth.createUserWithEmailAndPassword(email, password).then(async (res) => {

      console.log('createUserWithEmailAndPassword', res);

      if (res && res.user && res.user.uid) {
        const afterSignupFunction = functions.httpsCallable('afterSignup');

        afterSignupFunction({
          companyName,
          companyNumber,
          companyType,
          name,
          roleInCompany,
          phoneNumber,
        }).then(async (result) => {
          console.log('result', result);
          if (result && result.data && result.data.status === 'success') {
            console.log('sendEmailVerification');

            const sendVerificationEmailFunction = functions.httpsCallable('sendVerificationEmail');
            await sendVerificationEmailFunction().then((result) => {
              console.log('sendVerificationEmailFunction r', result);
            });

            auth.signOut().then(() => {
              console.log('do signout');
              dispatch({
                type: DO_LOGOUT
              });

              return resolve('success');
            });
          } else {
            return resolve(result.data.error);
          }
        });
      }
    }).catch(err => {
      return reject(err.message);
    });
  });
});

export const doLogin = ({ email, password }) => (dispatch) => new Promise((resolve, reject) => {
    if (!email) return reject('missing email');
    if (!password) return reject('missing password');

    console.log('doLogin');

    auth.signInWithEmailAndPassword(email, password).then(async (res) => {
      console.log('res.user', res.user);
      if (res && res.user && !res.user.emailVerified) {

        console.log('test 1');

        if (res.user && res.user.uid) {
          const userRef = firestore.collection('appClients').doc(res.user.uid);
          const snapshot = await userRef.get();

          console.log('test 2');

          if (snapshot.exists) {
            const data = snapshot.data();

            console.log('has data', data.uid);

            if (data.uid) {
              console.log('is appClients');

              const sendVerificationEmailFunction = functions.httpsCallable('sendVerificationEmail');
              await sendVerificationEmailFunction().then((result) => {
                console.log('sendVerificationEmailFunction r', result);
              });

              auth.signOut().then(() => {
                dispatch({
                  type: DO_LOGOUT
                });

                return reject('To access your account please click the verification link in the email sent to you.');
              });
            } else {
              console.log('is not appClients user');

              auth.signOut().then(() => {
                dispatch({
                  type: DO_LOGOUT
                });

                return reject('Wrong user data.');
              });
            }
          } else {
            console.log('has no user data');

            const sendVerificationEmailFunction = functions.httpsCallable('sendVerificationEmail');
            await sendVerificationEmailFunction().then((result) => {
              console.log('sendVerificationEmailFunction r', result);
            });

            auth.signOut().then(() => {
              dispatch({
                type: DO_LOGOUT
              });

              return reject('Wrong user data.');
            });
          }
        } else {
          console.log('test 3');
          const sendVerificationEmailFunction = functions.httpsCallable('sendVerificationEmail');
          await sendVerificationEmailFunction();

          auth.signOut().then(() => {
            dispatch({
              type: DO_LOGOUT
            });

            return reject('To access your account please click the verification link in the email sent to you.');
          });
        }
      } else {
        console.log('test 4');
        if (res.user && res.user.uid) {
          const userRef = firestore.collection('appClients').doc(res.user.uid);
          const snapshot = await userRef.get();

          console.log('test 5');

          if (snapshot.exists) {
            const data = snapshot.data();

            console.log('has data', data.uid);

            dispatch({
              type: DO_LOGIN,
              payload: 1
            });

            return resolve({ status: 'success' });

          } else {
            console.log('has no user data');

            auth.signOut().then(() => {
              dispatch({
                type: DO_LOGOUT
              });

              return reject('Wrong user data.');
            });
          }

        } else {
          console.log('test 7');

          const sendVerificationEmailFunction = functions.httpsCallable('sendVerificationEmail');
          await sendVerificationEmailFunction();

          auth.signOut().then(() => {
            dispatch({
              type: DO_LOGOUT
            });

            return reject('To access your account please click the verification link in the email sent to you.');
          });
        }
      }
    }).catch((error) => {
      if (error.code == 'auth/multi-factor-auth-required') {
        const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
         'size': 'invisible',
         'callback': function(response) {
           console.log('captcha finished');
        //   onSolvedRecaptcha();
         }
        });

        const resolver = error.resolver;
        // Ask user which second factor to use.
        if (resolver.hints[0].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
          var phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session
          };
          var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          // Send SMS verification code
          return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function(verificationId) {
              return resolve({status: 'success', verificationId, verificationType: 'verification', resolver });
              // Ask user for the SMS verification code.
            //  var cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
            //  var multiFactorAssertion =firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

              // Complete sign-in.
            //  return resolver.resolveSignIn(multiFactorAssertion)
          });
          //  .then(function(userCredential) {
              // User successfully signed in with the second factor phone number.
          //  });
        } else {
          // Unsupported second factor.
        }
      } else if (error.code == 'auth/wrong-password') {
        // Handle other errors such as wrong password.
        return reject(error.message);
      } else {
        return reject(error.message);
      }
    })
  //  }).catch((err) => {
  //    return reject(err.message);
  //  })
});


export const resetPasswordOld = (email) => (dispatch) => new Promise(async (resolve, reject) => {
    if (!email) return reject('missing email');

    const sendPasswordResetEmailFunction = functions.httpsCallable('sendPasswordResetEmail');
    await sendPasswordResetEmailFunction({ email }).then((result) => {
      console.log('sendPasswordResetEmailFunction r', result);

      return resolve();
    }).catch(err => {
      return resolve();
    });

  //  return auth.sendPasswordResetEmail(email)
  //    .then(() => resolve())
  //    .catch((err) => reject({ code: err.code }));
});

export const resetPassword = (email) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('resetPassword', email);

  if (!email) return reject('missing email');

    await auth
      .sendPasswordResetEmail(email)
      .then(() => {
        // onSuccess();
        return resolve();
      })
      .catch((err) => {
        return resolve();
      });
});


export const doLogout = () => dispatch => new Promise((resolve, reject) => {
    auth.signOut().then(() => {
      console.log('logoutVerify test 1');
      dispatch({
        type: DO_LOGOUT
      });

      if (unsubscribe) {
        unsubscribe();
      }

      return resolve();
    })
});

export const getUserData = () => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  if (user) {
    const usersRef = firestore.collection('users').doc(user.uid);

    unsubscribe = usersRef.onSnapshot(async (docSnapshot) => {
      if (!docSnapshot.exists) {
        return resolve();
      } else {
        const userData = docSnapshot.data();

        if (userData && auth.currentUser) {
          let has2fa = false;

          if (auth.currentUser.multiFactor && auth.currentUser.multiFactor.enrolledFactors && auth.currentUser.multiFactor.enrolledFactors.length) {
            has2fa = true;

            console.log('call DO_LOGIN');
            dispatch({ type: DO_LOGIN, doNotUpdateLastLogin: true });
          }

          console.log('userData', userData);
          console.log('auth.currentUser', auth.currentUser);

          if (userData && userData.roleClass === 'admin') {
          //  has2fa = true;

            const adminRef = firestore.collection('admins').doc(userData.uid);

            await adminRef.update({ lastActivity: firebase.firestore.FieldValue.serverTimestamp() });
          }

        //  dispatch({
       //       type: GET_USER_DATA,
       //       payload: { ...auth.currentUser, ...userData },
       //       has2fa,
       //   })

          return resolve({ ...auth.currentUser, ...userData });
        } else if (auth.currentUser) {
          let has2fa = false;

          if (auth.currentUser.multiFactor && auth.currentUser.multiFactor.enrolledFactors && auth.currentUser.multiFactor.enrolledFactors.length) {
            has2fa = true;

            console.log('call DO_LOGIN');
            dispatch({ type: DO_LOGIN, doNotUpdateLastLogin: true });
          }

        //  dispatch({
        //      type: GET_USER_DATA,
        //      has2fa,
        //      payload: { ...auth.currentUser, role: null, roleClass: null, company: null, companyName: null, dateCreated: null, status: null, roleInCompany: null },
        //  })

          return resolve({ ...auth.currentUser, role: null, roleClass: null, company: null, companyName: null, dateCreated: null, status: null, roleInCompany: null });
        } else {
          return resolve();
        }
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    });
  }
});

export const markAsVerified = (uid) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('markAsVerified', uid);

  const markAsVerifiedFunction = functions.httpsCallable('markAsVerified');
  await markAsVerifiedFunction({ uid });

  return resolve();
});

export const afterEmailLogin = (customToken) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('afterEmailLogin', customToken);

  if (customToken) {
    await auth.signInWithCustomToken(customToken);
  }

  const authUser = auth.currentUser;

  const { user } = getState();

  if (authUser && user) {
    const { userData } = user;
    const usersRef = firestore.collection('users').doc(authUser.uid);

    const doc = await usersRef.get();

    if (doc.exists) {
      console.log('has doc');
      const data = doc.data();

      console.log('data', data);

      const markAsVerifiedFunction = functions.httpsCallable('markAsVerified');
      await markAsVerifiedFunction({ uid: authUser.uid });

      if (data.pendingEmail) {

        const finishEmailChangeFunction = functions.httpsCallable('finishEmailChange');
        await finishEmailChangeFunction();

        await usersRef.update({
          email: data.pendingEmail,
          pendingEmail: null,
          hasVerifiedEmail: true,
        });

        //  await auth.updateEmail(auth.currentUser, "user@example.com")
        /*  await authUser.updateEmail(data.pendingEmail).then(() => {
            console.log('Email updated!');
            // Email updated!
            // ...
          }).catch((error) => {
            console.log('error', error);
            // An error occurred
            // ...
          });
  
          if (data.role === 'teamOwner') {
            const companyRef = firestore.collection('companies').doc(authUser.uid);
            const ownersRef = firestore.collection('owners').doc(authUser.uid);
  
            await companyRef.update({
              email: data.pendingEmail,
              pendingEmail: null,
            });
  
            await ownersRef.update({
              email: data.pendingEmail,
              pendingEmail: null,
            });
          } */

        return resolve();
      } else {
        await usersRef.update({
          hasVerifiedEmail: true,
        });

        return resolve();
      }
    }

    return resolve();
  }

  return resolve();
});

export const updateUserData = (id, value) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  const authUser = auth.currentUser;

  const { user } = getState();

  if (!value || !id) return reject('missing data');
  if (!(id === 'name' || id === 'email' || id === 'firstName' || id === 'roleInCompany' || id === 'phoneNumber')) return reject('wrong data id');


  if (authUser && user) {
    const { userData } = user;
    const usersRef = firestore.collection('users').doc(authUser.uid);

    if (id === 'email') {
      const updateUserEmailFunction = functions.httpsCallable('updateUserEmail');
      await updateUserEmailFunction({ email: value }).then(res => {
        if (res && res.data) {
          if (res.data === 'email taken') {
            return reject('Email address is taken');
          } else if (res.err && res.err.code === 'auth/requires-recent-login') {
            return reject('This operation is sensitive and requires recent authentication. Log in again before retrying this request.');
          } else if (res.err && res.err.code === 'auth/invalid-email') {
            return reject('The email address is badly formatted.');
          }
          return resolve();
        }
        return resolve();
      });

    //  authUser.verifyBeforeUpdateEmail(value).then(async (res) => {




      //  const newUser = auth.currentUser;

      //  console.log('sendEmailVerification', newUser);

      /*  if (newUser) {
         newUser.sendEmailVerification()
          .catch((err) => {
            console.log(err);
            console.log('Verification email failed to send')
          });
        } */

      //  await usersRef.update({
      //    [id]: value,
      //  });

      //  if (userData && userData.role && userData.role === 'teamOwner') {
      //    const ownersRef = firestore.collection('owners').doc(authUser.uid);

        //  await ownersRef.update({
        //    [id]: value,
        //  });
      //  }

      //  return resolve();
    /*  }).catch(err => {
        console.log('err', err);
        if (err && err.code === 'auth/requires-recent-login') {
          return reject('This operation is sensitive and requires recent authentication. Log in again before retrying this request.');
        } else if (err && err.code === 'auth/invalid-email') {
          return reject('The email address is badly formatted.');
        }

        return reject('Failed to update email');
      }); */

    } else {
      await usersRef.update({
        [id]: value,
      });

      if (userData && userData.role && userData.role === 'teamOwner') {
        const ownersRef = firestore.collection('owners').doc(authUser.uid);

        await ownersRef.update({
          [id]: value,
        });
      }

      return resolve();
    }
  }
});


export const verifyEmail = (id, value) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  const authUser = auth.currentUser;

  const { user } = getState();

  if (!value || !id) return reject('missing data');
  if (!(id === 'name' || id === 'email' || id === 'firstName' || id === 'roleInCompany' || id === 'phoneNumber')) return reject('wrong data id');


  if (authUser && user) {
    const { userData } = user;
    const usersRef = firestore.collection('users').doc(authUser.uid);

    if (id === 'email') {
      authUser.verifyBeforeUpdateEmail(value).then(async (res) => {
      //  const newUser = auth.currentUser;
      //  if (newUser) {
      //    newUser.sendEmailVerification()
      //    .catch(() => console.log('Verification email failed to send'));
      //  }

        await usersRef.update({
          [id]: value,
        });

        if (userData && userData.role && userData.role === 'teamOwner') {
          const ownersRef = firestore.collection('owners').doc(authUser.uid);

          await ownersRef.update({
            [id]: value,
          });
        }

        return resolve();
      }).catch(err => {
        console.log('err', err);
        if (err && err.code === 'auth/requires-recent-login') {
          return reject('This operation is sensitive and requires recent authentication. Log in again before retrying this request.');
        } else if (err && err.code === 'auth/invalid-email') {
          return reject('The email address is badly formatted.');
        }

        return reject('Failed to update email');
      })

    } else {
      await usersRef.update({
        [id]: value,
      });

      if (userData && userData.role && userData.role === 'teamOwner') {
        const ownersRef = firestore.collection('owners').doc(authUser.uid);

        await ownersRef.update({
          [id]: value,
        });
      }

      return resolve();
    }
  }
});


export const updatePassword = (newPassword) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  if (!newPassword) return reject('missing data');

  if (user) {
      user.updatePassword(newPassword).then(async res => {
        return resolve();
      }).catch(err => {
        if (err && err.code === 'auth/requires-recent-login') {
          return reject('This operation is sensitive and requires recent authentication. Log in again before retrying this request.');
        }

        return reject('Failed to update password');
      })
  }
});




export const loginAppUser = ({ email, password }) => (dispatch) => new Promise(async (resolve, reject) => {
  // dispatch(setIsFetching(true));

  console.log('loginAppUser', email, password);

  auth
    .signInWithEmailAndPassword(email, password)
    .then((res) => {
      const userDetails = res && res.user ? res.user : null;

      console.log('userDetails', userDetails);

      if (userDetails.emailVerified === false) {
        userDetails.sendEmailVerification().catch(() => console.log('Verification email failed to send'));

        console.log('Please verify your email.');
        return resolve();
        // dispatch(setIsFetching(false));
      } else {
        dispatch({
          type: DO_LOGIN,
          payload: 1
        });

        return resolve();
        // dispatch(setIsFetching(false));
      }
    })
    .catch((err) => {
      console.log(err.message);
      return resolve();
      // dispatch(setIsFetching(false));
    });
});




export const getAppUserData = () => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  console.log('getAppUserData', user);

  if (user) {
    const usersRef = firestore.collection('appClients').doc(user.uid);
    //  const doc = await usersRef.get();

    usersRef.update({ lastActive: firebase.firestore.FieldValue.serverTimestamp(), lastWebActive: firebase.firestore.FieldValue.serverTimestamp() });

    unsubscribe = usersRef.onSnapshot(async (docSnapshot) => {
      if (!docSnapshot.exists) {
        console.log('!docSnapshot.exists');
        return resolve();
      } else {
        const userData = docSnapshot.data();

        console.log('userData', userData);

        if (userData && auth.currentUser) {
          // let has2fa = false;

          /*
          if (auth.currentUser.multiFactor && auth.currentUser.multiFactor.enrolledFactors && auth.currentUser.multiFactor.enrolledFactors.length) {
            has2fa = true;

            console.log('call DO_LOGIN');
            dispatch({ type: DO_LOGIN, doNotUpdateLastLogin: true });
          } */

          console.log('userData', userData);
          console.log('auth.currentUser', auth.currentUser);

          dispatch({
            type: GET_USER_DATA,
            payload: { ...auth.currentUser, ...userData },
          //  has2fa,
          })

          return resolve({ ...auth.currentUser, ...userData });
        } else if (auth.currentUser) {
         // let has2fa = false;

          /* if (auth.currentUser.multiFactor && auth.currentUser.multiFactor.enrolledFactors && auth.currentUser.multiFactor.enrolledFactors.length) {
            has2fa = true;

            console.log('call DO_LOGIN');
            dispatch({ type: DO_LOGIN, doNotUpdateLastLogin: true });
          } */

          dispatch({
            type: GET_USER_DATA,
          //  has2fa,
            payload: { ...auth.currentUser, role: null, roleClass: null, company: null, companyName: null, dateCreated: null, status: null, roleInCompany: null },
          })

          return resolve({ ...auth.currentUser, role: null, roleClass: null, company: null, companyName: null, dateCreated: null, status: null, roleInCompany: null });
        } else {
          return resolve();
        }
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    });
  }
});