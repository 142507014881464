import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';

import { Eye, EyeOff } from 'react-feather';

import { Card, CardBody, Row, Col, Input, Label, Form, FormGroup, Button, Alert, Spinner } from 'reactstrap';

import { connect } from 'react-redux';

import {
  doLogin,
//  completePhoneVerification,
//  completePhoneEnrollment,
//  startPhoneEnrollment,
} from '../../actions/user';

import notifications from '../../const/notifications';

class Login extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    login: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      email: null,
      password: null,
    //  phoneCode: [],
      showPassword1: 'password',
    };

    this.digit1 = React.createRef();
    this.digit2 = React.createRef();
    this.digit3 = React.createRef();
    this.digit4 = React.createRef();
    this.digit5 = React.createRef();
    this.digit6 = React.createRef();
  }

  componentDidMount() {
    console.log('Login componentDidMount');
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

 // handleChangePhoneNumber = (phone) => {
 //   this.setState({ phoneNumber: phone });
 // }

  handleSubmit = (e) => {
    e.preventDefault();

    console.log('handleSubmit');

    const { login, history } = this.props;
    const { email, password, loading } = this.state;

    if(loading) return;

    this.setState({ loading: true });

    return login({ email: email, password: password })
      .then((res) => {
        console.log('login then', { res });
        if (res && res.status === 'success') {
        //  if (res.verificationType === 'enrollment') {
         //   this.setState({ loading: false, errorGreen: false, show2faPhoneModal: true, verificationType: res.verificationType });
         // } else if (res.verificationId) {
        //    this.setState({ loading: false, errorGreen: false, show2faModal: true, verificationId: res.verificationId, verificationType: res.verificationType, resolver: res.resolver });
        //  } else {
            history.push('/');
        //  }
        }
      //  history.push('/');
      })
      .catch((err) => {
        console.log('login error', err);

        console.log(notifications.login);
        if (err === 'missing email') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noEmail || err });
        } else if (err === 'missing password') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.noPassword || err });
        } else if (err === 'The email address is badly formatted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.badEmail || err });
        } else if (err === 'To access your account please click the verification link in the email sent to you.') {
          this.setState({ loading: false, errorGreen: true, error: notifications.login.needVerification || err });
        } else if (err === 'The password is invalid or the user does not have a password.' || 'There is no user record corresponding to this identifier. The user may have been deleted.') {
          this.setState({ loading: false, errorGreen: false, error: notifications.login.wrongLogin || err });
        } else {
          this.setState({ loading: false, errorGreen: false, error: err });
        }
      });
  }

  toggleShowPassword = (id) => {
    console.log('toggleShowPassword');
    console.log(id);
    if (id === 1) {
      this.setState({
        showPassword1: this.state.showPassword1 === 'input' ? 'password' : 'input'
      })
    } else if (id === 2) {
      this.setState({
        showPassword2: this.state.showPassword2 === 'input' ? 'password' : 'input'
      })
    }
  }

  render() {
    const { branding } = this.props;
    const {
      email,
      password,
      error,
      errorGreen,
      loading,
      showPassword1,
    } = this.state;

    return (
      <>
        <div class="breadcrumbs">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">Customer Sign In</h1>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <ul class="breadcrumb-nav">
                  <li><Link to="/">Home</Link></li>
                  <li>Customer Sign In</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section class="login section">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
                <div class="form-head">
                  <h4 class="title">Customer Sign In</h4>
                  {(!!error) && <Alert color={errorGreen ? "success" : "danger"}>{error}</Alert>}
                  <Form onSubmit={this.handleSubmit}>
                    <div class="form-group">
                      <label>Email</label>
                      <Input
                        name="email"
                        id="email"
                        placeholder="john@dover.com"
                        value={email}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div class="form-group" style={{ position: 'relative' }}>
                      <label>Password</label>
                      <Input
                        type={showPassword1}
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        value={password}
                        onChange={this.handleChange}
                      />
                      {showPassword1 === 'input' ? (
                        <Eye style={{ position: 'absolute', right: 0, bottom: 8, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)} />
                      ) : (
                        <EyeOff style={{ position: 'absolute', right: 0, bottom: 8, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)} />
                      )}
                    </div>
                    <div class="check-and-pass">
                      <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6 col-12">
                          <div class="form-check">
                            <input type="checkbox" class="form-check-input width-auto" id="exampleCheck1" />
                              <label class="form-check-label">Remember me</label>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                          <Link to="/reset-password" class="lost-pass">Lost your password?</Link>
                        </div>
                      </div>
                    </div>
                    <div class="button">
                      <button disabled={loading} id="sign-in-button" type="submit" class="btn">
                        Login Now
                        {loading ? (
                          <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                        ) : (null)}
                      </button>
                    </div>
                    <p class="outer-link">Don't have an account? <Link to="/signup">Sign up here.</Link></p>
                    <p style={{ marginTop: 5, fontWeight: 700 }} class="outer-link"><a href="https://app.steppr.com/login" target="_blank">Business sign in here.</a></p>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )

    return (
      <div>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Card>
              <CardBody>
                {(!!error) && <Alert color={errorGreen ? "success" : "danger"}>{error}</Alert>}
                <Form onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      name="email"
                      id="email"
                      placeholder="john@doe.corp"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup style={{ position: 'relative' }}>
                    <Label for="password">Password</Label>
                    <Input
                      type={showPassword1}
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      value={password}
                      onChange={this.handleChange}
                    />
                    {showPassword1 === 'input' ? (
                      <Eye style={{ position: 'absolute', right: 0, bottom: 8, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)} />
                    ) : (
                      <EyeOff style={{ position: 'absolute', right: 0, bottom: 8, margin: '0.375rem 0.75rem', width: 20 }} onClick={() => this.toggleShowPassword(1)} />
                    )}
                  </FormGroup>
                  <Button disabled={loading} id="sign-in-button" className="custom-primary-button" color="primary">
                    Login
                    {loading ? (
                      <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                    ):(null)}
                  </Button>
                </Form>

                <Row style={{ marginTop: 10 }}>
                  <Col sm="12">
                    <Link className="common-link" to="/reset-password" style={{ fontSize: 13, marginRight: 30, color: branding.sidebarPrimaryColor ? branding.sidebarPrimaryColor : '#162c50' }}>Forgot Password?</Link>
                    <Link className="common-link" to="/signup" style={{ fontSize: 13, color: branding.sidebarPrimaryColor ? branding.sidebarPrimaryColor : '#162c50' }} >New? Sign up here</Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user || {},
  branding: state.contentManagement.branding || {},
});

const mapDispatchToProps = {
  login: doLogin,
//  completeVerification: completePhoneVerification,
//  completeEnrollment: completePhoneEnrollment,
//  startEnrollment: startPhoneEnrollment,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));