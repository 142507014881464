
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import ReactWOW from 'react-wow';

import { WOW } from 'wowjs';
import {
  Row,
  Media,
} from 'reactstrap';

// import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import BESTS_darker from '../../../assets/images/BusinessHomepage/Darker/BESTS_darker.webp';
import BIL_darker from '../../../assets/images/BusinessHomepage/Darker/BIL_darker.webp';
import MBOLE_darker from '../../../assets/images/BusinessHomepage/Darker/MBOLE_darker.webp';
import PITCH_darker from '../../../assets/images/BusinessHomepage/Darker/PITCH_darker.webp';
import TODAYN_darker from '../../../assets/images/BusinessHomepage/Darker/TODAYN_darker.webp';

import BESTS_grey from '../../../assets/images/BusinessHomepage/Grey/BESTS_grey.webp';
import BIL_grey from '../../../assets/images/BusinessHomepage/Grey/BIL_grey.webp';
import MBOLE_grey from '../../../assets/images/BusinessHomepage/Grey/MBOLE_grey.webp';
import PITCH_grey from '../../../assets/images/BusinessHomepage/Grey/PITCH_grey.webp';
import TODAYN_grey from '../../../assets/images/BusinessHomepage/Grey/TODAYN_grey.webp';

const sliderSettings = {
  lazyload: true,
  autoplay: true,
  autoplayButtonOutput: false,
  mouseDrag: true,
  gutter: 15,
  nav: false,
  controls: true,
  controlsText: ['<i class="lni lni-chevron-left"></i>', '<i class="lni lni-chevron-right"></i>'],
  items: 5,
};

const sliderSettingsMobile = {
  lazyload: true,
  autoplay: true,
  autoplayButtonOutput: false,
  mouseDrag: true,
  gutter: 15,
  nav: false,
  controls: true,
  controlsText: ['<i class="lni lni-chevron-left"></i>', '<i class="lni lni-chevron-right"></i>'],
  items: 1,
};

class AsSeenIn extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      companyNotifications: [],
      companyInformation: '',
      selectedStep: 1,
      purchaseModal: false,
      showPassword1: 'password',
      hoverId: null,
    };
  }

  componentDidMount() {
    const { user } = this.props;

    new WOW().init();
  }

  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  onMouseEnter = (id) => {
    this.setState({ hoverId: id });
  }

  onMouseLeave = (id) => {
    this.setState({ hoverId: null });
  }

  render() {
    const { user, vouchersList, companies, categories, changeActiveCategory, activeCategory } = this.props;
    const {
      activeTab, hoverId,
    } = this.state;

    console.log('categories', categories);

    return (
      <section className="items-tab section categories style2" style={{ background: "#F4FAFF" }}>
        <ReactWOW animation='fadeInUp' delay=".4s">
          <div className="container">
            <div className="cat-inner">
              <div className="row">
                <div className="col-12">
                  <div className="section-title">
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">As Seen In</h2>
                  </div>
                </div>
              </div>
              <div>
                <div className="container">
                  <div className="client-logo-wrapper">
                    <div className="client-logo-wrapper d-none d-md-block d-lg-block" style={{ position: 'relative' }}>
                      <TinySlider
                        settings={sliderSettings}
                        className="client-logo-carousel d-flex align-items-center justify-content-between"
                      >
                        <a onMouseEnter={() => this.onMouseEnter(0)} onMouseLeave={() => this.onMouseLeave(0)} href="https://beststartup.co.uk/exploring-londons-innovative-advertising-platform-startups-in-the-uk-scene/ " key={0} className="client-logo">
                          <Media src={hoverId === 0 ? BESTS_darker : BESTS_grey} alt="#" />
                        </a>
                        <a onMouseEnter={() => this.onMouseEnter(1)} onMouseLeave={() => this.onMouseLeave(1)} href="https://brokeinlondon.com/food-shopping/uk-deals-free-stuff/" key={1} className="client-logo">
                          <Media style={{ height: 44, marginLeft: '19%' }} src={hoverId === 1 ? BIL_darker : BIL_grey} alt="#" />
                        </a>
                        <a onMouseEnter={() => this.onMouseEnter(2)} onMouseLeave={() => this.onMouseLeave(2)} href="https://www.businessmole.com/innovating-gift-giving-steppr-launches-user-friendly-voucher-gifting-for-app-users/ " key={2} className="client-logo">
                          <Media src={hoverId === 2 ? MBOLE_darker : MBOLE_grey} alt="#" />
                        </a>
                        <a onMouseEnter={() => this.onMouseEnter(3)} onMouseLeave={() => this.onMouseLeave(3)} href="https://thestartuppitch.com/beta/beta-pitch-for-steppr/ " key={3} className="client-logo">
                          <Media src={hoverId === 3 ? PITCH_darker : PITCH_grey} alt="#" />
                        </a>
                        <a onMouseEnter={() => this.onMouseEnter(4)} onMouseLeave={() => this.onMouseLeave(4)} href="https://todaynews.co.uk/2023/01/17/steppr-startup-free-voucher-ecosystem-launches-for-businesses/ " key={4} className="client-logo">
                          <Media src={hoverId === 4 ? TODAYN_darker : TODAYN_grey} alt="#" />
                        </a>
                      </TinySlider>
                    </div>
                    <div className="client-logo-wrapper d-block d-md-none d-lg-none" style={{ position: 'relative' }}>
                      <TinySlider
                        settings={sliderSettingsMobile}
                        className="client-logo-carousel d-flex align-items-center justify-content-between"
                      >
                        <a onMouseEnter={() => this.onMouseEnter(0)} onMouseLeave={() => this.onMouseLeave(0)} href="https://beststartup.co.uk/exploring-londons-innovative-advertising-platform-startups-in-the-uk-scene/ " key={0} className="client-logo">
                          <Media style={{ width: '60%', marginLeft: '20%', marginRight: '20%' }} src={hoverId === 0 ? BESTS_darker : BESTS_grey} alt="#" />
                        </a>
                        <a onMouseEnter={() => this.onMouseEnter(1)} onMouseLeave={() => this.onMouseLeave(1)} href="https://brokeinlondon.com/food-shopping/uk-deals-free-stuff/" key={1} className="client-logo">
                          <Media style={{ width: '60%', marginLeft: '20%', marginRight: '20%' }} src={hoverId === 1 ? BIL_darker : BIL_grey} alt="#" />
                        </a>
                        <a onMouseEnter={() => this.onMouseEnter(2)} onMouseLeave={() => this.onMouseLeave(2)} href="https://www.businessmole.com/innovating-gift-giving-steppr-launches-user-friendly-voucher-gifting-for-app-users/ " key={2} className="client-logo">
                          <Media style={{ width: '60%', marginLeft: '20%', marginRight: '20%' }} src={hoverId === 2 ? MBOLE_darker : MBOLE_grey} alt="#" />
                        </a>
                        <a onMouseEnter={() => this.onMouseEnter(3)} onMouseLeave={() => this.onMouseLeave(3)} href="https://thestartuppitch.com/beta/beta-pitch-for-steppr/ " key={3} className="client-logo">
                          <Media style={{ width: '60%', marginLeft: '20%', marginRight: '20%' }} src={hoverId === 3 ? PITCH_darker : PITCH_grey} alt="#" />
                        </a>
                        <a onMouseEnter={() => this.onMouseEnter(4)} onMouseLeave={() => this.onMouseLeave(4)} href="https://todaynews.co.uk/2023/01/17/steppr-startup-free-voucher-ecosystem-launches-for-businesses/ " key={4} className="client-logo">
                          <Media style={{ width: '60%', marginLeft: '20%', marginRight: '20%' }} src={hoverId === 4 ? TODAYN_darker : TODAYN_grey} alt="#" />
                        </a>
                      </TinySlider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactWOW>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AsSeenIn));
