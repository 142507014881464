
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import ReactWOW from 'react-wow';

import {
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';

// import 'rsuite-table/dist/css/rsuite-table.css';

import { connect } from 'react-redux';

import CampaignCard from './Components/CampaignCard';

class PopularVouchers extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    getVouchers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      activeTab: 2,
    };
  }

  componentDidMount() {
    const { user } = this.props;
  }

  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  setActiveTab = (tab) => {
    const { lastScroll } = this.state;
    this.setState({ activeTab: tab, tabChanged: true });

    // this.setTimeout({ window.scrollBy(0, 1) }, 50)
    setTimeout(() => {
      if (lastScroll === 'down') {
        window.scrollBy(0, -1);
        this.setState({ lastScroll: 'up' });
      } else {
        window.scrollBy(0, 1);
        this.setState({ lastScroll: 'down' });
      }
    }, 50);
  }

  render() {
    const { vouchersList, mostPopular, newest, featured, scrollToAllVouchers } = this.props;
    const {
      activeTab, tabChanged,
    } = this.state;

    console.log('vouchersList activeTab', { tabChanged, vouchersList, mostPopular, newest });

    return (
      <div style={{ marginBottom: 2 }}>
        <section className="items-tab section popular-vouchers-tab">
          <div className="container">
            {/* <ReactWOW animation='fadeInUp' delay=".4s">
              <div className="row">
                <div className="col-12">
                  <div className="section-title">
                    <h2 className="wow fadeInUp" data-wow-delay=".4s">Popular Vouchers</h2>
                    <p className="wow fadeInUp" data-wow-delay=".6s">There are many variations of passages of Lorem
                      Ipsum available, but the majority have suffered alteration in some form.</p>
                  </div>
                </div>
              </div>
            </ReactWOW> */}
            <div className="row">
              <div className="col-12">
                <ReactWOW animation='fadeInUp' delay=".4s">
                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <button onClick={() => this.setActiveTab(2)} className={`nav-link ${activeTab === 2 ? 'active' : ''}`} id="nav-popular-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-popular" type="button" role="tab" aria-controls="nav-popular" aria-selected="false">
                        Featured
                      </button>
                      <button onClick={() => this.setActiveTab(1)} className={`nav-link ${activeTab === 1 ? 'active' : ''}`} id="nav-latest-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-latest" type="button" role="tab" aria-controls="nav-latest" aria-selected="true">
                        Newest
                      </button>
                      <button onClick={() => this.setActiveTab(3)} className={`nav-link ${activeTab === 3 ? 'active' : ''}`} id="nav-random-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-random" type="button" role="tab" aria-controls="nav-random" aria-selected="false">
                        Trending
                      </button>
                    </div>
                  </nav>
                </ReactWOW>
                <TabContent activeTab={activeTab} className="tab-content" id="nav-tabContent">
                  <TabPane tabId={1} id="nav-latest" role="tabpanel">
                    <div className="row" style={{ minHeight: 480 }}>
                      <ReactWOW animation='fadeInUp' delay=".4s" disabled={tabChanged}>
                        <div className="col-lg-3 col-md-4 col-12 single-item-grid-wrapper">
                          <CampaignCard type={'newest1'} key={(newest && newest[0]) ? newest[0].key : null} vouchersList={vouchersList} display={!(newest && newest.length)} voucher={(newest && newest[0] && vouchersList && vouchersList[newest[0].key]) ? vouchersList[newest[0].key] : undefined} voucherId={(newest && mostPopular[0]) ? newest[0].key : null} />
                          {/* <CampaignCard type={'newest1'} key={'newest1'} newest={newest} vouchersList={vouchersList} display={!(newest && newest.length)}  voucher={(newest && newest[0] && vouchersList && vouchersList[newest[0].key]) ? vouchersList[newest[0].key] : null} voucherId={(newest && newest[0]) ? newest[0].key : null} /> */}
                        </div>
                      </ReactWOW>
                      <ReactWOW animation='fadeInUp' delay=".4s" disabled={tabChanged}>
                        <div className="col-lg-3 col-md-4 col-12 single-item-grid-wrapper">
                          <CampaignCard type={'newest2'} key={'newest2'} newest={newest} vouchersList={vouchersList} display={!(newest && newest.length)} voucher={(newest && newest[1] && vouchersList && vouchersList[newest[1].key]) ? vouchersList[newest[1].key] : undefined} voucherId={(newest && newest[1]) ? newest[1].key : null} />
                        </div>
                      </ReactWOW>
                      <ReactWOW animation='fadeInUp' delay=".4s" disabled={tabChanged}>
                        <div className="col-lg-3 col-md-4 col-12 single-item-grid-wrapper">
                          <CampaignCard type={'newest3'} key={'newest3'} newest={newest} vouchersList={vouchersList} display={!(newest && newest.length)} voucher={(newest && newest[2] && vouchersList && vouchersList[newest[2].key]) ? vouchersList[newest[2].key] : undefined} voucherId={(newest && newest[2]) ? newest[2].key : null} />
                        </div>
                      </ReactWOW>
                      <ReactWOW animation='fadeInUp' delay=".4s" disabled={tabChanged}>
                        <div className="col-lg-3 col-md-4 col-12 single-item-grid-wrapper">
                          <CampaignCard type={'newest4'} key={'newest4'} newest={newest} vouchersList={vouchersList} display={!(newest && newest.length)} voucher={(newest && newest[3] && vouchersList && vouchersList[newest[3].key]) ? vouchersList[newest[3].key] : undefined} voucherId={(newest && newest[3]) ? newest[3].key : null} />
                        </div>
                      </ReactWOW>
                      {/* 
{newest ? newest.map((camp, index) => {
                        if (index > 3) { return null };

                        if (activeCategory && !camp.categoryIds.includes(activeCategory)) { return null };

                        //  if (tabChanged) {
                        return (
                          <div className="col-lg-3 col-md-4 col-12">
                            <CampaignCard vouchersList={vouchersList} voucher={vouchersList[camp.key]} voucherId={camp.key} />
                          </div>
                        );
                        //  }
                        return (
                          <ReactWOW animation='fadeInUp' delay=".04s" disabled={true}>
                            <div className="col-lg-3 col-md-4 col-12">
                              <CampaignCard vouchersList={vouchersList} voucher={vouchersList[camp.key]} voucherId={camp.key} />
                            </div>
                          </ReactWOW>
                        );
                      }) : (
                        <>
                          {(() => {
                            const rows = [];
                              for (let i = 0; i < 4; i++) {

                                rows.push(
                                  <ReactWOW animation='fadeInUp' delay=".4s" disabled={tabChanged}>
                                    <div className="col-lg-3 col-md-4 col-12"><PlaceholderCard key={`newest-${i}`} /></div>
                                  </ReactWOW>
                                );
                            }
                            return rows;
                          })()}
                        </>
                      )} */}
                    </div>
                  </TabPane>
                  <TabPane tabId={2} id="nav-popular" role="tabpanel">
                    <div className="row" style={{ minHeight: 480 }}>
                      <>
                        {(() => {
                          const rows = [];
                          for (let i = 0; i < 4; i++) {
                            let hasFeatured = false;
                            let hasVouchers = false;

                            if (featured && featured.length) {
                              hasFeatured = true;
                            }

                            if (vouchersList && Object.keys(vouchersList).length) {
                              hasVouchers = true;
                            }

                            console.log('hasFeatured', hasVouchers, featured);

                            let voucher = null;
                            let voucherId = null;

                            if (hasFeatured && featured[i] && hasVouchers && vouchersList[featured[i].key]) {
                              voucher = vouchersList[featured[i].key];
                              voucherId = featured[i].key;
                            } else if (hasVouchers && Object.keys(vouchersList)[i] && vouchersList[Object.keys(vouchersList)[i]] && !vouchersList[Object.keys(vouchersList)[i]].featured) {
                              voucherId = Object.keys(vouchersList)[i];
                              voucher = vouchersList[voucherId];
                            } else {
                              voucher = undefined;
                            }

                            console.log('voucherIdvoucherId', voucherId, voucher);

                            rows.push(
                              <ReactWOW animation='fadeInUp' delay=".4s" disabled={tabChanged}>
                                <div className="col-lg-3 col-md-4 col-12 single-item-grid-wrapper">
                                  <CampaignCard
                                    type={voucherId}
                                    key={voucherId}
                                    vouchersList={vouchersList}
                                    display={!(hasFeatured || hasVouchers)}
                                    voucher={voucher}
                                    voucherId={voucherId}
                                    tabChanged={tabChanged}
                                  />
                                </div>
                              </ReactWOW>
                            );
                          }
                          return rows;
                        })()}
                      </>

                      {/*
                      {featured ? featured.map((camp, index) => {
                        if (index > 3) { return null };

                        if (activeCategory && !camp.categoryIds.includes(activeCategory)) { return null };

                          return (
                            <div className="col-lg-3 col-md-4 col-12">
                              <CampaignCard vouchersList={vouchersList} voucher={vouchersList[camp.key]} voucherId={camp.key} imageLoaded={this.imageLoaded} />
                            </div>
                          );

                        {/* <ReactWOW animation='fadeInUp' delay=".04s" disabled={true}> 
                        return (
                            <div className="col-lg-3 col-md-4 col-12">
                              <CampaignCard vouchersList={vouchersList} voucher={vouchersList[camp.key]} voucherId={camp.key} imageLoaded={this.imageLoaded} />
                            </div>
                        );
                        {/* </ReactWOW>
                      }) : (
                        <>
                          {(() => {
                            const rows = [];
                            for (let i = 0; i < 4; i++) {
                              rows.push(
                                <ReactWOW animation='fadeInUp' delay=".2s" disabled={tabChanged}>
                                  <div className="col-lg-3 col-md-4 col-12"><PlaceholderCard key={`featured-${i}`} /></div>
                                </ReactWOW>
                              );
                            }
                            return rows;
                          })()}
                        </>
                      )}
                      {featured && Object.keys(featured).length < 4 ? (
                        <>
                          {vouchersList ? Object.keys(vouchersList).map((key, index) => {
                            if (index > (3 - Object.keys(featured).length)) { return null };

                            if (activeCategory && !vouchersList[key].categoryIds.includes(activeCategory)) { return null };

                            if (tabChanged) {
                              return (
                                <div className="col-lg-3 col-md-4 col-12">
                                  <CampaignCard vouchersList={vouchersList} voucher={vouchersList[key]} voucherId={key} />
                                </div>
                              );
                            }

                            return (
                              <ReactWOW animation='fadeInUp' delay=".04s" disabled={true}>
                                <div className="col-lg-3 col-md-4 col-12">
                                  <CampaignCard vouchersList={vouchersList} voucher={vouchersList[key]} voucherId={key} />
                                </div>
                              </ReactWOW>
                            );
                          }) : null}
                        </>
                      ) : (null)} */}
                    </div>
                  </TabPane>
                  <TabPane tabId={3} id="nav-random" role="tabpanel">
                    <div className="row" style={{ minHeight: 480 }}>
                      <ReactWOW animation='fadeInUp' delay=".4s" disabled={tabChanged}>
                      <div className="col-lg-3 col-md-4 col-12 single-item-grid-wrapper">
                          <CampaignCard type={'mostPopular1'} key={(mostPopular && mostPopular[0]) ? mostPopular[0].key : null} vouchersList={vouchersList} display={!(mostPopular && mostPopular.length)} voucher={(mostPopular && mostPopular[0] && vouchersList && vouchersList[mostPopular[0].key]) ? vouchersList[mostPopular[0].key] : undefined} voucherId={(mostPopular && mostPopular[0]) ? mostPopular[0].key : null} />
                        </div>
                      </ReactWOW>
                        <ReactWOW animation='fadeInUp' delay=".4s" disabled={tabChanged}>
                      <div className="col-lg-3 col-md-4 col-12 single-item-grid-wrapper">
                          <CampaignCard type={'mostPopular2'} key={(mostPopular && mostPopular[1]) ? mostPopular[1].key : null} vouchersList={vouchersList} display={!(mostPopular && mostPopular.length)} voucher={(mostPopular && mostPopular[1] && vouchersList && vouchersList[mostPopular[1].key]) ? vouchersList[mostPopular[1].key] : undefined} voucherId={(mostPopular && mostPopular[1]) ? mostPopular[1].key : null} />
                        </div>
                      </ReactWOW>
                          <ReactWOW animation='fadeInUp' delay=".4s" disabled={tabChanged}>
                      <div className="col-lg-3 col-md-4 col-12 single-item-grid-wrapper">
                          <CampaignCard type={'mostPopular3'} key={(mostPopular && mostPopular[2]) ? mostPopular[2].key : null} vouchersList={vouchersList} display={!(mostPopular && mostPopular.length)} voucher={(mostPopular && mostPopular[2] && vouchersList && vouchersList[mostPopular[2].key]) ? vouchersList[mostPopular[2].key] : undefined} voucherId={(mostPopular && mostPopular[2]) ? mostPopular[2].key : null} />
                        </div>
                      </ReactWOW>
                            <ReactWOW animation='fadeInUp' delay=".4s" disabled={tabChanged}>
                      <div className="col-lg-3 col-md-4 col-12 single-item-grid-wrapper">
                          <CampaignCard type={'mostPopular4'} key={(mostPopular && mostPopular[3]) ? mostPopular[3].key : null} vouchersList={vouchersList} display={!(mostPopular && mostPopular.length)} voucher={(mostPopular && mostPopular[3] && vouchersList && vouchersList[mostPopular[3].key]) ? vouchersList[mostPopular[3].key] : undefined} voucherId={(mostPopular && mostPopular[3]) ? mostPopular[3].key : null} />
                        </div>
                      </ReactWOW>

                      {/*
                      {mostPopular ? mostPopular.map((camp, index) => {
                        if (index > 3) { return null };

                        if (activeCategory && !camp.categoryIds.includes(activeCategory)) { return null };

                        return (
                          <div className="col-lg-3 col-md-4 col-12">
                            <CampaignCard vouchersList={vouchersList} voucher={vouchersList[camp.key]} voucherId={camp.key} />
                          </div>
                        );

                        return (
                          <ReactWOW animation='fadeInUp' delay=".04s" disabled={true}>
                            <div className="col-lg-3 col-md-4 col-12">
                              <CampaignCard vouchersList={vouchersList} voucher={vouchersList[camp.key]} voucherId={camp.key} />
                            </div>
                          </ReactWOW>
                        );
                      }) : (
                        <>
                            {(() => {
                              const rows = [];
                              for (let i = 0; i < 4; i++) {
                                rows.push(
                                  <ReactWOW animation='fadeInUp' delay=".4s" disabled={tabChanged}>
                                    <div className="col-lg-3 col-md-4 col-12"><PlaceholderCard key={`mostPopular-${i}`} /></div>
                                  </ReactWOW>
                                );
                              }
                              return rows;
                            })()}
                        </>
                      )} */}
                    </div>
                  </TabPane>
                </TabContent>
                <div className={`popular-vouchers-all-button-wrapper`}>
                  <Button color="primary" onClick={scrollToAllVouchers} className={`popular-vouchers-all-button`}>
                    All Vouchers
                    <i class="lni lni-chevron-down" style={{ height: 22, lineHeight: "22px", paddingLeft: 8, paddingTop: 1 }}></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  mostPopular: state.customer.mostPopular || null,
  newest: state.customer.newest || null,
  featured: state.customer.featured || null,
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopularVouchers));









