import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import {
  Row,
  Col,
} from 'reactstrap';

import { connect } from 'react-redux';

import Hierarchy1 from '../ReactIcons/Hierarchy1';
import BoxGift1 from '../ReactIcons/BoxGift1';
import Trophy from '../ReactIcons/Trophy';
import TrendDown1 from '../ReactIcons/TrendDown1';

const sliderSettings = {
  lazyload: true,
  autoplay: true,
  autoplayButtonOutput: false,
  mouseDrag: true,
  gutter: 15,
  nav: false,
  controls: false,
};

const responsive = {
  '992': {
    items: 6
  },
  '1124': {
    items: 8
  },
  '21124': {
    items: 8
  },
  '762': {
    items: 4
  },
  '576': {
    items: 2
  }
}

class DashboardInfoBox extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      companyNotifications: [],
      companyInformation: '',
      selectedStep: 1,
      purchaseModal: false,
      showPassword1: 'password',

      sliderSettings: {
        lazyload: true,
        autoplay: true,
        autoplayButtonOutput: false,
        mouseDrag: true,
        gutter: 15,
        nav: false,
        controls: false,
        //  items: 8,
      },
    };
  }

  componentDidMount() {
    const { user } = this.props;

    // window.addEventListener('resize', this.handleResize);

    // const carousel = useRef(null)
  }

  handleResize = () => {
    const width = window.innerWidth;
    let items = 8;
    if (width > 992) {
      items = 8;
    } else if (width > 762) {
      items = 6;
    } else if (width > 576) {
      items = 4;
    } else {
      items = 2;
    }

    console.log('handleResize items', items);

    this.setState({
      sliderSettings: {
        lazyload: true,
        autoplay: true,
        autoplayButtonOutput: false,
        mouseDrag: true,
        gutter: 15,
        nav: false,
        controls: false,
        items: items,
      },
    });
  };

  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { user, vouchersList, companies, categories, categoriesArr, changeActiveCategory, activeCategory } = this.props;
    const {
      sliderSettings,
    } = this.state;

    return (
      <div style={{ marginBottom: -60 }}>
        <section className="hero-area overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
                <div className="hero-text text-center">
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="info-box">
          <div className="container">
            <div className="info-box-inner">
              <Row>
                <Col xs="12" sm="12" md="4" className="single-info-box-item single-info-box-item-main-text">
                  <h5 className="single-info-box-item-main-text">Vouchers & Gifting</h5>
                </Col>

                <Col xs="6" sm="3" md="2" className="single-info-box-item">
                  <div className="icon single-info-box-item-icon" style={{ justifyContent: 'center', display: 'flex' }} >
                    <Trophy />
                  </div>
                  <h5 className="single-info-box-item-text">Exclusive Vouchers</h5>
                </Col>

                <Col xs="6" sm="3" md="2" className="single-info-box-item">
                  <div className="icon single-info-box-item-icon" style={{ justifyContent: 'center', display: 'flex' }} >
                    <TrendDown1 />
                  </div>
                  <h5 className="single-info-box-item-text">Lower Prices</h5>
                </Col>

                <Col xs="6" sm="3" md="2" className="single-info-box-item">
                  <div className="icon single-info-box-item-icon" style={{ justifyContent: 'center', display: 'flex' }} >
                    <Hierarchy1 />
                  </div>
                  <h5 className="single-info-box-item-text">Support Businesses</h5>
                </Col>

                <Col xs="6" sm="3" md="2" className="single-info-box-item">
                  <div className="icon single-info-box-item-icon" style={{ justifyContent: 'center', display: 'flex' }} >
                    <BoxGift1 />
                  </div>
                  <h5 className="single-info-box-item-text">Great Gifts</h5>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  categories: state.customer.categories || {},
  categoriesArr: state.customer.categoriesArr || [],
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardInfoBox));
