
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import {
  Row,
  Col,
  Media,
} from 'reactstrap';

import { connect } from 'react-redux';

import companyLogo1 from '../../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Amadeus Jewellery.webp';
import companyLogo2 from '../../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Buddha Beauty.webp';
import companyLogo3 from '../../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Crystals Healing.webp';
import companyLogo4 from '../../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Fennel and Ginger.webp';
import companyLogo5 from '../../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Grounded Body Scrub.webp';
import companyLogo6 from '../../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Guava and Gold.webp';
import companyLogo7 from '../../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Mos Accessories.webp';
import companyLogo8 from '../../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Munki Magik.webp';
import companyLogo9 from '../../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Pitod.webp';
import companyLogo10 from '../../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Rocket Jack.webp';
import companyLogo11 from '../../../../assets/images/BusinessHomepage/Co Logos/Steppr loyalty and gifting - Trend Tonic.webp';

const sliderSettingsOld = {
  lazyload: true,
  autoplay: true,
  autoplayButtonOutput: false,
  mouseDrag: true,
  gutter: 15,
  nav: false,
  controls: true,
};

const responsive = {
  '992': {
    items: 6
  },
  '1124': {
    items: 8
  },
  '21124': {
    items: 8
  },
  '762': {
    items: 4
  },
  '576': {
    items: 2
  }
}




const sliderSettings = {
  lazyload: true,
 // loop: false,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  mouseDrag: true,
  gutter: 15,
  nav: false,
  controls: true,
//  prevButton: <div className="category-slider-prev-button">{`<`}</div>,
  //  nextButton: <div className="category-slider-next-button">{`>`}</div>,
  controlsText: ['<i class="lni lni-chevron-left"></i>', '<i class="lni lni-chevron-right"></i>'],
  // controlsText: [<i class="lni lni-chevron-left" />, <i class= "lni lni-chevron-right" />],
  items: 6,
};

const sliderSettingsMobile = {
  lazyload: true,
  // loop: false,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  mouseDrag: true,
  gutter: 15,
  nav: false,
  controlsText: ['<i class="lni lni-chevron-left"></i>', '<i class="lni lni-chevron-right"></i>'],
  items: 1,
};

class CategoriesList extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      companyNotifications: [],
      companyInformation: '',
      selectedStep: 1,
      purchaseModal: false,
      showPassword1: 'password',

      /* sliderSettings:{
        lazyload: true,
        autoplay: true,
        autoplayButtonOutput: false,
        mouseDrag: true,
        gutter: 15,
        nav: false,
        controls: false,
      //  items: 8,
      }, */
    };
  }

  componentDidMount() {
    const { user } = this.props;

    // window.addEventListener('resize', this.handleResize);

    // const carousel = useRef(null)
  }

  /*
  handleResize = () => {
    const width = window.innerWidth;
    let items = 8;
    if (width > 992) {
      items = 8;
    } else if (width > 762) {
      items = 6;
    } else if (width > 576) {
      items = 4;
    } else {
      items = 2;
    }

    console.log('handleResize items', items);

    this.setState({
      sliderSettings: {
        lazyload: true,
        autoplay: true,
        autoplayButtonOutput: false,
        mouseDrag: true,
        gutter: 15,
        nav: false,
        controls: false,
        items: items,
      },
    });
  }; */

  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { user, vouchersList, companies, categories, categoriesArr, changeActiveCategory, activeCategory } = this.props;
    const {
    //  sliderSettings,
    } = this.state;

    console.log('categoriescategories', categories);

    return (
      <div style={{ marginBottom: 20 }}>
        <section class="categories">
          <div class="container">
            <div class="cat-inner">
              <Row>
                <Col xs="12" class="col-12 p-0">
                  <Row class="category-slider">

                    <div className="client-logo-wrapper d-none d-md-block d-lg-block">
                      <TinySlider
                        settings={sliderSettings}
                        className="client-logo-carousel d-flex align-items-center justify-content-between"
                      >
                        <div
                          key={'all'}
                          style={{ background: (activeCategory === 'all') ? '#f4faff' : '#f4faff', cursor: 'pointer', paddingTop: 10, paddingBottom: 5 }}
                          className="single-cat"
                          onClick={() => changeActiveCategory('all')}
                        >
                          <h5 style={{ textAlign: 'center', fontSize: 26, paddingTop: 10 }}>All</h5>
                        </div>
                        {/* <a key={1} className="client-logo">
                          <Media src={companyLogo2} alt="#" />
                        </a>
                         <a key={2} className="client-logo">
                          <Media src={companyLogo3} alt="#" />
                        </a>
                        <a key={3} className="client-logo">
                          <Media src={companyLogo4} alt="#" />
                        </a>
                        <a key={4} className="client-logo">
                          <Media src={companyLogo5} alt="#" />
                        </a>
                        <a key={5} className="client-logo">
                          <Media src={companyLogo6} alt="#" />
                        </a>
                        <a key={6} className="client-logo">
                          <Media src={companyLogo7} alt="#" />
                        </a>
                        <a key={7} className="client-logo">
                          <Media src={companyLogo8} alt="#" />
                        </a>
                        <a key={8} className="client-logo">
                          <Media src={companyLogo9} alt="#" />
                        </a>
                        <a key={9} className="client-logo">
                          <Media src={companyLogo10} alt="#" />
                        </a>
                        <a key={10} className="client-logo">
                          <Media src={companyLogo11} alt="#" />
                        </a> */}

                        {categoriesArr ? categoriesArr
                          .filter((category) => (category.key && categories[category.key].name !== "Rewards unlocked" && categories[category.key].widget !== "unlocked"))
                          .map((category) => {
                            const { key } = category;

                            //   if (categories[key].name === "Rewards unlocked" || categories[key].widget === "unlocked") {
                            //     return null;
                            //   }

                            console.log('activeCategory', activeCategory, categories[key].id);

                            return (
                              <div
                                key={key}
                                style={{ background: activeCategory === categories[key].id ? '#f4faff' : '#f4faff', cursor: 'pointer', paddingTop: 10, paddingBottom: 5 }}
                                className="single-cat"
                                onClick={() => changeActiveCategory(categories[key].id)}
                              >
                                <div className="icon">
                                  <div className={`${activeCategory === categories[key].id ? 'icon-wrapper-active' : ''} icon-wrapper`}>
                                    <img loading='lazy' src={categories[key].imageUrl} alt={categories[key].name} />
                                  </div>
                                </div>
                                <h5 style={{ textAlign: 'center', fontSize: 14, paddingTop: 5 }}>{categories[key].name}</h5>
                              </div>
                            );
                          }) : (null)}
                      </TinySlider>

                      {/*<TinySlider
                        settings={sliderSettings}
                        className="client-logo-carousel d-flex align-items-center justify-content-between"
                      >
                        <div
                          key={'all'}
                          style={{ background: (activeCategory === 'all') ? '#f4faff' : '#f4faff', cursor: 'pointer', paddingTop: 10, paddingBottom: 5 }}
                          className="single-cat"
                          onClick={() => changeActiveCategory('all')}
                        >
                          <h5 style={{ textAlign: 'center', fontSize: 26, paddingTop: 10 }}>All</h5>
                        </div>
                        {categoriesArr ? categoriesArr
                          .filter((category) => (category.key && categories[category.key].name !== "Rewards unlocked" && categories[category.key].widget !== "unlocked"))
                          .map((category) => {
                          const { key } = category;

                       //   if (categories[key].name === "Rewards unlocked" || categories[key].widget === "unlocked") {
                       //     return null;
                       //   }

                            console.log('activeCategory', activeCategory, categories[key].id);

                            return (
                              <div
                                // key={key}
                                style={{ background: activeCategory === categories[key].id ? '#f4faff' : '#f4faff', cursor: 'pointer', paddingTop: 10, paddingBottom: 5 }}
                                className="single-cat"
                                onClick={() => changeActiveCategory(categories[key].id)}
                              >
                                <div className="icon">
                                  <div className={`${activeCategory === categories[key].id ? 'icon-wrapper-active' : ''} icon-wrapper`}>
                                  <img loading='lazy' src={categories[key].imageUrl} alt={categories[key].name} />
                                </div>
                              </div>
                              <h5 style={{ textAlign: 'center', fontSize: 14, paddingTop: 5 }}>{categories[key].name}</h5>
                            </div>
                          );
                        }) : (null)}
                      </TinySlider> */}
                    </div>
                    <div className="client-logo-wrapper d-block d-md-none d-lg-none">
                      <TinySlider
                        settings={sliderSettingsMobile}
                        className="client-logo-carousel d-flex align-items-center justify-content-between"
                      >
                        <div
                          key={'all'}
                          style={{ background: (activeCategory === 'all') ? '#f4faff' : '#f4faff', cursor: 'pointer', padding: 10, paddingTop: 10, paddingBottom: 5 }}
                          className="single-cat"
                          onClick={() => changeActiveCategory('all')}
                        >
                          <h5 style={{ textAlign: 'center', fontSize: 26, paddingTop: 10 }}>All</h5>
                        </div>
                        {categoriesArr ? categoriesArr
                          .filter((category) => (category.key && categories[category.key].name !== "Rewards unlocked" && categories[category.key].widget !== "unlocked"))
                          .map((category) => {
                          const { key } = category;

                        //  if (!categories || !categories[key] || categories[key].name === "Rewards unlocked" || categories[key].widget === "unlocked") {
                       //     return null;
                       //   }

                          return (
                            <div
                              key={key}
                              style={{ background: activeCategory === categories[key].id ? '#f4faff' : '#f4faff', cursor: 'pointer', paddingTop: 10, paddingBottom: 5 }}
                              className="single-cat"
                              onClick={() => changeActiveCategory(categories[key].id)}
                            >
                              <div className="icon">
                                <div className={`${activeCategory === categories[key].id ? 'icon-wrapper-active' : ''} icon-wrapper`}>
                                  <img className="" loading='lazy' src={categories[key].imageUrl} alt={categories[key].name} />
                                </div>
                              </div>
                              <h5 style={{ textAlign: 'center', fontSize: 14, paddingTop: 5 }}>{categories[key].name}</h5>
                            </div>
                          );
                        }) : (null)}
                      </TinySlider>
                    </div>
                  </Row> 
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
  categories: state.customer.categories || {},
  categoriesArr: state.customer.categoriesArr || [],
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoriesList));
