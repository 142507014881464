import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';
import ReactWOW from 'react-wow';

import {
  Row,
  Col,
  Button,
  Alert,
  Input,
  Spinner,
} from 'reactstrap';

import { connect } from 'react-redux';

import CampaignCard from './Components/CampaignCard';
import { auth } from '../../../lib/firebase';

import {
  getUnlockedVouchers,
  getOrderedVouchers,
  getVoucherList,

  updateUserName,
  updateUserEmail,
  updateUserPassword,
  deleteAccount,
} from '../../../actions/customer';

import { doLogout } from '../../../actions/user';

// import unlock from '../../../assets/icons/unlock.svg';
import voucher from '../../../assets/icons/voucher.svg';
import gift_card from '../../../assets/icons/gift_card.svg';
import checkmark from '../../../assets/icons/checkmark.svg';

const pound = `${'\u00A3'}`;

class ForMe extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    fetchUnlockedVouchers: PropTypes.func.isRequired,
    fetchOrderedVouchers: PropTypes.func.isRequired,
    getVouchers: PropTypes.func.isRequired,

    updateName: PropTypes.func.isRequired,
    updateEmail: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      activeTab: '2',
      fetchingVouchers: true,
      orderedVouchersFilter: 'all',
    };
  }

  componentDidMount() {
    const { fetchUnlockedVouchers, fetchOrderedVouchers, getVouchers, history } = this.props;
    const { fetchingVouchers } = this.state;

    auth.onAuthStateChanged(user => {
      if (user && user.uid && fetchingVouchers) {
        getVouchers().then(() => {
          this.setState({ fetchingVouchers: false });

          //  fetchUnlockedVouchers().then(() => {
          //    this.setState({ fetchingVouchers: false });
          //  }).catch((err) => {
          //    this.setState({ fetchingVouchers: false });
          //  });
        });
      } else if (!user) {
        setTimeout(() => {
          if (!auth.currentUser || !auth.currentUser.uid) {
            history.push('/login');
          }
        }, 500);
      }
    });
  }


  changeActiveTab = (id) => {
    const { fetchUnlockedVouchers, fetchOrderedVouchers } = this.props;

    if (id === '2') {
      fetchOrderedVouchers();
    } else if (id === '1') {
      // this.setState({ fetchingVouchers: true });

      fetchUnlockedVouchers().then((res) => {
        console.log("fetchUnlockedVouchers", res);

        this.setState({ fetchingVouchers: false });
      }).catch((err) => {
        this.setState({ fetchingVouchers: false });
      });
    } else if (id === '3') {
      const { user } = this.props;

      console.log('edit profile user', user);

      this.setState({ email: user.email, name: user.name });
    }
    this.setState({ activeTab: id });
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value, updateProfileSuccess: null, updateProfileError: null, updatePasswordSuccess: null, updatePasswordError: null });

  updateProfile = () => {
    const { user, updateName, updateEmail } = this.props;
    const { name, email } = this.state;

    console.log('updateProfile', name, email, user);

    if (name !== user.name && user.uid) {
      this.setState({ updateNameSpinner: true });
      updateName(user.uid, name).then((res) => {
        console.log('updateName res', res);
        this.setState({ updateNameSpinner: null, updateProfileSuccess: true, updateProfileError: null });
      }).catch((err) => {
        console.log('updateName err', err);
        this.setState({ updateNameSpinner: null, updateProfileSuccess: null, updateProfileError: err });
      });
    }

    if (email !== user.email && user.uid) {
      this.setState({ updateEmailSpinner: true });
      updateEmail(user.uid, email).then((res) => {
        this.scrollToTop();
        this.setState({ updateEmailSpinner: null, updateProfileSuccess: true, updateProfileError: null });
        console.log('updateEmail res', res);
      }).catch((err) => {
        if (err === 'This operation is sensitive and requires recent authentication. Log in again before retrying this request.') {
          this.setState({
            updateEmailSpinner: null,
            updateProfileSuccess: null,
            updateProfileError: 'Please change to �This requires recent authentication. Please log out and in, and then try again.',
          });
        } else {
          this.setState({ updateEmailSpinner: null, updateProfileSuccess: null, updateProfileError: err });
        }

        console.log('updateEmail err', err);
      });
    }
  }

  updatePassword = () => {
    const { user, updatePassword } = this.props;
    const { email, password, newPassword, retypePassword } = this.state;

    console.log('updatePassword', email, password, newPassword, retypePassword, user);

    if (password && newPassword && retypePassword && retypePassword === newPassword) {
      this.setState({ updatePasswordSpinner: true });
      updatePassword(email, password, newPassword).then((res) => {
        console.log('updatePassword res', res);
        this.setState({ updatePasswordSpinner: null, updatePasswordSuccess: true, updatePasswordError: null });
      }).catch((err) => {
        console.log('updatePassword err', err);
        this.setState({ updatePasswordSpinner: null, updatePasswordSuccess: null, updatePasswordError: err });
      });
    }
  }

  logout = () => {
    const { logout } = this.props;

    logout();
  }

  scrollToTop = () => {
    console.log('scrollToTop');
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }

  changeOrderedVouchersFilter = (newVal) => {
    this.setState({ orderedVouchersFilter: newVal });
  }

  copyCode = (code) => {
    try {
      this.setState({ codeCopied: code });
      navigator.clipboard.writeText(code);
    } catch (err) { }
  }

  closeCodePopover = () => {
    this.setState({ codeCopied: null });
  }

  openDelete = () => {
    this.setState({ activeTab: '4' });
  }

  cancelDelete = () => {
    this.setState({ activeTab: '3' });
  }

  deleteAcount = () => {
    const { deleteAccountFunc } = this.props;

    deleteAccountFunc();
  }

  setHoverId = (newId) => {
    console.log('setHoverId', newId);

    this.setState({ hoverId: newId });
  }

  render() {
    const {
      user,
      vouchersList,
      unlockedList,
      orderedVouchers,
      sortedOrderedVouchers,
      sortedUsed,
      sortedGifted,
      sortedUnused,
      sortedCanceled,
      companies,
      myVouchersCount,
      giftedVouhcersCount,
      usedVouchersCount,
    } = this.props;
    const {
      activeTab,
      fetchingVouchers,
      name,
      email,
      password,
      newPassword,
      retypePassword,
      updateNameSpinner,
      updateEmailSpinner,
      updatePasswordSpinner,
      updateProfileSuccess,
      updateProfileError,
      updatePasswordSuccess,
      updatePasswordError,
      orderedVouchersFilter,
      codeCopied,
      hoverId,
    } = this.state;

    if (!vouchersList || fetchingVouchers) {
      return (
        <div className="preloader">
          <div className="preloader-inner">
            <div className="preloader-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      );
    }

    let orderedVouchersArr = sortedOrderedVouchers;

    if (orderedVouchersFilter === 'used') {
      orderedVouchersArr = sortedUsed;
    } else if (orderedVouchersFilter === 'gifted') {
      orderedVouchersArr = sortedGifted;
    } else if (orderedVouchersFilter === 'unused') {
      orderedVouchersArr = sortedUnused;
    } else if (orderedVouchersFilter === 'canceled') {
      orderedVouchersArr = sortedCanceled;
    }

    console.log('orderedVouchersArr', orderedVouchersArr);

    return (
      <div>

        <div class="breadcrumbs">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">My Vouchers & Account</h1>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <ul class="breadcrumb-nav">
                  <li><Link onClick={this.scrollToTop} to="/">Home</Link></li>
                  <li>My Vouchers & Account</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section className="for-me dashboard section">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-12">
                <div className="dashboard-sidebar">
                  <div className="user-image" style={{ paddingLeft: 30 }}>
                    <h3>{user.name || ''}</h3>
                    <span>{user.email || ''}</span>
                  </div>
                  <div className="dashboard-menu">
                    <ul>
                      <li onClick={() => this.changeActiveTab('2')}><a className={activeTab === '2' ? "active" : ""}>{/*<Media src={voucher} />*/}<i className="lni lni-wallet-1"></i>Voucher Wallet</a></li>
                      <li onClick={() => this.changeActiveTab('1')}><a className={activeTab === '1' ? "active" : ""}>{/*<Media src={unlock} />*/}<i class="lni lni-unlocked-2"></i>Unlocked Vouchers</a></li>
                      <li onClick={() => this.changeActiveTab('3')}><a className={activeTab === '3' ? "active" : ""}><i className="lni lni-pencil-alt" style={{ fontSize: 13, marginRight: 9, marginLeft: 2 }}></i>Edit Profile</a></li>
                    </ul>
                    <div className="button">
                      <a className="btn" onClick={this.logout}>Logout</a>
                    </div>
                  </div>
                </div>
                <div className="" style={{ marginTop: 10 }}>
                  <a onClick={this.openDelete} className="close-account-button" style={{ color: "#AAA", cursor: 'pointer' }}>Close Account</a>
                </div>
              </div>
              <div class="col-lg-9 col-md-12 col-12">
                <div class="main-content">
                  <Row style={{ marginBottom: 10 }}>
                    <Col xs="12" md="4" className="for-me-graph-block for-me-graph-block-first dashboard-block">
                      <Row>
                        <Col xs="4">
                          <img
                            src={voucher}
                            alt=""
                            className="for-me-graph-icon for-me-graph-icon-1"
                          />
                        </Col>
                        <Col xs="8">
                          <h3 className="for-me-graph-count">{myVouchersCount || 0}</h3>
                          <div className="for-me-graph-name">My Vouchers</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" md="4" className="for-me-graph-block dashboard-block">
                      <Row>
                        <Col xs="4">
                          <img
                            src={gift_card}
                            alt=""
                            className="for-me-graph-icon for-me-graph-icon-2"
                          />
                        </Col>
                        <Col xs="8">
                          <h3 className="for-me-graph-count">{giftedVouhcersCount || 0}</h3>
                          <div className="for-me-graph-name">Gifted Vouchers</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" md="4" className="for-me-graph-block for-me-graph-block-last dashboard-block">
                      <Row>
                        <Col xs="4">
                          <img
                            src={checkmark}
                            alt=""
                            className="for-me-graph-icon for-me-graph-icon-3"
                          />
                        </Col>
                        <Col xs="8">
                          <h3 className="for-me-graph-count">{usedVouchersCount || 0}</h3>
                          <div className="for-me-graph-name">Used Vouchers</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div class={`${(activeTab === '3' || activeTab === '4') ? '' : 'dashboard-block'} ${(activeTab === '2' || activeTab === '1') ? 'for-me-wallet-block' : ''} mt-0`}>
                    {activeTab === '1' ? (
                      <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
                        <>
                          {(() => {
                            const rows = [];
                            for (let i = 0; i < 12; i++) {
                              let hasVouchers = false;

                              if (!fetchingVouchers) {
                                hasVouchers = true;
                              }

                              let voucher = null;
                              let voucherId = null;

                              if (hasVouchers && Object.keys(unlockedList)[i]) {
                                const unlockedListId = Object.keys(unlockedList)[i];

                                voucher = unlockedList[unlockedListId];
                                voucherId = voucher.voucherId;
                              }

                              console.log('voucherIdvoucherId', hasVouchers, voucherId, voucher);

                              rows.push(
                                <ReactWOW animation='fadeInUp' delay=".4s">
                                  <div className="col-lg-4 col-md-6 col-12" class="single-item-grid-wrapper">
                                    <CampaignCard
                                      key={voucherId}
                                      vouchersList={unlockedList}
                                      display={!(hasVouchers)}
                                      voucher={voucher}
                                      voucherId={voucherId}
                                    />
                                  </div>
                                </ReactWOW>
                              );
                            }
                            return rows;
                          })()}
                        </>
                      </Row>
                    ) : (null)}

                    {activeTab === '2' ? (
                      <>
                        <Row>
                          <Col xs="12">
                            <nav class="for-me-list-nav">
                              <ul>
                                <li><a onClick={() => this.changeOrderedVouchersFilter('all')} style={{ cursor: 'pointer' }} className={orderedVouchersFilter === 'all' ? 'active' : ''}>All <span>{sortedOrderedVouchers.length || 0}</span></a></li>
                                <li><a onClick={() => this.changeOrderedVouchersFilter('unused')} style={{ cursor: 'pointer' }} className={orderedVouchersFilter === 'unused' ? 'active' : ''}>Unused <span>{sortedUnused.length || 0}</span></a></li>
                                <li><a onClick={() => this.changeOrderedVouchersFilter('used')} style={{ cursor: 'pointer' }} className={orderedVouchersFilter === 'used' ? 'active' : ''}>Used <span>{sortedUsed.length || 0}</span></a></li>
                                <li><a onClick={() => this.changeOrderedVouchersFilter('gifted')} style={{ cursor: 'pointer' }} className={orderedVouchersFilter === 'gifted' ? 'active' : ''}>Gifted <span>{sortedGifted.length || 0}</span></a></li>
                                <li><a onClick={() => this.changeOrderedVouchersFilter('canceled')} style={{ cursor: 'pointer' }} className={orderedVouchersFilter === 'canceled' ? 'active' : ''} >Cancelled <span>{sortedCanceled.length || 0}</span></a></li>
                              </ul>
                            </nav>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" className="d-none d-lg-block d-xl-block">
                            <Row className=" for-me-wallet-row for-me-wallet-row-header">
                              <Col xs="3">
                                <div className="for-me-wallet-header" style={{ textAlign: 'left' }}>Company</div>
                              </Col>
                              <Col>
                                <div className="for-me-wallet-header">Purchase Date</div>
                              </Col>
                              <Col>
                                <div className="for-me-wallet-header">Value</div>
                              </Col>
                              <Col>
                                <div className="for-me-wallet-header">Discount</div>
                              </Col>
                              <Col>
                                <div className="for-me-wallet-header">Expiry</div>
                              </Col>
                              <Col xs="2">
                                <div className="for-me-wallet-header">Status</div>
                              </Col>
                            </Row>
                          </Col>
                          {orderedVouchersArr.map((voucher) => {
                            const { voucherData, code, giftingData, voucherId, voucherStep, used, canceled, buyFor, paymentDate, companyId } = voucher;
                            const { companyName, availableVouchers, discount, expiry, ended, status } = voucherData;

                            if (buyFor === 'gift') {
                              console.log('gifted voucher', voucher);
                            }

                            const companyData = companies[companyId] || {};
                            const vouhcerDbId = (voucher.voucher && voucher.voucher.id) ? voucher.voucher.id : null;

                            let logoSrc;
                            const website = companyData.website;

                            if (companyData && companyData.logoImage) {
                              logoSrc = companyData.logoImage;
                            }

                            const selectedStep = availableVouchers[voucherId];
                            const selectedDiscount = discount[voucherStep];
                            const youPay = selectedStep * ((100 - selectedDiscount) / 100);

                            const date = new Date((paymentDate.seconds * 1000) + (expiry ? (parseInt(expiry, 10) * 365.24 * 24 * 60 * 60 * 1000) : 0));
                            const formattedDate = date.toLocaleString("en-GB", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                            });

                            const formattedPaymentDate = new Date(paymentDate.seconds * 1000).toLocaleString("en-GB", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                            });

                            return (
                              <>
                                <Col xs="12" className="d-block d-md-none d-lg-none">
                                  <Row className="for-me-wallet-row" style={{ borderBottom: '0px' }}>
                                    <Col xs="3">
                                      <div className="for-me-wallet-logo-wrapper">
                                        {logoSrc ? (<img className="for-me-wallet-logo" src={logoSrc} />) : (null)}
                                      </div>
                                      {/*<div className="for-me-wallet-text-wrapper">
                                        <div className="for-me-wallet-text-company">{companyName || ""}</div>
                                        <div><a href={website} className="for-me-wallet-text-link" target="_blank">Business website</a></div>
                                        {(!vouhcerDbId || ended || status !== 1) ? (null) : <div><Link to={`/campaign/${vouhcerDbId}`} className="for-me-wallet-text-link">See campaign</Link></div>}
                                      </div> */}
                                    </Col>
                                    <Col xs="5">
                                      <div className="for-me-wallet-text-wrapper" style={{ float: 'left' }}>
                                        <div className="for-me-wallet-text-company">{companyName || ""}</div>
                                        <div><a href={website} className="for-me-wallet-text-link" target="_blank">Business website</a></div>
                                        {(!vouhcerDbId || ended || status !== 1) ? (null) : <div><Link onClick={this.scrollToTop} to={`/campaign/${vouhcerDbId}`} className="for-me-wallet-text-link">See campaign</Link></div>}
                                      </div>
                                    </Col>
                                    <Col xs="4">
                                      <Row>
                                        <Col xs="12">
                                          <div className="for-me-wallet-text-large">{formattedPaymentDate ? formattedPaymentDate : ''}</div>
                                        </Col>
                                        <Col xs="12">
                                          <div className="for-me-wallet-text-large">{formattedDate ? formattedDate : ''}</div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>

                                  <Row className="for-me-wallet-row">
                                    <Col xs="4">
                                      <div className="for-me-wallet-text-large">{`${pound}${selectedStep.toFixed(2)}`}</div>
                                      <div className="for-me-wallet-text">{`Paid ${pound}${youPay.toFixed(2)}`}</div>
                                    </Col>
                                    <Col xs="4">
                                      <div className="for-me-wallet-text-large">{`${selectedDiscount}%`}</div>
                                      <div className="for-me-wallet-text">{`Step ${voucherStep}`}</div>
                                    </Col>
                                    <Col xs="4">
                                      {canceled ? (
                                        <div className="for-me-wallet-text-large for-me-wallet-text-cancelled">CANCELLED</div>
                                      ) : (
                                        <>
                                          {buyFor === 'gift' ? (
                                              <>
                                                <div className="for-me-wallet-text-large for-me-wallet-text-gifted">GIFTED</div>
                                                {(hoverId && hoverId === voucher.key) ? (
                                                  <div onMouseLeave={() => this.setHoverId(null)}>
                                                    <div className="for-me-wallet-text-code">{giftingData ? `${giftingData.name}` : ''}</div>
                                                    <div className="for-me-wallet-text-code">{giftingData ? `${giftingData.email}` : ''}</div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="for-me-wallet-text-code for-me-wallet-text-gifted"
                                                    onMouseEnter={() => this.setHoverId(voucher.key || null)}
                                                    onMouseLeave={() => this.setHoverId(null)}
                                                  >
                                                    Details
                                                  </div>
                                                )}
                                                {/* <div className="for-me-wallet-text-large for-me-wallet-text-gifted">GIFTED</div>
                                              <div className="for-me-wallet-text-code">{giftingData ? `${giftingData.name} ${giftingData.email}` : ''}</div> */}
                                            </>
                                          ) : (
                                            <>
                                              {used ? (
                                                <div className="for-me-wallet-text-large for-me-wallet-text-used">USED</div>
                                              ) : (
                                                <>
                                                  <div id={`code-wrap-${code}`} className="for-me-wallet-text-code" style={{ position: 'relative' }}>
                                                    {code}
                                                    <i id={`code-${code}`} className="lni lni-clipboard" onClick={() => this.copyCode(code)} style={codeCopied === code ? { color: "#00aeff" } : {}}></i>
                                                  </div>
                                                  <div className="for-me-wallet-text-large for-me-wallet-text-unused">UNUSED</div>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                  {/* <Row className="for-me-wallet-row">
                                    <Col xs="6">
                                      <div className="for-me-wallet-text-large">{formattedPaymentDate ? formattedPaymentDate : ''}</div>
                                    </Col>
                                    <Col xs="6">
                                      <div className="for-me-wallet-text-large">{formattedDate ? formattedDate : ''}</div>
                                    </Col>
                                  </Row> */}

                                </Col>
                                <Col xs="12" className="d-none d-md-block d-lg-block">
                                  <Row className="for-me-wallet-row">
                                    <Col xs="3">
                                      <div className="for-me-wallet-logo-wrapper">
                                        {logoSrc ? (<img className="for-me-wallet-logo" src={logoSrc} />) : (null)}
                                      </div>
                                      <div className="for-me-wallet-text-wrapper">
                                        <div className="for-me-wallet-text-company">{companyName || ""}</div>
                                        <div><a href={website} className="for-me-wallet-text-link" target="_blank">Business website</a></div>
                                        {(!vouhcerDbId || ended || status !== 1) ? (null) : <div><Link onClick={this.scrollToTop} to={`/campaign/${vouhcerDbId}`} className="for-me-wallet-text-link">See campaign</Link></div>}
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="for-me-wallet-text-large">{formattedPaymentDate ? formattedPaymentDate : ''}</div>
                                    </Col>
                                    <Col>
                                      <div className="for-me-wallet-text-large">{`${pound}${selectedStep.toFixed(2)}`}</div>
                                      <div className="for-me-wallet-text">{`Paid ${pound}${youPay.toFixed(2)}`}</div>
                                    </Col>
                                    <Col>
                                      <div className="for-me-wallet-text-large">{`${selectedDiscount}%`}</div>
                                      <div className="for-me-wallet-text">{`Step ${voucherStep}`}</div>
                                    </Col>

                                    <Col>
                                      <div className="for-me-wallet-text-large">{formattedDate ? formattedDate : ''}</div>
                                    </Col>
                                    <Col xs="2">
                                      {canceled ? (
                                        <div className="for-me-wallet-text-large for-me-wallet-text-cancelled">CANCELLED</div>
                                      ) : (
                                        <>
                                          {buyFor === 'gift' ? (
                                            <>
                                              <div className="for-me-wallet-text-large for-me-wallet-text-gifted">GIFTED</div>
                                              {(hoverId && hoverId === voucher.key) ? (
                                                <div onMouseLeave={() => this.setHoverId(null)}>
                                                  <div className="for-me-wallet-text-code">{giftingData ? `${giftingData.name}` : ''}</div>
                                                  <div className="for-me-wallet-text-code">{giftingData ? `${giftingData.email}` : ''}</div>
                                                </div>
                                              ) : (
                                                <div
                                                  className="for-me-wallet-text-code for-me-wallet-text-gifted"
                                                  onMouseEnter={() => this.setHoverId(voucher.key || null)}
                                                  onMouseLeave={() => this.setHoverId(null)}
                                                >
                                                  Details
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {used ? (
                                                <div className="for-me-wallet-text-large for-me-wallet-text-used">USED</div>
                                              ) : (
                                                <>
                                                  <div id={`code-wrap-${code}`} className="for-me-wallet-text-code" style={{ position: 'relative' }}>
                                                    {code}
                                                    <i
                                                      id={`code-${code}`}
                                                      className="lni lni-clipboard"
                                                      onClick={() => this.copyCode(code)}
                                                      style={codeCopied === code ? { color: "#00aeff" } : {}}
                                                    ></i>

                                                  </div>
                                                  <div className="for-me-wallet-text-large for-me-wallet-text-unused">UNUSED</div>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </>
                            );
                          })}
                          <div className="for-me-wallet-info-text">
                            To redeem a voucher just enter the code when paying at a business' online checkout, unless otherwise stated.
                          </div>
                        </Row>
                      </>
                    ) : (null)}


                    {activeTab === '3' ? (
                      <>
                        <div class="dashboard-block mt-0 profile-settings-block" style={{ marginBottom: 10, marginLeft: -15, marginRight: 10 }}>
                          <h3 class="block-title">Profile Settings</h3>
                          <div class="inner-block">
                            <form class="profile-setting-form" method="post" action="#">
                              {updateProfileSuccess ? (<Alert color="success"> Update successful </Alert>) : null}
                              {(!updateProfileSuccess && updateProfileError) ? (<Alert color="danger"> {`${updateProfileError}`} </Alert>) : null}
                              <div class="row">
                                <div class="col-lg-12 col-12">
                                  <div class="form-group">
                                    <label>Name*</label>
                                    <Input name="name" type="text" placeholder="Steve" value={name} onChange={this.handleChange} />
                                  </div>
                                </div>
                                <div class="col-lg-12 col-12">
                                  <div class="form-group">
                                    <label>Email Address*</label>
                                    <Input name="email" type="email" placeholder="username@gmail.com" value={email} onChange={this.handleChange} />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group button mb-0">
                                    <Button color="primary" onClick={this.updateProfile} className="btn">
                                      Update Profile
                                      {(updateNameSpinner || updateEmailSpinner) ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.2rem', height: '1.2rem', marginLeft: 10, marginTop: 0 }}> {''} </Spinner>
                                      ) : (null)}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div class="dashboard-block password-change-block" style={{ marginBottom: 10, marginLeft: -15, marginRight: 10 }}>
                          <h3 class="block-title">Change Password</h3>
                          <div class="inner-block">
                            <form class="default-form-style" method="post" action="#">
                              {updatePasswordSuccess ? (<Alert color="success"> Update successful </Alert>) : null}
                              {(!updatePasswordSuccess && updatePasswordError) ? (<Alert color="danger"> {`${updatePasswordError}`} </Alert>) : null}

                              <div class="row">
                                <div class="col-12">
                                  <div class="form-group">
                                    <label>Current Password*</label>
                                    <Input name="password" type="password" placeholder="Enter old password" value={password} onChange={this.handleChange} />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label>New Password*</label>
                                    <Input name="newPassword" type="password" placeholder="Enter new password" value={newPassword} onChange={this.handleChange} />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group">
                                    <label>Retype Password*</label>
                                    <Input name="retypePassword" type="password" placeholder="Retype password" value={retypePassword} onChange={this.handleChange} />
                                  </div>
                                </div>
                                <div class="col-12">
                                  <div class="form-group button mb-0">
                                    <Button color="primary" onClick={this.updatePassword} class="btn ">
                                      Update Password
                                      {updatePasswordSpinner ? (
                                        <Spinner animation="border" variant="primary" style={{ width: '1.2rem', height: '1.2rem', marginLeft: 10, marginTop: 0, position: 'absolute' }}> {''} </Spinner>
                                      ) : (null)}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    ) : (null)}

                    {activeTab === '4' ? (
                      <div className="dashboard-block close-content mt-0">
                        <h2>Delete Your Account</h2>
                        <h4>Are you sure, you want to delete your account?</h4>
                        <div class="button">
                          <Button href="" color="primary" className="btn-white" onClick={this.deleteAcount}>Delete Account</Button>
                          <Button href="" color="primary" className="" onClick={this.cancelDelete}>Cancel</Button>
                        </div>
                      </div>
                    ) : (null)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div >
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  vouchersList: state.customer.list || null,
  companies: state.customer.companies || {},
  orderedVouchers: state.customer.orderedVouchers || {},
  sortedOrderedVouchers: state.customer.sortedOrderedVouchers || [],
  sortedUsed: state.customer.sortedUsed || [],
  sortedGifted: state.customer.sortedGifted || [],
  sortedUnused: state.customer.sortedUnused || [],
  sortedCanceled: state.customer.sortedCanceled || [],
  unlockedList: state.customer.unlockedList || {},
  myVouchersCount: state.customer.myVouchersCount || 0,
  giftedVouhcersCount: state.customer.giftedVouhcersCount || 0,
  usedVouchersCount: state.customer.usedVouchersCount || 0,
});

const mapDispatchToProps = {
  getVouchers: getVoucherList,
  fetchUnlockedVouchers: getUnlockedVouchers,
  fetchOrderedVouchers: getOrderedVouchers,

  updateName: updateUserName,
  updateEmail: updateUserEmail,
  updatePassword: updateUserPassword,
  logout: doLogout,
  deleteAccountFunc: deleteAccount,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForMe));