import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Link } from 'react-router-dom';

import {
  Row,
  Col,
  Media,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, } from 'reactstrap';

import { connect } from 'react-redux';

import { Mail } from 'react-feather';

import { auth } from '../../../lib/firebase';

import SearchResults from './SearchResults';

/*
import {
  getFooters,
  getFooterIcons,
} from '../../../actions/contentManagement';
*/
class Header extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    pendingTabData: PropTypes.shape(),
    fetchFooters: PropTypes.func.isRequired,
    fetchFooterIcons: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    dashboardTooltips: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: null,
      data: {},
      showNawbar: false,
    };
  }

  componentDidMount() {
    const { admin, user, isLoggedIn, history } = this.props;
  }

  toggleNavbar = () => {
    const { showNawbar } = this.state;

    this.setState({ collapsing: true });

    setTimeout(() => {
      this.setState({ collapsing: false });
    }, 300);

    if (showNawbar) {
      this.setState({ showNawbar: null });
    } else {
      this.setState({ showNawbar: true });
    }
  }

  scrollToTop = () => {
    console.log('scrollToTop');

    this.setState({ showNawbar: null });

    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });

    // this.searchVouchers(e.target.value);
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.searchVouchers();
    }
  }

  searchVouchers = () => {
    const { vouchersList } = this.props;
    const { searchValue } = this.state;

    console.log('search vouchersList', vouchersList);

    if (searchValue && vouchersList && Object.keys(vouchersList).length) {
      const filterValue = searchValue.toLowerCase();

      let filteredVouchers = {};
    //  let filteredVouchers2 = [];

      Object.keys(vouchersList).map((key) => {
        const { keyWords, companyName, description } = vouchersList[key];

        console.log('searxh vouchers', key, keyWords, companyName, filterValue, description);

        if (companyName && companyName.toLowerCase().includes(filterValue)) {
          filteredVouchers[key] = vouchersList[key];
        //  filteredVouchers2.push({ key, ...vouchersList[key] });
        } else if (keyWords && keyWords.includes(filterValue)) {
          filteredVouchers[key] = vouchersList[key];
        } else if (description && description.toLowerCase().includes(filterValue)) {
          filteredVouchers[key] = vouchersList[key];
        }
      });

      if (filteredVouchers && Object.keys(filteredVouchers).length) {
        this.setState({ showFilteredModal: true, filteredVouchers: filteredVouchers });
      } else {
        this.setState({ filteredVouchers: null });
        // this.setState({ filteredVouchers: filteredVouchers });
      }
    } else {
      this.setState({ filteredVouchers: null });
    }
  }

  closeFilteredModal = () => {
    this.setState({ showFilteredModal: null, filteredVouchers: null });
  }

  changeActiveCategory = (id) => {
    const { history } = this.props;

    console.log('changeActiveCategory', id);

    if (id === 'all') {
      this.setState({ activeCategory: 'all' });
    } else {
      this.setState({ activeCategory: id });
    }
  }

  render() {
    const { user, branding } = this.props;
    const {
      error,
      success,
      name,
      data,
      showNawbar,
      collapsing,
      searchValue,
      filteredVouchers,
      showFilteredModal,
      activeCategory,
    } = this.state;

    const { pathname } = window.location;

    console.log('filteredVouchersfilteredVouchers', showFilteredModal, filteredVouchers, searchValue);

    console.log('userisLoggedIn', user);

    console.log('branding header', branding);

    let isLoggedIn = false;
    if (auth.currentUser && auth.currentUser.uid) {
      isLoggedIn = true;
    }

    console.log('isLoggedIn', isLoggedIn);

    return (
      <header class="header navbar-area sticky">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="nav-inner">
                <nav class="navbar navbar-expand-lg">
                  <Link onClick={this.scrollToTop} class="navbar-brand" to="/">
                    <img
                      src={(branding && branding.favicon) ? branding.favicon : 'https://firebasestorage.googleapis.com/v0/b/steppr-app.appspot.com/o/siteImages%2Ffavicon.png?alt=media&token=81470106-1f40-4850-95f3-297607aa0841'}
                      alt="Logo"
                      style={{ maxWidth: 35, maxHeight: 35, aspectRatio: '1/1', display: 'inline-block', position: 'relative', top: -10 }}
                    />
                    <span style={{ display: 'inline-block', fontSize: 28, color: '#00aeff' }}>
                      Steppr
                    </span>
                  </Link>
                  <button class={`navbar-toggler mobile-menu-btn ${showNawbar ? 'active' : ''}`} type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded={showNawbar ? true : false} aria-label="Toggle navigation" onClick={this.toggleNavbar}>
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                  </button>

                  <div style={(collapsing && showNawbar) ? { height: 225 } : {}} className={`mobile-navbar ${collapsing ? 'collapsing' : 'collapse'} navbar-collapse sub-menu-bar ${showNawbar ? 'show' : ''}`} id="navbarSupportedContent">
                    <ul id="nav" class="navbar-nav ms-auto" >
                      <li class="nav-item">
                        <Link onClick={this.scrollToTop} to="/" aria-label="Toggle navigation">Home</Link>
                      </li>
                      <li class="nav-item">
                        <Link onClick={this.scrollToTop} to={(user && user.uid) ? "/for-me" : "/signup"} aria-label="Toggle navigation">My Vouchers & Account</Link>
                      </li>



                      {(isLoggedIn || (user && user.uid)) ? (null) : (
                        <>
                          <li style={{ padding: "12px 16px" }}>
                            <Link onClick={this.scrollToTop} to="/login"><i class="lni lni-enter"></i> Sign In</Link>
                          </li>
                          <li style={{ padding: "12px 16px" }}>
                            <Link onClick={this.scrollToTop} to="/signup"><i class="lni lni-user"></i> Sign Up</Link>
                          </li>
                        </>
                      )}
                      {/*<div class="login-button">
                      <ul>
                        <li>
                          <Link to="/login"><i class="lni lni-enter"></i> Sign In</Link>
                        </li>
                        <li>
                          <Link to="/signup"><i class="lni lni-user"></i> Sign Up</Link>
                        </li>
                      </ul>
                    </div> */}
                      {pathname === '/business' ? (
                        <li class="nav-item">
                          <a href="https://app.steppr.com/signup" class="btn">Create Business Account</a>
                        </li>
                      ) : (
                        <li class="nav-item">
                            <Link onClick={this.scrollToTop} to="/business" class="btn">I'm a Business</Link>
                        </li>
                      )}
                      <li class="nav-item">
                        <a>
                          <div class="search-input" style={{ position: 'relative' }}>
                            <Input
                              style={{ minWidth: 130, borderRadius: 4, borderWidth: 1 }}
                              type="text"
                              name="searchValue"
                              id="searchValue"
                              placeholder="Voucher search"
                              value={searchValue}
                              onChange={this.handleChange}
                              onKeyDown={this.handleKeyDown}
                            />
                            <label for="searchValue" onClick={this.searchVouchers} style={{ float: 'right', position: 'absolute', top: 10, right: 0, paddingRight: '0.5rem' }}>
                              <i class="lni lni-search-alt theme-color"></i>
                            </label>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div style={(collapsing && showNawbar) ? { height: 280 } : {}} className={`browser-navbar ${collapsing ? 'collapsing' : 'collapse'} navbar-collapse sub-menu-bar ${showNawbar ? 'show' : ''}`} id="navbarSupportedContent">
                    <ul id="nav" class="navbar-nav ms-auto" >
                      <li class="nav-item">
                        <Link onClick={this.scrollToTop} to="/" aria-label="Toggle navigation">Home</Link>
                      </li>
                      <li class="nav-item">
                        <Link onClick={this.scrollToTop} to={(user && user.uid) ? "/for-me" : "/signup"} aria-label="Toggle navigation">My Vouchers & Account</Link>
                      </li>

                      <li class="nav-item">
                        <a>
                          <div class="search-input" style={{ position: 'relative', top: -7 }}>
                            <Input
                              style={{ minWidth: 130, borderRadius: 4, borderWidth: 1 }}
                              type="text"
                              name="searchValue"
                              id="searchValue"
                              value={searchValue}
                              placeholder="Voucher search"
                              onChange={this.handleChange}
                              onKeyDown={this.handleKeyDown}
                            />
                            <label for="searchValue" onClick={this.searchVouchers} style={{ float: 'right', position: 'absolute', top: 10, right: 0, paddingRight: '0.5rem' }}>
                              <i class="lni lni-search-alt theme-color"></i>
                            </label>
                          </div>
                        </a>
                      </li>
                    </ul>

                    {(isLoggedIn || (user && user.uid)) ? (null) : (
                      <div class="login-button">
                        <ul>
                          <li>
                            <Link onClick={this.scrollToTop} to="/login"><i class="lni lni-enter"></i> Sign In</Link>
                          </li>
                          <li>
                            <Link onClick={this.scrollToTop} to="/signup"><i class="lni lni-user"></i> Sign Up</Link>
                          </li>
                        </ul>
                      </div>
                    )}
                    {pathname === '/business' ? (
                      <div class="button header-button">
                        <a href="https://app.steppr.com/signup" class="btn">Create Business Account</a>
                      </div>
                    ) : (
                      <div class="button header-button" style={{ textTransform: 'none' }}>
                          <Link onClick={this.scrollToTop} to="/business" class="btn">I'm a Business</Link>
                      </div>
                    )}
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <Modal size="lg" isOpen={showFilteredModal} toggle={this.closeFilteredModal}>
          <ModalBody>
            <div>
              <SearchResults vouchersList={filteredVouchers || {}} closeFilteredModal={this.closeFilteredModal} />
            </div>
          </ModalBody>
        </Modal> 
      </header>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
 // footerIcons: state.contentManagement.footerIcons || {},
  branding: state.contentManagement.branding || {},
  vouchersList: state.customer.list || null,
});

const mapDispatchToProps = {
 // fetchFooters: getFooters,
//  fetchFooterIcons: getFooterIcons,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
