import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import ReactWOW from 'react-wow';

import { WOW } from 'wowjs';

import { connect } from 'react-redux';

class HowItWorks extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
    getVouchers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      deleteSettingModal: false,
      deleteSettingErr: null,
      settingToDelete: null,
      activeTab: '1',
      companyNotification: '',
      allChecked: true,
      checked: {},
      companyNotifications: [],
      companyInformation: '',
      selectedStep: 1,
      purchaseModal: false,
      showPassword1: 'password',
    };
  }

  componentDidMount() {
    const { user } = this.props;

    new WOW().init();
  }

  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { user, vouchersList, companies, categories, changeActiveCategory, activeCategory } = this.props;
    const {
      activeTab,
    } = this.state;

    console.log('categories', categories);

    return (
      <div style={{ marginBottom: 0 }}>
        <section class="how-works section">
          <div class="container">
            {/*  <ReactWOW animation='fadeInUp' delay=".4s">
              <div class="row">
                <div class="col-12">
                  <div class="section-title">
                     <h2 class="wow fadeInUp" data-wow-delay=".4s">How Steppr Works</h2>
                     <p class="wow fadeInUp" data-wow-delay=".6s" style={{ color: '#FFF' }}>
                      Support businesses by buying their vouchers and be rewarded through lower prices. It's easy! Find our full FAQs <a href="https://steppr.freshdesk.com/support/solutions/101000186530" target="_blank"> here</a>.
                    </p> 
                  </div>
                </div>
              </div>
            </ReactWOW > */}
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="single-work wow fadeInUp" data-wow-delay=".2s">
                  <ReactWOW animation='fadeInUp' delay=".4s">
                    <div>
                    <span class="serial">01</span>
                    <h3>Exclusive Vouchers</h3>
                      <p>Discover vouchers from a range of great, independent businesses.</p>
                    </div>
                  </ReactWOW>
                  </div>
                </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="single-work wow fadeInUp" data-wow-delay=".4s">
                  <ReactWOW animation='fadeInUp' delay=".4s">
                    <div>
                    <span class="serial">02</span>
                    <h3>Save Money</h3>
                      <p>You never pay full price, and the discounts increases when you buy the same voucher again.</p>
                  </div>
                </ReactWOW>
              </div>
                </div>
              <div class="col-lg-4 col-md-4 col-12">
                  <div class="single-work wow fadeInUp" data-wow-delay=".6s">
                    <ReactWOW animation='fadeInUp' delay=".4s">
                      <div>
                    <span class="serial">03</span>
                    <h3>Bespoke Gifts</h3>
                        <p>You can buy vouchers for yourself or we can send them seamlessly to your selected recipient.</p>
                      </div>
                    </ReactWOW>
                  </div>
                </div>
              </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
  isLoggedIn: state.user.isLoggedIn || false,
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HowItWorks));
