import { auth, firestore, storage, functions, firebase } from '../../lib/firebase';
import {
  UPDATE_USER,
  GET_COMPANY_DATA,
  GET_CATEGORIES,
  GET_REWARDS_UNLOCKED,
  GET_COMPANY_CHANGES,
  GET_COMPANY_OWNER_DATA,
  COMPANY_NOTIFICATIONS,
  UPDATE_API_KEY,
  RESET_COMPANY_DATA,
  COMPLIANCE_DATA,
  SORT_COMPANY_PAYMENTS,
  GET_COMPANY_PAYMENTS,
  GET_MORE_COMPANY_PAYMENTS,
  GET_COMPANY_PAYMENTS_TOTAL,
  UPDATE_SHOPIFY_API,
  UPDATE_WOOCOMMERCE_API,
  UPDATE_MANUAL_API,
  UPDATE_ACTIVE_API,
  GET_SINGLE_COMPANY_PAYMENT,
  GET_COMPANY_LINKED_COMPANIES,
} from '../index';

import { uuid } from '../../lib/uuid';

import moment from 'moment';

let unsubscribe;
let unsubscribe2;
let unsubscribe3;

export const getCompanyPayouts = (companyId, startDate, endDate) => new Promise(async (resolve, reject) => {

  if (!companyId || !startDate || !endDate) return resolve()

  const getCompanyPayoutsFunction = functions.httpsCallable('getCompanyPayouts');

  getCompanyPayoutsFunction({
    companyId,
    startDate,
    endDate,
  }).then((result) => {
    if (result.status && result.status === 'error') {
      return reject(result.error);
    }
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  });
});

export const getCompanyData = (company, isAdmin) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  if (user && company) {
    const companyRef = firestore.collection('companies').doc(company);
    const ownerRef = firestore.collection('owners').doc(company);
    const userRef = firestore.collection('users').doc(user.uid);

    if (!isAdmin) {
      await ownerRef.update({ lastActive: firebase.firestore.FieldValue.serverTimestamp() });
      await userRef.update({ lastActive: firebase.firestore.FieldValue.serverTimestamp() });
    }

    unsubscribe = companyRef.onSnapshot(async (docSnapshot) => {
      if (docSnapshot.exists) {
        const company = docSnapshot.data();

        if (company) {

          let companyPrimaryVoucherImage;
          let logoImage;
          let voucherImagesArray = [];

          if (company.primaryVoucherImage) {
            const primaryVoucherImageReference = storage.ref(company.primaryVoucherImage);

            await primaryVoucherImageReference.getDownloadURL().then((url) => {
              companyPrimaryVoucherImage = url;
            }).catch((error) => {
              console.log('get image error', { error });
            });

          }

          if (company.logo) {
            const logoReference = storage.ref(company.logo);

            await logoReference.getDownloadURL().then((url) => {
              logoImage = url;
            }).catch((error) => {
              console.log('get image error', { error });
            });
          }

          if (company.voucherImages) {
            await company.voucherImages.map(async(image, index) => {
              const imageReference = storage.ref(image);

              await imageReference.getDownloadURL().then((url) => {
                voucherImagesArray[index] = url;
              }).catch((error) => {
                console.log('get image error', { error });
              });

            })
          }

          dispatch({
              type: GET_COMPANY_DATA,
              payload: { ...company, companyPrimaryVoucherImage, logoImage, voucherImagesArray },
          })

          return resolve({ ...company });
        } else {
          dispatch({
              type: GET_COMPANY_DATA,
              payload: {},
          })

          return resolve({});
        }
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    });
  }
});


export const updateLastActive = (company) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('updateLastActive', company);
  const user = auth.currentUser;

  if (user && company) {
    const ownerRef = firestore.collection('owners').doc(company);
    const userRef = firestore.collection('users').doc(user.uid);

    await ownerRef.update({ lastActive: firebase.firestore.FieldValue.serverTimestamp() });
    await userRef.update({ lastActive: firebase.firestore.FieldValue.serverTimestamp() });
  }
});

export const getSingleCompanyPayment = (companyId, dbId) => (dispatch) => new Promise(async (resolve, reject) => {
  const paymentRef = await firestore.collection('ordersPerCompany').doc(companyId).collection('orders').doc(dbId).get();

  if (!paymentRef.exists) {
    return resolve();
  }

  const data = paymentRef.data();

  if (data) {
    dispatch({
      type: GET_SINGLE_COMPANY_PAYMENT,
      dbId,
      payload: data,
    })
  }
});



export const getCompanyPayments = (companyId, timeFrame, customStartDate, customEndDate, orderBy, sortType, redeemedByFilter, codeFilter) => (dispatch) => new Promise(async (resolve, reject) => {
  let dateLength = 1;
  let startDate;
  let endDate;

  console.log('getCompanyPaymentsgetCompanyPayments', companyId, timeFrame, customStartDate, customEndDate, orderBy, sortType, redeemedByFilter, codeFilter);

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);
    endDate = moment(customEndDate);
  } else {
    if (timeFrame === '4weeks') {
      dateLength = 30;
    }

    startDate = moment().subtract(dateLength, 'days');
    endDate = moment().add(1, 'days');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  const startDay = startDateSplit[0];
  const startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];

  //, redeemedByFilter, 
  if (codeFilter) {
    const paymentsRef = await firestore
      .collection('ordersPerCompany').doc(companyId).collection('orders')
      .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
      .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
      // .where('voucherCodePreview', '==', codeFilter)
      .where('voucherCodePreviewKeyWords', 'array-contains', codeFilter.toUpperCase())
      .get();

    if (!paymentsRef.docs) {
      return resolve();
    }

    let data = {};
    let lastKey;

    paymentsRef.docs.map((doc, index) => {
      const docData = doc.data();
      const id = doc.id;

      data[id] = docData;

      if (index === paymentsRef.docs.length - 1) {
        lastKey = id;
      }
    });

    console.log('company payments data', data);

    if (data) {
      dispatch({
        type: GET_COMPANY_PAYMENTS,
        payload: data,
        lastKey,
        timeFrame,
        orderBy,
        sortType,
      })
    }
  } else if (redeemedByFilter) {
    const paymentsRef = await firestore
      .collection('ordersPerCompany').doc(companyId).collection('orders')
      .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
      .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
      // .where('redeemedBy', '==', redeemedByFilter)
      .where('redeemedByKeyWords', 'array-contains', redeemedByFilter.toLowerCase())
      .get();

    if (!paymentsRef.docs) {
      return resolve();
    }

    let data = {};
    let lastKey;

    paymentsRef.docs.map((doc, index) => {
      const docData = doc.data();
      const id = doc.id;

      data[id] = docData;

      if (index === paymentsRef.docs.length - 1) {
        lastKey = id;
      }
    });

    console.log('company payments data', data);

    if (data) {
      dispatch({
        type: GET_COMPANY_PAYMENTS,
        payload: data,
        lastKey,
        timeFrame,
        orderBy,
        sortType,
      })
    }
  } else {

    if (orderBy && orderBy !== 'paymentDate') {
      const paymentsRef = await firestore
        .collection('ordersPerCompany').doc(companyId).collection('orders')
        .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
        .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
        .get();

      if (!paymentsRef.docs) {
        return resolve();
      }

      let data = {};
      let lastKey;

      paymentsRef.docs.map((doc, index) => {
        const docData = doc.data();
        const id = doc.id;

        data[id] = docData;

        if (index === paymentsRef.docs.length - 1) {
          lastKey = id;
        }
      });

      console.log('company payments data', data);

      if (data) {
        dispatch({
          type: GET_COMPANY_PAYMENTS,
          payload: data,
          lastKey,
          timeFrame,
          orderBy,
          sortType,
        })
      }
    } else {
      const paymentsRef = await firestore
        .collection('ordersPerCompany').doc(companyId).collection('orders')
        .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
        .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
        .orderBy('paymentDate', sortType)
        .limit(500)
        .get();

      if (!paymentsRef.docs) {
        return resolve();
      }

      let data = {};
      let lastKey;

      paymentsRef.docs.map((doc, index) => {
        const docData = doc.data();
        const id = doc.id;

        data[id] = docData;

        if (index === paymentsRef.docs.length - 1) {
          lastKey = id;
        }
      });

      console.log('company payments data', data);

      if (data) {
        dispatch({
          type: GET_COMPANY_PAYMENTS,
          payload: data,
          lastKey,
          timeFrame,
          orderBy,
          sortType,
        })
      }
    }
  }
});

export const getMoreCompanyPayments = (companyId, timeFrame, customStartDate, customEndDate, orderBy, sortType) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  const { company } = getState();

  const { lastPaymentId } = company;

  if (!lastPaymentId) {
    return resolve();
  }

  let dateLength = 1;
  let startDate;
  let endDate;

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);
    endDate = moment(customEndDate);
  } else {
    if (timeFrame === '4weeks') {
      dateLength = 30;
    }

    startDate = moment().subtract(dateLength, 'days');
    endDate = moment().add(1, 'days');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  const startDay = startDateSplit[0];
  const startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];

  var lastPayment = firestore.collection('ordersPerCompany').doc(companyId).collection("orders").doc(lastPaymentId);

    lastPayment.get().then(async (doc) => {
      if (doc.exists) {
        if (orderBy && orderBy !== 'paymentDate') {
          let paymentsRef;
          if (orderBy === 'redeemed') {
            paymentsRef = await firestore
              .collection('ordersPerCompany').doc(companyId).collection('orders')
              .orderBy(orderBy, sortType)
              .orderBy('refunded', sortType)
              .startAfter(doc.data())
              .limit(10)
              .get();
          } else {
            paymentsRef = await firestore
              .collection('ordersPerCompany').doc(companyId).collection('orders')
              .orderBy(orderBy || 'paymentDate', sortType)
              .startAfter(doc.data())
              .limit(10)
              .get();
          }

            if (!paymentsRef.docs) {
              return resolve();
            }

            let data = {};
            let lastKey;

            paymentsRef.docs.map((doc, index) => {
              const docData = doc.data();
              const id = doc.id;

              data[id] = docData;

              if (index === paymentsRef.docs.length - 1) {
                lastKey = id;
              }
            });

          if (data) {
            return resolve();
          }
        } else {
          const paymentsRef = await firestore
            .collection('ordersPerCompany').doc(companyId).collection('orders')
            .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
            .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
            .orderBy('paymentDate', sortType)
            .startAfter(doc)
            .limit(10)
            .get();

            if (!paymentsRef.docs) {
              return resolve();
            }

            let data = {};
            let lastKey;

            paymentsRef.docs.map((doc, index) => {
              const docData = doc.data();
              const id = doc.id;

              data[id] = docData;

              if (index === paymentsRef.docs.length - 1) {
                lastKey = id;
              }
            });
          if (data) {
            dispatch({
              type: GET_MORE_COMPANY_PAYMENTS,
              payload: data,
              lastKey,
              timeFrame,
              orderBy,
              sortType,
            });

            return resolve();
          }
        }
      }
    })
});

export const sortCompanyPayments = (sortColumn, sortType) => (dispatch) => new Promise(async (resolve, reject) => {
  return resolve(dispatch({
    type: SORT_COMPANY_PAYMENTS,
    sortColumn,
    sortType,
  }));
});


export const getCompanyPaymentsTotals = (companyId, typeFilter, timeFrame, customStartDate, customEndDate) => (dispatch) => new Promise(async (resolve, reject) => {
  let dateLength = 1;
  let startDate;
  let endDate;
//  const dateNow = moment();

//  const dateNowSplit = dateNow.format("DD-MM-YYYY").split("-");

//  nowDay = dateNowSplit[0];
//  nowMonth = dateNowSplit[1];
//  nowYear = dateNowSplit[2];

  console.log('getCompanyPaymentsTotals', companyId, typeFilter, timeFrame, customStartDate, customEndDate);

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);
    endDate = moment(customEndDate);
  } else if (timeFrame === 'all') {
    let allStartDate = new Date(2021, 1, 1);
    startDate = moment(allStartDate);

    endDate = moment().add(1, 'days');
  } else {
    if (timeFrame === '4weeks') {
      dateLength = 30;
    } else if (timeFrame === '1day') {
      dateLength = 1;
    } else if (timeFrame === '1week') {
      dateLength = 7;
    } else if (timeFrame === '2weeks') {
      dateLength = 14;
    } else if (timeFrame === '6months') {
      dateLength = 183;
    }

    startDate = moment().subtract(dateLength, 'days');

    endDate = moment().add(1, 'days');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  const startDay = startDateSplit[0];
  const startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];

    const paymentsRef = await firestore
      .collection('ordersPerCompany').doc(companyId).collection('totals')
      .where('date', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
      .where('date', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`)).get();

      if (!paymentsRef.docs) {
        return resolve();
      }

      let data = {};

      let totals = {
        amountCaptured: 0,
        faceValue: 0,
        commission: 0,
        gift: 0,
        giftValue: 0,
        redeemed: 0,
        redeemedValue: 0,
        refunded: 0,
        amountRefunded: 0,
      };

      let totalsPerCampaign = {};

      paymentsRef.docs.map(doc => {
        const docData = doc.data();
        const id = doc.id;

        data[id] = docData;

        if (!totalsPerCampaign[docData.voucherId]) {
          totalsPerCampaign[docData.voucherId] = {
              amountCaptured: 0,
              faceValue: 0,
              commission: 0,
              gift: 0,
              giftValue: 0,
              redeemed: 0,
              redeemedValue: 0,
              refunded: 0,
              amountRefunded: 0,

          }
        }

        totals.amountCaptured = totals.amountCaptured + docData.amount_captured;
        totals.faceValue = totals.faceValue + docData.faceValue;
        totals.commission = totals.commission + docData.commission;

        totalsPerCampaign[docData.voucherId].amountCaptured = totalsPerCampaign[docData.voucherId].amountCaptured + docData.amount_captured;
        totalsPerCampaign[docData.voucherId].faceValue = totalsPerCampaign[docData.voucherId].faceValue + docData.faceValue;
        totalsPerCampaign[docData.voucherId].commission = totalsPerCampaign[docData.voucherId].commission + docData.commission;

        if (docData.gift) {
          totals.gift = totals.gift + 1;
          totals.giftValue = totals.giftValue + docData.faceValue;

          totalsPerCampaign[docData.voucherId].gift = totalsPerCampaign[docData.voucherId].gift + 1;
          totalsPerCampaign[docData.voucherId].giftValue = totalsPerCampaign[docData.voucherId].giftValue + docData.faceValue;
        }

        if (docData.refunded) {
          totals.redeemed = totals.redeemed + 1;
          totals.redeemedValue = totals.redeemedValue + docData.faceValue;

          totalsPerCampaign[docData.voucherId].redeemed = totalsPerCampaign[docData.voucherId].redeemed + 1;
          totalsPerCampaign[docData.voucherId].redeemedValue = totalsPerCampaign[docData.voucherId].redeemedValue + docData.faceValue;
        }

        if (docData.refunded) {
          totals.refunded = totals.refunded + 1;
          totals.amountRefunded = totals.amountRefunded + docData.amountRefunded;

          totalsPerCampaign[docData.voucherId].redeemed = totalsPerCampaign[docData.voucherId].redeemed + 1;
          totalsPerCampaign[docData.voucherId].redeemedValue = totalsPerCampaign[docData.voucherId].redeemedValue + docData.faceValue;
        }
        return null;
      });

    console.log('getCompanyPaymentsTotals', totals);
    console.log('totalsPerCampaign', totalsPerCampaign);

    if (data) {
      dispatch({
        type: GET_COMPANY_PAYMENTS_TOTAL,
        payload: totals,
        totalsPerCampaign,
        timeFrame,
        typeFilter,
      })
    }
});


export const getCompanyOwnerData = (company) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  if (user && company) {
    const companyRef = firestore.collection('owners').doc(company);

    unsubscribe2 = companyRef.onSnapshot(docSnapshot => {
      if (docSnapshot.exists) {
        const company = docSnapshot.data();

        if (company) {
          dispatch({
              type: GET_COMPANY_OWNER_DATA,
              payload: { ...company },
          })

          return resolve({ ...company });
        } else {
          dispatch({
              type: GET_COMPANY_OWNER_DATA,
              payload: {},
          })

          return resolve({});
        }
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    });
  }
});

export const getCompanyNotifications = (companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  const companyNotificationsRef = firestore.collection('companyNotifications')
  .where('status', '==', 'active')
  .where('companies', '==', 'all');

  const companyNotificationsRef2 = firestore.collection('companyNotifications')
  .where('status', '==', 'active')
  .where('companies', 'array-contains', companyId);

  const snapshot = await companyNotificationsRef.get();
  const snapshot2 = await companyNotificationsRef2.get();

  let allCompanyNotifications = [];
  let thisCompanyNotifications = [];

  if (snapshot.docs) {
    snapshot.docs.map((doc, index) => {
      allCompanyNotifications.push({ id: doc.id, ...doc.data() });
      return null;
    });
  }

  if (snapshot2.docs) {
    snapshot2.docs.map((doc, index) => {
      thisCompanyNotifications.push({ id: doc.id, ...doc.data() });
      return null;
    });
  }

  const notifications = [ ...allCompanyNotifications, ...thisCompanyNotifications ];

  notifications.sort((a, b) => {
    if (!a.date) {
      return 1;
    }
    if (!b.date) {
      return -1;
    }
    if ( b.date.seconds < a.date.seconds ){
      return -1;
    }
    if ( b.date.seconds > a.date.seconds){
      return 1;
    }
    return 0;
  });

  return resolve(dispatch({
    type: COMPANY_NOTIFICATIONS,
    payload: notifications,
  }));
});

export const dismissCompanyNotification = (company, notificationId) => (dispatch) => new Promise(async (resolve, reject) => {
  const companyRef = firestore.collection('owners').doc(company);

  return firestore.runTransaction((transaction) => {
    return transaction.get(companyRef).then((companyDoc) => {
        if (!companyDoc.exists) {
          throw "Document does not exist!";
        }

        let newDissmissed = {};
        const { dissmissedNotifications } = companyDoc.data();
        if (dissmissedNotifications) {
          newDissmissed = { ...dissmissedNotifications, [notificationId]: true };
        } else {
          newDissmissed = { [notificationId]: true };
        }
        transaction.update(companyRef, { dissmissedNotifications: newDissmissed });
    });
  }).then(() => {
    console.log("Transaction successfully committed!");
  }).catch((error) => {
    console.log("Transaction failed: ", error);
  });
});

export const getCompanyChanges = (company, campaign) => (dispatch) => new Promise(async (resolve, reject) => {
    let companyRef = firestore.collection('companyChanges').doc(company).collection('changes');

    if (campaign) {
      companyRef = firestore.collection('companyChanges').doc(company).collection('changes').where('campaign', 'in', [campaign, 'all']);
    }

    const snapshot = await companyRef.get();

    if (snapshot.docs) {
      const companyChanges = [];

      snapshot.docs.map(doc => {
        companyChanges.push({ ...doc.data(), id2: doc.id });
        return null;
      })

      companyChanges.sort((a, b) => {
        if (!a.time) {
          return 1;
        }
        if (!b.time) {
          return -1;
        }
        if ( b.time.seconds < a.time.seconds ){
          return -1;
        }
        if ( b.time.seconds > a.time.seconds){
          return 1;
        }
        return 0;
      });

      dispatch({
        type: GET_COMPANY_CHANGES,
        payload: companyChanges,
      })

      return resolve('success');
    }
});

export const cancelCompanyListeners = () => (dispatch) => new Promise(async (resolve, reject) => {
  dispatch({
    type: RESET_COMPANY_DATA
  });

  if (unsubscribe) {
    unsubscribe();
  }
  if (unsubscribe2) {
    unsubscribe2();
  }

  if (unsubscribe3) {
    unsubscribe3();
  }


  return resolve();
})


export const cancelPendingData = (id, changeId, companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  if (!changeId) {
    return reject('missing changeId');
  } else if (!companyId) {
    return reject('missing companyId');
  }

  if (user && companyId) {
    const cancelPendingDataFunction = functions.httpsCallable('cancelPendingData');

    cancelPendingDataFunction({
      id,
      changeId,
      companyId,
    }).then((result) => {
      if (result.data && result.data.status && result.data.status === 'error') {
        return reject(result.data.error);
      }
      return resolve(result);
    }).catch((err) => {
      return reject(err);
    });
  }
});

export const getComplianceData = (company) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('getComplianceData', company);
  if (company) {
    const complianceRef = firestore.collection('complianceData').doc(company);

    const snapshot = await complianceRef.get();

    if (snapshot.exists) {
      const complianceData = snapshot.data();

      dispatch({
        type: COMPLIANCE_DATA,
        data: complianceData,
      });

      return resolve(complianceData);
    } else {
      dispatch({
        type: COMPLIANCE_DATA,
        data: null,
      });

      return resolve(null);
    }
  }
});

export const updateComplianceData = (id, complianceData, company) => (dispatch) => new Promise(async (resolve, reject) => {
  if (id && (complianceData || complianceData === false || complianceData === '') && company) {
    const complianceRef = firestore.collection('complianceData').doc(company);

    const snapshot = await complianceRef.get();

    let oldComplianceData = {};

    if (snapshot.exists) {
      oldComplianceData = snapshot.data();
    }

    await complianceRef.set({
      [id]: complianceData,
    }, { merge: true });

    const complianceBeforeRef = firestore.collection('complianceDataBefore').doc(company);

    const snapshot2 = await complianceBeforeRef.get();

    let beforeData = {};

    if (snapshot2.exists) {
      beforeData = snapshot2.data() || {};
    }

    const companyCompliancePendingRef = firestore.collection('companyCompliancePending').doc(company);

    if ((beforeData[id] || beforeData[id] === false || beforeData[id] === '') && beforeData[id] === complianceData) {
      await complianceBeforeRef.set({
        [id]: firebase.firestore.FieldValue.delete(),
      }, { merge: true });

      await companyCompliancePendingRef.set({
        [id]: firebase.firestore.FieldValue.delete(),
      }, { merge: true });
    } else if (beforeData[id] || beforeData[id] === false || beforeData[id] === '') {
      await companyCompliancePendingRef.set({
        [id]: complianceData,
      }, { merge: true });
    } else {
      if (id === 'question1' || id === 'question2' || id === 'question3') {
        await complianceBeforeRef.set({
          [id]: oldComplianceData[id] || false,
        }, { merge: true });
      } else {
        await complianceBeforeRef.set({
          [id]: oldComplianceData[id] || '',
        }, { merge: true });
      }

      await companyCompliancePendingRef.set({
        [id]: complianceData,
      }, { merge: true });
    }

    const setCompanyComplianceToPendingFunction = functions.httpsCallable('setCompanyComplianceToPending');

    await setCompanyComplianceToPendingFunction({
      id,
      complianceData,
      oldComplianceData: oldComplianceData[id] || null,
      beforeData: beforeData[id] || null,
      company,
    }).then(async (result) => {
      console.log('setCompanyComplianceToPending result', result, company);

      if (company) {
        const complianceRef = firestore.collection('complianceData').doc(company);

        const snapshot = await complianceRef.get();

        if (snapshot.exists) {
          const complianceData = snapshot.data();

          console.log('setCompanyComplianceToPending complianceData', complianceData);
          dispatch({
            type: COMPLIANCE_DATA,
            data: complianceData,
          });

          return resolve('success');
        } else {
          dispatch({
            type: COMPLIANCE_DATA,
            data: null,
          });

          return resolve('success');
        }
      }
      return resolve('success');
    });
  }
});

export const updateCompanyData = (id, value, companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  console.log('updateCompanyData', id, value, companyId);
  console.log('updateCompanyData 2', value === true, value === false);

  if (id === 'hasDisplayName' && !(value === true || value === false)) {
    return reject('missing data hasDisplayName');
  } else if (id === 'displayName' && !(value || value === '')) {
    return reject('missing data displayName');
  } else if (id !== 'hasDisplayName' && id !== 'displayName' && (!value || !id)) {
    return reject('missing data');
  }

  if (user && companyId) {
    const updateCompanyDataFunction = functions.httpsCallable('updateCompanyData');

    updateCompanyDataFunction({
      id,
      value,
      companyId,
      type: 'data',
    }).then((result) => {
      console.log('updateCompanyData', result);

      if (result.data && result.data.status && result.data.status === 'error') {
        return reject(result.data.error);
      }
      return resolve(result);
    }).catch((err) => {
      console.log('test2');
      return reject(err);
    });
  }
});


export const updateCompanyLogo = (image, companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  if (!image) return reject('missing data');

  if (user && companyId) {
    let imageName;
    let imageLink;

    var storageRef = storage.ref();

    console.log('updateCompanyLogo, image', image);

    let extension = '.jpeg';
    if (image.type === 'image/jpeg') {
      extension = '.jpeg';
    } else if (image.type === 'image/png') {
      extension = '.png';
    } else if (image.type === 'image/jpg') {
      extension = '.jpg';
    }

    imageName = `${companyId}logo${extension}`;
    imageLink = `pending/${imageName}`;

    var imageRef = storageRef.child(`pending/${imageName}`);

    await imageRef.put(image).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at', downloadURL);
      });
    });

    const updateCompanyDataFunction = functions.httpsCallable('updateCompanyData');

    updateCompanyDataFunction({
      image: { name: image.name, type: image.type },
      imageLink,
      companyId,
      type: 'logo',
    }).then((result) => {
      if (result.data && result.data.status && result.data.status === 'error') {
        return reject(result.data.error);
      }
      return resolve(result);
    }).catch((err) => {
      return reject(err);
    });
  }
});

export const updateCompanyPrimaryVoucherImage = (image, companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  if (!image) return reject('missing data');

  if (user && companyId) {
    let imageName;
    let imageLink;

    var storageRef = storage.ref();

    let extension = '.jpeg';
    if (image.type === 'image/jpeg') {
      extension = '.jpeg';
    } else if (image.type === 'image/png') {
      extension = '.png';
    } else if (image.type === 'image/jpg') {
      extension = '.jpg';
    }

    imageName = `primary${extension}`;
    imageLink = `pending/${companyId}/${imageName}`;

    var imageRef = storageRef.child(`pending/${companyId}/${imageName}`);

    await imageRef.put(image).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at', downloadURL);
      });
    });

    const updateCompanyDataFunction = functions.httpsCallable('updateCompanyData');

    updateCompanyDataFunction({
      image: { name: image.name, type: image.type },
      imageLink,
      companyId,
      type: 'primaryVoucherImage',
    }).then((result) => {
      if (result.data && result.data.status && result.data.status === 'error') {
        return reject(result.data.error);
      }

      return resolve(result);
    }).catch((err) => {
      console.log(err);
      return reject(err);
    });
  }
});


export const updateCompanyVoucherImages = (images, companyId, deletedExistingImages) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  if (!images) return reject('missing data');

  if (user && companyId) {
    let promises = [];
    let imageLinks = [];

    var storageRef = storage.ref(`pending/${companyId}/other`);

    await images.map(async (image) => {
      if (image.name && image.size && image.type) {
        promises.push(new Promise(async resolve2 => {
          const imageName = `${uuid()}${image.name}`;
          imageLinks.push(`pending/${companyId}/other/${imageName}`);

          var imageRef = storageRef.child(`${imageName}`);

          await imageRef.put(image).then((snapshot) => {
            console.log('Uploaded a blob or file!');
          });
          return resolve2();
        }))
      } else {
        if (image.path) {
          console.log(image.path);
          imageLinks.push(image.path);
        }
      }
    });

    return Promise.all(promises).then(() => {
      const updateCompanyDataFunction = functions.httpsCallable('updateCompanyData');

      updateCompanyDataFunction({
        imageLinks,
        companyId,
        deletedExistingImages,
        type: 'voucherImages',
      }).then((result) => {
        if (result.data && result.data.status && result.data.status === 'error') {
          return reject(result.data.error);
        }

        return resolve(result);
      }).catch((err) => {
        return reject(err);
      });
    });
  }
});

export const getCategories = () => (dispatch) => new Promise(async (resolve, reject) => {
    const categoriesRef = firestore.collection('categories');

    const snapshot = await categoriesRef.get();

    if (snapshot.docs) {
      const categories = {};

      snapshot.docs.map(doc => {
        categories[doc.id] = doc.data();
        return null;
      });

      dispatch({
          type: GET_CATEGORIES,
          payload: categories,
      });

      return resolve('success');
    }
});

export const getRewardsUnlocked = () => (dispatch) => new Promise(async (resolve, reject) => {
    const rewardsUnlockedRef = firestore.collection('images').doc('rewardsUnlocked');

    const snapshot = await rewardsUnlockedRef.get();

    if (snapshot.exists) {
      const rewardsUnlocked = snapshot.data();

      if (rewardsUnlocked.icon) {
        storage
          .ref(rewardsUnlocked.icon)
          .getDownloadURL()
          .then(url => {
            dispatch({
                type: GET_REWARDS_UNLOCKED,
                payload: { ...rewardsUnlocked, url },
            })
          });
      } else {
        dispatch({
            type: GET_REWARDS_UNLOCKED,
            payload: rewardsUnlocked,
        })
      }

      return resolve('success');
    }
});

export const updateCompanyWebsite = (id, website) => (dispatch) => new Promise(async (resolve, reject) => {
  const companyRef = firestore.collection('companies').doc(id);

  await companyRef.update({
    website: website,
  });

  const ownersRef = firestore.collection('owners').doc(id);

  await ownersRef.update({
    website: website,
  });

  const companyUsersRef = firestore.collection('users').where('company', '==', id);
  const snapshot2 = await companyUsersRef.get();

  if (!snapshot2.docs) {
    return resolve('success');
  } else {
    snapshot2.docs.map(async doc => {
      const userData = doc.data();
      const companyUserRef = firestore.collection('users').doc(doc.id);

      await companyUserRef.update({
        website: website,
      });

      dispatch({
          type: UPDATE_USER,
          payload: { website: website },
          key: doc.id,
          roleType: userData.role,
      })
    })

    return resolve('success');
  }
});

export const getCompanyApiKey = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  const companyAPIKeyRef = firestore.collection('companyAPIKeys').doc(id);

  const snapshot = await companyAPIKeyRef.get();

  if (!snapshot.exists) {
    return resolve('success');
  } else {
    const companyAPIKeyData = snapshot.data();

    dispatch({
        type: UPDATE_API_KEY,
        payload: companyAPIKeyData.apiKey || '',
    })

    return resolve('success');
  }
});


export const changeCompanyApiKey = (companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  const changeCompanyApiKeyFunction = functions.httpsCallable('changeCompanyApiKey');
  const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');


  changeCompanyApiKeyFunction().then((result) => {
    if (result.data && result.data.status && result.data.status === 'error') {
      return reject(result.error);
    }

    if (result.data && result.data.data) {
      const companyAPIKey = result.data.data;

      checkCompanyStatusFunction({ companyId }).then((result2) => {

        dispatch({
            type: UPDATE_API_KEY,
            payload: companyAPIKey,
        });
      });
    }

    return resolve(result);
  }).catch((err) => {
    return reject(err);
  });


  return resolve('success');
});

export const getShopifyAPIData = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  const companyAPIKeyRef = firestore.collection('companiesApi').doc(id);

  const snapshot = await companyAPIKeyRef.get();

  if (!snapshot.exists) {
    return resolve(dispatch({
        type: UPDATE_SHOPIFY_API,
        payload: {},
    }));
  } else {
    const companyAPIKeyData = snapshot.data();

    return resolve(dispatch({
        type: UPDATE_SHOPIFY_API,
        payload: companyAPIKeyData || {},
    }));
  }
});


export const changeShopifyAPIData = (id, accessToken, shopName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('changeShopifyAPIData', id, accessToken, shopName);
  if ((!accessToken && !shopName) || !id) {
    return resolve('misising data');
  }

  const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');

  const companyAPIKeyRef = firestore.collection('companiesApi').doc(id);

  console.log('test 1');

  await companyAPIKeyRef.set({
    accessData: {
      accessToken: accessToken || null,
      shopName: shopName || null,
    },
    type: 1,
  });

  console.log('test 2');

  await checkCompanyStatusFunction({ companyId: id }).then((result2) => {

    console.log('test 3', result2);

    return resolve(dispatch({
        type: UPDATE_SHOPIFY_API,
        payload: {
          accessData: {
            accessToken: accessToken || null,
            shopName: shopName || null,
          },
          type: 1,
        },
    }))
  });

  return resolve('success');
});


export const getManualAPIData = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  const companyAPIKeyRef = firestore.collection('companiesApiManual').doc(id);

  const snapshot = await companyAPIKeyRef.get();

  if (!snapshot.exists) {
    return resolve(dispatch({
      type: UPDATE_MANUAL_API,
      payload: null,
    }));
  } else {
    const companyAPIKeyData = snapshot.data();

    return resolve(dispatch({
      type: UPDATE_MANUAL_API,
      payload: companyAPIKeyData || null,
    }));
  }
});


export const changeManualAPIData = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('changeManualAPIData', id);
  if (!id) {
    return resolve('misising data');
  }

  const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');

  const companyAPIKeyRef = firestore.collection('companiesApiManual').doc(id);

  console.log('test 1');

  await companyAPIKeyRef.set({
    accessData: {
      data: null,
    },
    type: 1,
  });

  await checkCompanyStatusFunction({ companyId: id }).then((result2) => {

    console.log('test 3', result2);

    return resolve(dispatch({
      type: UPDATE_MANUAL_API,
      payload: {
        accessData: {
          data: null,
        },
        type: 1,
      },
    }))
  });

  return resolve('success');
});


export const getWoocommerceAPIData = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  const companyAPIKeyRef = firestore.collection('companiesApiWoocommerce').doc(id);

  const snapshot = await companyAPIKeyRef.get();

  if (!snapshot.exists) {
    return resolve(dispatch({
      type: UPDATE_WOOCOMMERCE_API,
      payload: {},
    }));
  } else {
    const companyAPIKeyData = snapshot.data();

    return resolve(dispatch({
      type: UPDATE_WOOCOMMERCE_API,
      payload: companyAPIKeyData || {},
    }));
  }
});


export const changeWoocommerceAPIData = (id, consumerKey, consumerSecret, url) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('changeWoocommerceAPIData', id, consumerKey, consumerSecret);
  if ((!consumerKey && !consumerSecret) || !id) {
    return resolve('misising data');
  }

  const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');

  const companyAPIKeyRef = firestore.collection('companiesApiWoocommerce').doc(id);

  console.log('test 1');

  await companyAPIKeyRef.set({
    accessData: {
      consumerKey: consumerKey || null,
      consumerSecret: consumerSecret || null,
      woocommerceShopName: url || null,
    },
    type: 1,
  });

  console.log('test 2');

  await checkCompanyStatusFunction({ companyId: id }).then((result2) => {

    console.log('test 3', result2);

    return resolve(dispatch({
      type: UPDATE_WOOCOMMERCE_API,
      payload: {
        accessData: {
          consumerKey: consumerKey || null,
          consumerSecret: consumerSecret || null,
        },
        type: 1,
      },
    }))
  });

  return resolve('success');
});



export const getActiveAPIData = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  const companyAPIKeyRef = firestore.collection('companiesApiActiveType').doc(id);

  const snapshot = await companyAPIKeyRef.get();

  if (!snapshot.exists) {
    return resolve(dispatch({
      type: UPDATE_ACTIVE_API,
      payload: 'shopify',
    }));
  } else {
    const companyAPIKeyData = snapshot.data();

    return resolve(dispatch({
      type: UPDATE_ACTIVE_API,
      payload: companyAPIKeyData.type ? companyAPIKeyData.type : 'shopify',
    }));
  }
});


export const changeActiveAPIData = (id, type) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('changeActiveAPIData', id, type);
  if (!type || !id) {
    return resolve('misising data');
  }

  if (type !== 'shopify' && type !== 'woocommerce' && type !== 'manual') {
    return resolve('misising data type');
  }

  const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');

  const companyAPIKeyRef = firestore.collection('companiesApiActiveType').doc(id);

  console.log('test 1');

  await companyAPIKeyRef.set({
    type: type,
  });

  console.log('test 2', type);

  dispatch({
    type: UPDATE_ACTIVE_API,
    payload: type ? type : 'shopify',
  });

  await checkCompanyStatusFunction({ companyId: id }).then((result2) => {
    console.log('test 3', result2);

    return resolve('success');
  });

  return resolve('success');
});



export const getLinkedAPIs = (companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('getLinkedAPIs', companyId);
  if (!companyId) {
    return resolve('misising data');
  }

  /*
  const companyAPIKeyRef = firestore.collection('companiesLinkedApis').doc(companyId).collection('linkedCompaniesAPIs');

  const snapshot = await companyAPIKeyRef.get();

  if (snapshot.docs) {
    const linkedCompaniesAPIs = {};

    snapshot.docs.map(doc => {
      linkedCompaniesAPIs[doc.id] = doc.data();
      return null;
    });

    console.log('getLinkedAPIs linkedCompaniesAPIs', linkedCompaniesAPIs);

    dispatch({
      type: GET_COMPANY_LINKED_COMPANIES,
      payload: linkedCompaniesAPIs,
    });

    return resolve('success');
  } */

  const companyAPIKeyRef = firestore.collection('companiesLinkedApis').doc(companyId).collection('linkedCompaniesAPIs');

  unsubscribe3 = companyAPIKeyRef.onSnapshot(snapshot => {
    if (snapshot.docs) {
      const linkedCompaniesAPIs = {};

      snapshot.docs.map(doc => {
        linkedCompaniesAPIs[doc.id] = doc.data();
        return null;
      });

      console.log('getLinkedAPIs linkedCompaniesAPIs', linkedCompaniesAPIs);

      dispatch({
        type: GET_COMPANY_LINKED_COMPANIES,
        payload: linkedCompaniesAPIs,
      });
    }
  }, err => {
    console.log(`getLinkedAPIs Encountered error: ${err}`);
  });
});



export const addLinkedAPI = (companyId, name, email, type, apiData) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('addLinkedAPI', companyId, name, email, type, apiData);
  if (!companyId || !name || !email || !type || !apiData) {
    return resolve('misising data');
  }

  if (type !== 'shopify' && type !== 'woocommerce' && type !== 'manual') {
    return resolve('misising data type');
  }

  const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');
  const companyAPIKeyRef = firestore.collection('companiesLinkedApis').doc(companyId).collection('linkedCompaniesAPIs');

  await companyAPIKeyRef.add({
    name,
    email,
    type,
    apiData,
    valid: false,
  });

  if (type === 'manual') {
    return resolve('success');
  }

  await checkCompanyStatusFunction({ companyId }).then((result2) => {
    console.log('checkCompanyStatusFunction res', result2);

    return resolve('success');
  });

  // return resolve('success');
});


export const updateLinkedAPI = (companyId, id, name, email, type, apiData) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('updateLinkedAPI', companyId, id, name, email, type, apiData);
  if (!id || !companyId || !name || !email || !type || !apiData) {
    return resolve('misising data');
  }

  if (type !== 'shopify' && type !== 'woocommerce' && type !== 'manual') {
    return resolve('misising data type');
  }

  const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');
  const companyAPIKeyRef = firestore.collection('companiesLinkedApis').doc(companyId).collection('linkedCompaniesAPIs').doc(id);

  await companyAPIKeyRef.set({
    name,
    email,
    type,
    apiData,
    valid: type === 'manual' ? true: false,
  });

  if (type === 'manual') {
    return resolve('success');
  }

  await checkCompanyStatusFunction({ companyId: id }).then((result2) => {
    return resolve('success');
  });

  // return resolve('success');
});

export const removeLinkedAPI = (companyId, id) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('removeLinkedAPI', companyId, id);

  if (!companyId || !id) {
    return resolve('misising id');
  }

  const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');
  const companyAPIKeyRef = firestore.collection('companiesLinkedApis').doc(companyId).collection('linkedCompaniesAPIs').doc(id);

  await companyAPIKeyRef.delete();

  await checkCompanyStatusFunction({ companyId }).then((result2) => {
    return resolve('success');
  });

  return resolve('success');
});


export const manuallyRedeemPayment = (chargeId, dbId, redeemedBy, isLinkedCompany) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('manuallyRedeemPayment', chargeId, dbId, redeemedBy, isLinkedCompany);

  if (isLinkedCompany) {
    const manuallyRedeemPaymentFunction = functions.httpsCallable('manuallyRedeemPayment');

    return manuallyRedeemPaymentFunction({
      chargeId,
      dbId,
      redeemedBy,
    }).then(async (result) => {
      console.log('manuallyRedeemPaymentFunction result', result);
      return resolve();
    });
  } else {
    const manuallyRedeemPaymentFunction = functions.httpsCallable('manuallyRedeemPaymentRegularAPI');

    return manuallyRedeemPaymentFunction({
      chargeId,
      dbId,
      redeemedBy,
    }).then(async (result) => {
      console.log('manuallyRedeemPaymentFunction result', result);
      return resolve();
    });
  }
});