import { auth, firestore, functions, storage, firebase } from '../../lib/firebase';
import {
  GET_USERS,
  COMPANIES_LIST,
  GET_USER_DATA,
  UPDATE_USER,
  DELETE_USER,
  GET_ROLES,
  GET_ADMIN_ROLES,
  GET_ADMIN_ACTIONS,
  GET_ADMINS_ROLES,
  GET_COMPANIES_ROLES,
  UPDATE_ROLE,
  REMOVE_ROLE,
  GET_COMPANY_COMPLIANCE_DATA,
  UPDATE_COMPANIES_ACTIVE_API_DATA,
  GET_COMPANY_OWNERS,
  GET_MORE_COMPANY_OWNERS,
  UPDATE_COMPANY,
  GET_COMPANYS_USERS,
  GET_COMPANY_LOGO,
  GET_APP_CUSTOMERS,
  GET_MORE_APP_CUSTOMERS,
  UPDATE_APP_CUSTOMER,
  GET_REQUESTING_CAMPAIGNS,
  GET_CAMPAIGNS,
  UPDATE_CAMPAIGN,
  GET_MORE_CAMPAIGNS,
  GET_CAMPAIGNS_BY_COMPANY,
  UPDATE_CAMPAIGN_BY_COMPANY,
  GET_MORE_CAMPAIGNS_BY_COMPANY,
  GET_ADMINS,
  GET_MORE_ADMINS,
  UPDATE_ADMIN,
  DELETE_ADMIN,
  GET_ADMIN_STATISTICS,
  GET_ADMIN_STATISTIC,
  GET_ADMIN_SETTINGS,
  GET_APP_SETTINGS,
  GET_ADMIN_DASHBOARD,
  GET_ADMIN_DASHBOARD_CUSTOM,
  GET_ADMIN_DASHBOARD_TOTALS,
  GET_ADMIN_DASHBOARD_STRIPE_DATA,
  GET_ADMIN_DASHBOARD_STRIPE_CUSTOM_DATA,
  GET_ADMIN_PAYMENTS,
  SORT_ADMIN_PAYMENTS,
  SORT_ADMIN_DATA,
  CARD_TEMPLATES,
  CARD_TEMPLATES_CATEGORIES,
  CARD_QUOTE,
  OPEN_COMPANY_DASHBOARD,
  LEAVE_COMPANY_DASHBOARD,
  SET_ANALYTICS_TEMPLATE,
  ANALYTICS_TEMPLATES,
  CARD_QUOTE_DESCRIPTIONS,
  GET_FIRST_ADMIN_PAYMENTS,
  GET_MORE_ADMIN_PAYMENTS,
  GET_SINGLE_ADMIN_PAYMENT,
  GREETING_TAB,
  GIFTING_PRICE,
  APP_GIFT_PREVIEW_LINK,
} from '../index';

import moment from 'moment';

import { v4 as uuidv4 } from "uuid";

const ADMIN_DASHBOARD_INIT_DATA = {
  ordersCount: 0,
  salesCount: 0,
  salesValue: 0,
  giftedCount: 0,
  giftedValue: 0,
  redemptionsCount: 0,
  redemptionsValue: 0,
  refundsCount: 0,
  refundsValue: 0,
  newCompanies: 0,
  deletedCompanies: 0,
  newCampaigns: 0,
  endedCampaigns: 0,
  newClients: 0,
  deletedClients: 0,
  applicationFeeValue: 0,
  amountCaptured: 0,
};

let unsubscribe;
let unsubscribe2;

export const cancelAdminListeners = () => dispatch => new Promise((resolve, reject) => {
    if (unsubscribe) {
      unsubscribe();
    }

    if (unsubscribe2) {
      unsubscribe2();
    }

    return resolve();
});

export const getAdminAnalyticsTempates = () => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  const userRef = firestore.collection('adminAnalyticsTempates').doc(user.uid).collection('templates');
  const snapshot = await userRef.get();

  if (snapshot.docs) {
    const analyticsTempates = {};

    snapshot.docs.map(doc => {
      analyticsTempates[doc.id] = doc.data();
    })

    return resolve(dispatch({
      type: ANALYTICS_TEMPLATES,
      payload: analyticsTempates,
    }));
  } else {
    return resolve(dispatch({
      type: ANALYTICS_TEMPLATES,
      payload: {},
    }));
  }
});

export const addAdminAnalyticsTemplate = (data) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  const userRef = firestore.collection('adminAnalyticsTempates').doc(user.uid).collection('templates');

  await userRef.add(data).then((res) => {
    return resolve(dispatch({
      type: SET_ANALYTICS_TEMPLATE,
      payload: data,
      id: res.id,
    }));
  }).catch(err => {
    return resolve('error');
  });
});

export const editAdminAnalyticsTemplate = (id, data) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  const userRef = firestore.collection('adminAnalyticsTempates').doc(user.uid).collection('templates').doc(id);

  await userRef.set(data).then((res) => {
    return resolve(dispatch({
      type: SET_ANALYTICS_TEMPLATE,
      payload: data,
      id: id,
    }));
  }).catch(err => {
    return resolve('error');
  });

});

export const removeAdminAnalyticsTemplate = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  const user = auth.currentUser;

  const userRef = firestore.collection('adminAnalyticsTempates').doc(user.uid).collection('templates').doc(id);

  await userRef.delete().then((res) => {
    return resolve(dispatch({
      type: SET_ANALYTICS_TEMPLATE,
      payload: null,
      id: id,
    }));
  }).catch(err => {
    return resolve('error');
  });

});

export const getCompanyList = () => (dispatch) => new Promise(async (resolve, reject) => {
  const companyListRef = firestore.collection('companiesList');

  const snapshot = await companyListRef.get();

  if (snapshot.docs) {
    const companiesList = {};

    snapshot.docs.map(doc => {
      companiesList[doc.id] = doc.data();
      return null;
    })

    return resolve(dispatch({
      type: COMPANIES_LIST,
      payload: companiesList,
    }));
  } else {
    return resolve(dispatch({
      type: COMPANIES_LIST,
      payload: {},
    }));
  }
});

export const getAdminStatistics = () => (dispatch) => new Promise(async (resolve, reject) => {
    const newCompaniesCountRef = firestore.collection('adminStatistics').doc('newCompaniesCount');
    const pendingVouchersCountRef = firestore.collection('adminStatistics').doc('pendingVouchersCount');

    unsubscribe = newCompaniesCountRef.onSnapshot(snapshot => {
      if (snapshot.exists) {
        const adminStatistic = snapshot.data();

        dispatch({
            type: GET_ADMIN_STATISTIC,
            payload: adminStatistic,
            key: 'newCompaniesCount',
        })

        return resolve('success');
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    });

    unsubscribe2 = pendingVouchersCountRef.onSnapshot(snapshot => {
      if (snapshot.exists) {
        const adminStatistic = snapshot.data();

        dispatch({
            type: GET_ADMIN_STATISTIC,
            payload: adminStatistic,
            key: 'pendingVouchersCount',
        })

        return resolve('success');
      }
    }, err => {
      console.log(`Encountered error: ${err}`);
    });
});

export const getAdminSettings = () => (dispatch) => new Promise(async (resolve, reject) => {
  const adminSettingsRef = firestore.collection('adminSettings').doc('adminSettings');

  const snapshot = await adminSettingsRef.get();

  let adminSettings = {};

  if (snapshot.exists) {
    adminSettings = snapshot.data();
  }

  dispatch({
    type: GET_ADMIN_SETTINGS,
    payload: adminSettings,
  })

  return resolve({ adminSettings });
});

export const getAppSettings = () => (dispatch) => new Promise(async (resolve, reject) => {
  const adminSettingsRef = firestore.collection('appSettings').doc('adminSettings');

  const snapshot = await adminSettingsRef.get();

  let appSettings = {};

  if (snapshot.exists) {
    appSettings = snapshot.data();
  }

  dispatch({
    type: GET_APP_SETTINGS,
    payload: appSettings,
  })

  return resolve({ appSettings });
});


export const updateAdminSettings = (key, data) => (dispatch) => new Promise(async (resolve, reject) => {
  const adminSettingsRef = firestore.collection('adminSettings').doc('adminSettings');

  await adminSettingsRef.update({
    [key]: data,
  });

  const snapshot = await adminSettingsRef.get();

  let adminSettings = {};

  if (snapshot.exists) {
    adminSettings = snapshot.data();
  }

  dispatch({
    type: GET_ADMIN_SETTINGS,
    payload: adminSettings,
  })

  return resolve({ adminSettings });
});

export const updateStepprPricePerCard = (data) => (dispatch) => new Promise(async (resolve, reject) => {
  const adminSettingsRef = firestore.collection('appSettings').doc('adminSettings');

  await adminSettingsRef.set({
    stepprCardPrice: data,
  }, { merge: true });

  const snapshot = await adminSettingsRef.get();

  let appSettings = {};

  if (snapshot.exists) {
    appSettings = snapshot.data();
  }

  dispatch({
    type: GET_APP_SETTINGS,
    payload: appSettings,
  })

  return resolve({ appSettings });
});

export const updateStepprVATPerCard = (data) => (dispatch) => new Promise(async (resolve, reject) => {
  const adminSettingsRef = firestore.collection('appSettings').doc('adminSettings');

  await adminSettingsRef.set({
    stepprCardVAT: data,
  }, { merge: true });

  const snapshot = await adminSettingsRef.get();

  let appSettings = {};

  if (snapshot.exists) {
    appSettings = snapshot.data();
  }

  dispatch({
    type: GET_APP_SETTINGS,
    payload: appSettings,
  })

  return resolve({ appSettings });
});

export const getCompanyMembers = (company) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!company) {
    return resolve(dispatch({
        type: GET_COMPANYS_USERS,
        payload: null,
    }))
  } else {

    const usersRef = firestore.collection('users').where('company', '==', company).where('role', '!=', 'teamOwner');
    const snapshot = await usersRef.get();

    if (snapshot.docs) {
      const users = [];

      snapshot.docs.map(doc => {
        users.push(doc.data());
      });

      return resolve(dispatch({
          type: GET_COMPANYS_USERS,
          payload: users,
      }));
    }
  }
});

export const getCompanyLogo = (company) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!company) {
    return resolve(null)
  } else {

    const companyRef = firestore.collection('companies').doc(company);
    const snapshot = await companyRef.get();

    if (!snapshot.exists) {
      return reject('no such company');
    } else {
      const companyData = snapshot.data();

      if (companyData && companyData.logo) {
        var logoReference = storage.ref(companyData.logo);

        logoReference.getDownloadURL().then((url) => {
          return resolve(url);
        }).catch((error) => {
          console.log(error);
          return resolve(null)
        });
      } else {
        return resolve(null)
      }
    }
  }
});


export const getRequestingCampaigns = () => (dispatch) => new Promise(async (resolve, reject) => {
    let campaignsRef = firestore.collection('vouchers');

    const snapshot = await campaignsRef.get();

    if (snapshot.docs) {
      const campaigns = {};
      const promises = [];

      await snapshot.docs.map(doc => promises.push(new Promise(async (resolve2, reject2) => {
        const data = doc.data() || {};

        let categoryData;
        let companyData;
        let companyId;
        let companyPendingChanges;

        let images = [];
        let promises2 = [];

        if (data.category && data.category.get) {
          await data.category.get().then((res) => {
            categoryData = res.data();
          }).catch(err => {
            console.log(err);
            categoryData = null;
          });
        }

        if (data.company) {
          await data.company.get().then((res) => {
            companyId = res.id;
            companyData = res.data();
          }).catch(err => {
            companyId = null;
            companyData = null;
          });

          if (data.hasPendingChanges && companyId) {
            let companyPendingRef = firestore.collection('companyPending').doc(companyId);

            await companyPendingRef.get().then((res2) => {
              companyPendingChanges = res2.data();
            }).catch((err2) => {
              companyPendingChanges = null;
            });
          }
        }

        if (data.images) {
          promises2.push(new Promise(async (resolve3, reject3) => data.images.map(async (image) => {
            var storageRef = storage.ref(image.file);

            await storageRef.getDownloadURL().then((url) => {
              images.push(url);

              return resolve3();
            }).catch((error) => {
              // Handle any errors

              return resolve3();
            });
          })));
        }

        Promise.all(promises2).then(() => {
          campaigns[doc.id] = {
            ...data,
            category: categoryData,
            company: companyData,
            companyPendingChanges: companyPendingChanges,
            companyId,
            images,
          };

          return resolve2();
        });
      })));

      return Promise.all(promises).then(() => {
        dispatch({
          type: GET_REQUESTING_CAMPAIGNS,
          payload: campaigns,
        })

        return resolve('success');
      })
    }
});


export const approveVoucherCampaign = (id, companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('approveVoucherCampaign', id, companyId);

    const approveVoucherCampaignFunction = functions.httpsCallable('approveVoucherCampaign');

  approveVoucherCampaignFunction({ voucherId: id, companyId }).then((result2) => {
      const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');

      checkCompanyStatusFunction({ companyId }).then((result2) => {
        return resolve('success');
      });
    });

  //  return resolve('success');
});

export const approveVoucherCampaignOld = (id, companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('approveVoucherCampaign', id, companyId);
  const voucherRef = firestore.collection('vouchers').doc(id);

  const approvedDoc = await voucherRef.get();

  if (!approvedDoc.exists) {
    return reject('no such user');
  } else {
    const campaign = approvedDoc.data();
    const companyRef = campaign.company;

    const liveVouchersRef = firestore.collection('vouchers').where('company', '==', companyRef).where('status', '<=', 3);
    const liveVouchersRefSnapshot = await liveVouchersRef.get();

    let liveCampaigns;

    if (!liveVouchersRefSnapshot.docs) {
      liveCampaigns = {};
    } else {
      let temp = {};

      liveVouchersRefSnapshot.docs.map(async(doc) => {
        console.log('liveCampaigns id', doc.id);
        temp[doc.id] = doc.data()
      });

      liveCampaigns = temp;
    }

    console.log('liveCampaigns.length', Object.keys(liveCampaigns).length);

    const pendingVouchersRef = firestore.collection('vouchers').where('company', '==', companyRef).where('status', '==', 8);
    const pendingVouchersRefSnapshot = await pendingVouchersRef.get();

    let pendingCampaigns;

    if (!pendingVouchersRefSnapshot.docs) {
      pendingCampaigns = {};
    } else {
      let temp = {};

      pendingVouchersRefSnapshot.docs.map(async(doc) => {
        console.log('pendingCampaigns id', doc.id);
        temp[doc.id] = doc.data()
      });

      pendingCampaigns = temp;
    }

    console.log('pendingCampaigns.length', Object.keys(pendingCampaigns).length);

    let companyPendingData;
    let vouchersPendingData;

    const companyPendingRef = firestore.collection('companyPending').doc(companyId);
    const vouchersPendingRef = firestore.collection('vouchersPending').doc(id);

    const companyPendingSnapshot = await companyPendingRef.get();
    const vouchersPendingSnapshot = await vouchersPendingRef.get();

    if (!companyPendingSnapshot.exists) {
      companyPendingData = null;
    } else {
      companyPendingData = companyPendingSnapshot.data();
    }

    if (!vouchersPendingSnapshot.exists) {
      vouchersPendingData = null;
    } else {
      vouchersPendingData = vouchersPendingSnapshot.data();
    }

    console.log('vouchersPendingData', { vouchersPendingData });
    console.log('companyPendingData', { companyPendingData });

    if (!vouchersPendingData && !companyPendingData) {   // && Object.keys(liveCampaigns).length === 0
      console.log('has pending data && is only pending campaign && has no live campaigns');

      if (campaign && campaign.status === 8) {
        if (new Date(campaign.startDate.seconds * 1000) <= new Date()) {
          await voucherRef.update({
            status: 1,
            isNew: false,
            isActive: true,
            frozen: false,
            hasPendingChanges: null,
          });
        } else {
          await voucherRef.update({
            status: 1,
            isNew: false,
            isActive: false,
            frozen: false,
            hasPendingChanges: null,
          });
        }
      }
    } else {
      if (campaign && campaign.status === 8) {
        if (new Date(campaign.startDate.seconds * 1000) <= new Date()) {
          await voucherRef.update({
            status: 1,
            isNew: false,
            isActive: true,
            frozen: false,
          //  hasPendingChanges: null,
          });
        } else {
          await voucherRef.update({
            status: 1,
            isNew: false,
            isActive: false,
            frozen: false,
          //  hasPendingChanges: null,
          });
        }
      }
    }

    const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');

    checkCompanyStatusFunction({ companyId }).then((result2) => {
      return resolve('success');
    });

  //  return resolve('success');
  }
});

export const approveVoucherCampaignChanges = (id, companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  const approveVoucherChangesFunction = functions.httpsCallable('approveVoucherChanges');

  approveVoucherChangesFunction({
    voucherId: id,
  }).then((result) => {
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  });
});

export const approveVoucherCampaignChange = (changeId, voucherId, companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  const approveVoucherChangeFunction = functions.httpsCallable('approveVoucherChange');

  approveVoucherChangeFunction({
    voucherId,
    changeId,
  }).then((result) => {
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  });
});

export const denyVoucherCampaignChange = (changeId, voucherId, companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  const denyVoucherChangeFunction = functions.httpsCallable('denyVoucherChange');

  denyVoucherChangeFunction({
    voucherId,
    changeId,
  }).then((result) => {
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
});

export const denyVoucherCampaign = (id, companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('denyVoucherCampaign');
  const voucherRef = firestore.collection('vouchers').doc(id);

  const approvedDoc = await voucherRef.get();

  if (!approvedDoc.exists) {
    return reject('no such user');
  } else {
    const campaign = approvedDoc.data();

    if (campaign && campaign.status === 8) {
      await voucherRef.update({
        status: 6,
        isNew: false,
        isActive: false,
        hasPendingChanges: null,
        endDate: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }

    return resolve('success');
  }
});


export const changeVoucherCampaignCommission = (id, commission, index, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  const voucherRef = firestore.collection('vouchers').doc(id);

  const voucherDoc = await voucherRef.get();

  if (!(commission >= 0 && commission < 100)) {
    return resolve('wrong commission');
  }

  if (!voucherDoc.exists) {
    return reject('no such user');
  } else {
    await voucherRef.update({
      commission: commission,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'changeVoucherCampaignCommission',
      voucherId: id,
      commission: commission || 0,
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      name: adminName || null,
    });

    dispatch({
      type: UPDATE_CAMPAIGN,
      changeType: 'commission',
      payload: commission,
      index,
    });

    return resolve('success');
  }
});

export const changeCompanyDefaultCommission = (id, commission, index, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  const ownersRef = firestore.collection('owners').doc(id);

  const ownersDoc = await ownersRef.get();

  if (!(commission >= 0 && commission < 100)) {
    return resolve('wrong commission');
  }

  if (!ownersDoc.exists) {
    return reject('no such user');
  } else {
    await ownersRef.update({
      commission: commission,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'changeCompanyDefaultCommission',
      companyId: id,
      commission: commission || null,
      time: firestore.Timestamp.now(),
      uid: currentUser.uid || null,
      name: adminName || null,
    });

    dispatch({
      type: UPDATE_COMPANY,
      changeType: 'commission',
      payload: commission,
      index,
    });

    return resolve('success');
  }
});

export const toggleRedemptionsStatus = (id, type, index) => (dispatch) => new Promise(async (resolve, reject) => {
  if (type === 'voucher') {
    const voucherRef = firestore.collection('vouchers').doc(id);

    let newValue;

    await firestore.runTransaction((transaction) => {
      return transaction.get(voucherRef).then((sfDoc) => {
          if (!sfDoc.exists) {
              throw "Document does not exist!";
          }

          if (sfDoc.data().enableRedemptions) {
            newValue = false;
            transaction.update(voucherRef, { enableRedemptions: false });
          } else {
            newValue = true;
            transaction.update(voucherRef, { enableRedemptions: true });
          }
        });
    }).then(() => {
        dispatch({
          type: UPDATE_CAMPAIGN,
          changeType: 'enableRedemptions',
          payload: newValue,
          index,
        })
    }).catch((err) => {
        console.error(err);
    });
  } else if (type === 'company') {
    const ownerRef = firestore.collection('owners').doc(id);
    const companyRef = firestore.collection('companies').doc(id);

    let newValue;

    await firestore.runTransaction(async (transaction) => {
      return await transaction.get(ownerRef).then((companyDoc) => {
        if (!companyDoc.exists) {
          throw "Document does not exist!";
        }

        if (companyDoc.data().enableRedemptions) {
          newValue = false;
          transaction.update(ownerRef, { enableRedemptions: false });
        } else {
          newValue = true;
          transaction.update(ownerRef, { enableRedemptions: true });
        }
      });
    }).then(async () => {

        dispatch({
          type: UPDATE_CAMPAIGN_BY_COMPANY,
          payload: newValue,
          changeType: 'enableRedemptions',
          index,
        })

        const vouchersRef = firestore.collection('vouchers').where('company', '==', companyRef);

        const snapshot2 = await vouchersRef.get();

        if (!snapshot2.docs) {
          return resolve('success');
        } else {
          let promises = [];

          snapshot2.docs.map(async doc => {
            const voucherRef = firestore.collection('vouchers').doc(doc.id);

            promises.push(voucherRef.update({ enableRedemptions: newValue }));
          });

          return resolve('success');
        }
    }).catch((err) => {
        console.error(err);
    });
  }
});


export const denyVoucherCampaignChanges = (id, companyId) => (dispatch) => new Promise(async (resolve, reject) => {
  const denyVoucherChangesFunction = functions.httpsCallable('denyVoucherChanges');

  denyVoucherChangesFunction({
    voucherId: id,
  }).then((result) => {
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
});

export const getUserData = () => dispatch => dispatch({
    type: GET_USER_DATA,
    payload: auth.currentUser
});

// *****************USERS**************************//

export const createUser = (email, password, name, company) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!email) return reject('no email');
  if (!password) return reject('no password');
  if (!name) return reject('no name');

  const createUserFunction = functions.httpsCallable('createUser');

  createUserFunction({
    email,
    password,
    name,
    company,
  }).then((result) => {
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })

});


export const createCompany = (email, password, name, roleInCompany, companyName, companyNumber, phoneNumber, website, logo) => (dispatch) => new Promise(async (resolve, reject) => {

  if (!name) return reject('no name');
  if (!roleInCompany) return reject('no role in company');
  if (!companyName) return reject('no Company Name');
  if (!companyNumber) return reject('no Company Number');
  if (!email) return reject('no email');
  if (!password) return reject('no password');

  const createCompanyFunction = functions.httpsCallable('createCompany');

  createCompanyFunction({
    email,
    password,
    name,
    roleInCompany,
    companyName,
    companyNumber,
    phoneNumber,
    website,
    logo,
  }).then((result) => {
    if (result && result.data && result.data.status === 'success' && result.data.data) {
      dispatch({
          type: UPDATE_USER,
          payload: result.data.data,
          key: result.data.data.uid,
          roleType: 'teamOwner',
      })

      return resolve(result);
    } else {
      if (result.data && result.data.error) {
        if (result.data.error.code === 'auth/email-already-exists'){
          return reject('Email address already used');
        } else if (result.data.error.code === 'auth/invalid-password'){
          return reject('Invalid password');
        } else {
          return reject('error registering account');
        }
      } else {
        return reject('error registering account');
      }
    }


  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
});

export const createAdmin = (email, password, name, role) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!email) return reject('no email');
  if (!password) return reject('no password');
  if (!name) return reject('no name');
  if (!role) return reject('no role');

  const createUserFunction = functions.httpsCallable('createAdmin');

  createUserFunction({
    email,
    password,
    name,
    role,
  }).then((result) => {
    if (result && result.data && result.data.status === 'success' && result.data.data) {
      dispatch({
        type: UPDATE_ADMIN,
        payload: result.data.data,
        key: result.data.data.uid,
      })

      return resolve(result);
    } else {
      if (result.data && result.data.error) {
        if (result.data.error.code === 'auth/email-already-exists'){
          return reject('Email address already used');
        } else if (result.data.error.code === 'auth/invalid-password'){
          return reject('Invalid password');
        } else {
          return reject('error registering account');
        }
      }
      return reject('error registering account');
    }


  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
});


export const deleteAdmin = (uid) => (dispatch) => new Promise(async (resolve, reject) => {

if (!uid) return reject('no user');
  const deleteUserFunction = functions.httpsCallable('deleteAdmin');

  deleteUserFunction({
    uid: uid.toString(),
  }).then((result) => {
    if (result && result.data && result.data.status === 'success') {
      dispatch({
        type: DELETE_ADMIN,
        key: uid,
      })
    }

    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
})


export const approvePendingUser = (uid, type, index, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
    const approvedRef = firestore.collection('users').doc(uid);

    console.log('approvePendingUser', uid, type, index);

    const approvedDoc = await approvedRef.get();

    if (!approvedDoc.exists) {
      return reject('no such user');
    } else {
      const user = approvedDoc.data();

      console.log('user', user);

      const currentUser = auth.currentUser;

      const adminActionRef = firestore.collection('adminActions');

      await adminActionRef.add({
        id: 'approvePendingUser',
        uid: uid,
        companyId: uid,
        type: type || null,
        time: firebase.firestore.FieldValue.serverTimestamp(),
        uid: currentUser.uid || null,
        name: adminName || null,
      });

      if (user && user.role === 'teamOwner') {
        const companyUsersRef = firestore.collection('users').where('company', '==', user.company);
        const ownerRef = firestore.collection('owners').doc(uid);

        const snapshot2 = await companyUsersRef.get();

        await ownerRef.update({
          status: 'approved',
          newUser: null,
        });

        if (!snapshot2.docs) {
          return resolve('success');
        } else {
          snapshot2.docs.map(async doc => {
            const userData = doc.data();
            const companyUserRef = firestore.collection('users').doc(doc.id);

            console.log('userData', userData);

            if (userData.role === 'teamOwner' && userData.newUser) {
              const adminStatisticsRef = firestore.collection('adminStatistics').doc('newCompaniesCount');
              await adminStatisticsRef.update({ count: firebase.firestore.FieldValue.increment(-1) });

              // newAccountAccessAccepted

              const sendCompanyAcceptedEmailFunction = functions.httpsCallable('sendCompanyAcceptedEmail');
              await sendCompanyAcceptedEmailFunction({
                uid: doc.id,
              })
            }

            await companyUserRef.update({
              status: 'approved',
              newUser: null,
            });

            dispatch({
              type: UPDATE_USER,
              payload: { status: 'approved', newUser: null },
              key: doc.id,
              roleType: userData.role,
              index,
            })
          })
          return resolve('success');
        }
      } else {
        console.log('test 1');
        await approvedRef.update({
          status: 'approved',
          newUser: null,
        });

        console.log('test 2');

      //  return resolve(dispatch({
      //      type: GET_COMPANYS_USERS,
      //      payload: users,
      //  }));

        dispatch({
          type: UPDATE_USER,
          payload: { status: 'approved', newUser: null },
          key: uid,
          roleType: type,
          index,
        });

        return resolve();
      }
  }
});


export const pauseActiveUser = (uid, type, index, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
    const approvedRef = firestore.collection('users').doc(uid);

    const approvedDoc = await approvedRef.get();

    if (!approvedDoc.exists) {
      return reject('no such user');
    } else {
      const user = approvedDoc.data();

      const currentUser = auth.currentUser;

      const adminActionRef = firestore.collection('adminActions');

      await adminActionRef.add({
        id: 'pauseActiveUser',
        uid: uid,
        companyId: uid,
        type: type || null,
        time: firebase.firestore.FieldValue.serverTimestamp(),
        uid: currentUser.uid || null,
        name: adminName || null,
      });

      if (user && user.role === 'teamOwner') {
        const companyUsersRef = firestore.collection('users').where('company', '==', user.company);
        const ownerRef = firestore.collection('owners').doc(uid);

        const snapshot2 = await companyUsersRef.get();

        await ownerRef.update({
          status: 'pending',
        });

        if (!snapshot2.docs) {
          return resolve('success');
        } else {
          snapshot2.docs.map(async doc => {
            const userData = doc.data();
            const companyUserRef = firestore.collection('users').doc(doc.id);

            await companyUserRef.update({
              status: 'pending',
            });

            dispatch({
                type: UPDATE_USER,
                payload: { status: 'pending' },
                key: doc.id,
                roleType: userData.role,
                index,
            })
          })

          return resolve('success');
        }
      } else {
        await approvedRef.update({
          status: 'pending',
        });

        dispatch({
          type: UPDATE_USER,
          payload: { status: 'pending' },
          key: uid,
          roleType: type,
          index,
        });

        return resolve();
      }
  }
});


export const changeTesterStatus = (uid, isTester, index, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('changeTesterStatus', uid, isTester, index);

  const userRef = firestore.collection('users').doc(uid);

  const companyRef = firestore.collection('companies').doc(uid);
  const ownerRef = firestore.collection('owners').doc(uid);

  const userDoc = await userRef.get();

  const currentUser = auth.currentUser;

  const adminActionRef = firestore.collection('adminActions');

  await adminActionRef.add({
    id: 'changeTesterStatus',
    companyId: uid,
    isTester: isTester || false,
    time: firebase.firestore.FieldValue.serverTimestamp(),
    uid: currentUser.uid || null,
    name: adminName || null,
  });

  if (isTester) {
    await companyRef.update({
      isTester: null,
    });

    await ownerRef.update({
      isTester: null,
    });

    dispatch({
      type: UPDATE_USER,
      payload: { isTester: null },
      key: uid,
      roleType: 'teamOwner',
      index,
    });

    return resolve();
  } else {
    await companyRef.update({
      isTester: true,
    });

    await ownerRef.update({
      isTester: true,
    });

    dispatch({
      type: UPDATE_USER,
      payload: { isTester: true },
      key: uid,
      roleType: 'teamOwner',
      index,
    });
  }
});



export const changeCompanyShowCommission = (uid, showCommission, index, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('changeCompanyShowCommission', uid, showCommission, index);

  const userRef = firestore.collection('users').doc(uid);
  const companyRef = firestore.collection('companies').doc(uid);
  const ownerRef = firestore.collection('owners').doc(uid);

  const userDoc = await userRef.get();


  const user = auth.currentUser;

  const adminActionRef = firestore.collection('adminActions');

  await adminActionRef.add({
    id: 'changeCompanyShowCommission',
    companyId: uid,
    showCommission: showCommission || false,
    time: firebase.firestore.FieldValue.serverTimestamp(),
    uid: user.uid || null,
    name: adminName || null,
  });

  if (showCommission) {
    await companyRef.update({
      showCommission: null,
    });

    await ownerRef.update({
      showCommission: null,
    });

    dispatch({
      type: UPDATE_USER,
      payload: { showCommission: false },
      key: uid,
      roleType: 'teamOwner',
      index,
    });

    return resolve();
  } else {
    await companyRef.update({
      showCommission: true,
    });

    await ownerRef.update({
      showCommission: true,
    });

    dispatch({
      type: UPDATE_USER,
      payload: { showCommission: true },
      key: uid,
      roleType: 'teamOwner',
      index,
    });
  }
});




export const changeUserRole = (uid, role) => (dispatch) => new Promise(async (resolve, reject) => {

  if (!uid) return reject('no user');
  if (!role) return reject('no role');

  const changeUserRoleFunction = functions.httpsCallable('changeUserRole');

  changeUserRoleFunction({
    uid,
    role,
    type: 'admin',
  }).then((result) => {
    if (result && result.data && result.data.status === 'success') {
      dispatch({
          type: UPDATE_USER,
          payload: { role: role },
          key: uid,
          roleType: role,
      })
    }

    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
});

export const deleteUser = (uid, type) => (dispatch) => new Promise(async (resolve, reject) => {
if (!uid) return reject('no user');
  let deleteUserFunction = functions.httpsCallable('deleteUser');

  if (type === 'teamOwner') {
    deleteUserFunction = functions.httpsCallable('deleteCompany');
  }

  deleteUserFunction({
    uid: uid.toString(),
    isClient: type === 'customer' ? true : false,
  }).then((result) => {

  //  dispatch({
  //    type: DELETE_USER,
  //    key: uid,
  //    roleType: type,
  //  })

    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  });
})


// *****************ROLES**************************//

export const getAllRoles = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
    const state = getState();

    const rolesRef = firestore.collection('roles');
    const snapshot = await rolesRef.get();

    if (snapshot.docs) {
      const roles = {};

      snapshot.docs.map(doc => {
        roles[doc.id] = doc.data();
      })

      dispatch({
          type: GET_ROLES,
          payload: roles,
      })

      return resolve(roles);
    }
});

export const getAllAdminRoles = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
    const state = getState();

    const rolesRef = firestore.collection('adminRoles');
    const snapshot = await rolesRef.get();

    if (snapshot.docs) {
      const roles = {};

      snapshot.docs.map(doc => {
        roles[doc.id] = doc.data();
      })

      dispatch({
          type: GET_ADMIN_ROLES,
          payload: roles,
      })

      return resolve(roles);
    }
});



export const getAdminActions = (customStartDate, customEndDate, companyFilter) => (dispatch, getState, adminName) => new Promise(async (resolve, reject) => {
//  const state = getState();

  console.log('getAdminActions', customStartDate, customEndDate, companyFilter);

  const adminActionsRef = firestore.collection('adminActions');
  let snapshot;

  let startDay, startMonth, startYear, endDay, endMonth, endYear;

  if (customStartDate && customEndDate) {
    const startDate = moment(customStartDate);
    const endDate = moment(customEndDate);

    const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
    const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

    startDay = startDateSplit[0];
    startMonth = startDateSplit[1];
    startYear = startDateSplit[2];

    endDay = endDateSplit[0];
    endMonth = endDateSplit[1];
    endYear = endDateSplit[2];
  }

  if (customStartDate && customEndDate && startDay && startMonth && endDay && endMonth && companyFilter && companyFilter !== 'all') {
    console.log('test 1', new Date(`${startYear}-${startMonth}-${startDay}`), companyFilter);

    snapshot = await adminActionsRef
      .where('companyId', '==', companyFilter)
      .where('time', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
      .where('time', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
      .orderBy('time', "desc")
      .limit(300).get();
  } else if (customStartDate && customEndDate && startDay && startMonth && endDay && endMonth) {
    console.log('test 2', new Date(`${startYear}-${startMonth}-${startDay}`));

    snapshot = await adminActionsRef
      .where('time', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
      .where('time', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
      .orderBy('time', "desc")
      .limit(300).get();
  } else if (companyFilter && companyFilter !== 'all') {
    console.log('test 3', companyFilter);

    snapshot = await adminActionsRef
      .where('companyId', '==', companyFilter)
      .orderBy('time', "desc")
      .limit(300).get();
  } else {
    console.log('test 4');

    snapshot = await adminActionsRef.orderBy('time', "desc").limit(300).get();
  }

  if (snapshot && snapshot.docs) {
    const adminActions = {};
    const adminActions2 = [];

    snapshot.docs.map(doc => {
      adminActions[doc.id] = doc.data();

      adminActions2.push({ ...doc.data(), dbId: doc.id });
    });

    console.log('adminActions2', adminActions2);

    adminActions2.sort((a, b) => {
      if (a.time.seconds < b.time.seconds) {
        return 1;
      }
      if (a.time.seconds > b.time.seconds) {
        return -1;
      }
      return 0;
    });

    console.log('adminActions2 sorted', adminActions2);

    dispatch({
      type: GET_ADMIN_ACTIONS,
      payload: adminActions2,
    })

    return resolve(adminActions);
  }
});

export const getAllCompaniesRoles = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
    const state = getState();

    const rolesRef = firestore.collection('companiesRoles');
    const snapshot = await rolesRef.get();

    if (snapshot.docs) {
    //  const roles = doc.data();
      const roles = {};

      snapshot.docs.map(doc => {
        roles[doc.id] = doc.data();
      })

      dispatch({
          type: GET_COMPANIES_ROLES,
          payload: roles,
      })

      return resolve(roles);
    }
});

export const getAllAdminsRoles = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
    const state = getState();

    const rolesRef = firestore.collection('adminsRoles');
    const snapshot = await rolesRef.get();

    if (snapshot.docs) {
      const roles = {};

      snapshot.docs.map(doc => {
        roles[doc.id] = doc.data();
      })

      dispatch({
          type: GET_ADMINS_ROLES,
          payload: roles,
      })

      return resolve(roles);
    }
});


export const createRole = (name, permissions, type, description) => (dispatch) => new Promise(async (resolve, reject) => {

  if (!name) return reject('no name');
  if (!permissions) return reject('no permissions');
  if (!description) return reject('no description');

  const createRoleFunction = functions.httpsCallable('createRole');

  createRoleFunction({
    name,
    permissions,
    type,
    description,
  }).then((result) => {
    if (result.data && result.data.status === 'success') {
      dispatch({
          type: UPDATE_ROLE,
          payload: result.data.data,
          key: result.data.id,
          roleType: type,
      })
    }

    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })

});

export const changeRolePermissions = (role, permissions, name, type, description) => (dispatch) => new Promise(async (resolve, reject) => {

  if (!role) return reject('no role');
  if (!permissions) return reject('no permissions');
  if (!description) return reject('no description');

  const changeRolePermissionsFunction = functions.httpsCallable('changeRolePermissions');

  changeRolePermissionsFunction({
    permissions,
    name,
    role,
    type,
    description,
  }).then((result) => {
    if (result.data && result.data.status === 'success') {
      dispatch({
          type: UPDATE_ROLE,
          payload: result.data.data,
          key: result.data.id,
          roleType: type,
      })
    }

    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })

});

export const deleteRole = (role, type) => (dispatch) => new Promise(async (resolve, reject) => {

  if (!role) return reject('no user');

  const deleteRoleFunction = functions.httpsCallable('deleteRole');

  deleteRoleFunction({
    role,
    type,
  }).then((result) => {
    dispatch({
        type: REMOVE_ROLE,
        key: role,
        roleType: type,
    })

    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  })
})


export const saveCardTemplate = (data, templateData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!data || !templateData) return reject('no data');

  const templateDataCopy = [...templateData];

  if (!data.category) return reject('no category');

  const categoryRef = firestore.collection('templatesCategory').doc(data.category.toString());
  const templateRef = firestore.collection('templates');
//  const doc = await pagesRef.get();

  var storageRef = storage.ref();

  await templateData.map(async (element, index) => {
    if (element.data) {
      let extension = '.jpeg';
      if (element.data.type === 'image/jpeg') {
        extension = '.jpeg';
      } else if (element.data.type === 'image/png') {
        extension = '.png';
      } else if (element.data.type === 'image/jpg') {
        extension = '.jpg';
      }

      let imageName = `${uuidv4()}-template${extension}`;
      let imageLink = `templates/${imageName}`;

      var imageRef = storageRef.child(`templates/${imageName}`);

      let elementCopy = { ...templateData[index], data: imageLink };

      templateDataCopy[index] = elementCopy;

      await imageRef.put(element.data).then(async (snapshot) => {
        await snapshot.ref.getDownloadURL().then(async (downloadURL) => {
          console.log('File available at', downloadURL);
        });
      });
    }
  });

  await templateRef.add({
    category: categoryRef,
    templateData: templateDataCopy,
  });

  return resolve();
});

export const deleteCardTemplate = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!id) return reject('no data');

  const templateRef = firestore.collection('templates').doc(id);

  await templateRef.delete();

  return resolve();
});

export const getCardQuote = () => (dispatch) => new Promise(async (resolve, reject) => {
  const getCardQuoteFunction = functions.httpsCallable('getCardQuote');

  return getCardQuoteFunction({
  }).then(async (result) => {
    if (result.data && result.data.status === 'success') {
      return resolve(dispatch({
          type: CARD_QUOTE,
          payload: result.data.data || {},
          productData: result.data.productData || {},
      }));
    } else {
      return resolve();
    }
  })
});

export const getDeliveryDescription = () => (dispatch) => new Promise(async (resolve, reject) => {
  const descriptionRef = firestore.collection('adminSettings').doc('deliveryDescriptions');

  const snapshot = await descriptionRef.get();

  let descriptions = {};

  if (snapshot.exists) {
    descriptions = snapshot.data();
  }

  dispatch({
      type: CARD_QUOTE_DESCRIPTIONS,
      payload: { descriptions },
  })

  return resolve({ descriptions });
});


export const updateDeliveryDescription = (data) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!data) {
    return resolve();
  }

  const descriptionRef = firestore.collection('adminSettings').doc('deliveryDescriptions');

  await descriptionRef.set(data, { merge: true });

  const snapshot = await descriptionRef.get();

  let descriptions = {};

  if (snapshot.exists) {
    descriptions = snapshot.data();
  }

  dispatch({
      type: CARD_QUOTE_DESCRIPTIONS,
      payload: { descriptions },
  })

  return resolve({ descriptions });
});


export const getGreetingCards = () => (dispatch) => new Promise(async (resolve, reject) => {
  const categoryRef = firestore.collection('templatesCategory');
  const templatesRef = firestore.collection('templates');

  const snapshot1 = await categoryRef.get();
  const snapshot2 = await templatesRef.get();

  var storageRef = storage.ref();

  let categories = {};
  let templates = [];

  if (snapshot1.docs) {
    const length = snapshot1.docs.length;

    snapshot1.docs.map((doc, index) => {
      categories[doc.id] = { ...doc.data(), id: doc.id };
    });
  }

  let promises = [];

  if (snapshot2.docs) {
    const length = snapshot2.docs.length;

    await snapshot2.docs.map((doc, index) => {
      const data = doc.data();
      const id = doc.id;

      const templateData = [ ...data.templateData ];

      templateData.map(async(temp, index) => {
        if (temp.data) {
          let imageRef = storageRef.child(temp.data);

          promises.push(imageRef.getDownloadURL().then(async (downloadURL) => {
            templateData[index] = { ...templateData[index], dataUrl: downloadURL };
          }));
        }
      })

      const newData = { ...data, id, templateData: templateData };

      templates.push(newData);
    });
  }

  return Promise.all(promises).then(() => {
    dispatch({
        type: CARD_TEMPLATES,
        payload: { categories, templates },
    })

    return resolve({ categories, templates });
  })
});

export const getGreetingCardsCategories = () => (dispatch) => new Promise(async (resolve, reject) => {
  const categoryRef = firestore.collection('templatesCategory');

  const snapshot1 = await categoryRef.get();

  let categories = {};

  if (snapshot1.docs) {
    const length = snapshot1.docs.length;

    snapshot1.docs.map((doc, index) => {
      categories[doc.id] = { ...doc.data(), id: doc.id };
    });
  }

  dispatch({
      type: CARD_TEMPLATES_CATEGORIES,
      payload: { categories },
  })

  return resolve({ categories });
});


export const changeCampaignStatus = (changeId, status) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!changeId || !status) return reject('no data');

  const changeCampaignStatusFunction = functions.httpsCallable('changeCampaignStatus');

  changeCampaignStatusFunction({
    changeId,
    status,
  }).then((result) => {
    console.log('result', result);
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  });

//  return resolve();
});

export const changeCampaignStatusCompany = (companyId, status) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!companyId || !status) return reject('no data');

  const changeCampaignStatusCompanyFunction = functions.httpsCallable('changeCampaignStatusCompany');

  changeCampaignStatusCompanyFunction({
    changeId: companyId,
    status,
  }).then((result) => {
    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  });

//  return resolve();
});

export const changeCustomerStatus = (userId, status, type, index) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!userId) return reject('no data');

  const changeCustomerStatusFunction = functions.httpsCallable('changeCustomerStatus');

  changeCustomerStatusFunction({
    changeId: userId,
    status,
    type,
  }).then((result) => {
    dispatch({
        type: UPDATE_APP_CUSTOMER,
      //  payload: { status, type },
        index,
        userId,
        changeType: type,
        status,
    })

    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  });

  return resolve();
});


export const changeCustomerTester = (userId, status, index) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!userId) return reject('no data');

  const changeCustomerTesterFunction = functions.httpsCallable('changeCustomerTester');

  changeCustomerTesterFunction({
    changeId: userId,
    status,
  //  type,
  }).then((result) => {
    dispatch({
        type: UPDATE_APP_CUSTOMER,
        index,
        userId,
        changeType: 'tester',
        status,
    })

    return resolve(result);
  }).catch((err) => {
    console.log(err);
    return reject(err);
  });

  return resolve();
});


export const getAppCustomerData = (id, timeFrame, customStartDate, customEndDate) => (dispatch) => new Promise(async (resolve, reject) => {
  if (!id) return reject('no data');

  let params = {
    id,
  }

    let dateLength = 1;
    let startDate;
    let endDate;
  //  const dateNow = moment();

  //  let nowDay;
  //  let nowMonth;
  //  let nowYear;

  //  const dateNowSplit = dateNow.format("DD-MM-YYYY").split("-");

  //  nowDay = dateNowSplit[0];
  //  nowMonth = dateNowSplit[1];
  //  nowYear = dateNowSplit[2];

    if (timeFrame === 'custom') {
      startDate = moment(customStartDate);
      endDate = moment(customEndDate);
    } else if (timeFrame === 'all') {
      let allStartDate = new Date(2021, 1, 1);
      startDate = moment(allStartDate);

      endDate = moment().add(1, 'days');
    } else if (timeFrame === null) {
      let allStartDate = new Date(2021, 1, 1);
      startDate = moment(allStartDate);

      endDate = moment().add(1, 'days');
    } else {
      if (timeFrame === '2weeks') {
        dateLength = 14;
      }

      startDate = moment().subtract(dateLength, 'days');

      endDate = moment().add(1, 'days');
    }

    const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
    const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

    const startDay = startDateSplit[0];
    const startMonth = startDateSplit[1];
    const startYear = startDateSplit[2];

    const endDay = endDateSplit[0];
    const endMonth = endDateSplit[1];
    const endYear = endDateSplit[2];

    params = {
      id,
      startDate: `${startYear}-${startMonth}-${startDay}`,
      endDate: `${endYear}-${endMonth}-${parseInt(endDay)}`,
    }

  const getAppCustomerDataFunction = functions.httpsCallable('getAppCustomerData');

  getAppCustomerDataFunction(params).then(async (result) => {
    if (result && result.data && result.data) {
      const { data: stripeData, clientData } = result.data;

      const paymentsRef = await firestore.collection('ordersFinished')
        .where('customerId', "==", id)
        .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
        .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`)).get();

        if (!paymentsRef.docs) {
          return resolve();
        }

        let data = [];

        paymentsRef.docs.map(doc => {
          const docData = doc.data();
          const id = doc.id;

          data.push({ ...docData, paymentDateSeconds: docData.paymentDate.seconds * 1000 });
        });

      return resolve({ clientData, stripeData: data });
    } else {
      return resolve();
    }
  });
});



export const getUnverifiedCount = () => (dispatch) => new Promise(async (resolve, reject) => {
  const dataRef = firestore.collection('owners').where('hasVerifiedEmail', '==', false);

  console.log('getUnverifiedCount');

  const snapshot = await dataRef.get();

  if (snapshot.docs) {
    const length = snapshot.docs.length;

    console.log('getUnverifiedCount length', length);
    return resolve(length);
  } else {
    console.log('getUnverifiedCount no data');
    return resolve(0);
  }
});

export const getCompaniesComplianceData = () => (dispatch) => new Promise(async (resolve, reject) => {
  const dataRef = firestore.collection('complianceData');

  const snapshot = await dataRef.get();
  let complianceData = {};

  if (snapshot.docs) {
    const complianceDataArr = snapshot.docs;

    complianceDataArr.map((doc, index) => {
      const data = doc.data();

      console.log('data', data);
      complianceData[doc.id] = { ...data };
    });

    console.log('getCompaniesComplianceData data', complianceData);

    dispatch({
      type: GET_COMPANY_COMPLIANCE_DATA,
      payload: complianceData,
    });

    
    return resolve(complianceData);
  } else {
    console.log('getCompaniesComplianceData no data');
    dispatch({
      type: GET_COMPANY_COMPLIANCE_DATA,
      payload: null,
    });

    return resolve(null);
  }
});

export const getCompaniesActiveAPIData = (id) => (dispatch) => new Promise(async (resolve, reject) => {
  const companyAPIKeyRef = firestore.collection('companiesApiActiveType');

  const snapshot = await companyAPIKeyRef.get();
  let companiesApiActiveType = {};

  if (snapshot.docs) {
    snapshot.docs.map((doc, index) => {
      const data = doc.data();
      companiesApiActiveType[doc.id] = { ...data };
    });
  }

  console.log('companiesApiActiveType', companiesApiActiveType);

  return resolve(dispatch({
    type: UPDATE_COMPANIES_ACTIVE_API_DATA,
    payload: companiesApiActiveType,
  }));
});


export const getDataFiltered = (dataId, orderId, order, filterValue, includeUnverified) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('getDataFiltered', dataId, orderId, order, filterValue, includeUnverified);
  let collectionId;
  let dispatchType;

  if (dataId === 'companies') {
    collectionId = 'owners';
    dispatchType = GET_COMPANY_OWNERS;
  } else if (dataId === 'admins') {
    collectionId = 'admins';
    dispatchType = GET_ADMINS;
  } else if (dataId === 'customers') {
    collectionId = 'appClients';
    dispatchType = GET_APP_CUSTOMERS;
  }

  let limit = 5;

  if (dataId === 'companies') {
    limit = 9999;
  } else if (dataId === 'customers') {
    limit = 20;
  }

  let dataRef;
  let pendingDataRef;

  if (dataId === 'companies' && includeUnverified) {
    if (orderId) {
      if (order === 'ascending') {
        if (filterValue) {
          dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId).limit(limit);
        } else {
          dataRef = firestore.collection(collectionId).orderBy(orderId).limit(limit);
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId, "desc").limit(limit);
        } else {
          dataRef = firestore.collection(collectionId).orderBy(orderId, "desc").limit(limit);
        }
      }
    } else {
      if (filterValue) {
        dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).limit(limit);
      } else {
        dataRef = firestore.collection(collectionId).where('status', '==', 'approved').orderBy("dateCreated", "desc").limit(limit);
        pendingDataRef = firestore.collection(collectionId).where('status', '==', 'pending').orderBy("dateCreated", "desc");
      }
    }
  } else if (dataId === 'companies' || dataId === 'admins') {
    if (orderId) {
      if (order === 'ascending') {
        if (filterValue) {
          dataRef = firestore.collection(collectionId).where('hasVerifiedEmail', '==', true).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId).limit(limit);
        } else {
          dataRef = firestore.collection(collectionId).where('hasVerifiedEmail', '==', true).orderBy(orderId).limit(limit);
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection(collectionId).where('hasVerifiedEmail', '==', true).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId, "desc").limit(limit);
        } else {
          dataRef = firestore.collection(collectionId).where('hasVerifiedEmail', '==', true).orderBy(orderId, "desc").limit(limit);
        }
      }
    } else {
      if (filterValue) {
        dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).limit(limit);
      } else {
        if (dataId === 'companies') {
          dataRef = firestore.collection(collectionId).where('hasVerifiedEmail', '==', true).where('status', '==', 'approved').orderBy("dateCreated", "desc").limit(limit);
          pendingDataRef = firestore.collection(collectionId).where('hasVerifiedEmail', '==', true).where('status', '==', 'pending').orderBy("dateCreated", "desc");
        } else {
          dataRef = firestore.collection(collectionId).limit(limit);
        }
      }
    }
  } else {
    if (orderId) {
      if (order === 'ascending') {
        if (filterValue) {
          dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId).limit(limit);
        } else {
          dataRef = firestore.collection(collectionId).orderBy(orderId).limit(limit);
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId, "desc").limit(limit);
        } else {
          dataRef = firestore.collection(collectionId).orderBy(orderId, "desc").limit(limit);
        }
      }
    } else {
      if (filterValue) {
        dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).limit(limit);
      } else {
        if (dataId === 'companies') {
          dataRef = firestore.collection(collectionId).where('status', '==', 'approved').orderBy("dateCreated", "desc").limit(limit);
          pendingDataRef = firestore.collection(collectionId).where('status', '==', 'pending').orderBy("dateCreated", "desc");
        } else {
          dataRef = firestore.collection(collectionId).limit(limit);
        }
      }
    }
  }

    const snapshot = await dataRef.get();

    let pendingSnapshot;
    let pendingUsersArr = [];
    if (pendingDataRef) {
      pendingSnapshot = await pendingDataRef.get();

      if (!pendingSnapshot.docs) {
      //  console.log('No pending companies!');
      } else {
        pendingSnapshot.docs.map((doc, index) => {
          const data = doc.data();
          pendingUsersArr.push({ ...data, lastActiveSort: Date.now() + index });
        });

          pendingUsersArr.sort((a, b) => {
            if ( b.dateCreated < a.dateCreated ){
              return -1;
            }
            if ( b.dateCreated > a.dateCreated ){
              return 1;
            }
            return 0;
          });
      }
    }

    if (snapshot.docs) {
      const usersArr = [];
      const companyOwnersObj = {};
      let lastVisible;

      if (snapshot.docs) {
        const length = snapshot.docs.length;

        console.log('usersArr length', length);

        snapshot.docs.map((doc, index) => {
          const data = doc.data();
          if (dataId !== 'customers' && !data.hasVerifiedEmail) {
            if (order === 'ascending') {
              usersArr.push({ ...data, lastActiveSort: index });
            } else {
              usersArr.push({ ...data, lastActiveSort: Date.now() + index });
            }
          } else {
            usersArr.push({ ...data, lastActiveSort: data.lastActive });
          }
          companyOwnersObj[doc.id] = data;

          if (index === length - 1) {
            lastVisible = doc;
          }
        });

        let sortId = orderId;

        if (orderId === 'lastActive') {
          sortId = 'lastActiveSort';
        }

        console.log('sortId', sortId);

        if (order === 'ascending') {
          usersArr.sort((a, b) => {
            if (a[sortId] < b[sortId] ){
              return -1;
            }
            if (a[sortId] > b[sortId] ){
              return 1;
            }
            return 0;
          });
        } else {
          usersArr.sort((a, b) => {
            if (b[sortId] < a[sortId] ){
              return -1;
            }
            if (b[sortId] > a[sortId] ){
              return 1;
            }
            return 0;
          });
        }

        console.log('usersArr', usersArr);
        console.log('pendingUsersArr', pendingUsersArr);

        if (pendingUsersArr && pendingUsersArr.length) {
          if (dataId === 'companies') {
            dispatch({
              type: dispatchType,
              payload: { data: [...pendingUsersArr, ...usersArr], lastVisible },
              companyOwnersObj,
            });
          } else {
            dispatch({
              type: dispatchType,
              payload: { data: [...pendingUsersArr, ...usersArr], lastVisible },
            });
          }
          
        } else {
          if (dataId === 'companies') {
            dispatch({
              type: dispatchType,
              payload: { data: usersArr, lastVisible },
              companyOwnersObj,
            });
          } else {
            dispatch({
              type: dispatchType,
              payload: { data: usersArr, lastVisible },
            });
          }
          
        }
      }

      return resolve('success');
    }
});


export const getDataFilteredNextPage = (dataId, orderId, order, filterValue, limit) => (dispatch, getState) => new Promise(async (resolve, reject) => {
    let collectionId;
    let dispatchType;
    let lastEntryId;
    if (dataId === 'companies') {
      collectionId = 'owners';
      dispatchType = GET_MORE_COMPANY_OWNERS;
      lastEntryId = 'lastCompanyOwner';
    } else if (dataId === 'admins') {
      collectionId = 'admins';
      dispatchType = GET_MORE_ADMINS;
      lastEntryId = 'lastAdmin';
    } else if (dataId === 'customers') {
      collectionId = 'appClients';
      dispatchType = GET_MORE_APP_CUSTOMERS;
      lastEntryId = 'lastAppCustomer';
    }

    let dataRef;

    const { admin } = getState();
  const lastEntry = admin[lastEntryId];

  console.log('lastEntry', lastEntry);

  if (lastEntry && dataId !== 'companies') {
    if (limit === 'all') {
      if (orderId) {
        if (order === 'ascending') {
          if (filterValue) {
            dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId).startAfter(lastEntry);
          } else {
            dataRef = firestore.collection(collectionId).orderBy(orderId).startAfter(lastEntry);
          }
        } else {
          if (filterValue) {
            dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId, "desc").startAfter(lastEntry);
          } else {
            dataRef = firestore.collection(collectionId).orderBy(orderId, "desc").startAfter(lastEntry);
          }
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).startAfter(lastEntry);
        } else {
          if (dataId === 'companies') {
            dataRef = firestore.collection(collectionId).where('hasVerifiedEmail', '==', true).where('status', '==', 'approved').orderBy("dateCreated", "desc").startAfter(lastEntry);
          } else {
            dataRef = firestore.collection(collectionId).startAfter(lastEntry);
          }
        }
      }
    } else {
      if (orderId) {
        if (order === 'ascending') {
          if (filterValue) {
            dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId).startAfter(lastEntry).limit(limit);
          } else {
            dataRef = firestore.collection(collectionId).orderBy(orderId).startAfter(lastEntry).limit(limit);
          }
        } else {
          if (filterValue) {
            dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId, "desc").startAfter(lastEntry).limit(limit);
          } else {
            dataRef = firestore.collection(collectionId).orderBy(orderId, "desc").startAfter(lastEntry).limit(limit);
          }
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection(collectionId).where('keyWords', 'array-contains', filterValue.toLowerCase()).startAfter(lastEntry).limit(limit);
        } else {
          if (dataId === 'companies') {
            dataRef = firestore.collection(collectionId).where('hasVerifiedEmail', '==', true).where('status', '==', 'approved').orderBy("dateCreated", "desc").startAfter(lastEntry).limit(limit);
          } else {
            dataRef = firestore.collection(collectionId).startAfter(lastEntry).limit(limit);
          }
        }
      }
    }

    const snapshot = await dataRef.get();

    if (snapshot.docs) {
      const dataArr = [];
      let lastVisible;

      if (snapshot.docs) {
        const length = snapshot.docs.length;

        snapshot.docs.map((doc, index) => {
          dataArr.push(doc.data());
          if (index === length - 1) {
            lastVisible = doc;
          }
        });

        let sortId = orderId;

        if (orderId === 'lastActive') {
          sortId = 'lastActiveSort';
        }

        console.log('sortId', sortId);

        if (order === 'ascending') {
          dataArr.sort((a, b) => {
            if (a[sortId] < b[sortId] ){
              return -1;
            }
            if (a[sortId] > b[sortId] ){
              return 1;
            }
            return 0;
          });
        } else {
          dataArr.sort((a, b) => {
            if (b[sortId] < a[sortId] ){
              return -1;
            }
            if (b[sortId] > a[sortId] ){
              return 1;
            }
            return 0;
          });
        }

        dispatch({
            type: dispatchType,
            payload: { data: dataArr, lastVisible },
        })
      }

      return resolve('success');
    }
  }
});
























export const getAppCustomersFiltered = (dataId, orderId, order, filterValue, includeUnverified, includeTesters) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('getAppCustomersFiltered', dataId, orderId, order, filterValue, includeUnverified, includeTesters);

  let limit = 20;

  if (includeTesters) {
    limit = 100;
  }

  let dataRef;
  let pendingDataRef;

  if (includeTesters) {
    dataRef = firestore.collection('appClients').where("tester", '==', true).limit(limit);
  } else if (orderId) {
    if (order === 'ascending') {
      if (filterValue) {
        dataRef = firestore.collection('appClients').where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId).limit(limit);
      } else {
        dataRef = firestore.collection('appClients').orderBy(orderId).limit(limit);
      }
    } else {
      if (filterValue) {
        dataRef = firestore.collection('appClients').where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId, "desc").limit(limit);
      } else {
        dataRef = firestore.collection('appClients').orderBy(orderId, "desc").limit(limit);
      }
    }
  } else {
    if (filterValue) {
      dataRef = firestore.collection('appClients').where('keyWords', 'array-contains', filterValue.toLowerCase()).limit(limit);
    } else {
      dataRef = firestore.collection('appClients').limit(limit);
    }
  }

  const snapshot = await dataRef.get();

  let pendingSnapshot;
  let pendingUsersArr = [];
  if (pendingDataRef) {
    pendingSnapshot = await pendingDataRef.get();

    if (!pendingSnapshot.docs) {
      //  console.log('No pending companies!');
    } else {
      pendingSnapshot.docs.map((doc, index) => {
        const data = doc.data();
        pendingUsersArr.push({ ...data, lastActiveSort: Date.now() + index });
      });

      pendingUsersArr.sort((a, b) => {
        if (b.dateCreated < a.dateCreated) {
          return -1;
        }
        if (b.dateCreated > a.dateCreated) {
          return 1;
        }
        return 0;
      });
    }
  }

  if (snapshot.docs) {
    const usersArr = [];
    const companyOwnersObj = {};
    let lastVisible;

    if (snapshot.docs) {
      const length = snapshot.docs.length;

      console.log('usersArr length', length);

      snapshot.docs.map((doc, index) => {
        const data = doc.data();

        usersArr.push({ ...data, lastActiveSort: data.lastActive });
        companyOwnersObj[doc.id] = data;

        if (index === length - 1) {
          lastVisible = doc;
        }
      });

      let sortId = orderId;

      if (orderId === 'lastActive') {
        sortId = 'lastActiveSort';
      }

      console.log('sortId', sortId);

      if (order === 'ascending') {
        usersArr.sort((a, b) => {
          if (a[sortId] < b[sortId]) {
            return -1;
          }
          if (a[sortId] > b[sortId]) {
            return 1;
          }
          return 0;
        });
      } else {
        usersArr.sort((a, b) => {
          if (b[sortId] < a[sortId]) {
            return -1;
          }
          if (b[sortId] > a[sortId]) {
            return 1;
          }
          return 0;
        });
      }

      console.log('usersArr', usersArr);
      console.log('pendingUsersArr', pendingUsersArr);

      if (pendingUsersArr && pendingUsersArr.length) {
        dispatch({
          type: GET_APP_CUSTOMERS,
          payload: { data: [...pendingUsersArr, ...usersArr], lastVisible },
        });
      } else {
        dispatch({
          type: GET_APP_CUSTOMERS,
          payload: { data: usersArr, lastVisible },
        });
      }
    }

    return resolve('success');
  }
});


export const getAppCustomersFilteredNextPage = (dataId, orderId, order, filterValue, limit) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  let dataRef;

  const { admin } = getState();
  const lastEntry = admin['lastAppCustomer'];

  console.log('lastEntry', lastEntry);

  if (lastEntry) {
    if (limit === 'all') {
      if (orderId) {
        if (order === 'ascending') {
          if (filterValue) {
            dataRef = firestore.collection('appClients').where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId).startAfter(lastEntry);
          } else {
            dataRef = firestore.collection('appClients').orderBy(orderId).startAfter(lastEntry);
          }
        } else {
          if (filterValue) {
            dataRef = firestore.collection('appClients').where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId, "desc").startAfter(lastEntry);
          } else {
            dataRef = firestore.collection('appClients').orderBy(orderId, "desc").startAfter(lastEntry);
          }
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection('appClients').where('keyWords', 'array-contains', filterValue.toLowerCase()).startAfter(lastEntry);
        } else {
          dataRef = firestore.collection('appClients').startAfter(lastEntry);
        }
      }
    } else {
      if (orderId) {
        if (order === 'ascending') {
          if (filterValue) {
            dataRef = firestore.collection('appClients').where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId).startAfter(lastEntry).limit(limit);
          } else {
            dataRef = firestore.collection('appClients').orderBy(orderId).startAfter(lastEntry).limit(limit);
          }
        } else {
          if (filterValue) {
            dataRef = firestore.collection('appClients').where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId, "desc").startAfter(lastEntry).limit(limit);
          } else {
            dataRef = firestore.collection('appClients').orderBy(orderId, "desc").startAfter(lastEntry).limit(limit);
          }
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection('appClients').where('keyWords', 'array-contains', filterValue.toLowerCase()).startAfter(lastEntry).limit(limit);
        } else {
          dataRef = firestore.collection('appClients').startAfter(lastEntry).limit(limit);
        }
      }
    }

    const snapshot = await dataRef.get();

    if (snapshot.docs) {
      const dataArr = [];
      let lastVisible;

      if (snapshot.docs) {
        const length = snapshot.docs.length;

        snapshot.docs.map((doc, index) => {
          dataArr.push(doc.data());
          if (index === length - 1) {
            lastVisible = doc;
          }
        });

        let sortId = orderId;

        if (orderId === 'lastActive') {
          sortId = 'lastActiveSort';
        }

        console.log('sortId', sortId);

        if (order === 'ascending') {
          dataArr.sort((a, b) => {
            if (a[sortId] < b[sortId]) {
              return -1;
            }
            if (a[sortId] > b[sortId]) {
              return 1;
            }
            return 0;
          });
        } else {
          dataArr.sort((a, b) => {
            if (b[sortId] < a[sortId]) {
              return -1;
            }
            if (b[sortId] > a[sortId]) {
              return 1;
            }
            return 0;
          });
        }

        dispatch({
          type: GET_MORE_APP_CUSTOMERS,
          payload: { data: dataArr, lastVisible },
        })
      }

      return resolve('success');
    }
  }
});


























export const getAdminsFiltered = (orderId, order, filterValue) => (dispatch) => new Promise(async (resolve, reject) => {
  let dataRef;

    if (orderId) {
      if (order === 'ascending') {
        if (filterValue) {
          dataRef = firestore.collection('admins').where('hasVerifiedEmail', '==', true).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId);
        } else {
          dataRef = firestore.collection('admins').where('hasVerifiedEmail', '==', true).orderBy(orderId);
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection('admins').where('hasVerifiedEmail', '==', true).where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId, "desc");
        } else {
          dataRef = firestore.collection('admins').where('hasVerifiedEmail', '==', true).orderBy(orderId, "desc");
        }
      }
    } else {
      if (filterValue) {
        dataRef = firestore.collection('admins').where('keyWords', 'array-contains', filterValue.toLowerCase());
      } else {
        dataRef = firestore.collection('admins');
      }
    }

    const snapshot = await dataRef.get();

    if (snapshot.docs) {
      const usersArr = [];
      let lastVisible;

      if (snapshot.docs) {
        const length = snapshot.docs.length;

        snapshot.docs.map((doc, index) => {
          usersArr.push(doc.data());
          if (index === length - 1) {
            lastVisible = doc;
          }
        });

        if (order === 'ascending') {
          usersArr.sort((a, b) => {
            if ( a[orderId] < b[orderId] ){
              return -1;
            }
            if ( a[orderId] > b[orderId] ){
              return 1;
            }
            return 0;
          });
        } else {
          usersArr.sort((a, b) => {
            if ( b[orderId] < a[orderId] ){
              return -1;
            }
            if ( b[orderId] > a[orderId] ){
              return 1;
            }
            return 0;
          });
        }

        dispatch({
          type: GET_ADMINS,
          payload: { data: usersArr, lastVisible },
        })
      }

      return resolve('success');
    }
});


export const sortData = (typeFilter, sortColumn, sortType) => (dispatch) => new Promise(async (resolve, reject) => {
  return resolve(dispatch({
    type: SORT_ADMIN_DATA,
    typeFilter,
    sortColumn,
    sortType,
  }));
});


export const getCampaignData = (campaignId, index) => (dispatch) => new Promise(async (resolve, reject) => {
  let dataRef = firestore.collection('vouchers').doc(campaignId);

  const snapshot = await dataRef.get();

  let campaign;

  if (!snapshot.exists) {
    return resolve('No such documents');
  } else {
    campaign = snapshot.data();
  }

    let companyData;
    let companyId;
    let companyPendingChanges;
    let voucherPendingChanges;
    let companyCompliancePendingChanges;
    let companyComplianceBeforeChanges;
    let complianceData;

    let category1;
    let category2;

    if (campaign && campaign.company) {
      await campaign.company.get().then((res) => {
        companyId = res.id;
        companyData = res.data() || {};
      }).catch(err => {
        companyId = null;
        companyData = {};
      });

      if (companyData && companyData.category1) {
        let categoryRef = firestore.collection('categories').doc(companyData.category1);

        await categoryRef.get().then((res2) => {
          category1 = res2.data();
        }).catch((err2) => {
          category1 = null;
        });
      }

      if (companyData && companyData.category2) {
        let categoryRef = firestore.collection('categories').doc(companyData.category2);

        await categoryRef.get().then((res2) => {
          category2 = res2.data();
        }).catch((err2) => {
          category2 = null;
        });
      }

      if (campaign.hasPendingChanges && companyId) {
        let companyPendingRef = firestore.collection('companyPending').doc(companyId);

        await companyPendingRef.get().then((res2) => {
          companyPendingChanges = res2.data();
        }).catch((err2) => {
          companyPendingChanges = null;
        });

        let voucherPendingRef = firestore.collection('vouchersPending').doc(campaignId);

        await voucherPendingRef.get().then((res2) => {
          voucherPendingChanges = res2.data();
        }).catch((err2) => {
          voucherPendingChanges = null;
        });

        let companyCompliancePendingRef = firestore.collection('companyCompliancePending').doc(companyId);

        await companyCompliancePendingRef.get().then((res2) => {
          companyCompliancePendingChanges = res2.data();
        }).catch((err2) => {
          companyCompliancePendingChanges = null;
        });

        await firestore.collection('complianceDataBefore').doc(companyId).get().then((res2) => {
          companyComplianceBeforeChanges = res2.data();
        }).catch((err2) => {
          companyComplianceBeforeChanges = null;
        });

        await firestore.collection('complianceData').doc(companyId).get().then((res2) => {
          complianceData = res2.data();
        }).catch((err2) => {
          complianceData = null;
        });

      }
    }

    campaign = {
      ...campaign,
      id: campaignId,
      category1: category1,
      category2: category2,
      company: companyData,
      companyPendingChanges: companyPendingChanges,
      voucherPendingChanges: voucherPendingChanges,
      companyCompliancePendingChanges: companyCompliancePendingChanges,
      companyComplianceBeforeChanges: companyComplianceBeforeChanges,
      complianceData: complianceData,
      companyId,
    };

    dispatch({
      type: UPDATE_CAMPAIGN,
      changeType: 'all',
      payload: campaign,
      index,
    });

    return resolve('success');
});

export const getCampaignsFiltered = (orderId, order, filterValue, timeFrame, customStartDate, customEndDate) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('getCampaignsFiltered', { orderId, order, filterValue, timeFrame, customStartDate, customEndDate });
  let dataRef;
  let dataRef2;

  let dateLength = 1;
  let startDate;
  let endDate;

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);

    endDate = moment(customEndDate);

    if (startDate > endDate) {
      return resolve('wrong dates');
    }
  } else if (timeFrame === 'all') {
    let allStartDate = new Date(2021, 1, 1);
    startDate = moment(allStartDate);

    endDate = moment().add(1100, 'years');
  } else {
    if (timeFrame === '4weeks') {
      dateLength = 30;
    } else if (timeFrame === '1day') {
      dateLength = 1;
    } else if (timeFrame === '1week') {
      dateLength = 7;
    } else if (timeFrame === '2weeks') {
      dateLength = 14;
    } else if (timeFrame === '6months') {
      dateLength = 183;
    }

    startDate = moment().subtract(dateLength, 'days');

    endDate = moment().add(1100, 'years');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  const startDay = startDateSplit[0];
  const startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];

  console.log('endDate', endDate);

    if (orderId) {
      if (order === 'ascending') {
        if (filterValue) {
          dataRef = firestore.collection('vouchers')
          .where('keyWords', 'array-contains', filterValue.toLowerCase())
          .where('endDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('endDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .orderBy('endDate');
        } else {
          dataRef = firestore.collection('vouchers')
          .where('hasPendingChanges', '==', null)
          .where('endDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('endDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .orderBy('endDate');

          dataRef2 = firestore.collection('vouchers')
          .where('hasPendingChanges', '==', true)
          .where('endDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('endDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .orderBy('endDate');
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection('vouchers')
          .where('keyWords', 'array-contains', filterValue.toLowerCase())
          .where('endDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('endDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .orderBy('endDate')
        } else {
          dataRef = firestore.collection('vouchers')
          .where('hasPendingChanges', '==', null)
          .where('endDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('endDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .orderBy('endDate');

          dataRef2 = firestore.collection('vouchers')
          .where('hasPendingChanges', '==', true)
          .where('endDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('endDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .orderBy('endDate');
        }
      }
    } else {
      if (filterValue) {
        dataRef = firestore.collection('vouchers')
        .where('keyWords', 'array-contains', filterValue.toLowerCase())
        .where('endDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
        .where('endDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
      //  .limit(15);
      } else {
        dataRef = firestore.collection('vouchers')
        .where('hasPendingChanges', '==', null)
        .where('endDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
        .where('endDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
        .orderBy('endDate')
      //  .limit(15);

        dataRef2 = firestore.collection('vouchers')
        .where('hasPendingChanges', '==', true)
        .where('endDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
        .where('endDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
        .orderBy('endDate');
      }
    }

    let categoriesRef = firestore.collection('categories');

    const categoriesSnapshot = await categoriesRef.get();

    const snapshot = await dataRef.get();

    let snapshot2;
    if (dataRef2) {
      snapshot2 = await dataRef2.get();
    }

    let categoriesData = {};

    if (categoriesSnapshot.docs) {

      categoriesSnapshot.docs.map((doc, index) => {
        categoriesData[doc.id] = doc.data();
        return null;
      })
    }

    console.log('categoriesData', categoriesData);

    if (!snapshot.docs) {
      return resolve('No such documents');
    }
      let lastVisible;

      const campaigns = [];
      const pendingCampaigns = [];
      const promises = [];

      const campaignsObj = {};

      const length = snapshot.docs.length;

      if (snapshot2 && snapshot2.docs) {
        await snapshot2.docs.map((doc, index) => promises.push(new Promise(async (resolve2, reject2) => {
          const data = doc.data() || {};

          let companyData;
          let companyId;
          let companyPendingChanges;
          let voucherPendingChanges;
          let companyCompliancePendingChanges;
          let companyComplianceBeforeChanges;
          let complianceData;

          let category1;
          let category2;

          if (data.company) {
            await data.company.get().then((res) => {
              companyId = res.id;
              companyData = res.data() || {};
            }).catch(err => {
              companyId = null;
              companyData = {};
            });

            console.log('category companyData', companyData);

            if (companyData && companyData.category1) {
              let categoryRef = firestore.collection('categories').doc(companyData.category1);

              await categoryRef.get().then((res2) => {
                category1 = res2.data();
              }).catch((err2) => {
                category1 = null;
              });
            }

            if (companyData && companyData.category2) {
              let categoryRef = firestore.collection('categories').doc(companyData.category2);

              await categoryRef.get().then((res2) => {
                category2 = res2.data();
              }).catch((err2) => {
                category2 = null;
              });
            }

            if (data.hasPendingChanges && companyId) {
              await firestore.collection('companyPending').doc(companyId).get().then((res2) => {
                companyPendingChanges = res2.data();
              }).catch((err2) => {
                companyPendingChanges = null;
              });

              await firestore.collection('vouchersPending').doc(doc.id).get().then((res2) => {
                voucherPendingChanges = res2.data();
              }).catch((err2) => {
                voucherPendingChanges = null;
              });

              await firestore.collection('companyCompliancePending').doc(companyId).get().then((res2) => {
                companyCompliancePendingChanges = res2.data();

                if (Object.keys(companyCompliancePendingChanges).length === 0) {
                  companyCompliancePendingChanges = null;
                }
              }).catch((err2) => {
                companyCompliancePendingChanges = null;
              });

              await firestore.collection('complianceDataBefore').doc(companyId).get().then((res2) => {
                companyComplianceBeforeChanges = res2.data();

                if (Object.keys(companyComplianceBeforeChanges).length === 0) {
                  companyComplianceBeforeChanges = null;
                }
              }).catch((err2) => {
                companyComplianceBeforeChanges = null;
              });

              await firestore.collection('complianceData').doc(companyId).get().then((res2) => {
                complianceData = res2.data();

                if (Object.keys(complianceData).length === 0) {
                  complianceData = null;
                }
              }).catch((err2) => {
                complianceData = null;
              });

            }
          }

          pendingCampaigns.push({
            ...data,
            id: doc.id,
            category1: category1,
            category2: category2,
            company: companyData,
            companyPendingChanges: companyPendingChanges,
            voucherPendingChanges: voucherPendingChanges,
            companyCompliancePendingChanges: companyCompliancePendingChanges,
            companyComplianceBeforeChanges: companyComplianceBeforeChanges,
            complianceData: complianceData,
            companyId,
            applicationFeeValue: data.applicationFeeValue || 0,
            salesValue: data.salesValue || 0,
            salesCount: data.salesCount || 0,
            customers: data.customers || 0,
            redemptionCount: data.redemptionCount || 0,
            redemptionsValue: data.redemptionsValue || 0,
            giftedCount: data.giftedCount || 0,
            giftedValue: data.giftedValue || 0,
            cancelledCount: data.cancelledCount || 0,
            cancelledValue: data.cancelledValue || 0,
          });

          return resolve2();
        })));
      }

      await snapshot.docs.map((doc, index) => promises.push(new Promise(async (resolve2, reject2) => {
        const data = doc.data() || {};

        if (index === length - 1) {
          lastVisible = doc;
        }

        let companyData;
        let companyId;
        let companyPendingChanges;
        let voucherPendingChanges;
        let companyCompliancePendingChanges;
        let companyComplianceBeforeChanges;
        let complianceData;

        let category1;
        let category2;

        let promises2 = [];

        if (data.company) {
          await data.company.get().then((res) => {
            companyId = res.id;
            companyData = res.data() || {};
          }).catch(err => {
            companyId = null;
            companyData = {};
          });

          console.log('category companyData', companyData);

          console.log('companyData.category1', companyData.category1, categoriesData)

          if (companyData && companyData.category1) {
            category1 = categoriesData[companyData.category1];

          /*  let categoryRef = firestore.collection('categories').doc(companyData.category1);

            await categoryRef.get().then((res2) => {
              category1 = res2.data();
            }).catch((err2) => {
              category1 = null;
            }); */
          }

          if (companyData && companyData.category2) {
            category2 = categoriesData[companyData.category2];

          /*  let categoryRef = firestore.collection('categories').doc(companyData.category2);

            await categoryRef.get().then((res2) => {
              category2 = res2.data();
            }).catch((err2) => {
              category2 = null;
            }); */
          }

          console.log('category1', category1);

          if (data.hasPendingChanges && companyId) {
            await firestore.collection('companyPending').doc(companyId).get().then((res2) => {
              companyPendingChanges = res2.data();
            }).catch((err2) => {
              companyPendingChanges = null;
            });

            await firestore.collection('vouchersPending').doc(doc.id).get().then((res2) => {
              voucherPendingChanges = res2.data();
            }).catch((err2) => {
              voucherPendingChanges = null;
            });

            await firestore.collection('companyCompliancePending').doc(companyId).get().then((res2) => {
              companyCompliancePendingChanges = res2.data();
            }).catch((err2) => {
              companyCompliancePendingChanges = null;
            });

            await firestore.collection('complianceDataBefore').doc(companyId).get().then((res2) => {
              companyComplianceBeforeChanges = res2.data();
            }).catch((err2) => {
              companyComplianceBeforeChanges = null;
            });

            await firestore.collection('complianceData').doc(companyId).get().then((res2) => {
              complianceData = res2.data();
            }).catch((err2) => {
              complianceData = null;
            });

          }
        }

        Promise.all(promises2).then(() => {
          campaigns.push({
            ...data,
            id: doc.id,
            category1: category1,
            category2: category2,
            company: companyData,
            companyPendingChanges: companyPendingChanges,
            voucherPendingChanges: voucherPendingChanges,
            companyCompliancePendingChanges: companyCompliancePendingChanges,
            companyComplianceBeforeChanges: companyComplianceBeforeChanges,
            complianceData: complianceData,
            companyId,
            applicationFeeValue: data.applicationFeeValue || 0,
            salesValue: data.salesValue || 0,
            salesCount: data.salesCount || 0,
            customers: data.customers || 0,
            redemptionCount: data.redemptionCount || 0,
            redemptionsValue: data.redemptionsValue || 0,
            giftedCount: data.giftedCount || 0,
            giftedValue: data.giftedValue || 0,
            cancelledCount: data.cancelledCount || 0,
            cancelledValue: data.cancelledValue || 0,
          });

          campaignsObj[doc.id] = {
            ...data,
            id: doc.id,
            category1: category1,
            category2: category2,
            company: companyData,
            companyPendingChanges: companyPendingChanges,
            voucherPendingChanges: voucherPendingChanges,
            companyCompliancePendingChanges: companyCompliancePendingChanges,
            companyComplianceBeforeChanges: companyComplianceBeforeChanges,
            complianceData: complianceData,
            companyId,
            applicationFeeValue: data.applicationFeeValue || 0,
            salesValue: data.salesValue || 0,
            salesCount: data.salesCount || 0,
            customers: data.customers || 0,
            redemptionCount: data.redemptionCount || 0,
            redemptionsValue: data.redemptionsValue || 0,
            giftedCount: data.giftedCount || 0,
            giftedValue: data.giftedValue || 0,
            cancelledCount: data.cancelledCount || 0,
            cancelledValue: data.cancelledValue || 0,
          }

          return resolve2();
        }).catch(err => {
          console.log('campaignsObj err');
          console.log(err);
        });
      })));

      return Promise.all(promises).then(() => {
        if (orderId) {
          if (order === 'ascending') {
            campaigns.sort((a, b) => {
              if ( a[orderId] < b[orderId] ){
                return -1;
              }
              if ( a[orderId] > b[orderId] ){
                return 1;
              }
              return 0;
            });
          } else {
            campaigns.sort((a, b) => {
              if ( b[orderId] < a[orderId] ){
                return -1;
              }
              if ( b[orderId] > a[orderId] ){
                return 1;
              }
              return 0;
            });
          }
        } else {
          if (order === 'ascending') {
            campaigns.sort((a, b) => {
              if ( a.endDate < b.endDate ){
                return -1;
              }
              if ( a.endDate > b.endDate ){
                return 1;
              }
              return 0;
            });
          } else {
            campaigns.sort((a, b) => {
              if ( b.endDate < a.endDate ){
                return -1;
              }
              if ( b.endDate > a.endDate ){
                return 1;
              }
              return 0;
            });
          }
        }

        console.log('GET_CAMPAIGNS campaigns', campaigns);
        console.log('GET_CAMPAIGNS campaignsObj', campaignsObj);

        dispatch({
          type: GET_CAMPAIGNS,
          payload: { data: [...pendingCampaigns, ...campaigns], lastVisible, pendingCampaigns },
          campaignsObj,
        })

        return resolve('success');
      });
});


// deploy this
export const dismissAllComplianceDataChanges = (companyId, adminName) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('dismissCompliance dismissAllComplianceDataChanges');
  const complianceRef = firestore.collection('companyCompliancePending').doc(companyId);
  const complianceBeforeRef = firestore.collection('complianceDataBefore').doc(companyId);

  const compliancePendingSnapshot = await complianceRef.get();

  let compliancePendingData;

  if (compliancePendingSnapshot.exists) {
    compliancePendingData = compliancePendingSnapshot.data();
  }

  await complianceRef.delete();
  await complianceBeforeRef.delete();

  if (!compliancePendingData) {
    console.log('no compliance pending, dont continue');
    return resolve('success');
  }

  const companyRef = firestore.collection('companyPending').doc(companyId);
  const companyDataRef = firestore.collection('companies').doc(companyId);
  const vouchersRef = firestore.collection('vouchers').where('company', '==', companyDataRef);

  const snapshot2 = await companyRef.get();
  const vouchersSnapshot = await vouchersRef.get();

  let companyData;

  if (snapshot2.exists) {
    companyData = snapshot2.data();
  }

  if (vouchersSnapshot.docs) {
    vouchersSnapshot.docs.map(async (doc) => {
      const data = doc.data();

      // has no company pending
      if (companyData) {
        console.log('dismissCompliance has company pending');
        return null;
      }

      // is live campaign
      if (data.status < 4) {
        console.log('dismissCompliance is live campaign', doc.id);
        const voucherPendingRef = firestore.collection('vouchersPending').doc(doc.id);

        const voucherPendingRefSnapshot = await voucherPendingRef.get();

        let voucherData = voucherPendingRefSnapshot.data();

        console.log('dismissCompliance voucherData', voucherData);

        // has no voucher pending
        if (voucherPendingRefSnapshot.exists) {
          console.log('dismissCompliance exists');
          return null;
        } else {
          const voucherRef = firestore.collection('vouchers').doc(doc.id);

          const vocherSnapshot = await voucherRef.get();

          let voucherData = vocherSnapshot.data();

          console.log('dismissCompliance update hasPendingChanges');

        //  if (voucherData && voucherData.hasPendingChanges) {
            await voucherRef.update({ hasPendingChanges: null });

            const pendingVouchersCountRef = firestore.collection('adminStatistics').doc('pendingVouchersCount');

            await pendingVouchersCountRef.update({ count: firebase.firestore.FieldValue.increment(-1) });
        //  }
        }
      }

    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'dismissAllComplianceDataChanges',
      companyId: companyId,
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      name: adminName || null,
    });

    return resolve('success');
  }

});

// deploy this
export const dismissComplianceDataChange = (companyId, voucherId, dataId) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('dismissComplianceDataChange');
  const complianceRef = firestore.collection('companyCompliancePending').doc(companyId);

  const complianceBeforeRef = firestore.collection('complianceDataBefore').doc(companyId);

  const snapshot = await complianceRef.get();

  if (snapshot.exists) {
    const complianceData = snapshot.data() || {};

    if (Object.keys(complianceData).length <= 1) {
      await complianceRef.delete();
      await complianceBeforeRef.delete();

      const companyRef = firestore.collection('companyPending').doc(companyId);
      const companyDataRef = firestore.collection('companies').doc(companyId);
      const vouchersRef = firestore.collection('vouchers').where('company', '==', companyDataRef);

      const snapshot2 = await companyRef.get();
      const vouchersSnapshot = await vouchersRef.get();

      let companyData;

      if (snapshot2.exists) {
        companyData = snapshot2.data();
      }

      let promises = [];

      if (vouchersSnapshot.docs) {
        vouchersSnapshot.docs.map(async (doc) => {
          const data = doc.data();

          // has no company pending
          if (companyData) {
            return null;
          }

          // is live campaign
          if (data.status === 8 || data.status < 4) {
            const voucherRef2 = firestore.collection('vouchersPending').doc(doc.id);
            const snapshot3 = await voucherRef2.get();

            // has no voucher pending
            if (snapshot3.exists) {
              return null;
            } else {
              const voucherRef3 = firestore.collection('vouchers').doc(doc.id);

              console.log('update hasPendingChanges')

              promises.push(voucherRef3.update({ hasPendingChanges: null }));

              console.log('decrease pendingVouchersCount');

              const pendingVouchersCountRef = firestore.collection('adminStatistics').doc('pendingVouchersCount');

              promises.push(pendingVouchersCountRef.update({ count: firebase.firestore.FieldValue.increment(-1) }));
            }
          }
        });

        return Promise.all(promises).then(() => {
          console.log('resolve dismissComplianceDataChange');
          return resolve('success');
        }).catch(err => {
          console.log('resolve dismissComplianceDataChange err', err);
          return resolve('success');
        })

      //  return resolve('success');
      } else {
        return resolve('success');
      }
    } else {
      await complianceRef.set({
        [dataId]: firebase.firestore.FieldValue.delete(),
      }, { merge: true });

      await complianceBeforeRef.set({
        [dataId]: firebase.firestore.FieldValue.delete(),
      }, { merge: true });

      return resolve('success');
    }
  }
});

export const getCampaignsFilteredNextPage = (orderId, order, filterValue, limit, timeFrame, customStartDate, customEndDate) => (dispatch, getState) => new Promise(async (resolve, reject) => {
    let dataRef;

    let dateLength = 1;
    let startDate;
    let endDate;

    if (timeFrame === 'custom') {
      startDate = moment(customStartDate);

      endDate = moment(customEndDate);
    } else if (timeFrame === 'all') {
      let allStartDate = new Date(2021, 1, 1);
      startDate = moment(allStartDate);

      endDate = moment().add(1, 'days');
    } else {
      if (timeFrame === '4weeks') {
        dateLength = 30;
      } else if (timeFrame === '1day') {
        dateLength = 1;
      } else if (timeFrame === '1week') {
        dateLength = 7;
      } else if (timeFrame === '2weeks') {
        dateLength = 14;
      } else if (timeFrame === '6months') {
        dateLength = 183;
      }

      startDate = moment().subtract(dateLength, 'days');

      endDate = moment().add(1, 'days');
    }

    const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
    const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

    const startDay = startDateSplit[0];
    const startMonth = startDateSplit[1];
    const startYear = startDateSplit[2];

    const endDay = endDateSplit[0];
    const endMonth = endDateSplit[1];
    const endYear = endDateSplit[2];

    const { admin } = getState();
    const { lastVoucherCampaign } = admin;

  if (lastVoucherCampaign && !orderId) {
    if (limit === 'all') {
      if (orderId) {
        if (order === 'ascending') {
          if (filterValue) {
            dataRef = firestore.collection('vouchers')
            .where('keyWords', 'array-contains', filterValue.toLowerCase())
            .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
            .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
            .startAfter(lastVoucherCampaign);
          } else {
            dataRef = firestore.collection('vouchers')
            .where('hasPendingChanges', '==', null)
            .where('campaignDates', 'array-contains-any', [`${startYear}-${startMonth}-${startDay}`, `${endYear}-${endMonth}-${endDay}`])
            .orderBy(orderId)
            .startAfter(lastVoucherCampaign);
          }
        } else {
          if (filterValue) {
            dataRef = firestore.collection('vouchers')
            .where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId, "desc")
            .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
            .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
            .startAfter(lastVoucherCampaign);
          } else {
            dataRef = firestore.collection('vouchers')
            .where('hasPendingChanges', '==', null)
            .where('campaignDates', 'array-contains-any', [`${startYear}-${startMonth}-${startDay}`, `${endYear}-${endMonth}-${endDay}`])
            .orderBy(orderId, "desc")
            .startAfter(lastVoucherCampaign);
          }
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection('vouchers')
          .where('keyWords', 'array-contains', filterValue.toLowerCase())
          .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .startAfter(lastVoucherCampaign);
        } else {
          dataRef = firestore.collection('vouchers')
          .where('hasPendingChanges', '==', null)
          .where('endDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .startAfter(lastVoucherCampaign);
        }
      }
    } else {
      if (orderId) {
        if (order === 'ascending') {
          if (filterValue) {
            dataRef = firestore.collection('vouchers')
            .where('keyWords', 'array-contains', filterValue.toLowerCase())
            .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
            .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
            .startAfter(lastVoucherCampaign)
          } else {
            dataRef = firestore.collection('vouchers')
            .where('hasPendingChanges', '==', null)
            .where('campaignDates', 'array-contains-any', [`${startYear}-${startMonth}-${startDay}`, `${endYear}-${endMonth}-${endDay}`])
            .orderBy(orderId)
            .startAfter(lastVoucherCampaign)
            .limit(limit);
          }
        } else {
          if (filterValue) {
            dataRef = firestore.collection('vouchers')
            .where('keyWords', 'array-contains', filterValue.toLowerCase())
            .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
            .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
            .startAfter(lastVoucherCampaign)
          } else {
            dataRef = firestore.collection('vouchers')
            .where('hasPendingChanges', '==', null)
            .where('campaignDates', 'array-contains-any', [`${startYear}-${startMonth}-${startDay}`, `${endYear}-${endMonth}-${endDay}`])
            .orderBy(orderId, "desc")
            .startAfter(lastVoucherCampaign)
            .limit(limit);
          }
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection('vouchers')
          .where('keyWords', 'array-contains', filterValue.toLowerCase())
          .startAfter(lastVoucherCampaign)
          .limit(limit);
        } else {
          dataRef = firestore.collection('vouchers')
          .where('hasPendingChanges', '==', null)
          .where('endDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .startAfter(lastVoucherCampaign)
          .limit(limit);
        }
      }
    }

    const snapshot = await dataRef.get();

    if (snapshot.docs) {
      let lastVisible;

      const campaigns = [];
      const promises = [];

      const length = snapshot.docs.length;

      await snapshot.docs.map((doc, index) => promises.push(new Promise(async (resolve2, reject2) => {
        const data = doc.data() || {};

        if (index === length - 1) {
          lastVisible = doc;
        }

        let companyData;
        let category1;
        let category2;
        let companyId;
        let companyPendingChanges;
        let voucherPendingChanges;
        let companyCompliancePendingChanges;
        let companyComplianceBeforeChanges;
        let complianceData;

        let images = [];
        let promises2 = [];

        if (data.company) {
          await data.company.get().then((res) => {
            companyId = res.id;
            companyData = res.data();
          }).catch(err => {
            companyId = null;
            companyData = null;
          });

          if (companyData && companyData.category1) {
            let categoryRef = firestore.collection('categories').doc(companyData.category1);

            await categoryRef.get().then((res2) => {
              category1 = res2.data();
            }).catch((err2) => {
              category1 = null;
            });
          }

          if (companyData && companyData.category2) {
            let categoryRef = firestore.collection('categories').doc(companyData.category2);

            await categoryRef.get().then((res2) => {
              category2 = res2.data();
            }).catch((err2) => {
              category2 = null;
            });
          }

          if (data.hasPendingChanges && companyId) {
            await firestore.collection('companyPending').doc(companyId).get().then((res2) => {
              companyPendingChanges = res2.data();
            }).catch((err2) => {
              companyPendingChanges = null;
            });

            await firestore.collection('vouchersPending').doc(doc.id).get().then((res2) => {
              voucherPendingChanges = res2.data();
            }).catch((err2) => {
              voucherPendingChanges = null;
            });

            await firestore.collection('companyCompliancePending').doc(companyId).get().then((res2) => {
              companyCompliancePendingChanges = res2.data();
            }).catch((err2) => {
              companyCompliancePendingChanges = null;
            });

            await firestore.collection('complianceDataBefore').doc(companyId).get().then((res2) => {
              companyComplianceBeforeChanges = res2.data();
            }).catch((err2) => {
              companyComplianceBeforeChanges = null;
            });

            await firestore.collection('complianceData').doc(companyId).get().then((res2) => {
              complianceData = res2.data();
            }).catch((err2) => {
              complianceData = null;
            });
          }
        }

        Promise.all(promises2).then(() => {
          campaigns.push({
            ...data,
            id: doc.id,
            category1,
            category2,
            company: companyData,
            companyPendingChanges: companyPendingChanges,
            voucherPendingChanges: voucherPendingChanges,
            companyCompliancePendingChanges: companyCompliancePendingChanges,
            companyComplianceBeforeChanges: companyComplianceBeforeChanges,
            complianceData: complianceData,
            companyId,
            images,
            salesValue: data.salesValue || 0,
            salesCount: data.salesCount || 0,
            customers: data.customers || 0,
            redemptionCount: data.redemptionCount || 0,
            redemptionsValue: data.redemptionsValue || 0,
            giftedCount: data.giftedCount || 0,
            giftedValue: data.giftedValue || 0,
          });

          return resolve2();
        });
      })));

      return Promise.all(promises).then(() => {
        if (order === 'ascending') {
          campaigns.sort((a, b) => {
            if ( a[orderId] < b[orderId] ){
              return -1;
            }
            if ( a[orderId] > b[orderId] ){
              return 1;
            }
            return 0;
          });
        } else {
          campaigns.sort((a, b) => {
            if ( b[orderId] < a[orderId] ){
              return -1;
            }
            if ( b[orderId] > a[orderId] ){
              return 1;
            }
            return 0;
          });
        }

        dispatch({
          type: GET_MORE_CAMPAIGNS,
          payload: { data: campaigns, lastVisible },
        })

        return resolve('success');
      });

      return resolve('success');
    }
  }
});



export const getCampaignCompaniesFiltered = (orderId, order, filterValue, timeFrame, customStartDate, customEndDate) => (dispatch) => new Promise(async (resolve, reject) => {
  let dataRef;

  let dateLength = 1;
  let startDate;
  let endDate;

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);

    endDate = moment(customEndDate);
  } else if (timeFrame === 'all') {
    let allStartDate = new Date(2021, 1, 1);
    startDate = moment(allStartDate);

    endDate = moment().add(1, 'days');
  } else {
    if (timeFrame === '4weeks') {
      dateLength = 30;
    } else if (timeFrame === '1day') {
      dateLength = 1;
    } else if (timeFrame === '1week') {
      dateLength = 7;
    } else if (timeFrame === '2weeks') {
      dateLength = 14;
    } else if (timeFrame === '6months') {
      dateLength = 183;
    }

    startDate = moment().subtract(dateLength, 'days');

    endDate = moment().add(1, 'days');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  const startDay = startDateSplit[0];
  const startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];

    if (orderId) {
      if (order === 'ascending') {
        if (filterValue) {
          dataRef = firestore.collection('owners')
          .where('keyWords', 'array-contains', filterValue.toLowerCase())
          .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .orderBy('startDate')
        } else {
          dataRef = firestore.collection('owners')
          .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .orderBy('startDate')
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection('owners')
          .where('keyWords', 'array-contains', filterValue.toLowerCase())
          .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
        } else {
          dataRef = firestore.collection('owners')
          .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .orderBy('startDate')
        }
      }
    } else {
      if (filterValue) {
        dataRef = firestore.collection('owners')
        .where('keyWords', 'array-contains', filterValue.toLowerCase())
        .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
        .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
        .limit(5);
      } else {
        dataRef = firestore.collection('owners')
        .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
        .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
        .limit(5);
      }
    }

    const snapshot = await dataRef.get();

    if (snapshot.docs) {
      let lastVisible;

      const campaigns = [];
      const promises = [];

      const length = snapshot.docs.length;

      await snapshot.docs.map((doc, index) => promises.push(new Promise(async (resolve2, reject2) => {
        const data = doc.data() || {};

        if (index === length - 1) {
          lastVisible = doc;
        }

        let companyData;
        let companyId;
        let companyPendingChanges;
        let voucherPendingChanges;

        let images = [];
        let promises2 = [];

        let category1;
        let category2;

        if (data.category1) {
          let categoryRef = firestore.collection('categories').doc(data.category1);

          await categoryRef.get().then((res2) => {
            category1 = res2.data();
          }).catch((err2) => {
            category1 = null;
          });
        }

        if (data.category2) {
          let categoryRef = firestore.collection('categories').doc(data.category2);

          await categoryRef.get().then((res2) => {
            category2 = res2.data();
          }).catch((err2) => {
            category2 = null;
          });
        }

        if (data.hasPendingChanges && companyId) {
          let companyPendingRef = firestore.collection('companyPending').doc(doc.id);

          await companyPendingRef.get().then((res2) => {
            companyPendingChanges = res2.data();
          }).catch((err2) => {
            companyPendingChanges = null;
          });

        }

        if (data.images) {
          promises2.push(new Promise(async (resolve3, reject3) => data.images.map(async (image) => {
            var storageRef = storage.ref(image.file);

            await storageRef.getDownloadURL().then((url) => {
              images.push(url);

              return resolve3();
            }).catch((error) => {
              return resolve3();
            });
          })));
        }

        Promise.all(promises2).then(() => {
          campaigns.push({
            ...data,
            id: doc.id,
            category1,
            category2,
            company: companyData,
            name: data.companyName,
            companyPendingChanges: companyPendingChanges,
            voucherPendingChanges: voucherPendingChanges,
            companyId,
            images,
            salesValue: data.salesValue || 0,
            salesCount: data.salesCount || 0,
            customers: data.customers || 0,
            redemptionCount: data.redemptionCount || 0,
            redemptionsValue: data.redemptionsValue || 0,
            giftedCount: data.giftedCount || 0,
            giftedValue: data.giftedValue || 0,
            liveVouchersCount: data.liveVouchersCount || 0,
          });

          return resolve2();
        });
      })));

      return Promise.all(promises).then(() => {
        if (order === 'ascending') {
          campaigns.sort((a, b) => {
            if ( a[orderId] < b[orderId] ){
              return -1;
            }
            if ( a[orderId] > b[orderId] ){
              return 1;
            }
            return 0;
          });
        } else {
          campaigns.sort((a, b) => {
            if ( b[orderId] < a[orderId] ){
              return -1;
            }
            if ( b[orderId] > a[orderId] ){
              return 1;
            }
            return 0;
          });
        }

        dispatch({
          type: GET_CAMPAIGNS_BY_COMPANY,
          payload: { data: campaigns, lastVisible },
        })

        return resolve('success');
      });
    }
});


export const getCampaignCompaniesFilteredNextPage = (orderId, order, filterValue, limit, timeFrame, customStartDate, customEndDate) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  let dataRef;

  let dateLength = 1;
  let startDate;
  let endDate;

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);

    endDate = moment(customEndDate);
  } else if (timeFrame === 'all') {
    let allStartDate = new Date(2021, 1, 1);
    startDate = moment(allStartDate);

    endDate = moment().add(1, 'days');
  } else {
    if (timeFrame === '4weeks') {
      dateLength = 30;
    } else if (timeFrame === '1day') {
      dateLength = 1;
    } else if (timeFrame === '1week') {
      dateLength = 7;
    } else if (timeFrame === '2weeks') {
      dateLength = 14;
    } else if (timeFrame === '6months') {
      dateLength = 183;
    }

    startDate = moment().subtract(dateLength, 'days');

    endDate = moment().add(1, 'days');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  const startDay = startDateSplit[0];
  const startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];

  const { admin } = getState();
  const { lastVoucherCampaignCompany } = admin;

  if (lastVoucherCampaignCompany && !orderId) {
    if (limit === 'all') {
      if (orderId) {
        if (order === 'ascending') {
          if (filterValue) {
            dataRef = firestore.collection('owners')
            .where('keyWords', 'array-contains', filterValue.toLowerCase())
            .orderBy(orderId)
            .startAfter(lastVoucherCampaignCompany)
          } else {
            dataRef = firestore.collection('owners')
            .where('campaignDates', 'array-contains-any', [`${startYear}-${startMonth}-${startDay}`, `${endYear}-${endMonth}-${endDay}`])
            .orderBy(orderId)
            .startAfter(lastVoucherCampaignCompany)
          }
        } else {
          if (filterValue) {
            dataRef = firestore.collection('owners')
            .where('keyWords', 'array-contains', filterValue.toLowerCase()).orderBy(orderId, "desc")
            .startAfter(lastVoucherCampaignCompany)
          } else {
            dataRef = firestore.collection('owners')
            .where('campaignDates', 'array-contains-any', [`${startYear}-${startMonth}-${startDay}`, `${endYear}-${endMonth}-${endDay}`])
            .orderBy(orderId, "desc")
            .startAfter(lastVoucherCampaignCompany)
          }
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection('owners')
          .where('keyWords', 'array-contains', filterValue.toLowerCase())
          .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .startAfter(lastVoucherCampaignCompany)
        } else {
          dataRef = firestore.collection('owners')
          .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .startAfter(lastVoucherCampaignCompany)
        }
      }
    } else {
      if (orderId) {
        if (order === 'ascending') {
          if (filterValue) {
            dataRef = firestore.collection('owners')
            .where('keyWords', 'array-contains', filterValue.toLowerCase())
            .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
            .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
            .orderBy('startDate')
            .startAfter(lastVoucherCampaignCompany)
          } else {
            dataRef = firestore.collection('owners')
            .where('campaignDates', 'array-contains-any', [`${startYear}-${startMonth}-${startDay}`, `${endYear}-${endMonth}-${endDay}`])
            .orderBy(orderId)
            .startAfter(lastVoucherCampaignCompany)
            .limit(limit);
          }
        } else {
          if (filterValue) {
            dataRef = firestore.collection('owners')
            .where('keyWords', 'array-contains', filterValue.toLowerCase())
            .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
            .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
            .orderBy('startDate')
            .startAfter(lastVoucherCampaignCompany)
          } else {
            dataRef = firestore.collection('owners')
            .where('campaignDates', 'array-contains-any', [`${startYear}-${startMonth}-${startDay}`, `${endYear}-${endMonth}-${endDay}`])
            .orderBy(orderId, "desc")
            .startAfter(lastVoucherCampaignCompany)
            .limit(limit);
          }
        }
      } else {
        if (filterValue) {
          dataRef = firestore.collection('owners')
          .where('keyWords', 'array-contains', filterValue.toLowerCase())
          .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .startAfter(lastVoucherCampaignCompany)
          .limit(limit);
        } else {
          dataRef = firestore.collection('owners')
          .where('startDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('startDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .startAfter(lastVoucherCampaignCompany)
          .limit(limit);
        }
      }
    }

    const snapshot = await dataRef.get();

    if (snapshot.docs) {
      let lastVisible;

      const campaigns = [];
      const promises = [];

      const length = snapshot.docs.length;

      await snapshot.docs.map((doc, index) => promises.push(new Promise(async (resolve2, reject2) => {
        const data = doc.data() || {};

        if (index === length - 1) {
          lastVisible = doc;
        }

        let companyData;
        let companyId;
        let companyPendingChanges;

        let images = [];
        let promises2 = [];

        let category1;
        let category2;

        if (data.category1) {
          let categoryRef = firestore.collection('categories').doc(data.category1);

          await categoryRef.get().then((res2) => {
            category1 = res2.data();
          }).catch((err2) => {
            category1 = null;
          });
        }

        if (data.category2) {
          let categoryRef = firestore.collection('categories').doc(data.category2);

          await categoryRef.get().then((res2) => {
            category2 = res2.data();
          }).catch((err2) => {
            category2 = null;
          });
        }

        if (data.hasPendingChanges && companyId) {
          let companyPendingRef = firestore.collection('companyPending').doc(doc.id);

          await companyPendingRef.get().then((res2) => {
            companyPendingChanges = res2.data();
          }).catch((err2) => {
            companyPendingChanges = null;
          });
        }

        if (data.images) {
          promises2.push(new Promise(async (resolve3, reject3) => data.images.map(async (image) => {
            var storageRef = storage.ref(image.file);

            await storageRef.getDownloadURL().then((url) => {
              images.push(url);

              return resolve3();
            }).catch((error) => {
              return resolve3();
            });
          })));
        }

        Promise.all(promises2).then(() => {
          campaigns.push({
            ...data,
            id: doc.id,
            category1,
            category2,
            company: companyData,
            companyPendingChanges: companyPendingChanges,
            companyId,
            images,
            salesValue: data.salesValue || 0,
            salesCount: data.salesCount || 0,
            customers: data.customers || 0,
            redemptionCount: data.redemptionCount || 0,
            redemptionsValue: data.redemptionsValue || 0,
            giftedCount: data.giftedCount || 0,
            giftedValue: data.giftedValue || 0,
            liveVouchersCount: data.liveVouchersCount || 0,
          });

          return resolve2();
        });
      })));

      return Promise.all(promises).then(() => {
        if (order === 'ascending') {
          campaigns.sort((a, b) => {
            if ( a[orderId] < b[orderId] ){
              return -1;
            }
            if ( a[orderId] > b[orderId] ){
              return 1;
            }
            return 0;
          });
        } else {
          campaigns.sort((a, b) => {
            if ( b[orderId] < a[orderId] ){
              return -1;
            }
            if ( b[orderId] > a[orderId] ){
              return 1;
            }
            return 0;
          });
        }

        dispatch({
          type: GET_MORE_CAMPAIGNS_BY_COMPANY,
          payload: { data: campaigns, lastVisible },
        })

        return resolve('success');
      });
    }
  }
});



const updateAdminDashboardData = (baseObject, data) => {
  console.log('updateAdminDashboardData salesPerCompany', baseObject, data);
  baseObject.salesCount = baseObject.salesCount + 1;
  baseObject.amountCaptured = baseObject.amountCaptured + (data.amount_captured / 100);
  baseObject.amountRefunded = baseObject.amountRefunded + (data.amountRefunded / 100);

  if (data.commission) {
    baseObject.commission = baseObject.commission + (data.commission / 100);
  }

  if (data && data.companyId) {
    if (baseObject.salesPerCompany[data.companyId]) {
      baseObject.salesPerCompany[data.companyId] = baseObject.salesPerCompany[data.companyId] + data.faceValue;
      if (!baseObject.companyNames[data.companyId]) {
        baseObject.companyNames[data.companyId] = data.companyName;
      }
    } else {
      baseObject.companyNames[data.companyId] = data.companyName;
      baseObject.salesPerCompany[data.companyId] = data.faceValue;
    }

    if (baseObject.salesCountPerCompany[data.companyId]) {
      baseObject.salesCountPerCompany[data.companyId] = baseObject.salesCountPerCompany[data.companyId] + 1;
    } else {
      baseObject.salesCountPerCompany[data.companyId] = 1;
    }

    if (data.gift) {
      if (baseObject.giftSalesPerCompany[data.companyId]) {
        baseObject.giftSalesPerCompany[data.companyId] = baseObject.giftSalesPerCompany[data.companyId] + 1;
      } else {
        baseObject.giftSalesPerCompany[data.companyId] = 1;
      }
    }
  }

  /*
  if (data && data.voucherId) {
    if (baseObject.salesPerCampaign[data.voucherId]) {
      baseObject.salesPerCampaign[data.voucherId] = baseObject.salesPerCampaign[data.voucherId] + data.faceValue;
      if (!baseObject.campaignNames[data.voucherId]) {
        baseObject.campaignNames[data.voucherId] = data.voucherName;
      }
    } else {
      baseObject.campaignNames[data.voucherId] = data.voucherName;
      baseObject.salesPerCampaign[data.voucherId] = data.faceValue;
    }

    if (baseObject.salesCountPerCampaign[data.voucherId]) {
      baseObject.salesCountPerCampaign[data.voucherId] = baseObject.salesCountPerCampaign[data.voucherId] + 1;
    } else {
      baseObject.salesCountPerCampaign[data.voucherId] = 1;
    }

  } */

  if (data.refunded) {
    baseObject.refundedCount = baseObject.refundedCount + 1;
  }

  return baseObject;
}


const getAdminDashboardDataTop = (baseObject, type) => {

  const { salesCountPerCompany, salesPerCompany, companyNames, giftSalesPerCompany } = baseObject;

  let companiesArr = [];
  let companiesCountArr = [];
  let giftCompaniesArr = [];
  // let campaignsArr = [];
  // let campaignsCountArr = [];

  Object.keys(salesPerCompany).map(key => {
    companiesArr.push({ id: key, sales: salesPerCompany[key], name: companyNames[key] });
    return null;
  });

  companiesArr.sort((a, b) => {
    if ( a.sales < b.sales ) { return 1; }
    if ( a.sales > b.sales ) { return -1; }
    return 0;
  });

  Object.keys(salesCountPerCompany).map(key => {
    companiesCountArr.push({ id: key, sales: salesCountPerCompany[key], name: companyNames[key] });
    return null;
  });

  companiesCountArr.sort((a, b) => {
    if ( a.sales < b.sales ) { return 1; }
    if ( a.sales > b.sales ) { return -1; }
    return 0;
  });

  Object.keys(giftSalesPerCompany).map(key => {
    giftCompaniesArr.push({ id: key, sales: giftSalesPerCompany[key], name: companyNames[key] });
    return null;
  });

  giftCompaniesArr.sort((a, b) => {
    if (a.sales < b.sales) { return 1; }
    if (a.sales > b.sales) { return -1; }
    return 0;
  });

  /*
  Object.keys(salesPerCampaign).map(key => {
    campaignsArr.push({ id: key, sales: salesPerCampaign[key], name: campaignNames[key] });
  });

  campaignsArr.sort((a, b) => {
    if ( a.sales < b.sales ) {
      return 1;
    }
    if ( a.sales > b.sales ) {
      return -1;
    }
    return 0;
  });

  Object.keys(salesCountPerCampaign).map(key => {
    campaignsCountArr.push({ id: key, sales: salesCountPerCampaign[key], name: campaignNames[key] });

    return null;
  });

  campaignsCountArr.sort((a, b) => {
    if ( a.sales < b.sales ) { return 1; }
    if ( a.sales > b.sales ) { return -1; }
    return 0;
  }); */

  const topCompanies = companiesArr.slice(0, 5);
  const topCompaniesCount = companiesCountArr.slice(0, 5);
  const topCompaniesGiftCount = giftCompaniesArr.slice(0, 5);
  // const topCampaigns = campaignsArr.slice(0, 5);
  // const topCampaignsCount = campaignsCountArr.slice(0, 5);

  return {
    ...baseObject,
    topCompanies,
    topCompaniesCount,
    topCompaniesGiftCount,
   // topCampaigns,
   // topCampaignsCount,
  };
}


export const getAdminDashboard = (timeFrame, customStartDate, customEndDate) => (dispatch) => new Promise(async (resolve, reject) => {
  let dateLength = 1;
  let startDate;
  let endDate;
  const dateNow = moment();

  let nowDay;
  let nowMonth;
  let nowYear;

  let keyFormat = "DD MMM";
  let dateFormat = "DD MMM YYYY";

  console.log('getAdminDashboard', timeFrame, customStartDate, customEndDate);

  const dateNowSplit = dateNow.format("DD-MM-YYYY").split("-");

  nowDay = dateNowSplit[0];
  nowMonth = dateNowSplit[1];
  nowYear = dateNowSplit[2];

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);
    endDate = moment(customEndDate).add(1, 'days');
  } else if (timeFrame === 'allTime') {
    let allStartDate = new Date(2021, 1, 1);
    startDate = moment(allStartDate);

    endDate = moment().add(1, 'days');
  } else {
    if (timeFrame === 'month') {
      dateLength = 30;
    } else if (timeFrame === 'day') {
      dateLength = 1;
    } else if (timeFrame === 'week') {
      dateLength = 7;
    } else if (timeFrame === 'weeks') {
      dateLength = 14;
    } else if (timeFrame === 'year') {
      dateLength = 365;
    }

    startDate = moment().subtract(dateLength, 'days');

    endDate = moment().add(1, 'days');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  const startDay = startDateSplit[0];
  const startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];

  const totalCompaniesRef = firestore.collection('adminStatistics').doc('totalCompaniesCount');
  const totalCampaignsRef = firestore.collection('adminStatistics').doc('totalCampaignsCount');
  const totalClientsRef = firestore.collection('adminStatistics').doc('totalClientsCount');
  const last24HoursClientsRef = firestore.collection('adminStatistics').doc('last24HoursClients');

  const totalCompaniesSnapshot = await totalCompaniesRef.get();
  const totalCampaignsSnapshot = await totalCampaignsRef.get();
  const totalClientsSnapshot = await totalClientsRef.get();
  const last24HoursClientsSnapshot = await last24HoursClientsRef.get();

  let totalCompanies = 0;
  let totalCampaigns = 0;
  let totalClients = 0;
  let last24HoursClients = null;

  if (totalCompaniesSnapshot.exists) {
    totalCompanies = totalCompaniesSnapshot.data().count || 0;
  }
  if (totalCampaignsSnapshot.exists) {
    totalCampaigns = totalCampaignsSnapshot.data().count || 0;
  }
  if (totalClientsSnapshot.exists) {
    totalClients = totalClientsSnapshot.data().count || 0;
  }
  if (last24HoursClientsSnapshot.exists) {
    last24HoursClients = last24HoursClientsSnapshot.data() || null;
  }

  console.log('last24HoursClients', last24HoursClients);

  dispatch({
    type: GET_ADMIN_DASHBOARD_TOTALS,
    payload: { totalCompanies, totalCampaigns, totalClients },
    last24HoursClients,
  });

  const dateLength1day = moment().subtract(1, 'days');
  const dateLength1week = moment().subtract(7, 'days');
  const dateLength1Month = moment().subtract(30, 'days');
  const dateLength1Year = moment().subtract(365, 'days');

  const paymentsRef = await firestore.collection('ordersFinished')
    .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
    .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`)).get();

  if (!paymentsRef.docs) {
    return resolve();
  }

  let salesCount = 0;
  let refundedCount = 0;
  let amountCaptured = 0;
  let amountRefunded = 0;
  let commisionTaken = 0;

  let salesPerCompany = {};
  let giftSalesPerCompany = {};

  let salesCountPerCompany = {};
  let companyNames = {};

  //  let salesPerCampaign = {};
  //  let salesCountPerCampaign = {};
  //  let campaignNames = {};

  let data1Day = {
    salesCount: 0,
    amountCaptured: 0,
    commisionTaken: 0,
    companyNames: {},
    salesPerCompany: {},
    salesCountPerCompany: {},
    giftSalesPerCompany: {},
    //  campaignNames: {},
    //  salesPerCampaign: {},
    //  salesCountPerCampaign: {},
    refundedCount: 0,
    amountRefunded: 0,
  };
  let data1Week = {
    salesCount: 0,
    amountCaptured: 0,
    commisionTaken: 0,
    companyNames: {},
    salesPerCompany: {},
    salesCountPerCompany: {},
    giftSalesPerCompany: {},
    //  campaignNames: {},
    // salesPerCampaign: {},
    //  salesCountPerCampaign: {},
    refundedCount: 0,
    amountRefunded: 0,
  };

  let data1Month = {
    salesCount: 0,
    amountCaptured: 0,
    commisionTaken: 0,
    companyNames: {},
    salesPerCompany: {},
    salesCountPerCompany: {},
    giftSalesPerCompany: {},
    //  campaignNames: {},
    //  salesPerCampaign: {},
    //  salesCountPerCampaign: {},
    refundedCount: 0,
    amountRefunded: 0,
  };
  let data1Year = {
    salesCount: 0,
    amountCaptured: 0,
    commisionTaken: 0,
    companyNames: {},
    salesPerCompany: {},
    salesCountPerCompany: {},
    giftSalesPerCompany: {},
    //  campaignNames: {},
    //  salesPerCampaign: {},
    //  salesCountPerCampaign: {},
    refundedCount: 0,
    amountRefunded: 0,
  };
  let dataAllTime = {
    salesCount: 0,
    amountCaptured: 0,
    commisionTaken: 0,
    companyNames: {},
    salesPerCompany: {},
    salesCountPerCompany: {},
    giftSalesPerCompany: {},
    //  campaignNames: {},
    //  salesPerCampaign: {},
    //  salesCountPerCampaign: {},
    refundedCount: 0,
    amountRefunded: 0,
  };

  paymentsRef.docs.map(doc => {
    const data = doc.data();

    if (timeFrame === '1month') {
      dateLength = 30;
    } else if (timeFrame === '1day') {
      dateLength = 1;
    } else if (timeFrame === '1week') {
      dateLength = 7;
    } else if (timeFrame === '2weeks') {
      dateLength = 14;
    } else if (timeFrame === '1year') {
      dateLength = 365;
    }

    console.log('data1Month dateLength1Month', dateLength1Month);

    if (moment(data.paymentDate.seconds * 1000) > dateLength1day) {
      data1Day = updateAdminDashboardData(data1Day, data);
    }

    if (moment(data.paymentDate.seconds * 1000) > dateLength1week) {
      data1Week = updateAdminDashboardData(data1Week, data);
    }

    if (moment(data.paymentDate.seconds * 1000) > dateLength1Month) {
      data1Month = updateAdminDashboardData(data1Month, data);
    }

    if (moment(data.paymentDate.seconds * 1000) > dateLength1Year) {
      data1Year = updateAdminDashboardData(data1Year, data);
    }

    dataAllTime = updateAdminDashboardData(dataAllTime, data);

    salesCount = salesCount + 1;
    amountCaptured = amountCaptured + data.amount_captured;
    amountRefunded = amountRefunded + data.amount_refunded;

    if (data.application_fee) {
      commisionTaken = commisionTaken + data.application_fee_amount;
    }

    if (data && data.companyId) {
      if (salesPerCompany[data.companyId]) {
        salesPerCompany[data.companyId] = salesPerCompany[data.companyId] + data.faceValue;
        salesCountPerCompany[data.companyId] = salesCountPerCompany[data.companyId] + 1;
        if (!companyNames[data.companyId]) {
          companyNames[data.companyId] = data.companyName;
        }
      } else {
        companyNames[data.companyId] = data.companyName;
        salesPerCompany[data.companyId] = data.faceValue;
        salesCountPerCompany[data.companyId] = 1;
      }

      if (data.gift) {
        if (giftSalesPerCompany[data.companyId]) {
          giftSalesPerCompany[data.companyId] = giftSalesPerCompany[data.companyId] + 1;
        } else {
          giftSalesPerCompany[data.companyId] = 1;
        }
      }
    }

    /*
    if (data && data.voucherId) {
      if (salesPerCampaign[data.voucherId]) {
        salesPerCampaign[data.voucherId] = salesPerCampaign[data.voucherId] + data.faceValue;
        salesCountPerCampaign[data.voucherId] = salesCountPerCampaign[data.voucherId] + 1;
        if (!campaignNames[data.voucherId]) {
          campaignNames[data.voucherId] = data.voucherName;
        }
      } else {
        campaignNames[data.voucherId] = data.voucherName;
        salesPerCampaign[data.voucherId] = data.faceValue;
        salesCountPerCampaign[data.voucherId] = 1;
      }
    } */

    if (data.refunded) {
      refundedCount = refundedCount + 1;
    }

    return null;
  });

  console.log('data1Day data 1', data1Day);
  console.log('data1Month data 1', data1Month);

  let companiesArr = [];
  let companiesCountArr = [];
  let giftCompaniesArr = [];
  //  let campaignsArr = [];
  //  let campaignsCountArr = [];

  Object.keys(salesPerCompany).map(key => {
    companiesArr.push({ id: key, sales: salesPerCompany[key], name: companyNames[key] });

    return null;
  });

  companiesArr.sort((a, b) => {
    if (a.sales < b.sales) { return 1; }
    if (a.sales > b.sales) { return -1; }
    return 0;
  });

  Object.keys(giftSalesPerCompany).map(key => {
    giftCompaniesArr.push({ id: key, sales: giftSalesPerCompany[key], name: companyNames[key] });

    return null;
  });

  giftCompaniesArr.sort((a, b) => {
    if (a.sales < b.sales) { return 1; }
    if (a.sales > b.sales) { return -1; }
    return 0;
  });

  Object.keys(salesCountPerCompany).map(key => {
    companiesCountArr.push({ id: key, sales: salesCountPerCompany[key], name: companyNames[key] });

    return null;
  });

  companiesCountArr.sort((a, b) => {
    if (a.sales < b.sales) { return 1; }
    if (a.sales > b.sales) { return -1; }
    return 0;
  });

  /*
      Object.keys(salesPerCampaign).map(key => {
        campaignsArr.push({ id: key, sales: salesPerCampaign[key], name: campaignNames[key] });

        return null;
      });

      campaignsArr.sort((a, b) => {
        if ( a.sales < b.sales ) {
          return 1;
        }
        if ( a.sales > b.sales ) {
          return -1;
        }
        return 0;
      });

      Object.keys(salesCountPerCampaign).map(key => {
        campaignsCountArr.push({ id: key, sales: salesCountPerCampaign[key], name: campaignNames[key] });

        return null;
      });

      campaignsCountArr.sort((a, b) => {
        if ( a.sales < b.sales ) { return 1; }
        if ( a.sales > b.sales ) { return -1; }
        return 0;
      }); */
  
  const topCompanies = companiesArr.slice(0, 5);
  const topCompaniesCount = companiesCountArr.slice(0, 5);
  const topCompaniesGiftCount = giftCompaniesArr.slice(0, 5);

   //   const topCampaigns = campaignsArr.slice(0, 5);
  // const topCampaignsCount = campaignsCountArr.slice(0, 5);

  data1Day = getAdminDashboardDataTop(data1Day, 'data1Day');
  data1Week = getAdminDashboardDataTop(data1Week, 'data1Week');
  data1Month = getAdminDashboardDataTop(data1Month, 'data1Month');
  data1Year = getAdminDashboardDataTop(data1Year, 'data1Year');
  dataAllTime = getAdminDashboardDataTop(dataAllTime, 'dataAllTime');

  console.log('topCompaniesGiftCount', topCompaniesGiftCount);

  dispatch({
    type: GET_ADMIN_DASHBOARD_STRIPE_DATA,
    payload: {
      salesCount,
      refundedCount,
      amountCaptured: amountCaptured / 100,
      amountRefunded: amountRefunded / 100,
      commisionTaken: commisionTaken / 100,
      topCompanies,
      topCompaniesCount,
      topCompaniesGiftCount,
      //  topCampaigns,
      //  topCampaignsCount,
      salesPerCompany,
      giftSalesPerCompany,
      //  salesPerCampaign,
      companyNames,
      // campaignNames,
    },
    data1Day,
    data1Week,
    data1Month,
    data1Year,
    dataAllTime,
    timeFrame,
  });




  const dataRef = firestore.collection('adminStatistics').doc('history').collection('history')
  .where('date', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
  .where('date', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))

    const snapshot = await dataRef.get();

    if (snapshot.docs) {
      const adminStatistics = {};

      const adminStatisticsFaceValueSales = [];

      const adminStatisticsSalesValue = [];
      const adminStatisticsSalesCount = [];
      const adminStatisticsCommission = [];

      const adminStatisticsRedemptionsCount = [];
      const adminStatisticsRedemptionsValue = [];
      const adminStatisticsRedemptionsPercentages = [];
      const adminStatisticsRedemptions = [];

      const adminStatisticsRefundsCount = [];
      const adminStatisticsRefundsValue = [];
      const adminStatisticsRefunds = [];
      const adminStatisticsCancelledVouchers = [];

      const adminStatisticsGiftedCount = [];
      const adminStatisticsGiftedValue = [];

      const adminStatisticsCompanies = [];
      const adminStatisticsCampaigns = [];
      const adminStatisticsCustomers = [];

      let adminStatistics1Day = {
        ...ADMIN_DASHBOARD_INIT_DATA,
      };
      let adminStatistics1Week = {
        ...ADMIN_DASHBOARD_INIT_DATA,
      };
      let adminStatistics1Month= {
        ...ADMIN_DASHBOARD_INIT_DATA,
      };
      let adminStatistics1Year = {
        ...ADMIN_DASHBOARD_INIT_DATA,
      };
      let adminStatisticsAllTime = {
        ordersCount: 0,
        salesCount: 0,
        salesValue: 0,
        giftedCount: 0,
        giftedValue: 0,
        redemptionsCount: 0,
        redemptionsValue: 0,
        refundsCount: 0,
        refundsValue: 0,
        cancelledVouchersCount: 0,
        cancelledVouchersValue: 0,
        applicationFeeValue: 0,
        amountCaptured: 0,
      };

      snapshot.docs.map((doc) => {
        const data = doc.data();

        console.log('data adminStatisticsSalesValue test', data);

        const date = moment(data.date.seconds * 1000).format("MMM DD, YYYY");

    //    console.log('data', data);

        if (moment(data.date.seconds * 1000) > dateLength1day) {
          adminStatistics1Day = {
            ordersCount: adminStatistics1Day.ordersCount + data.ordersCount,
            salesCount: adminStatistics1Day.salesCount + data.salesCount,
            salesValue: adminStatistics1Day.salesValue + data.salesValue,
            giftedCount: adminStatistics1Day.giftedCount + data.giftedCount,
            giftedValue: adminStatistics1Day.giftedValue + data.giftedValue,
            redemptionsCount: adminStatistics1Day.redemptionsCount + data.redemptionsCount,
            redemptionsValue: adminStatistics1Day.redemptionsValue + data.redemptionsValue,
            newCompanies: adminStatistics1Day.newCompanies + data.newCompanies,
            deletedCompanies: adminStatistics1Day.deletedCompanies + data.deletedCompanies,

            newCampaigns: adminStatistics1Day.newCampaigns + data.newCampaigns,
            endedCampaigns: adminStatistics1Day.endedCampaigns + data.endedCampaigns,
            newClients: adminStatistics1Day.newClients + data.newClients,
            deletedClients: adminStatistics1Day.deletedClients + data.deletedClients,
            applicationFeeValue: adminStatistics1Day.applicationFeeValue + data.applicationFeeValue,
            amountCaptured: adminStatistics1Day.amountCaptured + data.amountCaptured,
          };
        }

        if (moment(data.date.seconds * 1000) > dateLength1week) {
          adminStatistics1Week = {
            ordersCount: adminStatistics1Week.ordersCount + data.ordersCount,
            salesCount: adminStatistics1Week.salesCount + data.salesCount,
            salesValue: adminStatistics1Week.salesValue + data.salesValue,
            giftedCount: adminStatistics1Week.giftedCount + data.giftedCount,
            giftedValue: adminStatistics1Week.giftedValue + data.giftedValue,
            redemptionsCount: adminStatistics1Week.redemptionsCount + data.redemptionsCount,
            redemptionsValue: adminStatistics1Week.redemptionsValue + data.redemptionsValue,
            newCompanies: adminStatistics1Week.newCompanies + data.newCompanies,
            deletedCompanies: adminStatistics1Week.deletedCompanies + data.deletedCompanies,

            newCampaigns: adminStatistics1Week.newCampaigns + data.newCampaigns,
            endedCampaigns: adminStatistics1Week.endedCampaigns + data.endedCampaigns,
            newClients: adminStatistics1Week.newClients + data.newClients,
            deletedClients: adminStatistics1Week.deletedClients + data.deletedClients,
            applicationFeeValue: adminStatistics1Week.applicationFeeValue + data.applicationFeeValue,
            amountCaptured: adminStatistics1Week.amountCaptured + data.amountCaptured,
          };
        }

        if (moment(data.date.seconds * 1000) > dateLength1Month) {
          adminStatistics1Month = {
            ordersCount: adminStatistics1Month.ordersCount + data.ordersCount,
            salesCount: adminStatistics1Month.salesCount + data.salesCount,
            salesValue: adminStatistics1Month.salesValue + data.salesValue,
            giftedCount: adminStatistics1Month.giftedCount + data.giftedCount,
            giftedValue: adminStatistics1Month.giftedValue + data.giftedValue,
            redemptionsCount: adminStatistics1Month.redemptionsCount + data.redemptionsCount,
            redemptionsValue: adminStatistics1Month.redemptionsValue + data.redemptionsValue,
            newCompanies: adminStatistics1Month.newCompanies + data.newCompanies,
            deletedCompanies: adminStatistics1Month.deletedCompanies + data.deletedCompanies,

            newCampaigns: adminStatistics1Month.newCampaigns + data.newCampaigns,
            endedCampaigns: adminStatistics1Month.endedCampaigns + data.endedCampaigns,
            newClients: adminStatistics1Month.newClients + data.newClients,
            deletedClients: adminStatistics1Month.deletedClients + data.deletedClients,
            applicationFeeValue: adminStatistics1Month.applicationFeeValue + data.applicationFeeValue,
            amountCaptured: adminStatistics1Month.amountCaptured + data.amountCaptured,
          };
        }

        if (moment(data.date.seconds * 1000) > dateLength1Year) {
          adminStatistics1Year = {
            ordersCount: adminStatistics1Year.ordersCount + data.ordersCount,
            salesCount: adminStatistics1Year.salesCount + data.salesCount,
            salesValue: adminStatistics1Year.salesValue + data.salesValue,
            giftedCount: adminStatistics1Year.giftedCount + data.giftedCount,
            giftedValue: adminStatistics1Year.giftedValue + data.giftedValue,
            redemptionsCount: adminStatistics1Year.redemptionsCount + data.redemptionsCount,
            redemptionsValue: adminStatistics1Year.redemptionsValue + data.redemptionsValue,

            newCompanies: adminStatistics1Year.newCompanies + data.newCompanies,
            deletedCompanies: adminStatistics1Year.deletedCompanies + data.deletedCompanies,
            newCampaigns: adminStatistics1Year.newCampaigns + data.newCampaigns,
            endedCampaigns: adminStatistics1Year.endedCampaigns + data.endedCampaigns,
            newClients: adminStatistics1Year.newClients + data.newClients,
            deletedClients: adminStatistics1Year.deletedClients + data.deletedClients,

            applicationFeeValue: adminStatistics1Year.applicationFeeValue + data.applicationFeeValue,
            amountCaptured: adminStatistics1Year.amountCaptured + data.amountCaptured,
          };
        }

        adminStatisticsAllTime = {
          ordersCount: adminStatisticsAllTime.ordersCount + data.ordersCount,
          salesCount: adminStatisticsAllTime.salesCount + data.salesCount,
          salesValue: adminStatisticsAllTime.salesValue + data.salesValue,
          giftedCount: adminStatisticsAllTime.giftedCount + data.giftedCount,
          giftedValue: adminStatisticsAllTime.giftedValue + data.giftedValue,
          redemptionsCount: adminStatisticsAllTime.redemptionsCount + data.redemptionsCount,
          redemptionsValue: adminStatisticsAllTime.redemptionsValue + data.redemptionsValue,
          applicationFeeValue: adminStatisticsAllTime.applicationFeeValue + data.applicationFeeValue,
          amountCaptured: adminStatisticsAllTime.amountCaptured + data.amountCaptured,
        };

        adminStatistics[doc.id] = {
          ...data,
          date: date,
        };

        const dateObj = moment(doc.id.slice(0, 4) + "-" + doc.id.slice(4, 6) + "-" + doc.id.slice(6, 8));

        const dateKey = dateObj.clone().format(keyFormat);
        const dateFormatted = dateObj.clone().format(dateFormat);

        adminStatisticsFaceValueSales.push({ data: data.salesValue, key: dateKey, date: dateFormatted });

        let redemptionsCountPerc = 0;
        let redemptionsValuePerc = 0;
        if (data.redemptionsCount) {
          if (data.salesCount) {
            redemptionsCountPerc = (data.redemptionsCount / data.salesCount);
          } else {
            redemptionsCountPerc = 100;
          }
        }

        if (data.redemptionsValue) {
          if (data.salesValue) {
            redemptionsValuePerc = (data.redemptionsValue / data.salesValue);
          } else {
            redemptionsValuePerc = 100;
          }
        }

        let amountCaptured = 0;
        let customerPayments = 0;
        let customerPaymentsGifting = 0;
        let customerPaymentsVoucher = 0;

        if (data.amountCaptured) {
          amountCaptured = data.amountCaptured / 100;
        }

        if (data.customerPayments) {
          customerPayments = data.customerPayments / 100;
        }

         if (data.customerPaymentsGifting) {
          customerPaymentsGifting = data.customerPaymentsGifting / 100;
        }

        if (data.customerPaymentsVoucher) {
          customerPaymentsVoucher = data.customerPaymentsVoucher / 100;
        }

        /* if (data.buyFor === 'gift') {
          customerPaymentsVoucher = customerPaymentsVoucher + (data.amountCaptured - 99);
          customerPaymentsGifting = customerPaymentsGifting + 99;
        } else {
          customerPaymentsVoucher = customerPaymentsVoucher + data.amountCaptured;
        } */

       // console.log('redemptionsCountPerc', dateKey, redemptionsCountPerc, data);

        adminStatisticsSalesValue.push({
          'Customer payments': amountCaptured || 0,
          'Customer payments Gifting': customerPaymentsGifting || 0,
          'Customer payments Voucher': customerPaymentsVoucher || 0,
          'Face value sales': data.salesValue,
          'Commission': data.applicationFeeValue ? data.applicationFeeValue / 100 : 0,
          key: dateKey,
          date: dateFormatted,
        });

        adminStatisticsSalesCount.push({
          'Sales count': data.salesCount,
          'Self count': data.salesCount ? data.salesCount - (data.giftedCount || 0) : 0,
          key: dateKey,
          date: dateFormatted,
          'Gifting count': data.giftedCount,
        });
        adminStatisticsCommission.push({ 'Commission': data.applicationFeeValue, key: dateKey, date: dateFormatted });

        adminStatisticsRedemptionsCount.push({
          'Redemptions count': data.redemptionsCount, key: dateKey, date: dateFormatted
        }); //'Sales value': data.salesValue, percentage: redemptionsCountPerc,

        adminStatisticsRedemptionsValue.push({
          'Redemptions value': data.redemptionsValue, key: dateKey, date: dateFormatted
        }); //'Sales count': data.salesCount, percentage: redemptionsValuePerc,

        adminStatisticsRedemptionsPercentages.push({
          'Redemptions count percentage': redemptionsCountPerc,
          'Redemptions value percentage': redemptionsValuePerc,
          key: dateKey,
          date: dateFormatted,
        });

        adminStatisticsRedemptions.push({
          'Redemptions count': data.redemptionsCount,
          'Redemptions value': data.redemptionsValue,
          'Redemptions count percentage': redemptionsCountPerc,
          'Redemptions value percentage': redemptionsValuePerc,
          key: dateKey,
          date: dateFormatted,
        });

        adminStatisticsRefunds.push({
          'Refunds count': data.refundsCount,
          'Refunds value': data.refundsValue ? data.refundsValue / 100 : 0,
          'Refunds voucher count': data.refundsVoucherCount,
          'Refunds voucher value': data.refundsVoucherValue ? data.refundsVoucherValue / 100 : 0,
          'Refunds gift count': data.refundsGiftCount,
          'Refunds gift value': data.refundsGiftValue ? data.refundsGiftValue / 100 : 0,
          key: dateKey,
          date: dateFormatted,
        });


        adminStatisticsCancelledVouchers.push({
          'Cancelled vouchers count': data.cancelledVouchersCount,
          'Cancelled vouchers value': data.cancelledVouchersValue ? data.cancelledVouchersValue : 0,
          key: dateKey,
          date: dateFormatted,
        });


        adminStatisticsGiftedCount.push({ data: data.giftedCount, key: dateKey, date: dateFormatted });
        adminStatisticsGiftedValue.push({ data: data.giftedValue, key: dateKey, date: dateFormatted });

        console.log('adminStatisticsCompanies 1111', data);

        adminStatisticsCompanies.push({
          'Total companies': data.totalCompaniesCount,
          'New companies': data.newCompanies,
          'Deleted companies': data.deletedCompanies, key: dateKey, date: dateFormatted,
        });
        adminStatisticsCampaigns.push({
          'Total campaigns': data.totalCampaignsCount,
          'New campaigns': data.newCampaigns,
          'Ended campaigns': data.endedCampaigns, key: dateKey, date: dateFormatted,
        });
        adminStatisticsCustomers.push({
          'Total clients': data.totalClientsCount,
          'New clients': data.newClients,
          'Deleted clients': data.deletedClients, key: dateKey, date: dateFormatted,
        });

        return null;
      });

      if (timeFrame !== 'custom' || (timeFrame === 'custom' && nowDay === endDay && nowMonth === endMonth && nowYear === endYear)) {
        const todayDataRef = firestore.collection('adminStatistics').doc('today');
        const hourOrdersRef = firestore.collection('adminStatistics').doc('hour').collection('orders');
        const hourRedemptionsRef = firestore.collection('adminStatistics').doc('hour').collection('redemptions');
        const hourRefundsRef = firestore.collection('adminStatistics').doc('hour').collection('refunds');
        const hourCancelledVouchersRef = firestore.collection('adminStatistics').doc('hour').collection('cancelledVouchers');

        const hourNewCampaignsRef = firestore.collection('adminStatistics').doc('hourNewCampaignsCount');
        const hourNewCompaniesRef = firestore.collection('adminStatistics').doc('hourNewCompaniesCount');
        const hourNewClientsRef = firestore.collection('adminStatistics').doc('hourNewClientsCount');

        const hourEndedCampaignsRef = firestore.collection('adminStatistics').doc('hourEndedCampaignsCount');
        const hourDeletedCompaniesRef = firestore.collection('adminStatistics').doc('hourDeletedCompaniesCount');
        const hourDeletedClientsRef = firestore.collection('adminStatistics').doc('hourDeletedClientsCount');


        const todaySnapshot = await todayDataRef.get();
        const hourOrdersSnapshot = await hourOrdersRef.get();
        const hourRedemptionsSnapshot = await hourRedemptionsRef.get();
        const hourRefundsSnapshot = await hourRefundsRef.get();
        const hourCancelledVouchersSnapshot = await hourCancelledVouchersRef.get();
        

        const hourNewCampaignsSnapshot = await hourNewCampaignsRef.get();
        const hourNewCompaniesSnapshot = await hourNewCompaniesRef.get();
        const hourNewClientsSnapshot = await hourNewClientsRef.get();

        const hourEndedCampaignsSnapshot = await hourEndedCampaignsRef.get();
        const hourDeletedCompaniesSnapshot = await hourDeletedCompaniesRef.get();
        const hourDeletedClientsSnapshot = await hourDeletedClientsRef.get();

        let hoursalesCount = 0;
        let hoursalesValue = 0;
        let hourGiftedCount = 0;
        let hourGiftedValue = 0;
        let hourRedemptionsCount = 0;
        let hourRedemptionsValue = 0;

        let hourRefundsCount = 0;
        let hourRefundsValue = 0;

        let hourRefundsVoucherCount = 0;
        let hourRefundsVoucherValue = 0;

        let hourRefundsGiftCount = 0;
        let hourRefundsGiftValue = 0;

        let hourCancelledVouchersCount = 0;
        let hourCancelledVouchersValue = 0;

        let hourApplicationFeeValue = 0;
        let hourAmountCaptured = 0;

        let hourCustomerPayments = 0;
        let hourCustomerPaymentsVoucher = 0;
        let hourCustomerPaymentsGifting = 0;

        if (hourOrdersSnapshot.docs) {
          hourOrdersSnapshot.docs.map((doc) => {
            const data = doc.data();

            console.log('hourOrdersSnapshot data', data);

            hoursalesCount = hoursalesCount + 1;
            hoursalesValue = hoursalesValue + data.soldValue;

            if (data.application_fee_amount) {
              hourApplicationFeeValue = hourApplicationFeeValue + data.application_fee_amount;
            }
            if (data.amount_captured) {
              hourAmountCaptured = hourAmountCaptured + data.amount_captured / 100;
            }

            if (data.customerPayments) {
              hourCustomerPayments = hourCustomerPayments + data.customerPayments / 100;
            }

            console.log('hourCustomerPaymentsGifting 2', data);
           /*  if (data.customerPaymentsVoucher) {
              hourCustomerPaymentsVoucher = hourCustomerPaymentsVoucher + data.customerPaymentsVoucher / 100;
            }

            if (data.customerPaymentsGifting) {
              hourCustomerPaymentsGifting = hourCustomerPaymentsGifting + data.customerPaymentsGifting / 100;
            } */

            if (data.buyFor === 'gift') {
              hourCustomerPaymentsVoucher = hourCustomerPaymentsVoucher + (data.amount_captured ? (data.amount_captured - (data.amount_captured_gift || 99)) : 0);
              hourCustomerPaymentsGifting = hourCustomerPaymentsGifting + (data.amount_captured_gift || 99);
            } else {
              hourCustomerPaymentsVoucher = hourCustomerPaymentsVoucher + (data.amount_captured ? data.amount_captured : 0);
            }

            if (data.buyFor !== 'self') {
              hourGiftedCount = hourGiftedCount + 1;
              hourGiftedValue = hourGiftedValue + data.soldValue;
            }
            return null;
          });
        }

        if (hourRefundsSnapshot.docs) {
          hourRefundsSnapshot.docs.map((doc) => {
            const data = doc.data();

            console.log('hourRefundsSnapshot data', data);
            if (data.refundType === 'refundAll') {
              hourRefundsValue = hourRefundsValue + data.soldValue;

              if (data.gift) {
                hourRefundsGiftCount = hourRefundsGiftCount + 1;
                hourRefundsGiftValue = hourRefundsGiftValue + (data.amount_captured_gift || 99);

                hourRefundsVoucherCount = hourRefundsVoucherCount + 1;
                hourRefundsVoucherValue = hourRefundsVoucherValue + (data.soldValue - (data.amount_captured_gift || 99));
              } else {
                hourRefundsVoucherCount = hourRefundsVoucherCount + 1;
                hourRefundsVoucherValue = hourRefundsVoucherValue + data.soldValue;
              }
            } else if (data.refundType === 'refundGifting') {
              hourRefundsValue = hourRefundsValue + (data.amount_captured_gift || 99);

              hourRefundsGiftCount = hourRefundsGiftCount + 1;
              hourRefundsGiftValue = hourRefundsGiftValue + (data.amount_captured_gift || 99);
            } else if (data.refundType === 'refundVoucher') {
              if (data.gift) {
                hourRefundsValue = hourRefundsValue + (data.soldValue - (data.amount_captured_gift || 99));

                hourRefundsVoucherCount = hourRefundsVoucherCount + 1;
                hourRefundsVoucherValue = hourRefundsVoucherValue + (data.soldValue - (data.amount_captured_gift || 99));
              } else {
                hourRefundsVoucherCount = hourRefundsVoucherCount + 1;
                hourRefundsVoucherValue = hourRefundsVoucherValue + data.soldValue;
              }
            }

            // hourRefundsCount = hourRefundsCount + 1;
            // hourRefundsValue = hourRefundsValue + data.soldValue;

            //let refundsVoucherCountDay = hourRefundsVoucherCount;
            //let refundsVoucherValueDay = hourRefundsVoucherValue;

            //let refundsGiftCountDay = hourRefundsGiftCount;
            //let refundsGiftValueDay = hourRefundsGiftValue;

            return null;
          });
        }

        if (hourCancelledVouchersSnapshot.docs) {
          hourCancelledVouchersSnapshot.docs.map((doc) => {
            const data = doc.data();

            console.log('hourCancelledVouchersSnapshot data', data);

            hourCancelledVouchersCount = hourCancelledVouchersCount + 1;
            hourCancelledVouchersValue = hourCancelledVouchersValue + data.soldValue;

            return null;
          });
        }

        if (hourRedemptionsSnapshot.docs) {
          hourRedemptionsSnapshot.docs.map((doc) => {
            const data = doc.data();

            console.log('hourRedemptionsSnapshot data', data);

            hourRedemptionsCount = hourRedemptionsCount + 1;
            hourRedemptionsValue = hourRedemptionsValue + data.soldValue;

            return null;
          });
        }

        console.log('hourRedemptionsCount', hourRedemptionsCount, hourRedemptionsValue);

        let hourNewCampaignsValue = 0;
        let hourNewCompaniesValue = 0;
        let hourNewClientsValue = 0;

        let hourEndedCampaignsValue = 0;
        let hourDeletedCompaniesValue = 0;
        let hourDeletedClientsValue = 0;

        if (hourNewCampaignsSnapshot.exists) {
          const data = hourNewCampaignsSnapshot.data();
          console.log('hourNewCampaignsSnapshot', data);
          hourNewCampaignsValue = data.count;
        }

        if (hourNewCompaniesSnapshot.exists) {
          const data = hourNewCompaniesSnapshot.data();
          hourNewCompaniesValue = data.count;
        }

        if (hourNewClientsSnapshot.exists) {
          const data = hourNewClientsSnapshot.data();
          hourNewClientsValue = data.count;
        }

        if (hourEndedCampaignsSnapshot.exists) {
          const data = hourEndedCampaignsSnapshot.data();
          hourEndedCampaignsValue = data.count;
        }

        if (hourDeletedCompaniesSnapshot.exists) {
          const data = hourDeletedCompaniesSnapshot.data();
          hourDeletedCompaniesValue = data.count;
        }

        if (hourDeletedClientsSnapshot.exists) {
          const data = hourDeletedClientsSnapshot.data();
          hourDeletedClientsValue = data.count;
        }

        console.log('hourNewCampaignsValue', hourNewCampaignsValue, hourNewCompaniesValue, hourNewClientsValue);
        console.log('hourEndedCampaignsValue', hourEndedCampaignsValue, hourDeletedCompaniesValue, hourDeletedClientsValue);

        console.log('hourCancelledVouchersCount', hourCancelledVouchersCount, hourCancelledVouchersValue)

        if (todaySnapshot.exists) {
          const todayData = todaySnapshot.data();

          if (todayData) {
            let ordersCountDay = hoursalesCount;
            let salesCountDay = hoursalesCount;
            let salesValueDay = hoursalesValue;
            let giftedCountDay = hourGiftedCount;
            let giftedValueDay = hourGiftedValue;
            let redemptionsCountDay = hourRedemptionsCount;
            let redemptionsValueDay = hourRedemptionsValue;

            console.log('hourRefundsCount', hourRefundsCount, hourRefundsValue, hourRefundsVoucherCount, hourRefundsGiftValue);
            console.log('hourCustomerPaymentsGifting', hourCustomerPaymentsGifting, hourCustomerPaymentsVoucher);
            let refundsCountDay = hourRefundsCount;
            let refundsValueDay = hourRefundsValue;

            let refundsVoucherCountDay = hourRefundsVoucherCount;
            let refundsVoucherValueDay = hourRefundsVoucherValue;

            let refundsGiftCountDay = hourRefundsGiftCount;
            let refundsGiftValueDay = hourRefundsGiftValue;

            let cancelledVouchersCountDay = hourCancelledVouchersCount;
            let cancelledVouchersValueDay = hourCancelledVouchersValue;

            let applicationFeeValueDay = hourApplicationFeeValue;
            let amountCapturedDay = hourAmountCaptured;

            let customerPaymentsDay = hourCustomerPayments;
            let customerPaymentsVoucherDay = hourCustomerPaymentsVoucher ? hourCustomerPaymentsVoucher / 100 : 0;
            let customerPaymentsGiftingDay = hourCustomerPaymentsGifting ? hourCustomerPaymentsGifting / 100 : 0;

            let newCompaniesDay = hourNewCompaniesValue;
            let deletedCompaniesDay = hourDeletedCompaniesValue;
            let totalCompaniesDay = 0;

            let newCampaignsDay = hourNewCampaignsValue;
            let endedCampaignsDay = hourEndedCampaignsValue;
            let totalCampaignsDay = 0;

            let newClientsDay = hourNewClientsValue;
            let deletedClientsDay = hourDeletedClientsValue;
            let totalClientsDay  = 0;
            console.log('todayData', todayData);

            Object.keys(todayData).map((key) => {
              applicationFeeValueDay = applicationFeeValueDay + todayData[key].applicationFeeValue;
              if (todayData[key].amountCaptured) {
                amountCapturedDay = amountCapturedDay + todayData[key].amountCaptured / 100;
              }

              if (todayData[key].customerPayments) {
                customerPaymentsDay = customerPaymentsDay + todayData[key].customerPayments / 100;
              }

              if (todayData[key].customerPaymentsVoucher) {
                customerPaymentsVoucherDay = customerPaymentsVoucherDay + (todayData[key].customerPaymentsVoucher ? (todayData[key].customerPaymentsVoucher / 100) : 0);
              }

              if (todayData[key].customerPaymentsGifting) {
                customerPaymentsGiftingDay = customerPaymentsGiftingDay + (todayData[key].customerPaymentsGifting ? (todayData[key].customerPaymentsGifting / 100) : 0);
              }

              ordersCountDay = ordersCountDay + todayData[key].ordersCount;
              salesCountDay = salesCountDay + todayData[key].salesCount;
              salesValueDay = salesValueDay + todayData[key].salesValue;
              giftedCountDay = giftedCountDay + todayData[key].giftedCount;
              giftedValueDay = giftedValueDay + todayData[key].giftedValue;
              redemptionsCountDay = redemptionsCountDay + (todayData[key].redemptionsCount || 0);
              redemptionsValueDay = redemptionsValueDay + (todayData[key].redemptionsValue || 0);
              refundsCountDay = refundsCountDay + todayData[key].refundsCount;
              refundsValueDay = refundsValueDay + todayData[key].refundsValue;

              refundsVoucherCountDay = refundsVoucherCountDay + (todayData[key].refundsVoucherCount || 0);
              refundsVoucherValueDay = refundsVoucherValueDay + (todayData[key].refundsVoucherValue || 0);
              refundsGiftCountDay = refundsGiftCountDay + (todayData[key].refundsGiftCount || 0);
              refundsGiftValueDay = refundsGiftValueDay + (todayData[key].refundsGiftValue || 0);

              cancelledVouchersCountDay = cancelledVouchersCountDay + (todayData[key].cancelledVouchersCount || 0);
              cancelledVouchersValueDay = cancelledVouchersValueDay + (todayData[key].cancelledVouchersValue || 0);
              newCompaniesDay = newCompaniesDay + todayData[key].newCompanies;
              deletedCompaniesDay = deletedCompaniesDay + todayData[key].deletedCompanies;

              newCampaignsDay = newCampaignsDay + todayData[key].newCampaigns;
              endedCampaignsDay = endedCampaignsDay + (todayData[key].endedCampaigns || 0);

              newClientsDay = newClientsDay + todayData[key].newClients;
              deletedClientsDay = deletedClientsDay + (todayData[key].deletedClients || 0);
              return null;
            });

            totalCompaniesDay = totalCompanies;
            totalCampaignsDay = totalCampaigns;
            totalClientsDay = totalClients;

            const date = moment().format("MMM DD, YYYY");

            console.log('cancelledVouchersCountDay', cancelledVouchersCountDay);

            const dayData = {
              date: date,
              applicationFeeValue: applicationFeeValueDay,
              amountCaptured: amountCapturedDay,
              customerPayments: customerPaymentsDay,
              customerPaymentsVoucher: customerPaymentsVoucherDay,
              customerPaymentsGifting: customerPaymentsGiftingDay,

              ordersCount: ordersCountDay,
              salesCount: salesCountDay,
              salesValue: salesValueDay,
              giftedCount: giftedCountDay,
              giftedValue: giftedValueDay,
              redemptionsCount: redemptionsCountDay,
              redemptionsValue: redemptionsValueDay,
              refundsCount: refundsCountDay,
              refundsValue: refundsValueDay,

              refundsVoucherCount: refundsVoucherCountDay,
              refundsVoucherValue: refundsVoucherValueDay,
              refundsGiftCount: refundsGiftCountDay,
              refundsGiftValue: refundsGiftValueDay,

              cancelledVouchersCount: cancelledVouchersCountDay,
              cancelledVouchersValue: cancelledVouchersValueDay,
              newCompanies: newCompaniesDay,
              deletedCompanies: deletedCompaniesDay,
              newCampaigns: newCampaignsDay,
              endedCampaigns: endedCampaignsDay,
              newClients: newClientsDay,
              deletedClients: deletedClientsDay,
            };

            let redemptionsCountPerc = 0;
            let redemptionsValuePerc = 0;
            if (redemptionsCountDay) {
              if (salesCountDay) {
                redemptionsCountPerc = parseInt((redemptionsCountDay / salesCountDay).toFixed(2));
              } else {
                redemptionsCountPerc = 100;
              }
            }

            if (redemptionsValueDay) {
              if (salesValueDay) {
                redemptionsValuePerc = parseInt((redemptionsValueDay / salesValueDay).toFixed(2));
              } else {
                redemptionsValuePerc = 100;
              }
            }

            const dateKey = moment(`${nowYear}-${nowMonth}-${nowDay}`).clone().format(keyFormat);
            const dateFormatted = moment(`${nowYear}-${nowMonth}-${nowDay}`).clone().format(dateFormat);

            adminStatistics[dateKey] = dayData;

            adminStatisticsFaceValueSales.push({ data: salesValueDay, key: dateKey, date: dateFormatted });

            console.log('customerPaymentsGiftingDay', customerPaymentsGiftingDay, customerPaymentsVoucherDay, amountCapturedDay);

            adminStatisticsSalesValue.push({
              'Customer payments': amountCapturedDay || 0,
              'Customer payments Gifting': customerPaymentsGiftingDay || 0,
              'Customer payments Voucher': customerPaymentsVoucherDay || 0,
              'Face value sales': salesValueDay,
              'Commission': applicationFeeValueDay ? applicationFeeValueDay / 100 : 0,
              key: dateKey,
              date: dateFormatted,
            });
            adminStatisticsSalesCount.push({
              'Self count': salesCountDay ? salesCountDay - (giftedCountDay || 0) : 0,
              'Sales count': salesCountDay,
              'Gifting count': giftedCountDay,
              key: dateKey,
              date: dateFormatted,
            });
            adminStatisticsCommission.push({
              'Commission': applicationFeeValueDay,
              key: dateKey,
              date: dateFormatted,
            });

            adminStatisticsRedemptionsCount.push({
              'Redemptions count': redemptionsCountDay,
              key: dateKey,
              date: dateFormatted,
            }); //, salesCount: salesCountDay, percentage: redemptionsCountPerc,
            adminStatisticsRedemptionsValue.push({
              'Redemptions value': redemptionsValueDay,
              key: dateKey,
              date: dateFormatted,
            }); //, salesValue: salesValueDay, percentage: redemptionsValuePerc,
            adminStatisticsRedemptionsPercentages.push({
              'Redemptions count percentage': redemptionsCountPerc,
              'Redemptions value percentage': redemptionsValuePerc,
              key: dateKey,
              date: dateFormatted,
            });

            adminStatisticsRedemptions.push({
              'Redemptions count': redemptionsCountDay,
              'Redemptions value': redemptionsValueDay,
              'Redemptions count percentage': redemptionsCountPerc,
              'Redemptions value percentage': redemptionsValuePerc,
              key: dateKey,
              date: dateFormatted,
            });

            console.log('refundsCountDay', refundsCountDay, refundsValueDay, refundsVoucherCountDay, refundsGiftValueDay);

            adminStatisticsRefunds.push({
              'Refunds count': refundsCountDay,
              'Refunds value': refundsValueDay ? refundsValueDay / 100 : 0,
              'Refunds voucher count': refundsVoucherCountDay,
              'Refunds voucher value': refundsVoucherValueDay ? refundsVoucherValueDay / 100 : 0,
              'Refunds gift count': refundsGiftCountDay,
              'Refunds gift value': refundsGiftValueDay ? refundsGiftValueDay / 100 : 0,
              key: dateKey,
              date: dateFormatted,
            });

            adminStatisticsCancelledVouchers.push({
              'Cancelled vouchers count': cancelledVouchersCountDay,
              'Cancelled vouchers value': cancelledVouchersValueDay ? cancelledVouchersValueDay : 0,
              key: dateKey,
              date: dateFormatted,
            });

            adminStatisticsGiftedCount.push({ data: giftedCountDay, key: dateKey, date: dateFormatted });
            adminStatisticsGiftedValue.push({ data: giftedValueDay, key: dateKey, date: dateFormatted });

            console.log('adminStatisticsCompanies 2222 today', totalCompaniesDay, totalCampaignsDay);

            adminStatisticsCompanies.push({
              'Total companies': totalCompaniesDay,
              'New companies': newCompaniesDay,
              'Deleted companies': deletedCompaniesDay,
              key: dateKey,
              date: dateFormatted,
            });
            adminStatisticsCampaigns.push({
              'Total campaigns': totalCampaignsDay,
              'New campaigns': newCampaignsDay,
              'Ended campaigns': endedCampaignsDay,
              key: dateKey,
              date: dateFormatted,
            });
            adminStatisticsCustomers.push({
              'Total clients': totalClientsDay,
              'New clients': newClientsDay,
              'Deleted clients': deletedClientsDay,
              key: dateKey,
              date: dateFormatted,
            });

            console.log('adminStatisticsCancelledVouchers', adminStatisticsCancelledVouchers);

            adminStatistics1Day = {
              ordersCount: adminStatistics1Day.ordersCount + dayData.ordersCount,
              salesCount: adminStatistics1Day.salesCount + dayData.salesCount,
              salesValue: adminStatistics1Day.salesValue + dayData.salesValue,
              giftedCount: adminStatistics1Day.giftedCount + dayData.giftedCount,
              giftedValue: adminStatistics1Day.giftedValue + dayData.giftedValue,
              redemptionsCount: adminStatistics1Day.redemptionsCount + dayData.redemptionsCount,
              redemptionsValue: adminStatistics1Day.redemptionsValue + dayData.redemptionsValue,
              refundsCount: adminStatistics1Day.refundsCount + dayData.refundsCount,
              refundsValue: adminStatistics1Day.refundsValue + dayData.refundsValue,
              cancelledVouchersCount: adminStatistics1Day.cancelledVouchersCount + dayData.cancelledVouchersCount,
              cancelledVouchersValue: adminStatistics1Day.cancelledVouchersValue + dayData.cancelledVouchersValue,
              newCompanies: adminStatistics1Day.newCompanies + dayData.newCompanies,
              deletedCompanies: adminStatistics1Day.deletedCompanies + dayData.deletedCompanies,

              newCampaigns: adminStatistics1Day.newCampaigns + dayData.newCampaigns,
              endedCampaigns: adminStatistics1Day.endedCampaigns + dayData.endedCampaigns,
              newClients: adminStatistics1Day.newClients + dayData.newClients,
              deletedClients: adminStatistics1Day.deletedClients + dayData.deletedClients,
            };

            adminStatistics1Week = {
              ordersCount: adminStatistics1Week.ordersCount + dayData.ordersCount,
              salesCount: adminStatistics1Week.salesCount + dayData.salesCount,
              salesValue: adminStatistics1Week.salesValue + dayData.salesValue,
              giftedCount: adminStatistics1Week.giftedCount + dayData.giftedCount,
              giftedValue: adminStatistics1Week.giftedValue + dayData.giftedValue,
              redemptionsCount: adminStatistics1Week.redemptionsCount + dayData.redemptionsCount,
              redemptionsValue: adminStatistics1Week.redemptionsValue + dayData.redemptionsValue,
              refundsCount: adminStatistics1Week.refundsCount + dayData.refundsCount,
              refundsValue: adminStatistics1Week.refundsValue + dayData.refundsValue,
              cancelledVouchersCount: adminStatistics1Week.cancelledVouchersCount + dayData.cancelledVouchersCount,
              cancelledVouchersValue: adminStatistics1Week.cancelledVouchersValue + dayData.cancelledVouchersValue,
              newCompanies: adminStatistics1Week.newCompanies + dayData.newCompanies,
              deletedCompanies: adminStatistics1Week.deletedCompanies + dayData.deletedCompanies,

              newCampaigns: adminStatistics1Week.newCampaigns + dayData.newCampaigns,
              endedCampaigns: adminStatistics1Week.endedCampaigns + dayData.endedCampaigns,
              newClients: adminStatistics1Week.newClients + dayData.newClients,
              deletedClients: adminStatistics1Week.deletedClients + dayData.deletedClients,
            };

            adminStatistics1Month = {
              ordersCount: adminStatistics1Month.ordersCount + dayData.ordersCount,
              salesCount: adminStatistics1Month.salesCount + dayData.salesCount,
              salesValue: adminStatistics1Month.salesValue + dayData.salesValue,
              giftedCount: adminStatistics1Month.giftedCount + dayData.giftedCount,
              giftedValue: adminStatistics1Month.giftedValue + dayData.giftedValue,
              redemptionsCount: adminStatistics1Month.redemptionsCount + dayData.redemptionsCount,
              redemptionsValue: adminStatistics1Month.redemptionsValue + dayData.redemptionsValue,
              refundsCount: adminStatistics1Month.refundsCount + dayData.refundsCount,
              refundsValue: adminStatistics1Month.refundsValue + dayData.refundsValue,
              cancelledVouchersCount: adminStatistics1Month.cancelledVouchersCount + dayData.cancelledVouchersCount,
              cancelledVouchersValue: adminStatistics1Month.cancelledVouchersValue + dayData.cancelledVouchersValue,
              newCompanies: adminStatistics1Month.newCompanies + dayData.newCompanies,
              deletedCompanies: adminStatistics1Month.deletedCompanies + dayData.deletedCompanies,

              newCampaigns: adminStatistics1Month.newCampaigns + dayData.newCampaigns,
              endedCampaigns: adminStatistics1Month.endedCampaigns + dayData.endedCampaigns,
              newClients: adminStatistics1Month.newClients + dayData.newClients,
              deletedClients: adminStatistics1Month.deletedClients + dayData.deletedClients,
            };

            adminStatistics1Year = {
              ordersCount: adminStatistics1Year.ordersCount + dayData.ordersCount,
              salesCount: adminStatistics1Year.salesCount + dayData.salesCount,
              salesValue: adminStatistics1Year.salesValue + dayData.salesValue,
              giftedCount: adminStatistics1Year.giftedCount + dayData.giftedCount,
              giftedValue: adminStatistics1Year.giftedValue + dayData.giftedValue,
              redemptionsCount: adminStatistics1Year.redemptionsCount + dayData.redemptionsCount,
              redemptionsValue: adminStatistics1Year.redemptionsValue + dayData.redemptionsValue,
              refundsCount: adminStatistics1Year.refundsCount + dayData.refundsCount,
              refundsValue: adminStatistics1Year.refundsValue + dayData.refundsValue,
              cancelledVouchersCount: adminStatistics1Year.cancelledVouchersCount + dayData.cancelledVouchersCount,
              cancelledVouchersValue: adminStatistics1Year.cancelledVouchersValue + dayData.cancelledVouchersValue,
              newCompanies: adminStatistics1Year.newCompanies + dayData.newCompanies,
              deletedCompanies: adminStatistics1Year.deletedCompanies + dayData.deletedCompanies,

              newCampaigns: adminStatistics1Year.newCampaigns + dayData.newCampaigns,
              endedCampaigns: adminStatistics1Year.endedCampaigns + dayData.endedCampaigns,
              newClients: adminStatistics1Year.newClients + dayData.newClients,
              deletedClients: adminStatistics1Year.deletedClients + dayData.deletedClients,
            };

            adminStatisticsAllTime = {
              ordersCount: adminStatisticsAllTime.ordersCount + dayData.ordersCount,
              salesCount: adminStatisticsAllTime.salesCount + dayData.salesCount,
              salesValue: adminStatisticsAllTime.salesValue + dayData.salesValue,
              giftedCount: adminStatisticsAllTime.giftedCount + dayData.giftedCount,
              giftedValue: adminStatisticsAllTime.giftedValue + dayData.giftedValue,
              redemptionsCount: adminStatisticsAllTime.redemptionsCount + dayData.redemptionsCount,
              redemptionsValue: adminStatisticsAllTime.redemptionsValue + dayData.redemptionsValue,
              refundsCount: adminStatisticsAllTime.refundsCount + dayData.refundsCount,
              refundsValue: adminStatisticsAllTime.refundsValue + dayData.refundsValue,
              cancelledVouchersCount: adminStatisticsAllTime.cancelledVouchersCount + dayData.cancelledVouchersCount,
              cancelledVouchersValue: adminStatisticsAllTime.cancelledVouchersValue + dayData.cancelledVouchersValue,
            };
          }
        }
      }

    //  console.log('adminStatistics1Day', adminStatistics1Day);
    //  console.log('adminStatistics1Month', adminStatistics1Month);

      console.log('adminStatisticsCancelledVouchers', adminStatisticsCancelledVouchers);

      console.log('adminStatisticsRefunds', adminStatisticsRefunds);

      dispatch({
        type: GET_ADMIN_DASHBOARD,
        payload: adminStatistics,
        adminStatistics1Day,
        adminStatistics1Week,
        adminStatistics1Month,
        adminStatistics1Year,
        adminStatisticsAllTime,

        adminStatisticsFaceValueSales,
        adminStatisticsSalesValue,
        adminStatisticsSalesCount,
        adminStatisticsCommission,
        adminStatisticsRedemptionsCount,
        adminStatisticsRedemptionsValue,
      //  adminStatisticsRefundsCount,
      //  adminStatisticsRefundsValue,
        adminStatisticsRefunds,
        adminStatisticsCancelledVouchers,
        adminStatisticsRedemptionsPercentages,
        adminStatisticsRedemptions,

        adminStatisticsGiftedCount,
        adminStatisticsGiftedValue,

        adminStatisticsCompanies,
        adminStatisticsCampaigns,
        adminStatisticsCustomers,
      })

      return resolve('success');

    }
});


export const getAdminDashboardCustom = (customStartDate, customEndDate) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  const { admin } = getState();

  const { adminDashboardStripeRawData, adminDashboard } = admin;

  console.log('getAdminDashboardCustom', adminDashboard);

    let salesCount = 0;
    let refundedCount = 0;
    let amountCaptured = 0;
    let amountRefunded = 0;
    let commisionTaken = 0;

    let salesPerCompany = {};
    let companyNames = {};

    let salesPerCampaign = {};
    let campaignNames = {};

    if (adminDashboardStripeRawData) {
      Object.keys(adminDashboardStripeRawData).map((key) => {
        const data = adminDashboardStripeRawData[key];

        if (customStartDate && customEndDate) {
          const dateStart = moment(customStartDate);
          const dateEnd = moment(customEndDate);

          if (moment(data.created * 1000) < dateStart) {
            return null;
          }
          if (moment(data.created * 1000) > dateEnd) {
            return null;
          }
        }

        salesCount = salesCount + 1;
        amountCaptured = amountCaptured + data.amount_captured;
        amountRefunded = amountRefunded + data.amount_refunded;

        if (data.application_fee) {
          commisionTaken = commisionTaken + data.application_fee_amount;
        }

        if (data.metadata && data.metadata.companyId) {
          if (salesPerCompany[data.metadata.companyId]) {
            salesPerCompany[data.metadata.companyId] = salesPerCompany[data.metadata.companyId] + data.amount_captured;
            if (!companyNames[data.metadata.companyId]) {
              companyNames[data.metadata.companyId] = data.metadata.companyName;
            }
          } else {
            companyNames[data.metadata.companyId] = data.metadata.companyName;
            salesPerCompany[data.metadata.companyId] = data.amount_captured;
          }
        }

        if (data.metadata && data.metadata.voucherId) {
          if (salesPerCampaign[data.metadata.voucherId]) {
            salesPerCampaign[data.metadata.voucherId] = salesPerCampaign[data.metadata.voucherId] + data.amount_captured;
            if (!campaignNames[data.metadata.voucherId]) {
              campaignNames[data.metadata.voucherId] = data.metadata.voucherName;
            }
          } else {
            campaignNames[data.metadata.voucherId] = data.metadata.voucherName;
            salesPerCampaign[data.metadata.voucherId] = data.amount_captured;
          }

        }

        if (data.refunded) {
          refundedCount = refundedCount + 1;
        }
        return null;
      });

      let companiesArr = [];
      let campaignsArr = [];

      Object.keys(salesPerCompany).map(key => {
        companiesArr.push({ id: key, sales: salesPerCompany[key], name: companyNames[key] });
        return null;
      });

      companiesArr.sort((a, b) => {
        if ( a.sales < b.sales ) {
          return 1;
        }
        if ( a.sales > b.sales ) {
          return -1;
        }
        return 0;
      });

      Object.keys(salesPerCampaign).map(key => {
        campaignsArr.push({ id: key, sales: salesPerCampaign[key], name: campaignNames[key] });
        return null;
      });

      campaignsArr.sort((a, b) => {
        if ( a.sales < b.sales ) {
          return 1;
        }
        if ( a.sales > b.sales ) {
          return -1;
        }
        return 0;
      });

      const topCompanies = companiesArr.slice(0, 5);
      const topCampaigns = campaignsArr.slice(0, 5);

      dispatch({
        type: GET_ADMIN_DASHBOARD_STRIPE_CUSTOM_DATA,
        payload: {
          salesCount,
          refundedCount,
          amountCaptured: amountCaptured / 100,
          amountRefunded: amountRefunded / 100,
          commisionTaken: commisionTaken / 100,
          topCompanies,
          topCampaigns,

        },
      })
    }

      let adminStatisticsCustom = {
        ordersCount: 0,
        salesCount: 0,
        salesValue: 0,
        giftedCount: 0,
        giftedValue: 0,
        redemptionsCount: 0,
        redemptionsValue: 0,
        newCompanies: 0,
        deletedCompanies: 0,
        newCampaigns: 0,
        newClients: 0,
      };

      Object.keys(adminDashboard).map((key) => {
        const data = adminDashboard[key];

        if (customStartDate && customEndDate) {
          const dateStart = moment(customStartDate);
          const dateEnd = moment(customEndDate);

          if (moment(data.date) < dateStart) {
            return null;
          }
          if (moment(data.date) > dateEnd) {
            return null;
          }
        }

        adminStatisticsCustom = {
          ordersCount: adminStatisticsCustom.ordersCount + data.ordersCount,
          salesCount: adminStatisticsCustom.salesCount + data.salesCount,
          salesValue: adminStatisticsCustom.salesValue + data.salesValue,
          giftedCount: adminStatisticsCustom.giftedCount + data.giftedCount,
          giftedValue: adminStatisticsCustom.giftedValue + data.giftedValue,
          redemptionsCount: adminStatisticsCustom.redemptionsCount + data.redemptionsCount,
          redemptionsValue: adminStatisticsCustom.redemptionsValue + data.redemptionsValue,

          newCompanies: adminStatisticsCustom.newCompanies + data.newCompanies,
          deletedCompanies: adminStatisticsCustom.deletedCompanies + data.deletedCompanies,
          newCampaigns: adminStatisticsCustom.newCampaigns + data.newCampaigns,
          newClients: adminStatisticsCustom.newClients + data.newClients,
        };
        return null;
      });

      dispatch({
        type: GET_ADMIN_DASHBOARD_CUSTOM,
        payload: adminStatisticsCustom,
      })

      return resolve('success');

});

export const getPayments = (typeFilter, timeFrame, customStartDate, customEndDate) => (dispatch) => new Promise(async (resolve, reject) => {
  let dateLength = 1;
  let startDate;
  let endDate;
//  const dateNow = moment();

//  let nowDay;
//  let nowMonth;
//  let nowYear;

//  const dateNowSplit = dateNow.format("DD-MM-YYYY").split("-");

//  nowDay = dateNowSplit[0];
//  nowMonth = dateNowSplit[1];
//  nowYear = dateNowSplit[2];

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);
    endDate = moment(customEndDate);
  } else if (timeFrame === 'all') {
    let allStartDate = new Date(2021, 1, 1);
    startDate = moment(allStartDate);

    endDate = moment().add(1, 'days');
  } else {
    if (timeFrame === '4weeks') {
      dateLength = 30;
    } else if (timeFrame === '1day') {
      dateLength = 1;
    } else if (timeFrame === '1week') {
      dateLength = 7;
    } else if (timeFrame === '2weeks') {
      dateLength = 14;
    } else if (timeFrame === '6months') {
      dateLength = 183;
    }

    startDate = moment().subtract(dateLength, 'days');

    endDate = moment().add(1, 'days');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  const startDay = startDateSplit[0];
  const startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];

    const paymentsRef = await firestore.collection('ordersFinished')
      .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
      .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`)).get();

      if (!paymentsRef.docs) {
        return resolve();
      }

      let data = {};

      paymentsRef.docs.map(doc => {
        const docData = doc.data();
        const id = doc.id;

        data[id] = docData;
        return null;
      });

    if (data) {
      dispatch({
        type: GET_ADMIN_PAYMENTS,
        payload: data,
        timeFrame,
        typeFilter,
      });
  }

  return resolve();
});


export const sortPayments = (typeFilter, sortColumn, sortType) => (dispatch) => new Promise(async (resolve, reject) => {
  return resolve(dispatch({
    type: SORT_ADMIN_PAYMENTS,
    typeFilter,
    sortColumn,
    sortType,
  }));
});



export const getSingleAdminPayment = (dbId) => (dispatch) => new Promise(async (resolve, reject) => {
  const paymentRef = await firestore.collection('ordersFinished').doc(dbId).get();

  if (!paymentRef.exists) {
    return resolve();
  }

  const data = paymentRef.data();

  if (data) {
    dispatch({
      type: GET_SINGLE_ADMIN_PAYMENT,
      dbId,
      payload: data,
    })
  }

  return resolve();
});


export const getAdminPayments = (typeFilter, timeFrame, customStartDate, customEndDate, orderBy, sortType, filterValue) => (dispatch) => new Promise(async (resolve, reject) => {
  let dateLength = 1;
  let startDate;
  let endDate;

  console.log('getAdminPayments', { typeFilter, timeFrame, customStartDate, customEndDate, orderBy, sortType, filterValue });

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);
    endDate = moment(customEndDate);
  } else {
    if (timeFrame === '4weeks') {
      dateLength = 30;
    }

    startDate = moment().subtract(dateLength, 'days');
    endDate = moment().add(1, 'days');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  const startDay = startDateSplit[0];
  const startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];


  if (orderBy && orderBy !== 'paymentDate') {
    let paymentsQuery = firestore
      .collection('ordersFinished')
      .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
      .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
    //  .orderBy(orderBy, sortType)
    //  .limit(15)

      if (filterValue) {
        paymentsQuery = firestore
          .collection('ordersFinished')
          .where('keyWords', 'array-contains', filterValue.toLowerCase())
          .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
      }

      const paymentsRef = await paymentsQuery.get();

      if (!paymentsRef.docs) {
        return resolve();
      }

      let data = {};
      let lastKey;

      paymentsRef.docs.map((doc, index) => {
        const docData = doc.data();
        const id = doc.id;

        data[id] = docData;

        if (index === paymentsRef.docs.length - 1) {
          lastKey = id;
        }
      });

      if (data) {
        dispatch({
          type: GET_FIRST_ADMIN_PAYMENTS,
          payload: data,
          lastKey,
          timeFrame,
          orderBy,
          sortType,
        })
    }

    return resolve();
  } else {
    let paymentsQuery = firestore
      .collection('ordersFinished')
      .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
      .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
      .orderBy('paymentDate', sortType)
      .limit(15)

      if (filterValue) {
        paymentsQuery = firestore
          .collection('ordersFinished')
          .where('keyWords', 'array-contains', filterValue.toLowerCase())
          .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
          .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
          .orderBy('paymentDate', sortType)
          .limit(15)
      }

      const paymentsRef = await paymentsQuery.get();

      if (!paymentsRef.docs) {
        return resolve();
      }

      let data = {};
      let lastKey;

      paymentsRef.docs.map((doc, index) => {
        const docData = doc.data();
        const id = doc.id;

        data[id] = docData;

        if (index === paymentsRef.docs.length - 1) {
          lastKey = id;
        }
      });

      if (data) {
        dispatch({
          type: GET_FIRST_ADMIN_PAYMENTS,
          payload: data,
          lastKey,
          timeFrame,
          orderBy,
          sortType,
        })
    }

    return resolve();
  }
});

export const getMoreAdminPayments = (typeFilter, timeFrame, customStartDate, customEndDate, orderBy, sortType, filterValue) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  const { admin } = getState();

  const { lastPaymentId } = admin;

  if (!lastPaymentId) {
    return resolve();
  }

  let dateLength = 1;
  let startDate;
  let endDate;

  if (timeFrame === 'custom') {
    startDate = moment(customStartDate);
    endDate = moment(customEndDate);
  } else {
    if (timeFrame === '4weeks') {
      dateLength = 30;
    }

    startDate = moment().subtract(dateLength, 'days');
    endDate = moment().add(1, 'days');
  }

  const startDateSplit = startDate.format("DD-MM-YYYY").split("-");
  const endDateSplit = endDate.format("DD-MM-YYYY").split("-");

  const startDay = startDateSplit[0];
  const startMonth = startDateSplit[1];
  const startYear = startDateSplit[2];

  const endDay = endDateSplit[0];
  const endMonth = endDateSplit[1];
  const endYear = endDateSplit[2];

  var lastPayment = firestore.collection("ordersFinished").doc(lastPaymentId);

    lastPayment.get().then(async (doc) => {
      if (doc.exists) {

        if (orderBy && orderBy !== 'paymentDate') {
          let paymentsRef;
          if (orderBy === 'redeemed') {
            paymentsRef = await firestore
              .collection('ordersFinished')
              .orderBy(orderBy, sortType)
              .orderBy('refunded', sortType)
              .startAfter(doc)
              .limit(10)
              .get();
          } else {
            paymentsRef = await firestore
              .collection('ordersFinished')
              .orderBy(orderBy || 'paymentDate', sortType)
              .startAfter(doc)
              .limit(10)
              .get();
          }

            if (!paymentsRef.docs) {
              return resolve();
            }

            let data = {};
            let lastKey;

            paymentsRef.docs.map((doc, index) => {
              const docData = doc.data();
              const id = doc.id;

              data[id] = docData;

              if (index === paymentsRef.docs.length - 1) {
                lastKey = id;
              }
            });

          if (data) {
            dispatch({
              type: GET_MORE_ADMIN_PAYMENTS,
              payload: data,
              lastKey,
              timeFrame,
              orderBy,
              sortType,
            });

            return resolve();
          }
        } else {
          let paymentsQuery = firestore
            .collection('ordersFinished')
            .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
            .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
            .orderBy('paymentDate', sortType)
            .startAfter(doc)
            .limit(10)

            if (filterValue) {
              paymentsQuery = firestore
                .collection('ordersFinished')
                .where('keyWords', 'array-contains', filterValue)
                .where('paymentDate', ">=", new Date(`${startYear}-${startMonth}-${startDay}`))
                .where('paymentDate', "<", new Date(`${endYear}-${endMonth}-${parseInt(endDay)}`))
                .orderBy('paymentDate', sortType)
                .startAfter(doc)
                .limit(10)
            }

            const paymentsRef = await paymentsQuery.get();

            if (!paymentsRef.docs) {
              return resolve();
            }

            let data = {};
            let lastKey;

            paymentsRef.docs.map((doc, index) => {
              const docData = doc.data();
              const id = doc.id;

              data[id] = docData;

              if (index === paymentsRef.docs.length - 1) {
                lastKey = id;
              }
            });

          if (data) {
            dispatch({
              type: GET_MORE_ADMIN_PAYMENTS,
              payload: data,
              lastKey,
              timeFrame,
              orderBy,
              sortType,
            });

            return resolve();
          }
        }

      }
    })
});

export const openVoucherCodePreview = (dbId) => (dispatch) => new Promise(async (resolve, reject) => {
  let orderRef = firestore.collection('orders').doc(dbId);

  const orderSnap = await orderRef.get();

  if (orderSnap.exists) {
    const orderData = orderSnap.data();

    console.log('openVoucherCodePreview code', orderData.code);

    return resolve({ data: orderData.code });
  } else {
    return resolve({ data: null });
  }
});

export const refundStripePayment = (chargeId, dbId, refundType) => (dispatch) => new Promise(async (resolve, reject) => {
  const refundStripePaymentFunction = functions.httpsCallable('refundStripePayment');

  console.log('refundStripePayment', chargeId, dbId, refundType);

  return refundStripePaymentFunction({
    chargeId,
    dbId,
    refundType,
  }).then(async (result) => {
    return resolve();
  });
});


export const cancelVoucher = (chargeId, dbId) => (dispatch) => new Promise(async (resolve, reject) => {
  let orderRef = firestore.collection('orders').where('chargeId', '==', chargeId);

  const orderSnap = await orderRef.get();

  console.log('cancelVoucher', chargeId, dbId);

  if (orderSnap.docs && orderSnap.docs[0]) {
    const orderId = orderSnap.docs[0].id;
    const orderData = orderSnap.docs[0].data();

    const code = orderData.code;

    console.log('cancelVoucher code', code);

  /*  const deleteShopifyVoucherFunction = functions.httpsCallable('deleteShopifyVoucherTest');

    return deleteShopifyVoucherFunction({
      code,
    }).then(async (result) => {
      
    }); */

    
    const deleteShopifyVoucherFunction = functions.httpsCallable('deleteShopifyVoucher');

    return deleteShopifyVoucherFunction({
      code,
    }).then(async (result) => {
       const cancelShopifyVoucherFunction = functions.httpsCallable('cancelShopifyVoucher');

      return cancelShopifyVoucherFunction({
        orderId,
      }).then(async (result) => {
        return resolve();
      });
    });
  } else {
    return resolve();
  }
});


export const resendGiftingEmail = (chargeId, dbId, newEmail) => (dispatch) => new Promise(async (resolve, reject) => {
  const resendGiftingEmailFunction = functions.httpsCallable('resendGiftingEmail');

  return resendGiftingEmailFunction({
    chargeId,
    dbId,
    newEmail,
  }).then(async (result) => {
    if (!result.data) {
      return reject('error');
    } else if (result.data.status === 'error') {
      return reject(result.data.error);
    }
    return resolve();
  }).catch(err => {
    console.log(err);
    return reject(err);
  });
});


export const openCompanyDashboard = (companyId, companyName, path) => (dispatch) => new Promise(async (resolve, reject) => {
  if (companyId && companyName) {
    dispatch({
      type: OPEN_COMPANY_DASHBOARD,
      payload: companyId,
      companyName,
      path,
    });

    return resolve({ status: 'success' })
  } else {
    return resolve();
  }
});

export const leaveCompanyDashboard = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  const { admin } = getState();

  dispatch({
    type: LEAVE_COMPANY_DASHBOARD,
  });

  return resolve();
});

export const sendVerificationEmailAsAdmin = (userId) => (dispatch) => new Promise(async (resolve, reject) => {
  const sendVerificationEmailAsAdminFunction = functions.httpsCallable('sendVerificationEmailAsAdmin');

  return sendVerificationEmailAsAdminFunction({
      userId,
    }).then(async (result) => {
      console.log('result', result);

      return resolve(result);
    });

});


export const sendAppVerificationEmailAsAdmin = (userId) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('sendAppVerificationEmailAsAdmin', userId);

  const sendAppVerificationEmailAsAdminFunction = functions.httpsCallable('sendAppVerificationEmailAsAdmin');

  return sendAppVerificationEmailAsAdminFunction({
    uid: userId,
  }).then(async (result) => {
    console.log('result', result);

    return resolve(result);
  });

});


export const cancelUnverifiedCompany = (userId) => (dispatch) => new Promise(async (resolve, reject) => {
  const cancelUnverifiedCompanyFunction = functions.httpsCallable('cancelUnverifiedCompany');

  return cancelUnverifiedCompanyFunction({
    userId,
  }).then(async (result) => {
    console.log('result', result);

    return resolve(result);
  });

});





export const getEmailGretingData = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getEmailGretingData');
  const state = getState();

  const pageRef = firestore.collection('emailGreeting').doc('emailGreeting');
  const snapshot = await pageRef.get();

  const page2Ref = firestore.collection('emailGreeting').doc('emailGreetingFrom');
  const snapshot2 = await page2Ref.get();

  const page3Ref = firestore.collection('emailGreeting').doc('emailGreetingSubject');
  const snapshot3 = await page3Ref.get();

  const page4Ref = firestore.collection('emailGreeting').doc('emailGreetingFromEmail');
  const snapshot4 = await page4Ref.get();

  let emailGreetingFrom = null;
  let emailGreetingFromEmail = null;
  let emailGreetingSubject = null;

  if (snapshot2.exists) {
    emailGreetingFrom = snapshot2.data();
  }

  if (snapshot3.exists) {
    emailGreetingSubject = snapshot3.data();
  }

  if (snapshot3.exists) {
    emailGreetingFromEmail = snapshot4.data();
  }

  if (snapshot.exists) {
    const data = snapshot.data();

    console.log('data', { data });

    dispatch({
      type: GREETING_TAB,
      payload: data.data,
    });

    return resolve({
      emailGreeting: data.data,
      emailGreetingFrom: emailGreetingFrom ? emailGreetingFrom.data : '',
      emailGreetingFromEmail: emailGreetingFromEmail ? emailGreetingFromEmail.data : '',
      emailGreetingSubject: emailGreetingSubject ? emailGreetingSubject.data : '',
    });
  } else {
    return resolve();
  }
});


export const updateEmailGreetingData = (emailGreeting, emailGreetingFrom, emailGreetingFromEmail, emailGreetingSubject, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('updateEmailGreetingData', { emailGreeting, emailGreetingFrom, emailGreetingFromEmail, emailGreetingSubject });
  if (!emailGreeting) return reject('no data');

  const pageRef = firestore.collection('emailGreeting').doc('emailGreeting');
  const page2Ref = firestore.collection('emailGreeting').doc('emailGreetingFrom');
  const page3Ref = firestore.collection('emailGreeting').doc('emailGreetingFromEmail');
  const page4Ref = firestore.collection('emailGreeting').doc('emailGreetingSubject');

  await pageRef.set({ 'data': emailGreeting }, { merge: true }).then(async (res) => {
    dispatch({
      type: GREETING_TAB,
      payload: emailGreeting,
    });

    await page2Ref.set({ 'data': emailGreetingFrom }, { merge: true });
    await page3Ref.set({ 'data': emailGreetingFromEmail }, { merge: true });
    await page4Ref.set({ 'data': emailGreetingSubject }, { merge: true });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateEmailGreeting',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data: emailGreeting,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  return resolve();
});


export const getGiftingPrice = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getGiftingPrice');
  const state = getState();

  const pageRef = firestore.collection('giftingPrice').doc('giftingPrice');
  const snapshot = await pageRef.get();

  if (snapshot.exists) {
    const data = snapshot.data();

    console.log('getGiftingPrice data', { data });

    dispatch({
      type: GIFTING_PRICE,
      payload: data.giftingPrice,
    });

    return resolve(data.giftingPrice);
  } else {
    return resolve();
  }
});


export const updateGiftingPrice = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('updateGiftingPrice', { data });
  if (!data) return reject('no data');

  const pageRef = firestore.collection('giftingPrice').doc('giftingPrice');

  await pageRef.set({ 'giftingPrice': data }, { merge: true }).then(async (res) => {
    dispatch({
      type: GIFTING_PRICE,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updateGiftingPriceData',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  return resolve();
});

export const getPreviewLink = () => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('action - getPreviewLink');
  const state = getState();

  const pageRef = firestore.collection('appSettings').doc('giftPreviewLink');
  const snapshot = await pageRef.get();

  if (snapshot.exists) {
    const data = snapshot.data();

    console.log('getPreviewLink data', { data });

    dispatch({
      type: APP_GIFT_PREVIEW_LINK,
      payload: data.giftPreviewLink,
    });

    return resolve(data.giftPreviewLink);
  } else {
    return resolve();
  }
});

export const updatePreviewLink = (data, adminName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('updatePreviewLink', { data });
  if (!data) return reject('no data');

  const pageRef = firestore.collection('appSettings').doc('giftPreviewLink');

  await pageRef.set({ 'giftPreviewLink': data }, { merge: true }).then(async (res) => {
    dispatch({
      type: APP_GIFT_PREVIEW_LINK,
      payload: data,
    });

    const currentUser = auth.currentUser;

    const adminActionRef = firestore.collection('adminActions');

    await adminActionRef.add({
      id: 'updatePreviewLink',
      time: firebase.firestore.FieldValue.serverTimestamp(),
      uid: currentUser.uid || null,
      data,
      name: adminName || null,
    });

    return resolve('success');

    console.log(res);
  }).catch(err => {
    return resolve('error');

    console.log(err);
  });

  return resolve();
});


export const sendTestGiftingEmail = (toEmail) => (dispatch) => new Promise(async (resolve, reject) => {
  const sendTestGiftingEmailFunction = functions.httpsCallable('sendTestGiftingEmail');

  return sendTestGiftingEmailFunction({ toEmail }).then(async (result) => {
    console.log('result', result);

    return resolve(result);
  });

});




export const setCompanyAccountToLinkedCompaniesAccount = (companyId, value) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('setCompanyAccountToLinkedCompaniesAccount', { companyId, value });
  if (!companyId) return reject('no data');

  // linkedCompaniesAccount

  const pageRef = firestore.collection('appSettings').doc('giftPreviewLink');

  const companyRef = firestore.collection('companies').doc(companyId);
  const ownerRef = firestore.collection('owners').doc(companyId);
  const userRef = firestore.collection('users').doc(companyId);

  await companyRef.update({
    linkedCompaniesAccount: value ? true : false,
    allValidApis: false,
    someValidApis: false,
  });

  await ownerRef.update({
    linkedCompaniesAccount: value ? true : false,
    allValidApis: false,
    someValidApis: false,
  });

  await userRef.update({
    linkedCompaniesAccount: value ? true : false,
    allValidApis: false,
    someValidApis: false,
  });

  return resolve('success');
});