
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

/*
import {
  Row,
  Col,
} from 'reactstrap'; */

// import 'rsuite-table/dist/css/rsuite-table.css';

// import TinySlider from "tiny-slider-react";
// import 'tiny-slider/dist/tiny-slider.css';

import { connect } from 'react-redux';

import image from '../../../assets/AboutUsImage.webp';

class AboutUs extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    admin: PropTypes.shape(),
  };

  static defaultProps = {
    user: {},
    admin: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      enabled: false,
      activeTab: 2,
    };
  }

  componentDidMount() {
    const { user } = this.props;
  }

  fetchDisablePerksTab = () => {
    const { fetchDisablePerksTab } = this.props;

    fetchDisablePerksTab().then((res2) => {
      console.log('fetchDisablePerksTab', res2);

      this.setState({ disablePerks: res2 });
    })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  setImageLoaded = (id) => {
    this.setState({ [id]: true });
  }

  render() {
    const { user } = this.props;
    const {
      logoLoaded,
    } = this.state;

    return (
      <div>
        <div class="breadcrumbs">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="breadcrumbs-content">
                  <h1 class="page-title">About Us</h1>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <ul class="breadcrumb-nav">
                  <li><a href="/">Home</a></li>
                  <li>About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section class="about-us section">
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-6 col-md-12 col-12">
                <div class="content-left wow fadeInLeft" data-wow-delay=".3s">
                  <LazyLoadImage
                    alt={''}
                    src={image}
                    onLoad={() => { this.setImageLoaded(`logoLoaded`) }}
                  />
                  {logoLoaded ? (null) : (
                    <div class="image-placeholder" />
                  )}
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-12">
                <div class="content-right wow fadeInRight" data-wow-delay=".5s">
                  <span class="sub-heading">About</span>
                  <h2>
                    About Steppr</h2>
                  <p style={{ marginBottom: 15 }}>Steppr launched in 2022 as a useful and fair tool to help small businesses reach new customers. Businesses use the platform for free, and customers gain significant discounts on their products (vouchers), a win-win.</p>
                  <p>We've continued to grow Steppr's capabilities and offerings, while remaining free for businesses to use and financially rewarding for customers. We have a significant focus on enabling vouchers to be easily and elegantly gifted.</p>
                  <h3>How It Works</h3>
                  <p>Businesses offer vouchers to customers with progressive discounts. The more customers repeat a purchase, the higher the discount (up to 3x). We call the progressive discounts 'steps', hence 'Steppr'!</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="our-achievement section" style={{ background: "#00aeff" }}>
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-12">
                <div class="single-achievement wow fadeInUp" data-wow-delay=".2s">
                  <h3 class="counter"><span id="secondo1" class="countup" cup-end="1250">200</span>+</h3>
                  <p> Businesses on Steppr</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="single-achievement wow fadeInUp" data-wow-delay=".4s">
                  <h3 class="counter"><span id="secondo2" class="countup" cup-end="350">2</span></h3>
                  <p>Customer-Facing Native Apps</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="single-achievement wow fadeInUp" data-wow-delay=".6s">
                  <h3 class="counter"><span id="secondo3" class="countup" cup-end="2500">UK Focus</span></h3>
                  <p>Cater to UK Businesses & Customers</p>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="single-achievement wow fadeInUp" data-wow-delay=".6s">
                  <h3 class="counter"><span id="secondo3" class="countup" cup-end="250">2022</span>+</h3>
                  <p>Join Our Journey!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userData || {},
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AboutUs));









