import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';
import reducers from '../reducers/index';


// const reduxPersistConfig = { key: 'root', storage, blacklist: ['customer', 'vouchers'] };

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['customer', 'user', 'vouchers'],
}

const persistedReducer = persistReducer(persistConfig, reducers);

// let store = createStore(persistedReducer);
// let persistor = persistStore(store);

/*
export default () => {
  
  return { store, persistor }
}
*/

/*
const configureStore = () => {
  const reducer = persistCombineReducers(reduxPersistConfig, reducers);

  const store = createStore(reducer, applyMiddleware(thunk));
  const persistor = persistStore(store, null, () => { store.getState(); });

  return { persistor, store };
};

export default configureStore; */

export const store = createStore(persistedReducer, applyMiddleware(thunk));
 export const persistor = persistStore(store);

// export const store = createStore(reducers, applyMiddleware(thunk));