import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import { Card, CardBody, Input, Label, Form, FormGroup, Button, Alert, Spinner } from 'reactstrap';

import { verifyEmail, afterEmailLogin } from '../../actions/user';

import { auth } from '../../lib/firebase';

class ResetPassword extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }
  // static defaultProps = {}

  constructor(props) {
    super(props);
    this.state = {
      mode: null,
      uid: '',
      customToken: '',
      actionCode: '',
      newPassword: '',
      newPasswordConfirm: '',
      isCompanyUser: false,
    };
  }

  componentDidMount() {
  //  const { history, verifyUserEmail } = this.props;
    const { search } = window.location;

    console.log('componentDidMount');

    let email;
    let actionCode;
    let customToken;
    let isCompanyUser;

    try {
      const urlParams = new URLSearchParams(search);
      email = urlParams.get('email');
      actionCode = urlParams.get('actionCode');
      customToken = urlParams.get('customToken') || '';
      isCompanyUser = urlParams.get('isCompanyUser') || false;
    } catch (err) {
      email = this.getParameterByName('email');
      actionCode = this.getParameterByName('actionCode');
      customToken = this.getParameterByName('customToken') || '';
      isCompanyUser = this.getParameterByName('isCompanyUser') || false;
    }

    if (isCompanyUser === 'false') {
      isCompanyUser = false;
    }

    this.setState({ email, actionCode, customToken, isCompanyUser });
  }

  submitResetPassword = () => {
    const { history } = this.props;
    const { newPassword, newPasswordConfirm, actionCode, isCompanyUser } = this.state;

    console.log('submitResetPassword', newPassword, newPasswordConfirm, actionCode);

    if (!newPassword || !newPasswordConfirm) {
      this.setState({ error: 'Missing data' });
      return null;
    }

    if (newPassword !== newPasswordConfirm) {
      this.setState({ error: "Paswords don't match" });
      return null;
    }

    if (newPassword.length <= 6) {
      this.setState({ error: "New paswords must be at least 6 characters long" });
      return null;
    }

    if (newPassword && newPassword.length > 6) {
      // Save the new password.
      this.setState({ loading: true });

      auth.confirmPasswordReset(actionCode, newPassword).then((resp) => {
        console.log('submitResetPassword resp', resp);

        this.setState({ success: true, loading: false });
        // Password reset has been confirmed and new password updated.

        if (isCompanyUser) {
          history.push('/login');
        }

        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      }).catch((error) => {
        console.log('error');
        console.log(error);
        if (error.message) {
          this.setState({ error: error.message, loading: false });
        } else {
          this.setState({ error: error || 'Failed to change password', loading: false });
        }
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [event.target.name]: value,
    });
  }

  render() {
    const { error, success, email, actionCode, customToken, newPassword, newPasswordConfirm, isCompanyUser, loading } = this.state;

    console.log('render', email, actionCode, customToken, newPassword, newPasswordConfirm, isCompanyUser );

      return (
        <Card style={{ position: 'relative', textAlign: 'center', color: '#000', width: '30%', minWidth: 200, margin: 'auto', marginTop: 100 }}>
        <CardBody>
          Reset password
          {(!!error) && <Alert color={"danger"}>{error}</Alert>}
          {(!!success) && <Alert color={"success"}> Success </Alert>}

          {isCompanyUser ? (
            <> {(!!success) && <Button color={"success"} onClick={this.toLogin}> Login </Button>} </>
          ):(
            <> {(!!success) && <div > You can now login on the app </div>} </>
          )}

          <Form>
            <FormGroup>
              <Label for="newPassword">New password</Label>
              <Input
                type="password"
                name="newPassword"
                id="newPassword"
                placeholder="••••••••"
                value={newPassword}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">New password confirm</Label>
              <Input
                type="password"
                name="newPasswordConfirm"
                id="newPasswordConfirm"
                placeholder="••••••••"
                value={newPasswordConfirm}
                onChange={this.handleChange}
              />
            </FormGroup>
              <Button disabled={loading} id="sign-in-button" className="custom-primary-button" color="primary" onClick={this.submitResetPassword}>
                Change password
                {loading ? (
                  <Spinner animation="border" variant="primary" style={{ width: '1.3rem', height: '1.3rem', marginTop: 3 }}> {''} </Spinner>
                ) : (null)}
            </Button>
          </Form>
          </CardBody>
        </Card>
      );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  afterLogin: afterEmailLogin,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
