import { forEach, get } from 'lodash';

// import { firestore, auth, functions, storage } from '../../fb/firebase';

import { firestore, functions, storage, auth } from '../../lib/firebase';


import {
  ADD_GIFTING_DATA,
  GET_ORDERED_TEMPLATE,
  GET_SINGLE_ORDER,
  GET_GIFTING_PRICE,
  GET_SINGLE_VOUCHER,
  GET_SINGLE_VOUCHER_PURCHASED_LEVELS,
  GET_USER_VOUCHERS,
  CUSTOMER_GET_VOUCHER_CATEGORIES,
  CUSTOMER_GET_VOUCHER_COMPANIES,
  CUSTOMER_GET_VOUCHER_LIST,
  CUSTOMER_GET_VOUCHER_LIST_UNLOCKED,
  GET_VOUCHER_ORDERS,
  //SET_VOUCHER_LIST_FETCHING,
  //SET_VOUCHER_ORDERS_FETCHING,

  REMOVE_PAYMENT_DATA,
  SAVE_PAYMENT_DATA,
  GET_PAYMENT_PROGRESS,
} from '../index';

import { DB_TABLE_NAME } from '../../const/firebase';
// import { BUY_FOR } from '../../modules/const';
import { canBuyVoucher } from '../../modules/handlers';

export const getSingleVoucher = (voucher, userId) => (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('getSingleVoucher', voucher);

  const { customer } = getState();

  const { list } = customer;

  const singleVoucher = firestore.collection(DB_TABLE_NAME.vouchers).doc(voucher);

  singleVoucher.get().then((payload) => {
    const voucherData = payload.data();
    const companyData = firestore
      .collection("owners")
      .doc(voucherData.company.id)
      .get();

    const innerCompanyPromises = [];

    companyData.then(async (company) => {
      const companyDataObject = company.data();
      const companyId = company.id;
      const { primaryVoucherImage, logo } = companyDataObject;

      if (logo) {
        const logoPath = logo.split('.');
        const logoExtension = logoPath[1];
        const logoReference = storage.ref(logo);
        let logoImage;

        console.log('logo test 1', logo, logoExtension);

        innerCompanyPromises.push(logoReference.getDownloadURL().then((url) => {
          logoImage = url;
          companyDataObject.logoImage = url;
        }).catch((error) => {
          console.log('get image error', { error, logo });
          return resolve();
        }));
        // }
      }

      if (primaryVoucherImage) {
        const primaryVoucherImagePath = primaryVoucherImage.split('.');
        const primaryVoucherImageExtension = primaryVoucherImagePath[1];
        const primaryVoucherImageReference = storage.ref(primaryVoucherImage);
        let companyPrimaryVoucherImage;

        console.log('primaryVoucherImage test 1', primaryVoucherImage, primaryVoucherImageExtension);

        console.log('companyPrimaryVoucherImage', companyPrimaryVoucherImage);

        if (!companyPrimaryVoucherImage) {
          innerCompanyPromises.push(primaryVoucherImageReference.getDownloadURL().then((url) => {
            companyPrimaryVoucherImage = url;
            companyDataObject.companyPrimaryVoucherImage = url;
          }).catch((error) => {
            console.log('get image error', { error, primaryVoucherImage });
            return resolve();
          }));
        }
      }

      await Promise.all(innerCompanyPromises);

      dispatch({
        payload: { ...voucherData, id: voucher, companyData: companyDataObject, companyId, logo: companyDataObject.logoImage || null, primaryImage: companyDataObject.companyPrimaryVoucherImage || null },
        voucher,
        companyData: companyDataObject,
        type: GET_SINGLE_VOUCHER,
      });

      console.log('voucherData.company.id', voucherData.company.id, companyDataObject);

      if (auth.currentUser) {
        const vouchersOrder2 = firestore.collection(DB_TABLE_NAME.vouchersUnlocked);

        vouchersOrder2.where('uid', '==', auth.currentUser.uid).where('companyId', '==', companyId).get().then(async (querySnapshot) => {
          const promises = [];

          console.log('vouchersOrder222 list', list);

          querySnapshot.forEach(async (doc) => promises.push(new Promise(async (resolve2) => {
            const data = doc.data();

            console.log('vouchersOrder222 doc', doc.id, data);

            if (list && data && data.voucher && data.voucher.id && list[data.voucher.id]) {
              console.log('vouchersOrder222 voucher', data.voucher.id, list[data.voucher.id]);
            }
          })));
        });

        const vouchersOrder = firestore.collection(DB_TABLE_NAME.vouchersUnlocked);
        vouchersOrder.where('uid', '==', auth.currentUser.uid).where('voucher', '==', singleVoucher).get().then(async (querySnapshot) => {
          let payload = {};
          const promises = [];

          let singleVoucherPurchasedLevels = {};

          querySnapshot.forEach(async (doc) => promises.push(new Promise(async (resolve2) => {
            console.log('vouchersOrder doc', doc.id, doc.data());

            const { level, unlockedLevel } = doc.data();

            console.log('vouchersOrder level', level, unlockedLevel);

            // level === voucherId
            // unlockedLevel === voucherStep + 1
            if ((level || level === 0) && (unlockedLevel)) {
              if (singleVoucherPurchasedLevels[unlockedLevel - 1]) {
                singleVoucherPurchasedLevels[unlockedLevel - 1][level] = true;
              } else {
                singleVoucherPurchasedLevels[unlockedLevel - 1] = { [level]: true };
              }
            }

            return resolve2();
          })));

          await Promise.all(promises);

          console.log('singleVoucherPurchasedLevels', singleVoucherPurchasedLevels);

          dispatch({
            payload: singleVoucherPurchasedLevels,
            voucher,
            type: GET_SINGLE_VOUCHER_PURCHASED_LEVELS,
          });

          return resolve({
            payload: { ...voucherData, id: voucher, companyData: companyDataObject, companyId, logo: companyDataObject.logoImage || null, primaryImage: companyDataObject.companyPrimaryVoucherImage || null },
            voucher,
            companyData: companyDataObject,
            type: GET_SINGLE_VOUCHER,
          });
        });
      } else {
        return resolve({
          payload: { ...voucherData, id: voucher, companyData: companyDataObject, companyId, logo: companyDataObject.logoImage || null, primaryImage: companyDataObject.companyPrimaryVoucherImage || null },
          voucher,
          companyData: companyDataObject,
          type: GET_SINGLE_VOUCHER,
        });
      }
    }).catch(err => {
      console.log('companyData err', err);
    });
  });
});

export const getGiftingPrice = () => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('getGiftingPrice');

  const giftingPriceRef = firestore.collection('giftingPrice').doc('giftingPrice');

  giftingPriceRef.get().then((payload) => {
    const giftingPriceData = payload.data();

    console.log('getGiftingPrice giftingPriceData', giftingPriceData);

          dispatch({
            payload: giftingPriceData.giftingPrice || null,
            type: GET_GIFTING_PRICE,
          });

          return resolve({
            payload: giftingPriceData.giftingPrice || null,
            type: GET_GIFTING_PRICE,
          });
  });
});

export const getVoucherList = (onSuccess, activeCategory) => async (dispatch, getState) => new Promise(async (resolve) => {
  // const filters = getState().filters;

  // dispatch({
  //   type: SET_VOUCHER_LIST_FETCHING,
  //   payload: true,
  // });

  const date = Date.now();

  console.log('getVoucherList', activeCategory, auth.currentUser);

  // const checkCompanyStatusFunction = functions.httpsCallable('checkCompanyStatus');
  // await checkCompanyStatusFunction({ companyId: 'H43GwviDHNa2qp3ElAizKkqyDzL2' })

  const companiesList = {};

  const companies = firestore.collection(DB_TABLE_NAME.companies);
  const owners = firestore.collection('owners');
  const vouchersOrder = firestore.collection(DB_TABLE_NAME.orders);

  const promise1 = owners.get();
  const promise2 = companies.get();
  let promise3 = new Promise(resolve => resolve(null));
  let promise4 = new Promise(resolve => resolve(null));
  let promise5 = new Promise(resolve => resolve(null));

  console.log('auth.currentUser', auth.currentUser);

  if (auth.currentUser && auth.currentUser.uid) {
    promise3 = vouchersOrder.where('uid', '==', auth.currentUser.uid).get();

    console.log('has auth user');
    const voucherBoughtByUser = firestore.collection(DB_TABLE_NAME.vouchersBoughtByUser).doc(auth.currentUser.uid);

    promise4 = voucherBoughtByUser.collection('vouchers').get();

    promise5 = firestore.collection(DB_TABLE_NAME.appClients).doc(auth.currentUser.uid).get();
  }

  let vouchersRef = firestore.collection(DB_TABLE_NAME.vouchers).where('isActive', '==', true).where('frozen', '==', false).limit(1000);

  if (activeCategory) {
    const cat = firestore.collection(DB_TABLE_NAME.categories).doc(activeCategory);

    vouchersRef = firestore.collection(DB_TABLE_NAME.vouchers).where('frozen', '==', false).where('isActive', '==', true)
      .where('category', 'array-contains-any', [cat])
    // .orderBy(filters.sortValues.sort, filters.sortValues.sortMethod);
  }

  const promise6 = vouchersRef.get();

  Promise.all([promise1, promise2, promise3, promise4, promise5, promise6]).then(async (promisesRes) => {

    let querySnapshotOwners = promisesRes[0];
    let querySnapshot = promisesRes[1];
    let orders = promisesRes[2];
    let allUserVouchersFullyBought = promisesRes[3];
    let userSetting = promisesRes[4];
    let vouchers2 = promisesRes[5];

    console.log('userSetting', userSetting);

    console.log('date test 1', date - Date.now(), allUserVouchersFullyBought, orders, vouchers2);

    querySnapshotOwners.forEach((doc) => {
      companiesList[doc.id] = { ...doc.data() };
    });

    console.log('date test 2', date - Date.now());

    querySnapshot.forEach((doc) => {
      // companiesList[doc.id] = { ...doc.data() };
      companiesList[doc.id] = {
        ...companiesList[doc.id], ...companiesList[doc.id], ...doc.data(),
      };
    });

    console.log('companiesListcompaniesList', companiesList);

    let companyPromises = [];

    Object.keys(companiesList).map((key) => companyPromises.push(new Promise(async (resolve) => {
      const innerCompanyPromises = [];

      const { primaryVoucherImage, logo } = companiesList[key];

      if (logo) {
        const logoPath = logo.split('.');
        const logoExtension = logoPath[1];
        const logoReference = storage.ref(logo);
        let logoImage;

        console.log('logo test 1', logo, logoExtension);

        /* if (logoExtension === '.png' || logoExtension === '.jpg' || logoExtension === '.jpeg' || logoExtension === '.svg') {
          const minifiedImageReference = storage.ref(`companies/${key}/${key}-min.${logoExtension}`);
          innerCompanyPromises.push(minifiedImageReference.getDownloadURL().then((url) => {
            logoImage = url;
            companiesList[key].logoImage = url;
          }).catch((error) => {
            console.log('get minified image error', { error });
            return resolve();
          }));
        } else { */
        innerCompanyPromises.push(logoReference.getDownloadURL().then((url) => {
          logoImage = url;
          companiesList[key].logoImage = url;
        }).catch((error) => {
          console.log('get image error', { error, logo });
          return resolve();
        }));
        // }
      }

      if (primaryVoucherImage) {
        const primaryVoucherImagePath = primaryVoucherImage.split('.');
        const primaryVoucherImageExtension = primaryVoucherImagePath[1];
        const primaryVoucherImageReference = storage.ref(primaryVoucherImage);
        let companyPrimaryVoucherImage;

        console.log('primaryVoucherImage test 1', primaryVoucherImage, primaryVoucherImageExtension);

        /* if (primaryVoucherImageExtension === '.png' || primaryVoucherImageExtension === '.jpg' || primaryVoucherImageExtension === '.jpeg' || primaryVoucherImageExtension === '.svg') {
            const minifiedImageReference = storage.ref(`vouchers/${key}/primary-min.${primaryVoucherImageExtension}`);
 
            console.log('get minified image from ', `vouchers/${key}/primary-min.${primaryVoucherImageExtension}`);
            innerCompanyPromises.push(minifiedImageReference.getDownloadURL().then((url) => {
              console.log('minified url', url);
              companyPrimaryVoucherImage = url;
              companiesList[key].companyPrimaryVoucherImage = url;
            }).catch((error) => {
              console.log('get minified image error', { error, primaryVoucherImage });
              return resolve();
            }));
          } */

        console.log('companyPrimaryVoucherImage', companyPrimaryVoucherImage);

        if (!companyPrimaryVoucherImage) {
          innerCompanyPromises.push(primaryVoucherImageReference.getDownloadURL().then((url) => {
            companyPrimaryVoucherImage = url;
            companiesList[key].companyPrimaryVoucherImage = url;
          }).catch((error) => {
            console.log('get image error', { error, primaryVoucherImage });
            return resolve();
          }));
        }
      }

      Promise.all(innerCompanyPromises).then(() => {
        console.log('innerCompanyPromises then');
        return resolve();
      }).catch((err) => {
        console.log('innerCompanyPromises err', err);
        return resolve();
      });
    })));

    console.log('date test 3', date - Date.now());

    await Promise.allSettled(companyPromises);

    console.log('date test 4', date - Date.now());

    console.log('all companies settled', companiesList);

    dispatch({
      type: CUSTOMER_GET_VOUCHER_COMPANIES,
      payload: companiesList,
    });

    let vouchers = firestore
      .collection(DB_TABLE_NAME.vouchers)
      .where('isActive', '==', true)
      .where('frozen', '==', false)
      .limit(1000);

    if (activeCategory && activeCategory === 'unlocked') {
      return dispatch(getUnlockedVouchers());
    }

    console.log('test 111');

    console.log('date test 5', date - Date.now());

    let userOrders = {};
    // let userSetting = null;
    // let allUserVouchersFullyBought = null;

    if (auth.currentUser && auth.currentUser.uid) {
      //   const orders = await vouchersOrder.where('uid', '==', auth.currentUser.uid).get();

      console.log('is logged in, orders', orders);
      if (orders) {
        orders.docs.map((doc, index) => {
          const docData = doc.data();
          const id = doc.id;

          userOrders[id] = docData;
        });

        for (const doc of orders.docs) {
          const document = doc.data();

          userOrders = {
            ...userOrders,
            [document.voucher.id]: {
              ...get(userOrders, document.voucher.id, {}),
              [document.voucherStep]: {
                ...get(userOrders, [document.voucher.id, document.voucherStep], {}),
                [document.voucherId]: true,
              },
            },
          };
        }
      } else {
        console.log('no orders');
      }

      console.log('userOrders', userOrders);

      const voucherBoughtByUser = firestore.collection(DB_TABLE_NAME.vouchersBoughtByUser).doc(auth.currentUser.uid);

      //  allUserVouchersFullyBought = await voucherBoughtByUser.collection('vouchers').get();

      console.log('date test 8', date - Date.now());

    //  const userUid = auth.currentUser.uid;
   //   userSetting = await firestore
    //    .collection(DB_TABLE_NAME.appClients)
    //    .doc(userUid)
    //    .get();
    }

    console.log('allUserVouchersFullyBought', allUserVouchersFullyBought);

    let allUserVouchersFullyBoughtList = {};

    if (allUserVouchersFullyBought) {
      allUserVouchersFullyBought.forEach((doc) => {
        allUserVouchersFullyBoughtList = {
          ...allUserVouchersFullyBoughtList,
          [doc.id]: doc.data(),
        };
      });
    }

    console.log('test 666', allUserVouchersFullyBoughtList);

    if (activeCategory) {
      const cat = firestore.collection(DB_TABLE_NAME.categories).doc(activeCategory);

      vouchers = firestore
        .collection(DB_TABLE_NAME.vouchers)
        .where('frozen', '==', false)
        .where('isActive', '==', true)
        .where('category', 'array-contains-any', [cat])
      // .orderBy(filters.sortValues.sort, filters.sortValues.sortMethod);
    }

    const promises = [];

    console.log('date test 9', date - Date.now(), vouchers2);

    //  vouchers.get().then((querySnapshot) => {
    let payload = {};
    vouchers2.forEach(async (doc) => promises.push(new Promise(async (resolve2) => {
      const voucherData = doc.data();

      const smallerVoucher = voucherData.availableVouchers[0];
      const categories = voucherData.category;

      console.log('voucherdata categories', categories);

      const categoryIds = [];
      if (categories) {
        categories.map(cat => {
          if (cat && cat.id) {
            categoryIds.push(cat.id);
          }
        })
      }

      console.log('categoryIds', categoryIds);

      const company = voucherData.company;

      console.log('vouchers company', company.id, companiesList[company.id]);

      let companyPrimaryVoucherImage = companiesList[company.id].companyPrimaryVoucherImage;
      let logoImage = companiesList[company.id].logoImage;

      const canBuyVoucherArray = canBuyVoucher(voucherData, get(userOrders, [doc.id]));

      console.log('canBuyVoucherArray', canBuyVoucherArray);

      let hide = false;
      /* 
      forEach(canBuyVoucherArray, (v) => {
        forEach(v, (va) => {
          if (va.status !== 3 && va.status !== 2) {
            hide = false;
          }
        });
      }); */

      if (hide) {
        return resolve2();
      }

      if (voucherData.salesValueLeft1Step < smallerVoucher && !get(userOrders, [doc.id, 1])) {
        return resolve2();
      }

      if (voucherData.salesValueLeft2Step < smallerVoucher && voucherData.salesValueLeft1Step < smallerVoucher && voucherData.salesValueLeft3Step < smallerVoucher) {
        return resolve2();
      }

      if (voucherData.salesValueLeft2Step < smallerVoucher && !get(userOrders, [doc.id, 2])) {
        return resolve2();
      }

      if (voucherData.testMode) {
        if (!userSetting || !userSetting.data() || !userSetting.data().tester) {
          return resolve2();
        }
      }

      console.log('allUserVouchersFullyBoughtList', allUserVouchersFullyBoughtList, voucherData.availableVouchers, voucherData.salesValueLeft1Step, voucherData.salesValueLeft2Step, voucherData.salesValueLeft3Step);

      if (!allUserVouchersFullyBoughtList[doc.id] || allUserVouchersFullyBoughtList[doc.id].countBought < 9) {
        if (voucherData.availableVouchers[0] <= voucherData.salesValueLeft1Step ||
          voucherData.availableVouchers[0] <= voucherData.salesValueLeft2Step ||
          voucherData.availableVouchers[0] <= voucherData.salesValueLeft3Step
        ) {
          payload = { ...payload, [doc.id]: { ...voucherData, categoryIds, companyId: company.id, logo: logoImage, primaryImage: companyPrimaryVoucherImage } };
          console.log('add to payload', payload);
        }
      }

      return resolve2();
    })));
    if (onSuccess) {
      onSuccess();
    }

    Promise.all(promises).then(() => {
      console.log('payload', payload);

      console.log('date test A', date - Date.now());

      let payloadArr = [];

      Object.keys(payload).map((key, index) => {
        payloadArr.push({ ...payload[key], key });
      });

      console.log('[...payloadArr].sort((a, b) => a.salesCount - b.salesCount)', [...payloadArr].sort((a, b) => (b.salesCount || 0) - (a.salesCount || 0)));

      const mostPopular = [...payloadArr].sort((a, b) => (b.salesCount || 0) - (a.salesCount || 0)).slice(0, 5);
        const newest = [...payloadArr].sort((a, b) => b.created_at.seconds - a.created_at.seconds).slice(0, 5);
        const featured = [...payloadArr].filter((a) => a.featured === true);

      console.log('mostPopular', mostPopular);

        dispatch({
          type: CUSTOMER_GET_VOUCHER_LIST,
          payload,
          mostPopular,
          newest,
          featured,
        });
        return resolve();
      }).catch((err) => {
        console.log('promises err', err);
        return resolve();
      });

   // })
   //   .catch((err) => {
   //     console.log(err);
    //    return resolve();
    //  });
  });
  // });
});

export const getVoucherCategories = () => (dispatch) => {
  console.log('getVoucherCategories', DB_TABLE_NAME.categories);

  const vouchers = firestore.collection(DB_TABLE_NAME.categories);
  vouchers
    .where('enabled', '==', true)
    .orderBy('order', 'asc')
    .get()
    .then(function (querySnapshot) {
      let payload = {};
      const promises = [];

      console.log('getVoucherCategories then');

      querySnapshot.forEach((doc) => promises.push(new Promise(async (resolve2) => {
        const docData = doc.data();
        // payload = { ...payload, [doc.id]: { ...docData, id: doc.id } };

        storage.ref(docData.icon).getDownloadURL().then(async (url) => {
          //  const response = await fetch(url);
          //  const svg = await response.text();

          payload = { ...payload, [doc.id]: { ...docData, id: doc.id, imageUrl: url } };

          return resolve2();
          // saveCategoryData(docData.icon, svg);
        }).catch(() => {
          return resolve2();
        });
      })));

      Promise.all(promises).then(() => {
        dispatch({
          type: CUSTOMER_GET_VOUCHER_CATEGORIES,
          payload,
        });
      });

    });
};

export const getVoucherCompanies = () => (dispatch) => new Promise(async (resolve, reject) => {
  const companies = firestore.collection(DB_TABLE_NAME.companies);
  const owners = firestore.collection('owners');

  console.log('getVoucherCompanies', getVoucherCompanies);

  owners.get().then((querySnapshotOwners) => {
    let payload = {};
    querySnapshotOwners.forEach((doc) => {
      payload[doc.id] = doc.data();
    });

    companies.get().then((querySnapshot) => {
      // let payload = {};
      querySnapshot.forEach((doc) => {
        payload[doc.id] = {
          ...payload[doc.id], ...doc.data(),
        };
      });

      console.log('getVoucherCompanies payload', payload);

      dispatch({
        type: CUSTOMER_GET_VOUCHER_COMPANIES,
        payload,
      });

      return resolve();
    }).catch(() => resolve());
  }).catch(() => resolve());
});

export const getVoucherOrders = (voucherId, onSuccess) => (dispatch) => {
  //  dispatch({
  //    type: SET_VOUCHER_ORDERS_FETCHING,
  //    payload: true,
  //  });
  const vouchersOrder = firestore.collection(DB_TABLE_NAME.orders);
  const vouchersDoc = firestore
    .collection(DB_TABLE_NAME.vouchers)
    .doc(voucherId);
  vouchersOrder
    .where('uid', '==', auth.currentUser.uid)
    .where('voucher', '==', vouchersDoc)
    .get()
    .then(function (querySnapshot) {
      let payload = {};
      querySnapshot.forEach(function (doc) {
        const document = doc.data();
        payload = {
          ...payload,
          [document.voucherId]: {
            ...get(payload, document.voucherId, {}),
            [document.voucherStep]: document,
          },
        };
      });
      dispatch({
        type: GET_VOUCHER_ORDERS,
        payload,
      });
      //  dispatch({
      //    type: SET_VOUCHER_ORDERS_FETCHING,
      //    payload: false,
      //  });
      if (onSuccess) {
        onSuccess();
      }
    });
};

export const getUnlockedVouchers = () => (dispatch, getState) => new Promise(async (resolve) => {
  console.log('getUnlockedVouchers');

  const { customer } = getState();

  const { companies, list } = customer;
  const companiesList = companies;

  console.log('getUnlockedVouchers customer', customer, list);

  if (!auth.currentUser || !auth.currentUser.uid) {
    dispatch({
      type: CUSTOMER_GET_VOUCHER_LIST_UNLOCKED,
      payload: {},
      mostPopular: [],
      newest: [],
      featured: [],
    });

    return resolve({});
  }

  //  "RfEjOqOKlfcRrrKkhI9tTeFeHSy2" ||
  const uid = auth.currentUser.uid;

  const vouchersOrder = firestore.collection(DB_TABLE_NAME.vouchersUnlocked);
  vouchersOrder.where('uid', '==', uid).get().then(async (querySnapshot) => {
    let payload = {};
    const promises = [];

    querySnapshot.forEach(async (doc) => promises.push(new Promise(async (resolve2) => {
      const document = doc.data();
      const { voucher } = document;
      let voucherData;
      let companyPrimaryVoucherImage;
      let logoImage;
      let categoryIds = [];

      console.log('getUnlockedVouchers list[voucher.id] 1', voucher, voucher.id, list)

      if (list[voucher.id]) {
        console.log('getUnlockedVouchers list[voucher.id] 2', list[voucher.id]);

        voucherData = list[voucher.id];
        //  categoryIds = voucherData.categoryIds;
        //  companyPrimaryVoucherImage = voucherData.companyPrimaryVoucher;
        //  logoImage = voucherData.logo;

        payload = {
          ...payload,
          [`${document.voucher.id}_${document.level}`]: {
            ...document,
            ...voucherData,
            //  categoryIds,
            //  primaryVoucherImage: companyPrimaryVoucherImage,
            //  logo: logoImage,
            voucherId: document.voucher.id,
          },
        };
      } /* else {
          const voucherDoc = await voucher.get();
          if (!voucherDoc.exists) {
            voucherData = {};
          } else {
            voucherData = voucherDoc.data();
          }
          console.log('getUnlockedVouchers document', document, voucherData);

          const categories = voucherData.category;

          if (categories) {
            categories.map(cat => {
              if (cat && cat.id) {
                categoryIds.push(cat.id);
              }
            })
          }

          console.log('categoryIds', categoryIds);

          const company = voucherData.company;

          console.log('vouchers company', company.id, companiesList[company.id]);

          const { primaryVoucherImage, logo } = companiesList[company.id];

          const primaryVoucherImageReference = storage.ref(primaryVoucherImage);
          const logoReference = storage.ref(logo);

          console.log('primaryVoucherImage, logo', primaryVoucherImage, logo);

          if (primaryVoucherImage && logo) {
            await primaryVoucherImageReference.getDownloadURL().then((url) => {
              companyPrimaryVoucherImage = url;
            }).catch((error) => {
              console.log('get image error', { error, primaryVoucherImage });
            });

            console.log('test cccc');

            await logoReference.getDownloadURL().then((url) => {
              logoImage = url;
            }).catch((error) => {
              console.log('get image error', { error, logo });
            });
          }

          payload = {
          ...payload,
          [`${document.voucher.id}_${document.level}`]: {
            ...document,
            ...voucherData,
          //  categoryIds,
          //  primaryVoucherImage: companyPrimaryVoucherImage,
          //  logo: logoImage,
            voucherId: document.voucher.id,
          },
        };
        } */

      return resolve2();
    })));
    console.log('getUnlockedVouchers test 111');

    await Promise.allSettled(promises);

    console.log('getUnlockedVouchers test 222');

    let payloadArr = [];

    Object.keys(payload).map((key, index) => {
      payloadArr.push({ ...payload[key], key });
    });

    console.log('getUnlockedVouchers data', payload);

    const newest = [...payloadArr].sort((a, b) => b.created_at.seconds - a.created_at.seconds).slice(0, 4);
    const mostPopular = [...payloadArr].sort((a, b) => a.salesCount - b.salesCount).slice(0, 4);
    const featured = [...payloadArr].filter((a) => a.featured === true);

    dispatch({
      type: CUSTOMER_GET_VOUCHER_LIST_UNLOCKED,
      payload,
      mostPopular,
      newest,
      featured,
    });

    return resolve(payload);
  });
});

export const getOrderedVouchers = (usedVouchers = false) => (dispatch) => new Promise(async (resolve) => {
  if (!auth.currentUser || !auth.currentUser.uid) {
    dispatch({
      type: GET_USER_VOUCHERS,
      payload: {},
    });

    return resolve({});
  }

  // "RfEjOqOKlfcRrrKkhI9tTeFeHSy2" || 
  const uid = auth.currentUser.uid;

  console.log('getOrderedVouchers');
  const vouchersOrder = firestore.collection(DB_TABLE_NAME.orders);
  vouchersOrder
    .where('uid', '==', uid)
    // .where('used', '==', usedVouchers)
    // .where('buyFor', '==', 'self')
    .orderBy('paymentDate', 'desc')
    .get()
    .then(async (querySnapshot) => {
      let payload = {};
      const promises = [];

      let myVouchers = 0;
      let giftedVouhcers = 0;
      let usedVouchers = 0;

      querySnapshot.forEach(async (doc) => promises.push(new Promise(async (resolve2) => {
        const document = doc.data();
        const { voucher, company } = document;

        let voucherData;
        let companyId = company.id;

        const voucherDoc = await voucher.get();
        if (!voucherDoc.exists) {
          voucherData = {};
        } else {
          voucherData = voucherDoc.data();
        }

        const { used, buyFor, canceled } = document;

        if (buyFor === 'self') {
          myVouchers = myVouchers + 1;
        }

        if (buyFor === 'gift') {
          giftedVouhcers = giftedVouhcers + 1;
        }

        if (used && !canceled) {
          usedVouchers = usedVouchers + 1;
        }

        payload = {
          ...payload,
          [`${document.voucher.id}_${document.code}`]: {
            ...document,
            voucherData,
            companyId,
            id: doc.id,
          },
        };
        return resolve2();
      })));

      await Promise.allSettled(promises);

      console.log('getOrderedVouchers data', payload);

      dispatch({
        type: GET_USER_VOUCHERS,
        payload,
        myVouchersCount: myVouchers,
        giftedVouhcersCount: giftedVouhcers,
        usedVouchersCount: usedVouchers,
      });

      return resolve(payload);
    });
});

export const getTemplateInfo = (template) => (dispatch) => {
  const templateOrdered = firestore
    .collection(DB_TABLE_NAME.templatesOrdered)
    .doc(template);
  templateOrdered.get().then((t) => {
    dispatch({
      type: GET_ORDERED_TEMPLATE,
      payload: t.data(),
    });
  });
};

export const getOrderInfo = (id, onSuccess) => (dispatch) => {
  const vouchersOrder = firestore.collection(DB_TABLE_NAME.orders).doc(id);
  vouchersOrder.get().then((orderData) => {
    const ratingsData = firestore.collection(DB_TABLE_NAME.ordersRating);

    ratingsData
      .where('order', '==', vouchersOrder)
      .where('uid', '==', auth.currentUser.uid)
      .get()
      .then(async (snapshot) => {
        let rating = 0;

        snapshot.forEach((r) => {
          if (r.data().rating > 0) {
            rating = r.data().rating;
          }
        });

        const codeStatus = functions.httpsCallable('checkVouchedCodeStatus');

        dispatch({
          type: GET_SINGLE_ORDER,
          payload: {
            ...orderData.data(),
            id,
            rating,
          },
        });
        if (onSuccess) {
          onSuccess();
        }
      });
  });
};

export const postOrderRating = (order, rating) => (dispatch) => {
  const ordersDoc = firestore.collection(DB_TABLE_NAME.orders).doc(order);
  const ordersRating = firestore.collection(DB_TABLE_NAME.ordersRating);
  ordersRating
    .add({
      order: ordersDoc,
      uid: auth.currentUser.uid,
      rating,
    })
    .then();
};

export const submitPayment =
  (voucher, voucherId, voucherStep, onSuccess) => (dispatch) => {
    const vouchersDoc = firestore
      .collection(DB_TABLE_NAME.vouchers)
      .doc(voucher);
    const orders = firestore.collection(DB_TABLE_NAME.orders);
    orders
      .add({
        voucher: vouchersDoc,
        voucherStep: Number(voucherStep),
        voucherId: Number(voucherId),
        uid: auth.currentUser.uid,
      })
      .then(dispatch(getVoucherOrders(voucher, onSuccess)));
  };

export const receiveGift = (code, onSuccess) => () => {
  const receiveGiftF = functions.httpsCallable('receiveGift');

  receiveGiftF({ code: code, uid: auth.currentUser.uid })
    .then(onSuccess)
    .catch((err) => {
      console.log(err);
    });
};

export const addGiftingDetails = (payload) => ({
  type: ADD_GIFTING_DATA,
  payload,
});







export const removePaymentData = (payload) => (dispatch) =>
  dispatch({
    type: REMOVE_PAYMENT_DATA,
    payload: {},
  });

export const savePaymentData = (payload) => (dispatch) =>
  dispatch({
    type: SAVE_PAYMENT_DATA,
    payload,
  });

export const companyOffline = (companyId) => async (dispatch, getState) => new Promise(async (resolve, reject) => {
  console.log('companyOffline', companyId);

  const { customer } = getState();

  console.log('customer', customer);

  const companyOfflineFunc = functions.httpsCallable('companyOffline');
  const companyOfflineRes = await companyOfflineFunc({ companyId });
    
  const resData = companyOfflineRes.data;

  console.log('currentStatus resData', resData);

  if (resData && resData.data && resData.data.currentStatus) {
    return resolve({ status: resData.data.currentStatus });
  } else {
    return resolve({ status: 'offline' });
  }
});

export const doPayment = (paymentData, voucherData, templateOrdered) => async (dispatch, getState) => new Promise(async (resolve, reject) => {
  const userUid = (auth && auth.currentUser) ? auth.currentUser.uid : null;
  // const orders = firestore.collection('payments').doc(userUid);

  console.log('doPayment', paymentData, voucherData, templateOrdered);

  const { customer } = getState();

  console.log('customer', customer);

  let giftingPrice = 0;

  if (customer) {
    giftingPrice = customer.giftingPrice || 0;
  }

  console.log('giftingPrice', giftingPrice);

  // giftingPrice, voucherPrice

  let source;

  if (paymentData && paymentData.card) {
    const createToken = functions.httpsCallable('stripeCreateCardSource');
    const sourceData = await createToken(paymentData);
    source = sourceData.data;
  }

  console.log('doPayment source', source);

  let templateData = null;
  if (templateOrdered) {
    templateData = firestore.collection(DB_TABLE_NAME.templatesOrdered).doc(templateOrdered);
  }

  console.log('doPayment templateData', templateData);

  // TO DO call paymentSheet with data { voucherId, voucherStep, price, voucherDocumentId, giftingData, buyFor, giftingPrice, voucherPrice } to create payment intent

  const createPaymentSheet = functions.httpsCallable('paymentSheetWeb');

  if (!voucherData && !paymentData) {
    const createPaymentSheetResp = await createPaymentSheet({});

    return resolve({ status: 'success', ...createPaymentSheetResp.data });
  } else {
    const createPaymentSheetResp = await createPaymentSheet({
      voucherId: voucherData.voucherId,
      voucherStep: voucherData.voucherStep,
      price: paymentData.amount,
      voucherDocumentId: voucherData.voucher,
      giftingData: voucherData.buyFor === 'gift' ? voucherData.giftingData : null, // : voucherData.giftingData,
      buyFor: voucherData.buyFor,
      giftingPrice: voucherData.buyFor === 'gift' ? giftingPrice : null,
      voucherPrice: paymentData.voucherAmount || paymentData.amount,
    });

    console.log('createPaymentSheetResp', createPaymentSheetResp);

    dispatch({
      type: GET_PAYMENT_PROGRESS,
      payload: { id: createPaymentSheetResp.clientSecret },
    });

    if (createPaymentSheetResp) {
      if (createPaymentSheetResp.data) {
        return resolve({ status: 'success', ...createPaymentSheetResp.data });
      }
      return resolve({ status: 'success', ...createPaymentSheetResp });
    } else {
      return resolve();
    }
  }
  /*
  orders.collection('payments').add({
    templateOrdered: templateData,
    amount: paymentData.amount,
    currency: paymentData.currency,
    userUid,
    voucherData,
    source: source || null,
  }).then((data) => {
    console.log('doPayment document', data);
    dispatch({
      type: GET_PAYMENT_PROGRESS,
      payload: { id: data.id },
    });
  }).catch((err) => {
    console.log('doPayment error with document creation', err);
  }); */
});

export const getPaymentStatus = (paymentId) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('getPaymentStatus paymentId', paymentId);
  if (!auth || !auth.currentUser || !auth.currentUser.uid) {
    return resolve();
  }

  const userUid = auth.currentUser.uid;

  console.log('getPaymentStatus userUid', userUid);

  const payments = firestore.collection(DB_TABLE_NAME.payments).doc(userUid);
  // dispatch(setIsFetching(true));

  payments.collection('payments').where('paymentId', '==', paymentId).get().then((data) => {
    if (!data.docs) {
      return resolve();
    }

    let paymentDoc = null;
    let docId = null;

    data.docs.map((doc, index) => {
      paymentDoc = doc.data();
      docId = doc.id;
    });

    // const doc = data.data();
    console.log('getPaymentStatus doc', paymentDoc, docId);

    if (paymentDoc && (paymentDoc.status || paymentDoc.response || paymentDoc.error)) {
      console.log('call GET_PAYMENT_PROGRESS', paymentDoc, docId);

      dispatch({
        type: GET_PAYMENT_PROGRESS,
        payload: { id: docId, ...paymentDoc },
      });

      return resolve();

      // dispatch(setIsFetching(false));
    } else {
      // dispatch(getPaymentStatus(paymentId));

      return resolve();
    }
  });
});




export const loginAppUser = ({ email, password }) => (dispatch) => new Promise(async (resolve, reject) => {
  // dispatch(setIsFetching(true));

  console.log('loginAppUser', email, password);

  auth.signInWithEmailAndPassword(email, password).then((res) => {
    const userDetails = res && res.user ? res.user : null;

    console.log('userDetails', userDetails);

    if (userDetails.emailVerified === false) {
      const sendVerificationEmail = functions.httpsCallable('sendVerificationEmail');

      sendVerificationEmail().then(async () => {
        return resolve();
      }).catch(() => {
        console.log('Verification email failed to send');
        return resolve();
      });

      // userDetails.sendEmailVerification().catch(() => console.log('Verification email failed to send'));

      console.log('Please verify your email.');
      return resolve();
      // dispatch(setIsFetching(false));
    } else {
      // dispatch();
      return resolve();
      // dispatch(setIsFetching(false));
    }
  })
    .catch((err) => {
      console.log(err.message);
      return resolve();
      // dispatch(setIsFetching(false));
    });
});


export const updateUserName = (uid, firstName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('updateUserName ', uid, firstName);
  const doc = firestore.collection(DB_TABLE_NAME.appClients).doc(uid).get();
  doc.then((payload) => {
    console.log('updateUserName updateUserName payload1', payload.exists);
    if (!payload.exists) {
      return resolve();
    }
    console.log('updateUserName updateUserName payload2', payload);

    if (payload) {
      const userSetting = firestore.collection(DB_TABLE_NAME.appClients).doc(uid);
      userSetting.update({ name: firstName }).then(resolve('success'));
    } else {
      return reject('Missing user data');
    }
  })
    .catch((err) => {
      return reject('Failed to update');
    });
});

export const updateUserEmail = (uid, email) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('updateUserEmail ', uid, email);

  const user = auth.currentUser;

  if (!uid || !email) return reject('missing data');

  const sendVerificationEmail = functions.httpsCallable('sendVerificationEmail');

  if (user) {
  //  auth.signInWithEmailAndPassword(email, oldPassword).then((signInRes) => {
      // console.log('updatePassword signInRes', signInRes);
    user.updateEmail(email).then(async (res) => {

      console.log('updateUserEmail res', res);

        sendVerificationEmail().then(async (res2) => {
          console.log('updateUserEmail sendVerificationEmail res2', res2);

          const userSetting = firestore.collection(DB_TABLE_NAME.appClients).doc(uid);
          userSetting.update({ email: email, emailLower: email }).then(() => {
            auth.signOut();

            return resolve('success');
          });

          // return resolve('success');

        }).catch((err) => {
          console.log(err);

          return reject('Failed to update email');
        })
      }).catch(err => {
        if (err && err.code === 'auth/requires-recent-login') {
          return reject('This requires recent authentication. Please log out and in, and then try again.');
        }

        return reject('Failed to update email');
      });
  //  })
  //    .catch((err) => {
  //      console.log(err.message);
   //     return resolve();
   //   });
  }
});

export const updateUserPassword = (email, oldPassword, newPassword) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('updateUserPassword', email, oldPassword, newPassword);
  const user = auth.currentUser;

  if (!newPassword || !oldPassword) return reject('missing data');

  if (user) {
    auth.signInWithEmailAndPassword(email, oldPassword).then((signInRes) => {
      console.log('updateUserPassword signInRes', signInRes);
      user.updatePassword(newPassword).then(async (res) => {
        console.log('updateUserPassword res', res);

        return resolve('success');
      }).catch(err => {
        if (err && err.code === 'auth/requires-recent-login') {
          return reject('This operation is sensitive and requires recent authentication. Log in again before retrying this request.');
        }

        return reject('Failed to update password');
      });
    })
      .catch((err) => {
        console.log(err.message);
        return reject(err.message);
        // dispatch(setIsFetching(false));
      });
  }
});


export const deleteAccount = () => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('deleteAccount');
  const user = auth.currentUser;

  if (user) {

    user.delete().then(() => {
      console.log('user deleted');
    }).catch((error) => {
      console.log('error deleting user', error);
    });

    return resolve('success');

    /* auth.signInWithEmailAndPassword(email, oldPassword).then((signInRes) => {
      console.log('updateUserPassword signInRes', signInRes);
      user.updatePassword(newPassword).then(async (res) => {
        console.log('updateUserPassword res', res);

        return resolve('success');
      }).catch(err => {
        if (err && err.code === 'auth/requires-recent-login') {
          return reject('This operation is sensitive and requires recent authentication. Log in again before retrying this request.');
        }

        return reject('Failed to update password');
      });
    })
      .catch((err) => {
        console.log(err.message);
        return reject(err.message);
        // dispatch(setIsFetching(false));
      }); */
  }
});


export const doSignup = (email, password, firstName) => (dispatch) => new Promise(async (resolve, reject) => {
  console.log('doSignup', email, password, firstName);

  auth.createUserWithEmailAndPassword(email, password).then((res) => {
    console.log('doSignup createUserWithEmailAndPassword res', res.user.uid);
    const sendVerificationEmail = functions.httpsCallable('sendVerificationEmail');

    sendVerificationEmail().then(async () => {
      // auth.signOut();
      // return resolve({ type: 'success', error: null });

      console.log('doSignup sendVerificationEmail res');

      await new Promise((timeoutResolve) => (setTimeout(() => timeoutResolve(), 600)));

      console.log('doSignup sendVerificationEmail res 2');

      const doc = firestore.collection(DB_TABLE_NAME.appClients).doc(res.user.uid).get();

      doc.then((payload) => {
        console.log('doSignup updateUserName payload1', payload.exists);
        if (!payload.exists) {
          auth.signOut();

          return resolve({ type: 'success', error: null })
        }
        console.log('doSignup updateUserName payload2');

        if (payload) {
          const userSetting = firestore.collection(DB_TABLE_NAME.appClients).doc(res.user.uid);
          userSetting.update({ name: firstName }).then(() => {
            auth.signOut();
            return resolve({ type: 'success', error: null });
          });
        } else {
          auth.signOut();

          return resolve({ type: 'success', error: null });
        }
      })
        .catch((err) => {
          auth.signOut();

          return resolve({ type: 'error', error: err })
        });

     /* dispatch(
        updateUserName(res.user.uid, firstName).then((res2) => {
          console.log('doSignup updateUserName res', res2);
          auth.signOut();
          return resolve({ type: 'success', error: null });
        }),
      ); */
    });
  })
    .catch((error) => {
      console.log('doSignup error', error);
      if (error.message.indexOf('[auth/weak-password]') !== -1) {
        console.log('Please choose a stronger password.');
      }
      if (error.message.indexOf('[auth/email-already-in-use]') !== -1) {
        console.log('Email is already in use');
      }
      return resolve({ type: 'error', error });
    });
});
